import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import {
  File,
  Plans,
  Orders,
  Designer,
  Clients,
  Finance,
  Team,
  Tools,
  Notification,
  Search,
  Dots,
  Tme,
  Mail,
  DesignTool,
  DbFeatures,
  GoogleLogo,
  AdditionalAppIcon,
  AdditionalInteractivePrototype,
  AdditionalStockPhotos,
  AdditionalCustomIllustrations,
  AdditionalUIResearch,
  USD,
  DDown,
  Close,
  Delete,
  Clear,
  AvatarAdd,
  Edit,
  PriceEdit,
  Plus,
  Minus,
  Add,
  Settings,
  Email,
  Telegram,
  Instagram,
  Facebook,
  CompanyLogo,
  SwiperPrev,
  SwiperNext,
  ExcelDownload,
  Invoice,
  FinancePaidOrders,
  FinanceUnpaidOrders,
  FinanceTotalOrders,
  Expand,
  Shrink,
  PaymentReportDonwload,
  PaymentContract,
  Atention,
  ChevronDown,
  checkboxIcon,
  checkboxOff,
  checkboxReadonly,
  plusLarge,
  deadline,
  ArrowRight,
  MobileVersion,
  CopyToClipboard,
  QuestionMark,
  Domain,
  Niches,
  Kanban,
  Draggable,
  Desktop,
  Mobile,
  Lock,
  OpenLock
} from '@/components/Svg/index'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#7552CC', // #E53935
        secondary: '#091849' // #FFCDD2
      },
      dark: {
        primary: '#7552CC', // #E53935
        secondary: '#091849' // #FFCDD2
      }
    }
  },
  icons: {
    values: {
      plans: {
        component: Plans
      },
      orders: {
        component: Orders
      },
      designer: {
        component: Designer
      },
      copyToClipboard: {
        component: CopyToClipboard
      },
      clients: {
        component: Clients
      },
      finance: {
        component: Finance
      },
      team: {
        component: Team
      },
      tools: {
        component: Tools
      },
      notification: {
        component: Notification
      },
      search: {
        component: Search
      },
      dots: {
        component: Dots
      },
      tme: {
        component: Tme
      },
      mail: {
        component: Mail
      },
      designTool: {
        component: DesignTool
      },
      dbFeatures: {
        component: DbFeatures
      },
      googleLogo: {
        component: GoogleLogo
      },
      appIcon: {
        component: AdditionalAppIcon
      },
      interactivePrototype: {
        component: AdditionalInteractivePrototype
      },
      stockPhotos: {
        component: AdditionalStockPhotos
      },
      customIllustrations: {
        component: AdditionalCustomIllustrations
      },
      uIResearch: {
        component: AdditionalUIResearch
      },
      usd: {
        component: USD
      },
      ddown: {
        component: DDown
      },
      close: {
        component: Close
      },
      delete: {
        component: Delete
      },
      clear: {
        component: Clear
      },
      avatarAdd: {
        component: AvatarAdd
      },
      edit: {
        component: Edit
      },
      priceEdit: {
        component: PriceEdit
      },
      plus: {
        component: Plus
      },
      minus: {
        component: Minus
      },
      add: {
        component: Add
      },
      settings: {
        component: Settings
      },
      email: {
        component: Email
      },
      telegram: {
        component: Telegram
      },
      instagram: {
        component: Instagram
      },
      facebook: {
        component: Facebook
      },
      companyLogo: {
        component: CompanyLogo
      },
      swiperPrev: {
        component: SwiperPrev
      },
      swiperNext: {
        component: SwiperNext
      },
      excelDownload: {
        component: ExcelDownload
      },
      invoice: {
        component: Invoice
      },
      financePaidOrders: {
        component: FinancePaidOrders
      },
      financeUnpaidOrders: {
        component: FinanceUnpaidOrders
      },
      financeTotalOrders: {
        component: FinanceTotalOrders
      },
      expand: {
        component: Expand
      },
      shrink: {
        component: Shrink
      },
      paymentReportDownload: {
        component: PaymentReportDonwload
      },
      paymentContract: {
        component: PaymentContract
      },
      atention: {
        component: Atention
      },
      chevronDown: {
        component: ChevronDown
      },
      checkboxIcon: {
        component: checkboxIcon
      },
      checkboxOff: {
        component: checkboxOff
      },
      checkboxReadonly: {
        component: checkboxReadonly
      },
      plusLarge: {
        component: plusLarge
      },
      deadline: {
        component: deadline
      },
      arrowRight: {
        component: ArrowRight
      },
      mobileVersion: {
        component: MobileVersion
      },
      questionMark: {
        component: QuestionMark
      },
      domain: {
        component: Domain
      },
      niches: {
        component: Niches
      },
      kanban: {
        component: Kanban
      },
      draggable: {
        component: Draggable
      },
      desktop: {
        component: Desktop
      },
      mobile: {
        component: Mobile
      },
      lock: {
        component: Lock
      },
      openLock: {
        component: OpenLock
      },
      file: {
        component: File
      }
    }
  }
})
