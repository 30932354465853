<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'
import gql from 'graphql-tag'

export default {
  name: 'App',
  components: { MainLayout, EmptyLayout },
  computed: {
    layout() {
      return this.$route.meta.layout || 'EmptyLayout'
    }
  },
  methods: {
    checkMe() {
      this.$store.getters.getMe ? this.me = this.$store.getters.getMe : this.getMe()
    },
    async getMe () {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getMe {
          auth {
            me {
              id
              email
              roles
              initials
              fullName
              designerUser {
                businessName
                avatarUrl
                id
                businessInitials
                roleRate {
                  occupation
                }
              }
            }
            permissions {
              clientele {
                clientRead
                clientWrite
                companyRead
                companyWrite
                id
                read
                edit
              }
              finance {
                edit
                id
                operationEdit
                operationRead
                read
              }
              orders {
                edit
                orderEdit
                orderRead
                read
                id
              }
              pricing {
                id
                discountEdit
                discountRead
                edit
                managementRateEdit
                managementRateRead
                paymentTypeEdit
                paymentTypeRead
                productPlanEdit
                productPlanRead
                projectRateEdit
                projectRateRead
                read
                roleRateEdit
                roleRateRead
                serviceEdit
                serviceRead
              }
              team {
                read
                edit
                designerRead
                designerEdit
                id
              }
              id
            }
          }
        }`
      }).then((data) => {
        if (data.data.auth.me === null) this.$router.push('/auth')
        else if (!data.data.auth.me.roles.includes('superuser') || !data.data.auth.me.roles.includes('staff')) {
          this.$store.commit('updateIsDesignerStatus')
          if (this.$route.path !== '/salary') this.$router.push('/salary')
        } else {
          this.me = data.data.auth.me
          this.me.permissions = data.data.auth.permissions
          this.$store.commit('updateMe', this.me)
        }
        this.loading = false
      }).catch((err) => {
        console.log(err)
        this.loading = false
        this.$router.push('/auth')
      })
    }
  },
  mounted() {
    this.checkMe()
  }
}
</script>

<style lang="scss">
</style>
