import Vue from 'vue'
import VueRouter from 'vue-router'
// import AuthRequired from '@/utils/AuthRequired'
function coockieCheck() {
  let cookies = document.cookie.split(';')
  cookies = cookies.map((el) => {
    const index = el.indexOf('=')
    return index > -1 ? el.substr(0, index).trim() : el.trim()
  })
  if (
    cookies.includes('csrftoken') ||
    window.location.origin === 'http://localhost:8080'
  ) { return true } else return false
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/plans'
  },
  {
    name: 'Plans & Pricing',
    path: '/plans',
    component: () => import('@/views/design/plans'),
    redirect: '/plans/productplan',
    children: [
      {
        name: 'Product Plan',
        path: 'productplan',
        component: () => import('@/views/design/plans/productplan/index'),
        meta: { layout: 'MainLayout' },
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      },
      {
        name: 'Additional Services',
        path: 'additionalservices',
        component: () => import('@/views/design/plans/additionalservices/index'),
        meta: { layout: 'MainLayout' },
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      },
      {
        name: 'Project Rates',
        path: 'projectrates',
        component: () => import('@/views/design/plans/projectrates/index'),
        meta: { layout: 'MainLayout' },
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      },
      {
        name: 'Fee & Payment Rates',
        path: 'paymentsrates',
        component: () => import('@/views/design/plans/paymentsrates/index'),
        meta: { layout: 'MainLayout' },
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      },
      {
        name: 'Disctounts',
        path: 'discounts',
        component: () => import('@/views/design/plans/discounts/index'),
        meta: { layout: 'MainLayout' },
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      },
      {
        name: 'Management Rates',
        path: 'managementrates',
        component: () => import('@/views/design/plans/managementrates/index'),
        meta: { layout: 'MainLayout' },
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      },
      {
        name: 'Company Work Rate',
        path: 'workrate',
        component: () => import('@/views/design/plans/workrate/index'),
        meta: { layout: 'MainLayout' },
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      }
    ]
  },
  {
    name: 'Orders Page',
    redirect: '/orders/table',
    path: '/orders',
    component: () => import('@/views/design/orders'),
    children: [
      {
        name: 'Orders',
        path: '/orders/table',
        props: true,
        meta: { layout: 'MainLayout' },
        component: () => import('@/views/design/orders/ordersTab'),
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      },
      {
        name: 'Projects',
        path: '/orders/projects',
        props: true,
        meta: { layout: 'MainLayout' },
        component: () => import('@/views/design/orders/ProjectTab'),
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      }
    ]
  },
  {
    name: 'Team',
    path: '/team',
    props: true,
    component: () => import('@/views/design/team'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Settings',
    path: '/settings',
    component: () => import('@/views/design/settings'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Salary',
    path: '/salary',
    component: () => import('@/views/design/salary'),
    meta: {
      layout: 'MainLayout',
      isDesigner: true
    },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Finance',
    path: '/finance',
    component: () => import('@/views/design/finance'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Clients',
    path: '/clients',
    component: () => import('@/views/design/clients'),
    redirect: '/clients/leads',
    children: [
      {
        name: 'Leads',
        path: 'leads',
        props: true,
        component: () => import('@/views/design/clients/leads/index'),
        meta: { layout: 'MainLayout' },
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      },
      {
        name: 'Companys',
        path: 'companys',
        props: true,
        component: () => import('@/views/design/clients/companys/index'),
        meta: { layout: 'MainLayout' },
        beforeEnter: function (to, prev, next) {
          if (coockieCheck()) next()
          else next('/auth/login')
        }
      }
    ]
  },
  {
    name: 'Statistics',
    path: '/statistics',
    props: true,
    component: () => import('@/views/design/statistics'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Designer Account',
    path: '/account',
    component: () => import('@/views/design/account'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: () => import('@/views/design/dashboard'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'My Profile',
    path: '/profile',
    props: true,
    component: () => import('@/views/design/myProfile'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Tools',
    path: '/tools',
    component: () => import('@/views/design/tools'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Niches',
    path: '/niches',
    component: () => import('@/views/design/niches'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Test Combine',
    path: '/kanban',
    component: () => import('@/views/design/kanban'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Domains',
    path: '/domains',
    component: () => import('@/views/design/domains'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  {
    name: 'Search',
    path: '/search',
    component: () => import('@/views/design/search'),
    meta: { layout: 'MainLayout' },
    beforeEnter: function (to, prev, next) {
      if (coockieCheck()) next()
      else next('/auth/login')
    }
  },
  //   ]
  // },
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/Auth'),
    redirect: '/auth/login',
    children: [
      { path: 'login', component: () => import(/* webpackChunkName: "user" */ '@/components/Auth/Signin') },
      { path: 'forgotpassword', component: () => import(/* webpackChunkName: "user" */ '@/components/Auth/ForgotPassword') }
    ]
  },
  {
    path: '/mailtemplate',
    component: () => import(/* webpackChunkName: "user" */ '@/views/design/mailtemplate/index')
  },
  { name: 'not-found', path: '*', component: () => import(/* webpackChunkName: "error" */ './') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
