<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="4" fill="#E3E6EF"/>
<path d="M14.4563 8.20634C14.8469 7.81582 14.8469 7.18265 14.4563 6.79213C14.0658 6.40161 13.4326 6.40161 13.0421 6.79213L14.4563 8.20634ZM8.74923 12.4992L8.04212 13.2063L8.74923 13.9134L9.45634 13.2063L8.74923 12.4992ZM6.95711 9.29289C6.56658 8.90237 5.93342 8.90237 5.54289 9.29289C5.15237 9.68342 5.15237 10.3166 5.54289 10.7071L6.95711 9.29289ZM13.0421 6.79213L8.04212 11.7921L9.45634 13.2063L14.4563 8.20634L13.0421 6.79213ZM9.45634 11.7921L6.95711 9.29289L5.54289 10.7071L8.04212 13.2063L9.45634 11.7921Z" fill="white"/>
</svg>

</template>

<script>
export default {
  name: 'checkboxReadonly'
}
</script>

<style scoped>

</style>
