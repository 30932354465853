<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.995 10.3305C4.995 7.38376 7.3838 4.99495 10.3306 4.99495H23.6694C26.6162 4.99495 29.005 7.38376 29.005 10.3305V23.6694C29.005 26.6161 26.6162 29.0049 23.6694 29.0049H10.3306C7.3838 29.0049 4.995 26.6161 4.995 23.6694V10.3305Z" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 22.3356L23.1172 16.2184C24.159 15.177 25.8477 15.177 26.8895 16.2184L29.005 18.334" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9975 12.9985C12.1025 12.9985 12.9983 12.1027 12.9983 10.9977C12.9983 9.89265 12.1025 8.99685 10.9975 8.99685C9.89245 8.99685 8.99664 9.89265 8.99664 10.9977C8.99664 12.1027 9.89245 12.9985 10.9975 12.9985Z" stroke="#010820" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.46588 25.8623L11.1082 20.22C12.15 19.1785 13.8387 19.1785 14.8805 20.22L23.44 28.7795" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
