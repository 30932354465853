import { render, staticRenderFns } from "./checkboxReadonly.vue?vue&type=template&id=58b45896&scoped=true&"
import script from "./checkboxReadonly.vue?vue&type=script&lang=js&"
export * from "./checkboxReadonly.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b45896",
  null
  
)

export default component.exports