<template>
  <div :class="disabled ? 'disabled' : ''">
    <v-text-field
      id="inputCurrencyTransparent1"
      outlined
      single-line
      :value="focus ? Math.round(value) : (currency !== '₽' ? currency : '') + (Math.round(value)) + (currency === '₽' ? currency : '')"
      @focus="focus = true && !readOnly"
      @blur="focus = false; newValue = ($event.target.value).replace(/\D/g, ''); Math.round(newValue) !== Math.round(value) ? dialog = true : dialog = false"
      class="rounded-lg font-weight-normal border-none"
      :class="classes"
      hide-details
      :readonly="readOnly"
      dense
    />
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="370"
      content-class="rounded-xl"
      overlay-color="rgba(9, 24, 73, 0.2)"
      overlay-opacity="1"
      v-model="dialog"
    >
      <template v-slot:default>
        <v-card class="pa-7">
          <v-card-text class="py-0">
            <div class="fz-18 text-center text-black">
              Do you really want to change {{ message }}?
              <br>
              <b>{{ Math.round(value) }} -> {{ newValue }}</b>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-space-between pa-0">
            <v-btn
              elevation="0"
              color="#FF0000"
              width="150"
              height="40"
              class="rounded-lg white--text text-none fz-16 fw-600"
              @click="dialog = false"
            >
              Return
            </v-btn>
            <v-btn
              class="rounded-lg text-none fz-16 fw-600"
              outlined
              width="150"
              height="40"
              @click="!readOnly && $emit('blur', newValue); dialog = false"
            >
              Set value
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'inputCurrencyTransparent',
  props: ['value', 'readOnly', 'currency', 'classes', 'message', 'disabled'],
  data() {
    return {
      focus: false,
      dialog: false,
      newValue: null
    }
  }
}
</script>

<style scoped>

</style>
