import Vue from 'vue'

export default Vue.component('AmountView', {
  props: {
    currency: {
      type: String,
      required: true
    }
  },
  render(h) {
    return h('span', [
      this.currency !== '₽' ? this.currency : null,
      this.$scopedSlots.default(),
      this.currency === '₽' ? this.currency : null
    ])
  }
})
