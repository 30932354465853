<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3638 5.63576C21.8788 9.15076 21.8788 14.8488 18.3638 18.3638C14.8488 21.8788 9.15076 21.8788 5.63576 18.3638C2.12076 14.8488 2.12076 9.15076 5.63576 5.63576C9.15076 2.12076 14.8488 2.12076 18.3638 5.63576" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3638 5.63576C21.8788 9.15076 21.8788 14.8488 18.3638 18.3638C14.8488 21.8788 9.15076 21.8788 5.63576 18.3638C2.12076 14.8488 2.12076 9.15076 5.63576 5.63576C9.15076 2.12076 14.8488 2.12076 18.3638 5.63576" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 7V14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.0246 11.9746L11.9996 13.9996L9.97461 11.9746" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.46387 15.5356C10.4169 17.4886 13.5819 17.4886 15.5349 15.5356" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'PaymentReportDonwload'
}
</script>

<style scoped>

</style>
