<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.228 9.222L10.005 13L17.68 5.325C16.1 2.735 13.256 1 10 1C5.029 1 1 5.029 1 10C1 14.971 5.029 19 10 19C14.632 19 18.443 15.5 18.941 11" stroke="#3BD599" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>
