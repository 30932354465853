<template>
  <vc-date-picker
    class="inline-block h-full"
    locale="en"
    :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
    :popover="{ visibility: disabled ? 'hidden' : 'click' }"
    :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
    mode="range"
    is-range
    :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
    v-model="date"
    @input="changeDate"
  >
    <template>
      <v-col
        class="px-2 py-1"
        :class="disabled ? '' : 'clickable'"
      >
        <template v-if="format === 'Standard'">
          {{ formatDate(item.correctDateStart ? item.correctDateStart : item.dateStartPrediction) }}
          ->
          {{ formatDate(item.expirationDate ? item.expirationDate : item.dateEndPrediction) }}
        </template>
        <template v-if="format === 'Extension'">
          {{ formatDateMonthToDay(item.correctDateStart ? item.correctDateStart : item.dateStartPrediction) }}
          ->
          {{ formatDateMonthToDay(item.expirationDate ? item.expirationDate : item.dateEndPrediction) }}
        </template>
      </v-col>
    </template>
  </vc-date-picker>
</template>

<script>
import TimeFormat from '@/mixins/TimeFormat'

export default {
  name: 'DateOneLine',
  mixins: [TimeFormat],
  props: {
    format: {
      type: String,
      default: 'Standard',
      validator: (value) => {
        return ['Standard', 'Extension'].includes(value)
      }
    },
    item: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: ''
    }
  },
  methods: {
    changeDate(date) {
      this.$emit('change', { id: this.item.id, start: date.start, end: date.end })
    }
  }
}
</script>

<style scoped>

</style>
