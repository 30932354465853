<template>
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49959 0.00291293L5.68412 0C3.6445 0 2.32641 1.35233 2.32641 3.44542V5.03399H0.501041C0.343307 5.03399 0.215576 5.16187 0.215576 5.3196V7.62126C0.215576 7.77899 0.343453 7.90672 0.501041 7.90672H2.32641V13.7145C2.32641 13.8723 2.45414 14 2.61188 14H4.99347C5.1512 14 5.27893 13.8721 5.27893 13.7145V7.90672H7.41322C7.57095 7.90672 7.69869 7.77899 7.69869 7.62126L7.69956 5.3196C7.69956 5.24386 7.66941 5.17133 7.61596 5.11773C7.56251 5.06414 7.48968 5.03399 7.41395 5.03399H5.27893V3.68734C5.27893 3.04008 5.43317 2.71151 6.27631 2.71151L7.4993 2.71107C7.65688 2.71107 7.78462 2.58319 7.78462 2.4256V0.288381C7.78462 0.130936 7.65703 0.00320423 7.49959 0.00291293Z" fill="#091849"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
