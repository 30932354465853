<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.247281 6.75099L3.47316 7.95499L4.72177 11.9705C4.80167 12.2277 5.11615 12.3228 5.32489 12.1521L7.12305 10.6862C7.31154 10.5326 7.58001 10.525 7.77699 10.668L11.0202 13.0226C11.2435 13.1849 11.5599 13.0626 11.6159 12.7927L13.9917 1.36449C14.0529 1.06974 13.7633 0.823858 13.4826 0.932405L0.243501 6.03965C-0.083212 6.16565 -0.0803654 6.62821 0.247281 6.75099ZM4.52055 7.31407L10.8251 3.43108C10.9384 3.3615 11.055 3.51471 10.9577 3.60496L5.7546 8.44149C5.57171 8.61173 5.45374 8.83956 5.42033 9.08685L5.24309 10.4003C5.21961 10.5757 4.97326 10.5931 4.92487 10.4234L4.24321 8.02821C4.16513 7.75503 4.27891 7.46322 4.52055 7.31407Z" fill="#091849"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
