<template>
  <div>
    <slot></slot>
    <v-combobox
      @input="inputProject"
      :items="projectList"
      :value="project ? project.name : ''"
      :disabled="readOnly || isSelectedProject"
      :menu-props="{contentClass: 'select-client-menu', closeOnContentClick: true}"
      item-text="name"
      class="mx-4 select-client input-border text-black font-weight-medium rounded-lg"
      flat
      height="80"
      outlined
      single-line
      hide-details
    >
      <template slot="label" style="'top': '28px'">
        <span class="mt-2">Enter name project</span>
      </template>
      <template v-slot:append>
        <template v-if="isShowProjectAddBtn">
          <v-btn
            @click="showCreatingProject"
            :disabled="readOnly"
            height="40"
            right
            absolute
            elevation="0"
            class="primary text-none fz-14 fw-600 mt-5 new-client-btn" style="top: 0"
          >
            + Add new project
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            @click="editProject"
            :disabled="readOnly"
            elevation="0"
            height="40"
            width="40"
            class="mt-1 mr-3 min-width-unset rounded-4 bg-lgray"
            icon
          >
            <v-icon size="20" class="stroke-black">$edit</v-icon>
          </v-btn>
          <v-btn
            @click="removeProject"
            :disabled="readOnly"
            elevation="0"
            height="40"
            width="40"
            icon
            class="mt-1 min-width-unset rounded-4 bg-lgray"
          >
            <v-icon size="20" class="stroke-black">$clear</v-icon>
          </v-btn>
        </template>
      </template>
      <!--<template v-slot:selection>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-black font-weight-medium">
              {{ project ? project.name : '' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>-->
      <template v-slot:item="data">
        <v-list-item class="select-client-hover" @click="selectProject(data.item)">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              {{ data.item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
    <v-row class="ma-0" v-if="projectCreation">
      <v-col>
        <v-form ref="clientReg">
          <v-row class="ma-0 mb-3">
            <span class="fz-14 text-gray width-140 my-auto mr-8">Name</span>
            <v-text-field
              outlined
              single-line
              v-model="newProject.name"
              class="rounded-lg mb-n4 text-black font-weight-medium input-border"
              dense
            />
            <!--:rules="[$rules.required, $rules.minCounter]"-->
          </v-row>
          <v-row class="ma-0">
            <v-spacer/>
            <v-btn
              @click="cancelProjectCreation"
              height="40"
              class="rounded-lg fz-16 fw-600 text-none mr-3"
              elevation="0"
              outlined
              color="primary"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="editMode ? updateProject( { id: newProject.id, name: newProject.name } , (data) => {
                $emit('update:project', data.data.orders.updateProject)
                cancelProjectCreation()
                getProjects()
              }) : saveProject()"
              height="40"
              class="rounded-lg fz-16 fw-600 text-none"
              elevation="0"
              color="primary"
            >
              {{ editMode ? 'Update' : 'Save' }}
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import projects from '@/components/Hooks/Projects'

export default {
  name: 'ProjectSearch',
  props: ['project', 'readOnly'],
  data() {
    return {
      editMode: false,
      projectCreation: false,
      newProject: {
        name: ''
      }
    }
  },
  computed: {
    projectList() {
      if (this.editMode || this.projectCreation) {
        return []
      } else return this.allProjects
    },
    isSelectedProject() {
      return this.project ? Boolean(this.project.id) : false
    },
    isShowProjectAddBtn() {
      return this.project === null || typeof this.project === 'string' || !this.isSelectedProject
    }
  },
  methods: {
    inputProject(value) {
      this.$emit('update:project', { id: null, name: value })
    },
    showCreatingProject() {
      this.newProject = {
        name: this.project
      }
      this.projectCreation = true
    },
    editProject() {
      this.editMode = true
      this.newProject = JSON.parse(JSON.stringify(this.project))
      this.projectCreation = true
    },
    removeProject() {
      if (this.projectCreation || this.editMode) this.cancelProjectCreation()
      this.$emit('removeProject')
    },
    cancelProjectCreation() {
      this.newProject = {
        name: ''
      }
      this.projectCreation = false
      this.editMode = false
    },
    selectProject(item) {
      this.$emit('updateProject', item)
    },
    saveProject() {
      this.createProject(this.newProject.name, (data) => {
        this.selectProject(data.data.orders.createProject)
        this.cancelProjectCreation()
        this.getProjects()
      })
    }
    /* async getProjects() {
      await this.$apollo.query({
        query: gql`query getAllProjects {
          orders {
            project {
              data {
                id
                name
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.allProjects = data.data.orders.project.data
      }).catch((err) => {
        console.log(err)
      })
    }, */
    /* async saveProject() {
      await this.$apollo.mutate({
        mutation: gql`mutation createProject($name: String!) {
            orders {
              createProject(input: {name: $name}) {
                id
                name
              }
            }
          }`,
        variables: {
          name: this.newProject.name
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.selectProject(data.data.orders.createProject)
        this.cancelProjectCreation()
        this.getProjects()
        /!* this.snackbarMessage = ['Success', 'Item was updated difficulty with success'] *!/
      }).catch((err) => {
        console.log('changeDifficulty', err)
        /!* this.snackbarMessage = ['Error', 'There was some mistake, check setting of item again'] *!/
      })
    }, */
    /* async updateProject() {
      await this.$apollo.mutate({
        mutation: gql`mutation updateProject($id: ID!, $name: String!) {
            orders {
              updateProject(input: {id: $id, name: $name}) {
                id
                name
              }
            }
          }`,
        variables: {
          id: this.newProject.id,
          name: this.newProject.name
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.$emit('update:project', data.data.orders.updateProject)
        this.cancelProjectCreation()
        /!* this.snackbarMessage = ['Success', 'Item was updated difficulty with success'] *!/
      }).catch((err) => {
        console.log('changeDifficulty', err)
        /!* this.snackbarMessage = ['Error', 'There was some mistake, check setting of item again'] *!/
      })
    } */
    /* async deleteProject() {
      await this.$apollo.mutate({
        mutation: gql`mutation deleteProject($id: ID!) {
          orders {
            deleteProject(id: $id)
          }
        }`,
        variables: {
          id: this.newProject.id,
          name: this.newProject.name
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        /!* this.snackbarMessage = ['Success', 'Item was updated difficulty with success'] *!/
      }).catch((err) => {
        console.log('changeDifficulty', err)
        /!* this.snackbarMessage = ['Error', 'There was some mistake, check setting of item again'] *!/
      })
    } */
  },
  created() {
    this.pageOptions.pageSize = 9999
    this.getProjects()
  },
  setup() {
    const { allProjects, pageOptions, getProjects, createProject, updateProject } = projects()
    return {
      getProjects,
      createProject,
      updateProject,
      allProjects,
      pageOptions
    }
  }
}
</script>

<style scoped>

</style>
