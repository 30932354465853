<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="800"
    content-class="rounded-xl"
    overlay-color="rgba(9, 24, 73, 0.2)"
    overlay-opacity="1"
    v-model="dialog"
    @click:outside="closeModal()"
    @close="closeModal()"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <slot/>
      </div>
    </template>
    <template v-slot:default>
      <v-card
        class="pa-6 position-relative"
      >
        <v-card-title class="justify-center mb-8">
          <span>{{ designer.businessName }}</span>
          <v-spacer/>
          <v-icon class="close-icon" @click="closeModal()">$close</v-icon>
        </v-card-title>
        <v-card-text class="mb-8 scroll" style="height: 500px;">
          <v-row>
            <v-col>
              <v-list-item-title>
                Payment options
              </v-list-item-title>
              <v-list-item-content class="overflow-visible">
                <v-form
                  ref="form"
                >
                  <div>
                    <span class="fz-14 text-gray">Account</span>
                    <v-menu attach="#select1" content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-col class="pa-0">
                          <v-text-field
                            outlined
                            single-line
                            placeholder="Choose account"
                            :value="selectedAccount ? selectedAccount.name : ''"
                            class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
                            dense
                            readonly
                            :rules="[$rules.required]"
                          >
                            <template v-slot:prepend-inner>
                              <v-sheet
                                v-bind="attrs"
                                v-on="on"
                                class="absolute"
                                elevation="0"
                                color="transparent"
                                style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                              ></v-sheet>
                            </template>
                            <template v-slot:append>
                              <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                            </template>
                          </v-text-field>
                          <div id="select1" class="relative z-index-400"></div>
                        </v-col>
                      </template>
                      <v-card class="rounded-lg mt-1 hidden" elevation="0">
                        <v-card-text class="px-0 py-1">
                          <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                            <v-list-item
                              v-for="(item, index) in $store.getters.getPaymentAccounts"
                              :key="index"
                              style="min-height: 30px"
                              class="px-0 py-1"
                              @click="selectedAccount = item"
                            >
                              <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                  <div class="d-flex flex-column my-4">
                    <v-row no-gutters class="fz-14 text-gray mb-2">Amount</v-row>
                    <template v-for="(item, index) in localOrders">
                      <v-row
                        v-if="index <= 4 || isShowAll"
                        :key="index"
                        class="mt-1"
                        no-gutters
                      >
                        <v-col>
                          <span class="fz-14 text-gray mb-1">{{ item.order.id }} - {{ item.order.name }}</span>
                        </v-col>
                        <v-col no-gutters class="d-flex flex-column">
                          <div class="mb-1 d-flex justify-end">
                            <AmountView :currency="item.order.currencySymbol">
                              {{ item.order.designerSalary.paid.amount }}
                            </AmountView>
                            &nbsp;/&nbsp;
                            <AmountView :currency="item.order.currencySymbol">
                              {{ (+item.order.designerSalary.total.amount).toFixed(2) }}
                            </AmountView>
                          </div>
                          <v-progress-linear
                            color="primary"
                            rounded
                            height="12"
                            :value="(+item.order.designerSalary.paid.amount / +item.order.designerSalary.total.amount) * 100"
                          />
                        </v-col>
                        <v-row no-gutters class="mt-2">
                          <v-col class="mr-3">
                            <v-text-field
                              outlined
                              single-line
                              type="number"
                              placeholder="Enter value"
                              v-model="item.order.designerSalary.remainder.amount"
                              class="rounded-lg input-border input-qty mt-1 text-black fz-14 font-weight-medium"
                              :rules="[$rules.required]"
                              dense
                            />
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              outlined
                              single-line
                              readonly
                              :value="item.order.designerSalary.remainder.currency"
                              class="rounded-lg input-border input-qty mt-1 text-black fz-14 font-weight-medium"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-row>
                    </template>
                    <v-btn
                      v-if="orders.length - 1 >= 4 && !isShowAll"
                      elevation="0"
                      dark
                      height="30"
                      width="150"
                      min-width="16"
                      class="rounded-lg text-capitalize text-st btn-create fz-14 align-self-end"
                      @click="isShowAll = true"
                    >
                      Show more
                    </v-btn>
                  </div>
                  <div>
                    <div class="mb-1">Date of payment</div>
                    <vc-date-picker
                      locale="en"
                      class="inline-block h-full"
                      :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
                      :popover="{ visibility: 'click' }"
                      :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                      mode="date"
                      v-model="datePayment"
                      :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                      :attributes="[{key: 'todayY', highlight: true, dates: new Date()}]"
                    >
                      <template v-slot="">
                        <v-text-field
                          outlined
                          single-line
                          readonly
                          :value="formatDate(datePayment, 'DD MMMM YYYY')"
                          :rules="[$rules.required]"
                          class="rounded-lg input-border input-qty mt-1 text-black fz-14 font-weight-medium"
                          dense
                        />
                      </template>
                    </vc-date-picker>
                  </div>
                </v-form>
              </v-list-item-content>
            </v-col>
            <v-col>
              <v-list-item-title>
                Orders
              </v-list-item-title>
              <v-list-item-content class="overflow-visible">
                <div>
                  <span class="fz-14 text-gray">Select order</span>
                  <v-menu
                    attach="#select2"
                    content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          single-line
                          :value="selectedOrders.length ? selectedOrders.join(', ') : `All (${orders.length})`"
                          class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
                          dense
                          readonly
                        >
                          <template v-slot:prepend-inner>
                            <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              class="absolute"
                              elevation="0"
                              color="transparent"
                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                            ></v-sheet>
                          </template>
                          <template v-slot:append>
                            <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                          </template>
                        </v-text-field>
                        <div id="select2" class="relative z-index-400"></div>
                      </v-col>
                    </template>
                    <v-card class="rounded-lg mt-1 hidden" elevation="0">
                      <v-card-text class="px-0 py-1">
                        <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                          <v-list-item
                              style="min-height: 30px"
                              class="px-0 py-1"
                              @click="selectedOrders.length === orders.length ? selectedOrders = [] : selectedOrders = [...new Set(orders.map(el => String(el.order.id)))]"
                            >
                              <p class="ma-0 px-2 fz-12 text-black">All ({{ orders.length }})</p>
                            </v-list-item>
                          <v-list-item-group
                            v-model="selectedOrders"
                            multiple
                          >
                            <v-list-item
                              v-for="(item, index) in listOrders"
                              :key="index"
                              :value="item.id"
                              style="min-height: 30px"
                              class="px-0 py-1 my-1"
                            >
                              <p class="ma-0 px-2 fz-12 text-black">{{ item.id }} - {{ item.name }}</p>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </v-list-item-content>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            elevation="0"
            color="primary"
            height="40"
            width="155"
            class="ml-7 mr-3 rounded-lg fz-16 fw-600 text-none"
            outlined
            @click="closeModal()"
          >
            Cancel
          </v-btn>
          <v-btn
            elevation="0"
            @click="addIncome"
            color="primary"
            height="40"
            width="155"
            class="rounded-lg fz-16 fw-600 text-none"
          >
            Pay
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import gql from 'graphql-tag'
import GetExchange from '@/components/Hooks/GetExchange'
import AmountView from '@/components/App/UI/AmountView'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'SalaryOperation',
  components: {
    AmountView
  },
  props: {
    designer: {
      type: Object,
      default: () => ({})
    },
    orders: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShowAll: false,
      selectedAccount: null,
      datePayment: this.getFirstDayMonth(),
      dialog: false,
      selectedOrders: []
    }
  },
  computed: {
    listOrders() {
      return [...this.orders.map(el => ({ id: el.order.id, name: el.order.name }))]
    },
    localOrders() {
      if (this.selectedOrders.length) {
        return this.orders.filter(el => {
          for (const subEl of this.selectedOrders) {
            if (+subEl === +el.order.id) return true
          }
        })
      } else return this.orders
    }
  },
  methods: {
    getFirstDayMonth() {
      const date = new Date()
      date.setDate(1)
      return date
    },
    formatDate(date, format = 'YYYY-MM-DD') {
      return locale.format(date, format)
    },
    async addIncome () {
      if (this.$refs.form.validate()) {
        await this.$apollo.mutate({
          mutation: gql`mutation createSalaryOperations($salariesList: [AddSalariesInput!]!) {
          orders {
            createSalaryOperations(salariesList: $salariesList)
          }
        }`,
          variables: {
            salariesList: [...this.localOrders.map(el => (
              {
                orderId: el.order.id,
                date: this.formatDate(this.datePayment),
                amount: el.order.designerSalary.remainder.amount + ' ' + el.order.designerSalary.remainder.currency,
                accountId: this.selectedAccount.id,
                designerId: this.designer.id
              }
            ))]
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          console.log('Добавь snackbar')
          this.$emit('updateOrders')
          /* this.addSnack('item.name', 'Success', 'Payment created') */
        }).catch((err) => {
          console.log(err)
        })
        /* for (const item of this.localOrders) {
          await this.$apollo.mutate({
            mutation: gql`mutation addIncome ($id: ID!, $input: AddSalaryInput! ) {
            orders {
              order(id: $id) {
                addSalaryPayment(input: $input) {
                  id
                  order {
                    salaryPayments {
                      remainder
                      remainderOffice
                      remainderRemote
                      expectedTotal
                      byDesigner {
                        paid
                        total
                        operations {
                          date
                          amount
                          amountOrderCurrency
                          id
                        }
                        designer {
                          avatarUrl
                          businessInitials
                          businessName
                          team
                          id
                        }
                      }
                      total
                    }
                  }
                }
              }
            }
          }`,
            variables: {
              id: item.order.id,
              input: {
                date: this.formatDate(this.datePayment),
                amount: item.order.designerSalary.remainder.amount + ' ' + item.order.designerSalary.remainder.currency,
                extraFee: null,
                accountId: this.selectedAccount.id,
                designerId: this.designer.id
              }
            },
            fetchPolicy: 'no-cache'
          }).then((data) => {
            console.log('Добавь snackbar')
            this.$emit('updateOrders')
            /!* this.addSnack('item.name', 'Success', 'Payment created') *!/
          }).catch((err) => {
            console.log(err)
          })
        } */
      }
    },
    setPayment(currency, context) {
      console.log(currency)
      this.getExchange(currency, context.paid)
      this.getExchange(currency, context.total)
      this.getExchange(currency, context.remainder)
    },
    closeModal() {
      this.dialog = false
      this.isShowAll = false
    }
  },
  setup() {
    const { getExchange } = GetExchange()
    return {
      getExchange
    }
  },
  mounted() {
    if (!this.$store.getters.getPaymentAccounts) this.$store.dispatch('fetchPaymentAccounts')
  }
}
</script>

<style scoped>

</style>
