<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 10L12 6H4L8 10Z"/>
</svg>

</template>

<script>
export default {
  name: 'chevronDown'
}
</script>

<style scoped>

</style>
