<template>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="3" width="8" height="2" rx="1" fill="#010820"/>
    <rect x="3" y="8" width="8" height="2" rx="1" transform="rotate(-90 3 8)" fill="#010820"/>
  </svg>
</template>

<script>
export default {
}
</script>
