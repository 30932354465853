<template>
  <v-row class="mt-2 flex-wrap">
    <!--Designers-->
    <v-col cols="2" class="py-0">
      <v-data-table
        id="designers-table"
        style="width: 400px"
        :calculate-widths="true"
        :headers="assignee.headers"
        :items="assignee.items"
        item-key="designer.id"
        :header-props="{ sortIcon: 'mdi-menu-down' }"
        class="orders-table table-vertical-line"
        hide-default-footer
        single-select
        :items-per-page="-1"
        @click:row="showOrder"
      >
        <template v-slot:item.name="{ item }">
          <span>{{ item.designer.businessName }}</span>
        </template>
        <template v-slot:item.salary="{ item }">
          <span>{{ Math.round(item.salary) }} {{ item.currency }}</span>
        </template>
      </v-data-table>
    </v-col>
    <!--Orders-->
    <v-col class="pt-0 overflow-hidden">
      <SalaryOperation
        v-if="selectedRow.designer && !isDesigner"
        :designer="selectedRow.designer"
        :orders="orders.items"
        @updateOrders="getOrders()"
      >
        <v-btn
          elevation="0"
          dark
          height="30"
          width="100"
          min-width="16"
          class="rounded-lg text-capitalize text-st btn-create fz-14"
        >
          Pay Salary
        </v-btn>
      </SalaryOperation>
      <v-data-table
        class="orders-table table-vertical-line"
        :headers="orders.headers"
        :header-props="{ sortIcon: 'mdi-menu-down' }"
        :items="orders.items"
        :items-per-page="-1"
        @click:row="openOrder"
      >
        <template v-slot:item.status="{ item }">
          <OrderStatus
            :status="item.order.status"
            :cancellation-reasons="item.order.itemsCancellationReasons"
          />
          <!--<v-row class="ma-0">
            <v-col class="pa-0">
              <span
                style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                class="rounded-circle"
                :style="color(item.order.status.value)"
              />
              <span style="max-width: 80px">
                {{ item.order.status.label }}
              </span>
              <v-tooltip bottom max-width="600" v-if="item.order.status.id === 'CANCELED'">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="one-line py-0"
                  >
                    <v-icon size="32">$questionMark</v-icon>
                  </span>
                </template>
                <v-col class="d-flex flex-column flex-wrap flex-grow-0">
                  <div
                    v-for="(reason, index) of item.order.itemsCancellationReasons"
                    :key="index"
                    class="width-100"
                    style="word-break: break-all"
                  >
                    <span>
                      {{ reason }}
                    </span>
                  </div>
                </v-col>
              </v-tooltip>
            </v-col>
          </v-row>-->
        </template>
        <template v-slot:item.name="{ item }">
          <div style="max-width: 10vw" class="one-line">{{ item.order.name }}</div>
        </template>
        <template v-slot:item.number="{ item }">
          <div style="max-width: 10vw" class="one-line">{{ item.order.id }}</div>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span class="one-line">{{ formatDate(item.order.createdAt) }}</span>
        </template>
        <template v-slot:item.dateStart="{ item }">
          <span class="one-line">{{ item.order.dateStart ? formatDate(item.order.dateStart) : '' }}</span>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <span class="one-line">{{ formatDate(item.order.updatedAt) }}</span>
        </template>
        <template v-slot:item.dateEnd="{ item }">
          <v-col class="pa-0">
            <v-row class="ma-0">
                  <span class="one-line"
                        :class="{'color-primary': item.order.dateEndPrediction && item.order.dateEnd !== item.order.dateEndPrediction, 'fw-600': item.order.dateEndPrediction && item.order.dateEnd !== item.order.dateEndPrediction}">
                    {{ item.order.dateEnd ? formatDate(item.order.dateEnd) : '' }}
                  </span>
            </v-row>
            <v-row v-if="item.order.dateEndPrediction && item.order.dateEnd !== item.order.dateEndPrediction"
                   class="ma-0">
                  <span class="one-line fz-10 mt-n1">
                    {{ formatDate(item.order.dateEndPrediction) }}
                  </span>
            </v-row>
          </v-col>
        </template>
        <template v-slot:item.client="{ item }">
          <div class="one-line">{{ item.order.client.name }}</div>
        </template>
        <template v-slot:item.assignee="{ item }">
          <div v-if="item.order.executors.length === 0" class="text-black">
            Not assigned
          </div>
          <div v-else>
            {{ getDesignerSalaryPayments(item).designer.businessName }}
          </div>
        </template>
        <template v-slot:item.salaryPayments="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="my-auto">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="one-line"
                  :class="{
                    'text-orange': Math.round(+getDesignerSalaryPayments(item).paidRub.amount) !== Math.round(+getDesignerSalaryPayments(item).totalRub.amount) &&
                     Math.round(+getDesignerSalaryPayments(item).paidRub.amount) !== Math.round(+getDesignerSalaryPayments(item).totalRub.amount) > 0,
                    'text-pink': Math.round(+getDesignerSalaryPayments(item).paidRub.amount) === 0,
                     'fw-600': +getDesignerSalaryPayments(item).paidRub.amount === +getDesignerSalaryPayments(item).totalRub.amount
                  }"
                >
                  <span class="fz-13 fw-600">
                    <span>
                    {{ Math.round(getDesignerSalaryPayments(item).paidRub.amount) }} ₽
                  </span>
                    /
                    <span>
                    {{ Math.round(getDesignerSalaryPayments(item).totalRub.amount) }} ₽
                  </span>
                </span>
                </span>
              </div>
            </template>
            <v-col>
              <v-row class="ma-0">
                <span class="mr-10 fz-13 fw-600 white--text">
                  {{ getDesignerSalaryPayments(item).designer.businessName }}
                </span>
                <v-spacer></v-spacer>
                <span class="fz-13 fw-600 white--text">
                  <span>
                  {{ Math.round(getDesignerSalaryPayments(item).paidRub.amount) }} ₽
                </span>
                  /
                  <span>
                  {{ Math.round(getDesignerSalaryPayments(item).totalRub.amount) }} ₽
                </span>
                </span>
              </v-row>
            </v-col>
          </v-tooltip>
        </template>
        <template v-slot:item.manager="{ item }">
          <div class="one-line">
            <v-avatar
              :color="item.order.manager.avatarUrl ? 'transparent' : 'primary'"
              size="22"
              class="mr-3 my-auto"
            >
              <img
                v-if="item.order.manager.avatarUrl"
                :src="item.order.manager.avatarUrl"
              >
              <span v-else class="white--text">{{ item.order.manager.businessInitials || '??' }}</span>
            </v-avatar>
            {{ item.order.manager.businessName }}
          </div>
        </template>
        <template v-slot:item.history="{ item }">
          <div class="one-line">
            <SalaryStatusesHistory :orderId="statusesHistoryIdOrder">
              <v-btn
                elevation="0"
                x-small
                icon
                fab
                @click="showHistory(item)"
              >
                <v-icon size="20" class="stroke-gray">$edit</v-icon>
              </v-btn>
            </SalaryStatusesHistory>
          </div>
        </template>
      </v-data-table>
    </v-col>
    <!--EditOrder-->
    <EditOrder
      v-if="!isDesigner"
      @getOrders="getOrders"
      ref="editOrder"
    />
    <!--  Loader  -->
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </v-row>
</template>

<script>
import SalaryStatusesHistory from '@/components/App/Team/SalaryStatusesHistory'
import SalaryOperation from '@/components/App/Team/SalaryOperation'
import EditOrder from '@/components/App/Orders/EditOrder'
import OrderStatus from '@/components/App/UI/OrderStatus'
import gql from 'graphql-tag'
import { statusColor } from '@/helpers/staticColors'
import numberFormat from '@/helpers/numberFormat'
import { Locale } from 'v-calendar'

const locale = new Locale()

export default {
  name: 'SalaryTmTable',
  components: {
    EditOrder,
    SalaryOperation,
    SalaryStatusesHistory,
    OrderStatus
  },
  filters: {
    numberFormat
  },
  props: {
    queryOption: {
      type: Object
    }
  },
  data() {
    return {
      selectedRow: {
        designer: null,
        listOrders: []
      },
      assignee: {
        headers: [
          {
            text: 'Assignee',
            value: 'name',
            align: 'center',
            cellClass: 'pointer'
          },
          {
            text: 'Salary',
            value: 'salary',
            align: 'center',
            cellClass: 'pointer'
          }
        ],
        items: []
      },
      orders: {
        headers: [
          {
            text: 'Number',
            value: 'number',
            align: 'center',
            filterable: true,
            sortable: false,
            width: 'min-width-100',
            class: 'px-0',
            cellClass: 'pointer'
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
            filterable: true,
            sortable: false,
            width: '150px',
            class: 'px-0',
            cellClass: 'pointer'
          },
          {
            text: 'Project',
            value: 'name',
            align: 'center',
            filterable: false,
            sortable: false,
            class: 'max-width-10p',
            cellClass: 'pointer'
          },
          {
            text: 'Date of creation',
            value: 'createdAt',
            align: 'center',
            filterable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Start date',
            value: 'dateStart',
            align: 'center',
            filterable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Update date',
            value: 'updatedAt',
            align: 'center',
            filterable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Expiration date',
            value: 'dateEnd',
            align: 'center',
            filterable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Client',
            value: 'client',
            align: 'center',
            sortable: false,
            width: '10%',
            cellClass: 'pointer'
          },
          {
            text: 'Assignee',
            value: 'assignee',
            align: 'center',
            sortable: false,
            class: 'min-width-94',
            cellClass: 'pointer'
          },
          {
            text: 'Salary cost',
            value: 'salaryPayments',
            align: 'center',
            filterable: false,
            cellClass: 'pointer'
          },
          {
            text: 'Manager',
            value: 'manager',
            align: 'center',
            filterable: false,
            sortable: false,
            width: '10%',
            cellClass: 'pointer'
          },
          {
            text: 'History',
            value: 'history',
            align: 'center',
            filterable: false,
            sortable: false,
            width: '10%',
            cellClass: 'pointer'
          }
        ],
        items: [],
        statusLabels: []
      },
      statusesHistoryIdOrder: '0',
      loading: false
    }
  },
  computed: {
    isDesigner () {
      return this.$store.getters.isDesigner
    }
  },
  methods: {
    getDesignerSalaryPayments(item) {
      return item.order.salaryPayments.byDesigner.find(el => el.designer.id === this.selectedRow.designer.id)
    },
    showHistory(item) {
      this.statusesHistoryIdOrder = item.order.id
    },
    showOrder(item, row) {
      row.select()
      this.selectedRow.designer = item.designer
      this.selectedRow.listOrders = item.ordersList
      this.orders.items = []
      this.getOrders()
    },
    async getSalaryDesigners() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getDesignersSalary($input: DesignerSalaryFilterInput!) {
            team {
              designersSalary(input: $input) {
                designersSalary {
                  salary
                  currency
                  ordersList
                  designer {
                    id
                    businessName
                  }
                }
              }
            }
          }`,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            periodStart: this.queryOption.datePeriod.dateStart ? this.queryOption.datePeriod.dateStart : '2020-10-01',
            periodEnd: this.queryOption.datePeriod.dateEnd ? this.queryOption.datePeriod.dateEnd : '2099-10-01',
            roleId: this.queryOption.roleId === 'All' ? null : this.queryOption.roleId ? this.queryOption.roleId : null
          }
        }
      }).then((data) => {
        this.assignee.items = data.data.team.designersSalary.designersSalary
        this.loading = false
        this.$nextTick(() => {
          if (this.isDesigner) document.querySelector('#designers-table tbody tr').click()
        })
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    async getOrders() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getDesignerOrder($designerId: ID!, $input: DesignOrderFilterInput!) {
              team {
                designerOrders(designerId: $designerId, input: $input) {
                  data {
                    order {
                      id
                      number
                      name
                      currency
                      currencySymbol
                      createdAt
                      dateEndPrediction
                      dateEnd
                      dateStart
                      expirationDate
                      updatedAt
                      itemsCancellationReasons
                      type
                      paidCurrently
                      contractTitle
                      contractUrl
                      items {
                        id
                        name
                        cancellationReason
                        ... on OrderProductPlan {
                          orderItemVersion
                        }
                      }
                      invoices {
                        name
                        amount
                        id
                        downloadUrl
                      }
                      completionActs {
                        downloadUrl
                        name
                        id
                        invoice {
                          id
                        }
                      }
                      status {
                        label
                        value
                        id
                      }
                      manager {
                        businessName
                        avatarUrl
                        businessInitials
                      }
                      totalCost
                      client {
                        name
                      }
                      executors {
                        businessName
                      }
                      salaryPayments {
                      expectedTotal
                                  operations {
                          date
                          moneyOut
                          worker {
                            businessName
                            id
                          }
                        }
                        byDesigner {
                          remainderRub
                          paidRub
                          totalRub
                          paid
                          total
                          remainder
                          operations {
                            date
                            amount
                            amountOrderCurrency
                            id
                          }
                          designer {
                            avatarUrl
                            businessInitials
                            businessName
                            team
                            id
                          }
                        }
                        remainder
                        remainderOffice
                        remainderRemote
                        total
                        expectedTotal
                      }
                      operations {
                        moneyIn
                        amountOrderCurrency
                        date
                        id
                      }
                      paymentRemainder
                      paymentDone
                    }
                  }
                }
              }
            }`,
        variables: {
          designerId: this.selectedRow.designer.id,
          input: {
            ordersList: this.selectedRow.listOrders
          }
        },
        fetchPolicy: 'no-cache'
      }).then(data => {
        this.orders.items = data.data.team.designerOrders.data
        this.orders.items.forEach(el => {
          const designerSalary = el.order.salaryPayments.byDesigner.find(el => el.designer.id === this.selectedRow.designer.id)
          if (el.order.currency === 'USD') {
            el.order.designerSalary = {}
            el.order.designerSalary.paid = designerSalary.paid
            el.order.designerSalary.remainder = designerSalary.remainder
            el.order.designerSalary.total = designerSalary.total
          } else {
            el.order.designerSalary = {}
            el.order.designerSalary.paid = designerSalary.paidRub
            el.order.designerSalary.remainder = designerSalary.remainderRub
            el.order.designerSalary.total = designerSalary.totalRub
          }
        })
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    // For table
    openOrder(event) {
      if (this.isDesigner) return
      this.$refs.editOrder.getOrder(event.order.id)
    },
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    color(value) {
      return statusColor(value)
    },
    showPrice(item) {
      this.createPayment.amount = +item.paymentRemainder
      this.priceInfo = item
      this.paymentRemainder = {
        amount: +item.paymentRemainder,
        currency: item.currency
      }
      this.createPayment.currency = item.currency ? item.currency : 'USD'
      this.priceInfoShow = true
    },
    showSalary(item) {
      this.priceInfo = item
      this.salaryInfoShow = true
    }
  },
  created() {
    this.getSalaryDesigners()
  },
  mounted() {
  },
  watch: {
    queryOption: {
      handler(newVal, oldVal) {
        if (newVal.datePeriod.dateStart !== oldVal.datePeriod.dateStart || newVal.datePeriod.dateEnd !== oldVal.datePeriod.dateEnd || newVal.roleId !== oldVal.roleId) {
          this.selectedRow.designer = null
          this.orders.items = []
          this.getSalaryDesigners()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped></style>
