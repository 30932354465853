<template>
  <svg width="16" height="4" viewBox="0 0 16 4" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2" cy="2" r="2"/>
    <circle cx="8" cy="2" r="2"/>
    <circle cx="14" cy="2" r="2"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
