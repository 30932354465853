export function statusColor(value) {
  switch (value) {
    case 'BACKLOG':
      return 'background-color: rgb(131,131,131); box-shadow: 0px 3px 6px rgba(131,131,131,0.2);'
    case 'IN_PROGRESS':
      return 'background-color: rgb(253, 182, 0); box-shadow: 0px 3px 6px rgba(253, 182, 0, 0.2);'
    case 'TODO':
      return 'background-color: rgb(0, 135, 210); box-shadow: 0px 3px 6px rgba(0, 135, 210, 0.2);'
    case 'NEED_REVIEW':
      return 'background-color: rgb(126, 0, 224); box-shadow: 0px 3px 6px rgba(126, 0, 224, 0.2);'
    case 'REVIEW':
      return 'background-color: rgb(222, 0, 226); box-shadow: 0px 3px 6px rgba(222, 0, 226, 0.2);'
    case 'COMPLETED':
      return 'background-color: rgb(59, 213, 153); box-shadow: 0px 3px 6px rgba(59, 213, 153, 0.2);'
    case 'PRE_SALE':
      return 'background-color: rgb(253,91,0); box-shadow: 0px 3px 6px rgba(253,91,0, 0.2);'
    case 'CANCELED':
      return 'background-color: rgb(255,0,0); box-shadow: 0px 3px 6px rgba(255,0,0, 0.2);'
    default:
      return 'background-color: rgb(131,131,131); box-shadow: 0px 3px 6px rgba(131,131,131,0.2);'
  }
}

export function statusJustColor(value) {
  switch (value) {
    case 'BACKLOG':
      return 'rgb(131,131,131)'
    case 'IN_PROGRESS':
      return 'rgb(253, 182, 0)'
    case 'TODO':
      return 'rgb(0, 135, 210)'
    case 'NEED_REVIEW':
      return 'rgb(126, 0, 224)'
    case 'REVIEW':
      return 'rgb(222, 0, 226)'
    case 'COMPLETED':
      return 'rgb(59, 213, 153)'
    case 'PRE_SALE':
      return 'rgb(253,91,0)'
    case 'CANCELED':
      return 'rgb(255,0,0)'
    default:
      return 'rgb(131,131,131)'
  }
}

export function designerColor(value) {
  switch (value) {
    case 'Busy':
      return 'background-color: #FDA700; box-shadow: 0px 3px 6px rgba(253, 167, 0, 0.2);'
    case 'Very Busy':
      return 'background-color: #FD5B00; box-shadow: 0px 3px 6px rgba(253, 91, 0, 0.2);'
    case 'Available':
      return 'background-color: #3BD599; box-shadow: 0px 3px 6px rgba(59, 213, 153, 0.2);'
    case 'Cancel':
      return 'background-color: #FF0000; box-shadow: 0px 3px 6px rgba(255, 0, 0, 0.2);'
    default:
      return 'background-color: rgb(131,131,131); box-shadow: 0px 3px 6px rgba(131,131,131,0.2);'
  }
}

export function labelStatus(value) {
  switch (value) {
    case 'BACKLOG':
      return 'Backlog'
    case 'TODO':
      return 'To do'
    case 'IN_PROGRESS':
      return 'In progress'
    case 'NEED_REVIEW':
      return 'Need Review'
    case 'REVIEW':
      return 'Review customer'
    case 'COMPLETED':
      return 'Completed'
    case 'PRE_SALE':
      return 'Pre sale'
    case 'CANCELED':
      return 'Canceled'
  }
}
