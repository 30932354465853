<template>
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.489 11.0105C31.1771 15.6989 31.177 23.2999 26.4887 27.988C21.8005 32.6762 14.1995 32.6762 9.51125 27.988C4.82302 23.2999 4.82289 15.6989 9.51096 11.0105C11.7624 8.75903 14.816 7.49414 18 7.49414C21.184 7.49414 24.2376 8.75903 26.489 11.0105" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.9987 2.99445H21.0012" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.3508 17.7754L17.1641 21.9621L14.6461 19.4531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.5056 8.99539L28.5044 5.99414L30.005 7.49477L26.489 11.0107" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'deadline'
}
</script>

<style scoped>

</style>
