<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33334 2.66732L6.20001 1.58398C6.32668 1.42598 6.51801 1.33398 6.72068 1.33398H9.28001C9.48268 1.33398 9.67401 1.42598 9.80068 1.58398L10.6667 2.66732V2.66732V3.33398C10.6667 3.70198 10.368 4.00065 10 4.00065H6.00001C5.63201 4.00065 5.33334 3.70198 5.33334 3.33398V2.66732V2.66732H5.33334Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.33332 2.66602H3.99999C3.26332 2.66602 2.66666 3.26268 2.66666 3.99935V12.666C2.66666 13.4027 3.26332 13.9993 3.99999 13.9993H12C12.7367 13.9993 13.3333 13.4027 13.3333 12.666V3.99935C13.3333 3.26268 12.7367 2.66602 12 2.66602H10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.33334 7.33333H10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.33334 10.6673H10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Invoice'
}
</script>

<style scoped>

</style>
