<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2326:92695)">
<path d="M2.21259 12.8942L6.13132 8.97545L5.02504 7.87695L1.10628 11.7957L1.44908 11.0166V8.92871L0 8.91311V14.0005H5.07179L5.07957 12.5514H2.95269L2.21259 12.8942Z" fill="#010820"/>
<path d="M8.91116 0L8.92675 1.44908H11.0147L11.7938 1.10628L7.875 5.02504L8.9735 6.13132L12.8923 2.21259L12.5495 2.95269V5.07957L13.9985 5.07179V0H8.91116Z" fill="#010820"/>
</g>
<defs>
<clipPath id="clip0_2326:92695">
<rect width="14" height="14" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  name: 'Expand'
}
</script>

<style scoped>

</style>
