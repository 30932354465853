<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2326:92695)">
<path d="M3.91876 8.98274L2.95639e-05 12.9015L1.10631 14L5.02507 10.0812L4.68227 10.8603V12.9482L6.13135 12.9638L6.13135 7.87646L1.05956 7.87646L1.05178 9.32554H3.17866L3.91876 8.98274Z" fill="#010820"/>
<path d="M12.9624 6.13086L12.9468 4.68178H10.8589L10.0798 5.02458L13.9985 1.10582L12.9 -0.000458717L8.98127 3.91827L9.32408 3.17817V1.05129L7.875 1.05907L7.875 6.13086L12.9624 6.13086Z" fill="#010820"/>
</g>
<defs>
<clipPath id="clip0_2326:92695">
<rect width="14" height="14" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  name: 'Shrink'
}
</script>

<style scoped>

</style>
