<template>
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.995 10.3316C4.995 7.3849 7.3838 4.99609 10.3306 4.99609H23.6694C26.6162 4.99609 29.005 7.3849 29.005 10.3316V23.6705C29.005 26.6173 26.6162 29.0061 23.6694 29.0061H10.3306C7.3838 29.0061 4.995 26.6173 4.995 23.6705V10.3316Z" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 22.3358L23.1172 16.2186C24.159 15.1771 25.8477 15.1771 26.8895 16.2186L29.005 18.3341" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9975 12.9997C12.1026 12.9997 12.9984 12.1039 12.9984 10.9989C12.9984 9.89385 12.1026 8.99805 10.9975 8.99805C9.89251 8.99805 8.9967 9.89385 8.9967 10.9989C8.9967 12.1039 9.89251 12.9997 10.9975 12.9997Z" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.46594 25.8629L11.1083 20.2205C12.1501 19.1791 13.8388 19.1791 14.8805 20.2205L23.4401 28.7801" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
