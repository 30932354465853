<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8003 3.99622C14.2224 3.99622 14.599 4.26125 14.7417 4.65849L15.2499 6.07408C15.3306 6.29669 15.4872 6.48372 15.6921 6.6023L17.1997 7.47266C17.4045 7.59079 17.6444 7.63261 17.877 7.59071L19.3576 7.3236C19.7734 7.248 20.1918 7.4418 20.4031 7.8078L21.2034 9.18838C21.4152 9.55339 21.3752 10.012 21.1034 10.3349L20.132 11.4833C19.979 11.6639 19.895 11.8929 19.8949 12.1296V13.8703C19.895 14.107 19.979 14.336 20.132 14.5166L21.1034 15.6651C21.3752 15.9879 21.4152 16.4465 21.2034 16.8116L20.4031 18.1921C20.192 18.5577 19.7741 18.7515 19.3586 18.6763L17.878 18.4092C17.6454 18.3673 17.4055 18.4091 17.2007 18.5273L15.6931 19.3976C15.4882 19.5162 15.3316 19.7032 15.2509 19.9259L14.7427 21.3414C14.5999 21.739 14.2228 22.0041 13.8003 22.0037H12.1997C11.7776 22.0037 11.4009 21.7387 11.2583 21.3414L10.7501 19.9259C10.6694 19.7035 10.5133 19.5166 10.3089 19.3976L8.80024 18.5273C8.59551 18.4091 8.35557 18.3673 8.12296 18.4092L6.64234 18.6763C6.22657 18.7519 5.80814 18.5581 5.5969 18.1921L4.79657 16.8116C4.58477 16.4465 4.62479 15.9879 4.89661 15.6651L5.86802 14.5166C6.02101 14.336 6.10502 14.107 6.10512 13.8703V12.1296C6.10502 11.8929 6.02101 11.6639 5.86802 11.4833L4.90662 10.3349C4.6348 10.012 4.59477 9.55339 4.80657 9.18838L5.60691 7.8078C5.81801 7.44219 6.23591 7.24846 6.65134 7.3236L8.13196 7.59071C8.36458 7.63261 8.60451 7.59079 8.80924 7.47266L10.3179 6.6023C10.5223 6.48335 10.6784 6.2964 10.7591 6.07408L11.2673 4.65849C11.4088 4.26433 11.7809 4.00005 12.1997 3.99622H13.8003Z" stroke="#010820" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 15.7512C14.5194 15.7512 15.7511 14.5195 15.7511 13C15.7511 11.4806 14.5194 10.2489 13 10.2489C11.4806 10.2489 10.2488 11.4806 10.2488 13C10.2488 14.5195 11.4806 15.7512 13 15.7512Z" stroke="#010820" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Settings'
}
</script>
