<template>
  <v-app-bar app color="#fff" flat height="80" class="bb-gray z-index-300">
    <v-app-bar-nav-icon v-if="sidebarMini && $vuetify.breakpoint.mdAndUp" @click="$emit('showSidebar')"></v-app-bar-nav-icon>
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="$emit('showSidebar')"></v-app-bar-nav-icon>
    <v-toolbar-title class="ml-4 title-offset fz-24 text-black fw-800">{{ pageTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <div v-if="currency && $vuetify.breakpoint.mdAndUp" class="mr-12 fz-14 font-weight-medium">
      <div class="mr-2 d-inline-flex justify-center align-center rounded-circle font-weight-bold currency-border"><v-icon class="currency-icon rounded-circle" x-small>$usd</v-icon> </div>
      <span class="text-black fz-14 font-weight-medium">{{ currency.title }}</span>
      <div class="currency-value text-black fz-14 font-weight-medium d-inline-flex justify-center align-center pa-1 rounded ml-1 mr-2">{{ currency.value }}</div>
      <small class="font-weight-bold" :class="{'red-text': currency.diff < 0, 'green-text': currency.diff > 0}">{{ currency.diff }}</small>
    </div>
    <v-menu
      v-if="$vuetify.breakpoint.mdAndUp"
      offset-y
      right
      nudge-bottom="10"
      content-class="rounded-lg border-lightgray menu-shadow"
      min-width="200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          elevation="0"
          dark
          height="40"
          width="100"
          min-width="16"
          class="rounded-lg mr-4 pl-4 pr-2 btn-create fz-14"
          :class="{'btn-create-active': attrs['aria-expanded'] === 'true'}"
          v-bind="attrs"
          v-on="on"
          >
          Create
          <v-icon class="ml-5 mr-1 mt-n2 pt-3 divider-l stroke-white" size="8">$ddown</v-icon>
        </v-btn>
      </template>
      <v-list class="px-4 py-0">
        <template v-for="(item, index) in items">
          <v-divider class="ma-0"
                     v-if="index"
                     style="border-color: #EEF2F7 !important;"
            :key="index + 'createDivider'"></v-divider>
          <v-list-item
            :key="index"
            class="px-0"
          >
            <v-list-item-title class="fz-14 fw-600 d-flex align-center header-create-menu-item pointer" @click="redirectTo(item.page, null, 'New')">
              <span>{{ item.title }}</span>
              <v-spacer></v-spacer>
              <v-icon size="30" style="padding: 10px" class="rounded-circle">$add</v-icon>
            </v-list-item-title>
          </v-list-item>
<!--        #EEF2F7-->
          </template>
      </v-list>
    </v-menu>
    <v-btn
      elevation="0"
      dark
      height="40"
      width="100"
      min-width="16"
      class="rounded-lg mr-15 btn-create fz-14"
      @click="salaryDesignerDialog = true"
    >
      Salary
    </v-btn>
    <div class="search-parent mr-3 search-offset">
        <v-text-field
        placeholder="Search by section"
        prepend-inner-icon="$search"
        v-model="searchValue"
        rounded
        filled
        dense
        height="50"
        hide-details
        ref="globalSearch"
        class="rounded-4 expanding-search relative"
        :class="{'closed': searchClosed, 'active': !searchClosed}"
        :style="{'width': width + 60 + 'px'}"
        @focus="showSearch(); searchClosed = false"
        @keyup="debounceSearch"
        @blur="closeSearch"
      >
         <template v-slot:append>
           <v-icon v-if="searchValue" @click="searchValue = ''" size="16" class="stroke-black mt-1">$clear</v-icon>
         </template>
        </v-text-field>
    </div>
    <!--<v-btn
    width="50"
    elevation="0"
    height="50"
    min-width="50"
    class="rounded-4 mr-5">
      <v-badge
        color="#FF0000"
        bordered
        offset-x="14"
        offset-y="12"
        content="2"
      >
        <v-icon
        >$notification</v-icon>
      </v-badge>
    </v-btn>-->
    <v-menu
      v-if="me && me.fullName && $vuetify.breakpoint.mdAndUp"
      offset-y
      nudge-bottom="5"
      content-class="rounded-lg border-lightgray menu-shadow"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="ma-0 d-flex align-center"
          >
          <v-avatar
            :color="me.designerUser && me.designerUser.avatarUrl ? 'transparent' : 'primary'"
            size="50"
          >
            <img
              v-if="me.designerUser && me.designerUser.avatarUrl"
              :alt="me.initials"
              :src="me.designerUser.avatarUrl"
            >
            <span v-else class="fz-16 white--text">{{ me.initials }}</span>
          </v-avatar>
          <span
            class="mx-4 account-dd text-black"
          >
            {{ me.fullName }}
            <v-icon class="ml-n1">mdi-menu-down</v-icon>
          </span>
        </div>
      </template>
      <v-card>
        <v-card-title class="pl-5 py-3 fz-13 fw-600 text-black">
          {{ me.designerUser && me.designerUser.role ? me.designerUser.role.roleName : ''}}
        </v-card-title>
        <v-divider class="ma-0"
         style="border-color: #EEF2F7 !important;"></v-divider>
        <v-card-text class="px-5 pt-0 pb-2">
          <v-list class="pa-0">
            <v-list-item class="pa-0 pb-3 pt-3">
              <v-col class="pa-0">
                <span class="d-block fz-13 lh-20 text-gray">Name</span>
                <span class="fz-13 lh-20 font-weight-medium text-black">{{ me.fullName }}</span>
              </v-col>
            </v-list-item>
            <v-divider class="ma-0"
             style="border-color: #EEF2F7 !important;"></v-divider>
            <v-list-item class="pa-0 pb-3 pt-3">
              <v-col class="pa-0">
                <span class="d-block fz-13 lh-20 text-dgray">E-mail</span>
                <a :href="'mailto:' + me.email" class="fz-13 lh-20 font-weight-medium" style="color: #395ED8 !important;">{{ me.email }}</a>
              </v-col>
            </v-list-item>
            <v-divider class="ma-0"
             style="border-color: #EEF2F7 !important;"></v-divider>
            <v-list-item @click="redirectTo('My Profile', null, true)" class="pa-0 ma-0 rounded-0 pt-1">
              <v-row class="ma-0 align-center">
                <v-icon class="stroke-black mr-2" size="20">$priceEdit</v-icon>
                <span class="fz-14 text-black fw-600 lh-20">Edit profile info</span>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="searchResultShow"
      scrollable
      fullscreen
      transition="dialog-top-transition"
      class="z-index-200"
    >
      <v-card v-if="searchResult">
        <v-list
          class="mt-20 px-3 scroll"
          :class="{'ml-14': sidebarMini && !sidebarMobile, 'ml-64': !sidebarMini && !sidebarMobile}"
        >
          <v-list-item v-if="searchResult.managers.length > 0" class="mb-12">
            <v-col class="pa-0">
              <v-row class="ma-0 fz-20 text-black fw-600">Managers</v-row>
              <v-divider class="black bb-1 mb-5 mt-7"></v-divider>
              <v-row v-for="manager in searchResult.managers" :key="manager.id" class="ma-0 height-50">
                  <div class="d-inline-block my-auto pointer" @click="redirectTo('Team', manager.designer.id, null)">
                    <v-avatar
                      :color="manager.designer.avatarUrl ? 'transparent' : 'primary'"
                      size="32"
                      class="mr-1 my-auto"
                    >
                      <img
                        v-if="manager.designer.avatarUrl"
                        :src="manager.designer.avatarUrl"
                      >
                      <span v-else class="white--text fz-8 white--text">{{ manager.designer.businessInitials || '??' }}</span>
                      </v-avatar>
                    <span class="my-auto fz-14 text-black">{{ manager.designer.businessName }}</span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-chip
                    v-if="manager.orders.data.length > 0 && $vuetify.breakpoint.smAndUp"
                    label
                    color="#EEF2F7"
                    class="ml-3 my-auto"
                  >
                    <span class="mr-5 vertical-divider-small text-black fz-13">{{ manager.orders.data[0].order.name }}</span>
                    <span class="text-dgray fz-13">{{ manager.orders.data[0].totalCost }} $</span>
                  </v-chip>
                  <v-chip
                    label
                    color="#EEF2F7"
                    class="ml-3 my-auto"
                    v-if="manager.orders.data.length > 1 && $vuetify.breakpoint.mdAndUp"
                  >
                    <span class="mr-5 vertical-divider-small text-black fz-13">{{ manager.orders.data[1].order.name }}</span>
                    <span class="text-dgray fz-13">{{ manager.orders.data[1].totalCost }} $</span>
                  </v-chip>
                  <v-chip
                    label
                    color="#EEF2F7"
                    class="ml-3 my-auto"
                    v-if="manager.orders.data.length > ($vuetify.breakpoint.mdAndUp ? 2 : $vuetify.breakpoint.smAndUp ? 1 : 0)"
                  >
                    <span class="text-black fz-13 fw-600">
                      {{ $vuetify.breakpoint.smAndUp ? '+' : '' }}{{ manager.orders.data.length - ($vuetify.breakpoint.mdAndUp ? 2 : $vuetify.breakpoint.smAndUp ? 1 : 0) + ' projects' }}
                    </span>
                  </v-chip>
                </v-row>
            </v-col>
          </v-list-item>
          <v-list-item v-if="searchResult.designers.length > 0" class="mb-12">
            <v-col class="pa-0">
              <v-row class="ma-0 fz-20 text-black fw-600">Teammates</v-row>
              <v-divider class="black bb-1 mb-5 mt-7"></v-divider>
              <v-row v-for="designer in searchResult.designers" :key="designer.id" class="ma-0 height-50">
                  <div class="d-inline-block my-auto pointer" @click="redirectTo('Team', designer.designer.id, null)">
                    <v-avatar
                      :color="designer.designer.avatarUrl ? 'transparent' : 'primary'"
                      size="32"
                      class="mr-1 my-auto"
                    >
                      <img
                        v-if="designer.designer.avatarUrl"
                        :src="designer.designer.avatarUrl"
                      >
                      <span v-else class="white--text fz-8">{{ designer.designer.businessInitials || '??' }}</span>
                      </v-avatar>
                    <span class="my-auto fz-14 text-black">{{ designer.designer.businessName }}</span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-chip
                    v-if="designer.orders.data.length > 0 && $vuetify.breakpoint.smAndUp"
                    label
                    color="#EEF2F7"
                    class="ml-3 my-auto"
                  >
                    <span class="mr-5 vertical-divider-small text-black fz-13">{{ designer.orders.data[0].order.name }}</span>
                    <span class="text-dgray fz-13">{{ designer.orders.data[0].cost }} $</span>
                  </v-chip>
                  <v-chip
                    label
                    color="#EEF2F7"
                    class="ml-3 my-auto"
                    v-if="designer.orders.data.length > 1 && $vuetify.breakpoint.mdAndUp"
                  >
                    <span class="mr-5 vertical-divider-small text-black fz-13">{{ designer.orders.data[1].order.name }}</span>
                    <span class="text-dgray fz-13">{{ designer.orders.data[1].cost }} $</span>
                  </v-chip>
                  <v-chip
                    label
                    color="#EEF2F7"
                    class="ml-3 my-auto"
                    v-if="designer.orders.data.length > ($vuetify.breakpoint.mdAndUp ? 2 : $vuetify.breakpoint.smAndUp ? 1 : 0)"
                  >
                    <span class="text-black fz-13 fw-600">
                      {{ $vuetify.breakpoint.smAndUp ? '+' : '' }}{{ designer.orders.data.length - ($vuetify.breakpoint.mdAndUp ? 2 : $vuetify.breakpoint.smAndUp ? 1 : 0) + ' projects' }}
                    </span>
                  </v-chip>
                </v-row>
            </v-col>
          </v-list-item>
          <v-list-item v-if="searchResult.clients.length > 0" class="mb-12">
            <v-col class="pa-0">
              <v-row class="ma-0 fz-20 text-black fw-600">Clients</v-row>
              <v-divider class="black bb-1 mb-5 mt-7"></v-divider>
              <v-row v-for="client in searchResult.clients" :key="client.client.id" class="ma-0 height-50">
                <div class="d-inline-block my-auto pointer" @click="redirectTo('Leads', client.client.id, null)">
                  <v-avatar
                    :color="client.client.photoUrl ? 'transparent' : 'primary'"
                    size="32"
                    class="mr-1 my-auto"
                  >
                    <img
                      v-if="client.client.photoUrl"
                      :src="client.client.photoUrl"
                    >
                    <span v-else class="white--text fz-8">{{ client.client.initials || '??' }}</span>
                    </v-avatar>
                  <span class="my-auto fz-14 text-black">{{ client.client.name }}</span>
                </div>
                <v-spacer></v-spacer>
                <v-chip
                  v-if="client.orders.data.length > 0 && $vuetify.breakpoint.smAndUp"
                  label
                  color="#EEF2F7"
                  class="ml-3 my-auto"
                >
                  <span class="mr-5 vertical-divider-small text-black fz-13">{{ client.orders.data[0].name }}</span>
                  <span class="text-dgray fz-13">{{ client.orders.data[0].totalCost }} $</span>
                </v-chip>
                <v-chip
                  label
                  color="#EEF2F7"
                  class="ml-3 my-auto"
                  v-if="client.orders.data.length > 1 && $vuetify.breakpoint.mdAndUp"
                >
                  <span class="mr-5 vertical-divider-small text-black fz-13">{{ client.orders.data[1].name }}</span>
                  <span class="text-dgray fz-13">{{ client.orders.data[1].totalCost }} $</span>
                </v-chip>
                <v-chip
                  label
                  color="#EEF2F7"
                  class="ml-3 my-auto"
                  v-if="client.orders.data.length > ($vuetify.breakpoint.mdAndUp ? 2 : $vuetify.breakpoint.smAndUp ? 1 : 0)"
                >
                  <span class="text-black fz-13 fw-600">
                    {{ $vuetify.breakpoint.smAndUp ? '+' : '' }}{{ client.orders.data.length - ($vuetify.breakpoint.mdAndUp ? 2 : $vuetify.breakpoint.smAndUp ? 1 : 0) + ' projects' }}</span>
                </v-chip>
              </v-row>
            </v-col>
          </v-list-item>
          <v-list-item v-if="searchResult.orders.data.length > 0" class="mb-12">
            <v-col class="pa-0">
              <v-row class="ma-0 fz-20 text-black fw-600">Orders</v-row>
              <v-divider class="black bb-1 mb-5 mt-7"></v-divider>
              <v-data-table
                class="orders-table table-vertical-line"
                :headers="headers"
                :items="searchResult.orders.data"
                :server-items-length="queryOptions.totalItems"
                hide-default-footer
                :options.sync="options"
              >
                <template v-slot:item.name="{ item }">
                  <div class="pointer" @click="redirectTo('Orders', item.id, null)">
                    <span class="text-black">{{ item.name }}</span>
                  </div>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span class="one-line">{{ formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <span class="one-line">{{ formatDate(item.updatedAt) }}</span>
                </template>
                <template v-slot:item.expirationDate="{ item }">
                  <v-col class="pa-0">
                    <v-row class="ma-0">
                      <span class="one-line"
                        :class="{'color-primary': item.expirationDate, 'fw-600': item.expirationDate}">
                        {{ formatDate(item.dateEndPrediction) }}
                      </span>
                    </v-row>
                    <v-row v-if="item.expirationDate" class="ma-0">
                      <span class="one-line fz-10">
                        {{ formatDate(item.expirationDate) }}
                      </span>
                    </v-row>
                  </v-col>
                </template>
                <template v-slot:item.client="{ item }">
                  <div class="one-line">{{ item.client.name }}</div>
                </template>
                <template v-slot:item.totalCost="{ item }">
                  <v-row class="ma-0">
                    <span class="one-line">{{ +item.totalCost | numberFormat }}$</span>
                  </v-row>
                </template>
                <template v-slot:item.manager="{ item }">
                  <div class="one-line">
                    <v-avatar
                    :color="item.manager.avatarUrl ? 'transparent' : 'primary'"
                    size="32"
                    class="mr-3 my-auto"
                  >
                    <img
                      v-if="item.manager.avatarUrl"
                      :src="item.manager.avatarUrl"
                    >
                    <span v-else class="white--text">{{ item.manager.businessInitials || '??' }}</span>
                  </v-avatar>
                    {{ item.manager.businessName }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-list-item>
          <v-list-item
            v-if="searchResult.orders.data.length === 0 &&
            searchResult.designers.length === 0 &&
            searchResult.clients.length === 0 &&
            searchResult.orders.data.length === 0"
            class="mb-12">
            <v-list-item-content>
              <v-list-item-title class="fz-20 text-black fw-600">No matching results</v-list-item-title>
              <v-divider class="black bb-1 my-5"></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
<!--    <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="primary"
      ></v-progress-linear>-->
    <v-dialog
      v-model="salaryDesignerDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="z-index-500"
      overlay-color="rgba(9, 24, 73, 0.2)"
      overlay-opacity="1"
    >
      <SalaryDesigners @closeSalaryDialog="salaryDesignerDialog = false"/>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import gql from 'graphql-tag'
import { Locale } from 'v-calendar'
import numberFormat from '@/helpers/numberFormat'
import _ from 'lodash'
import SalaryDesigners from '@/components/App/Team/Salary'

const locale = new Locale()

export default {
  components: {
    SalaryDesigners
  },
  props: ['sidebarMini', 'drawer', 'currency', 'me', 'sidebarMobile'],
  filters: {
    numberFormat
  },
  data: () => ({
    // loading: true,
    salaryDesignerDialog: false,
    item: {
      businessName: 'Testing Search',
      avatarUrl: null,
      businessInitials: 'TS'
    },
    searchValue: '',
    selectedItem: -1,
    items: [
      {
        title: 'Designer',
        page: 'Team'
      },
      {
        title: 'Client',
        page: 'Leads'
      },
      {
        title: 'Order',
        page: 'Orders'
      },
      {
        title: 'Company',
        page: 'Companys'
      }
    ],
    searchClosed: true,
    width: null,
    headers: [
      { text: 'Project', value: 'name', filterable: false, width: '200px', sortable: false },
      {
        text: 'Number',
        align: 'start',
        value: 'number',
        filterable: false,
        sortable: false
      },
      { text: 'Date of creation', value: 'createdAt', filterable: false, width: '110px', sortable: false },
      { text: 'Update date', value: 'updatedAt', filterable: false, width: '108px', sortable: false },
      { text: 'Expiration date', value: 'expirationDate', filterable: false, width: '110px', sortable: false },
      { text: 'Client', value: 'client', sortable: false, width: '10%' },
      { text: 'Final cost', value: 'totalCost', filterable: false, sortable: false },
      { text: 'Manager', value: 'manager', filterable: false, sortable: false, width: '10%' }
    ],
    options: {
      itemsPerPage: 13
    },
    orders: [],
    queryOptions: {
      dateEnd: '',
      dateStart: '',
      orderBy: 'NUMBER_ASC',
      page: 1,
      pageSize: 13,
      searchClient: '',
      searchManager: '',
      searchName: '',
      totalPages: null
    },
    searchResultShow: false,
    searchResult: null
  }),
  methods: {
    test (item) {
      console.log(item)
    },
    showSearch () {
      const search = document.querySelector('.search-offset').offsetLeft
      const title = document.querySelector('.title-offset').offsetLeft
      this.width = search - title
    },
    redirectTo (page, id, newItem) {
      this.$router
        .push({ name: 'Search' }, () => {
          this.$router.replace({ name: page, params: { id: id, new: newItem } })
          this.searchResult = null
          this.searchResultShow = false
          this.searchValue = ''
          this.$refs.globalSearch.blur()
          this.closeSearch()
        })
    },
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    closeSearch () {
      if (this.searchValue === null || String(this.searchValue).length === 0) this.searchClosed = true
    },
    debounceSearch: _.debounce(function () {
      if (this.searchValue !== null && String(this.searchValue).length !== 0) this.globalSearch()
    }, 1000),
    async globalSearch () {
      await this.$apollo.query({
        query: gql`query globalSearch($input: GlobalSearchInput!) {
          design {
            globalSearch(input: $input) {
              clients {
                client {
                  id
                  name
                  photoUrl
                  initials
                }
                orders {
                  data {
                    id
                    name
                    totalCost
                  }
                }
              }
              designers {
                designer {
                  id
                  businessName
                  businessInitials
                  avatarUrl
                }
                orders {
                  data {
                    cost
                    order {
                      totalCost
                      id
                      name
                    }
                  }
                }
              }
              managers {
                designer {
                  id
                  businessName
                  businessInitials
                  avatarUrl
                }
                id
                orders {
                  data {
                    cost
                    order {
                      totalCost
                      id
                      name
                    }
                  }
                }
              }
              orders {
                data {
                  name
                  id
                  totalCost
                  number
                  createdAt
                  expirationDate
                  updatedAt
                  dateEndPrediction
                  client {
                    id
                    name
                  }
                  manager {
                    avatarUrl
                    businessInitials
                    businessName
                    id
                  }
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            search: this.searchValue,
            ordersPage: 1,
            ordersPageSize: 10
          }
        }
      }).then((data) => {
        this.searchResult = data.data.design.globalSearch
        this.searchResultShow = true
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  computed: {
    pageTitle () {
      return this.$route.name
    }
  }
}
</script>

<style scoped lang="scss">
  * {
    font-family: inter, sans-serif;
  }
  .v-btn {
    text-transform:none !important;
  }
  .account-dd {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #091849;
  }

  .divider-l:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 20px;
    background-color: white;
    opacity: .25;
    left: -9px;
  }
</style>
