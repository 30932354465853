import gql from 'graphql-tag'
import { ref } from 'vue'

export default function projects() {
  const allProjects = ref([])
  const pageOptions = ref({
    page: 1,
    pageSize: 10,
    totalPages: 0
  })
  const project = ref({
    name: ''
  })

  async function getProjects(searchName = null, page = 1) {
    await this.$apollo.query({
      query: gql`query getAllProjects($pageSize: Int, $page: Int, $searchName: String) {
          orders {
            project(input: {page: $page, pageSize: $pageSize, searchName: $searchName}) {
              page
              totalPages
              data {
                id
                name
                ordersCount
                ordersSumUsd
                ordersSumRub
              }
            }
          }
        }`,
      variables: {
        searchName: searchName,
        page: page,
        pageSize: pageOptions.value.pageSize
      },
      fetchPolicy: 'no-cache'
    }).then((data) => {
      allProjects.value = data.data.orders.project.data
      pageOptions.value.totalPages = data.data.orders.project.totalPages
    }).catch((err) => {
      console.log('getProjects', err)
      this.$store.commit('showSnackbar', ['Error', err])
    })
  }

  async function createProject(name, callback) {
    console.log('create')
    await this.$apollo.mutate({
      mutation: gql`mutation createProject($name: String!) {
            orders {
              createProject(input: {name: $name}) {
                id
                name
              }
            }
          }`,
      variables: {
        name: name
      },
      fetchPolicy: 'no-cache'
    }).then((data) => {
      callback(data)
      /* this.snackbarMessage = ['Success', 'Item was updated difficulty with success'] */
    }).catch((err) => {
      console.log('createProject', err)
      this.$store.commit('showSnackbar', ['Error', err])
    })
  }

  async function updateProject(payload, callback) {
    await this.$apollo.mutate({
      mutation: gql`mutation updateProject($id: ID!, $name: String!) {
            orders {
              updateProject(input: {id: $id, name: $name}) {
                id
                name
              }
            }
          }`,
      variables: {
        id: payload.id,
        name: payload.name
      },
      fetchPolicy: 'no-cache'
    }).then((data) => {
      if (callback) callback(data)
      /* this.snackbarMessage = ['Success', 'Item was updated difficulty with success'] */
    }).catch((err) => {
      console.log('changeDifficulty', err)
      this.$store.commit('showSnackbar', ['Error', err])
    })
  }

  async function getSpecificProject(id) {
    await this.$apollo.query({
      query: gql`query getSpecificProject($searchId: ID!) {
        orders {
          project(input: {searchId: $searchId}) {
            data {
              id
              name
              orders {
                id
                name
                itemsCancellationReasons
                dateStart
                dateEnd
                dateEndPrediction
                status {
                  id
                  value
                  label
                }
                client {
                  id
                  name
                }
              }
            }
          }
        }
      }`,
      variables: {
        searchId: id
      },
      fetchPolicy: 'no-cache'
    }).then((data) => {
      project.value = data.data.orders.project.data[0]
    }).catch((err) => {
      console.log('getSpecificProject', err)
      this.$store.commit('showSnackbar', ['Error', err])
    })
  }

  async function deleteProject(id, callback) {
    await this.$apollo.mutate({
      mutation: gql`mutation deleteProject($id: ID!) {
            orders {
              deleteProject(id: $id)
            }
          }`,
      variables: {
        id: id
      },
      fetchPolicy: 'no-cache'
    }).then(() => {
      if (callback) callback()
      /* this.snackbarMessage = ['Success', 'Item was updated difficulty with success'] */
    }).catch((err) => {
      console.log('deleteProject', err)
      this.$store.commit('showSnackbar', ['Error', err])
    })
  }

  return {
    allProjects,
    project,
    pageOptions,
    getProjects,
    getSpecificProject,
    createProject,
    updateProject,
    deleteProject
  }
}
