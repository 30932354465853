<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4483 17.4999H6.54831C5.67581 17.4999 4.95081 16.8266 4.88581 15.9558L4.13831 5.83325H15.8333L15.1108 15.9516C15.0483 16.8241 14.3225 17.4999 13.4483 17.4999V17.4999Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 9.16675V14.1667" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.33337 5.83341H16.6667" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.1667 5.83333L13.3225 3.58167C13.0784 2.93083 12.4567 2.5 11.7617 2.5H8.23837C7.54337 2.5 6.92171 2.93083 6.67754 3.58167L5.83337 5.83333" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.8583 9.16675L12.5 14.1667" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.14167 9.16675L7.50001 14.1667" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>
