<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28 14.6667V24C28 26.2093 26.2093 28 24 28H8C5.79067 28 4 26.2093 4 24V8C4 5.79067 5.79067 4 8 4H17.3333" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.6666 5.33333L28 4" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28 9.33333H29.3333" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.6667 4V2.66666" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6666 21.3333L15.1066 16.8933" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.288 14.5959L15.1106 16.8892L17.404 20.7119C17.5506 20.9559 17.916 20.9159 18.0066 20.6452L21.1226 11.2986C21.2093 11.0386 20.9613 10.7906 20.7013 10.8772L11.3546 13.9932C11.084 14.0839 11.044 14.4492 11.288 14.5959V14.5959Z" stroke="#010820" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
