<template>
  <v-row class="mt-2 flex-nowrap">
    <!--Executor-->
    <v-col cols="2" class="py-0">
      <v-data-table
        style="width: 400px"
        :calculate-widths="true"
        :headers="usersFix.headers"
        :items="usersFix.items"
        item-key="designer.id"
        :header-props="{ sortIcon: 'mdi-menu-down' }"
        class="orders-table table-vertical-line"
        hide-default-footer
        single-select
        :items-per-page="-1"
        @click:row="showOrder"
      >
        <template v-slot:item.name="{ item }">
          <span>{{ item.designer.businessName }}</span>
        </template>
        <template v-slot:item.salary="{ item }">
          <span>{{ Math.round(item.totalSalary) }} {{ item.currency }}</span>
        </template>
      </v-data-table>
    </v-col>
    <!--FixSalary-->
    <v-col class="pt-0 overflow-hidden">
      <!--  Filter -->
      <v-row>
        <v-col>
          <v-btn-toggle
            v-model="fixSalary.filter"
            mandatory
            active-class="salary-filter__active"
          >
            <v-btn
              elevation="0"
              dark
              height="30"
              width="60"
              min-width="16"
              class="text-capitalize text-st fz-14 text-black"
              style="background: rgb(242, 244, 245);"
              value="showed">
              Unpaid
            </v-btn>
            <v-btn
              elevation="0"
              dark
              height="30"
              width="60"
              min-width="16"
              class="text-capitalize text-st fz-14 text-black"
              style="background: rgb(242, 244, 245);"
              value="paid">
              Paid
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <!--  Table -->
      <v-data-table
        :headers="fixSalary.headers"
        :items="fixSalary.items"
      >
        <template v-slot:item.designer="{ item }">
          <span>{{ item.designer.businessName }}</span>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span>{{ formatDate(item.createdAt) }}</span>
        </template>
        <template v-slot:item.amount="{ item }">
          <span>{{ item.amount.amount }} {{ item.amount.symbol }}</span>
        </template>
        <template v-slot:item.isPaid="{ item }">
        <span
          style="color: white; height: 30px"
          :style="[item.isPaid ? {'background': 'rgb(59, 213, 153)'} : {'background': 'rgb(253, 91, 0)'}]"
          class="fz-14 pa-1 rounded-lg"
        >
          {{ item.isPaid ? 'Paid' : 'Unpaid'}}
        </span>
        </template>
        <template v-slot:item.operation="{ item }">
          <v-btn
            elevation="0"
            dark
            height="30"
            width="60"
            min-width="16"
            class="rounded-lg text-capitalize text-st btn-create fz-14"
            :disabled="!item.isShowed"
            @click="selectPayment(item)"
          >
            Pay
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <!--  Loader  -->
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <!--  PaymentDialog  -->
    <v-dialog
      v-model="isShowPaymentDialog"
      width="600"
    >
      <v-col class="d-flex flex-column bg-white py-5">
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col>
              <p class="fz-16 lh-22 mb-0 font-weight-medium text-black">{{ this.selectedPayment.businessName }}</p>
            </v-col>
          </v-row>
          <v-row class="flex-wrap">
            <v-col class="pb-0">
              <v-text-field
                label="Amount"
                v-model="selectedPayment.amount"
                :rules="[rules.required]"
                required
              />
            </v-col>
            <v-col class="pb-0">
              <v-select
                label="Account Type"
                v-model="selectedPayment.accountId"
                :value="selectedPayment.accountId"
                :items="accountsTypes"
                item-value="id"
                item-text="name"
                :rules="[rules.required]"
                required
              >
                <template v-slot:item="{ item }">
                  <span>{{ item.name }} {{ item.id }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-text-field
                label="Description"
                v-model="selectedPayment.description"
                :rules="[rules.required]"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-spacer/>
            <v-col align="right">
              <v-btn
                elevation="0"
                dark
                height="40"
                width="100"
                min-width="16"
                class="rounded-lg text-capitalize text-st btn-create fz-14"
                @click="createOperationAndFixSalary">
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-dialog>
    <!--  PaymentDialog  -->
    <v-snackbars
      :messages.sync="messages"
      color="red"
      timeout="3000"
      multi-line
      max-width="350"
      left
      rounded="lg"
      light
      elevation="4"
      content-class="snackbar"
    >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
  </v-row>
</template>

<script>
import gql from 'graphql-tag'
import VSnackbars from 'v-snackbars'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'SalaryFixTable',
  components: { 'v-snackbars': VSnackbars },
  props: {
    queryOption: {
      type: Object
    }
  },
  data() {
    return {
      selectedRowId: null,
      usersFix: {
        headers: [
          {
            text: 'Executor',
            value: 'name',
            align: 'center',
            cellClass: 'pointer'
          },
          {
            text: 'Salary',
            value: 'salary',
            align: 'center',
            cellClass: 'pointer'
          }
        ],
        items: []
      },
      fixSalary: {
        headers: [
          {
            text: 'Created',
            value: 'createdAt',
            sortable: false
          },
          /* {
            text: 'Showed',
            value: 'isShowed',
            class: 'min-width-100'
          }, */
          {
            text: 'Designer',
            value: 'designer',
            class: 'min-width-100',
            sortable: false
          },
          {
            text: 'Paid\'s status',
            value: 'isPaid',
            class: 'min-width-120',
            sortable: false,
            align: 'center'
          },
          {
            text: 'Amount',
            value: 'amount',
            class: 'min-width-100',
            sortable: false,
            align: 'center'
          },
          {
            text: 'Operation',
            value: 'operation',
            align: 'center',
            sortable: false,
            class: 'min-width-100'
          }
        ],
        items: [],
        filter: 'showed'
      },
      accountsTypes: [],
      selectedPayment: {
        businessName: '',
        paymentId: null,
        type: 'EXPENSE',
        accountId: null,
        designerId: null,
        date: '',
        amount: '',
        description: 'Fix salary'
      },
      isShowPaymentDialog: false,
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.',
        requiredOrder: value => this.operation.category.required === 'ORDER' ? !!value || 'Required.' : true,
        requiredWorker: value => this.operation.category.required === 'WORKER' ? !!value || 'Required.' : true,
        requiredAccount: value => this.operation.category.required === 'ACCOUNT' ? !!value || 'Required.' : true,
        dateBirth: value => {
          if (value === '') return true
          if (!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value)) return 'Date is invalid'
          // Parse the date parts to integers
          const parts = value.split('.')
          const day = parseInt(parts[0], 10)
          const month = parseInt(parts[1], 10)
          const year = parseInt(parts[2], 10)
          // Check the ranges of month and year
          if (year < 1000 || year > 3000 || month === 0 || month > 12) { return 'Date is invalid' }
          const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
          // Adjust for leap years
          if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) { monthLength[1] = 29 }
          // Check the range of the day
          return (day > 0 && day <= monthLength[month - 1]) || 'Date is invalid'
        }
      },
      valid: false,
      loading: false,
      messages: []
    }
  },
  methods: {
    showOrder(item, row) {
      row.select()
      this.selectedRowId = item.designer.id
      this.fixSalary.items = []
      this.getDesignersFixSalary()
    },
    async getFixSalaryStatistics() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getFixSalaryStatistics($input: FixSalaryFilterStatisticsInput) {
            team {
              designersFixSalaryStatistics(input: $input) {
                designersSalary {
                  currency
                  totalSalary
                  designer {
                    id
                    businessName
                  }
                }
              }
            }
          }`,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            isShowed: true,
            isPaid: false,
            roleId: this.queryOption.roleId === 'All' ? null : this.queryOption.roleId ? this.queryOption.roleId : null,
            periodStart: this.queryOption.datePeriod.dateStart ? this.queryOption.datePeriod.dateStart : '2020-10-01',
            periodEnd: this.queryOption.datePeriod.dateEnd ? this.queryOption.datePeriod.dateEnd : '2099-10-01'
          }
        }
      }).then((data) => {
        this.usersFix.items = data.data.team.designersFixSalaryStatistics.designersSalary
        this.loading = false
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async getDesignersFixSalary() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getDesignersFixSalary($input: FixSalaryFilterInput!) {
          team {
            designersFixSalary(input: $input) {
              data {
                amount
                createdAt
                isPaid
                isShowed
                id
                paidAt
                updatedAt
                designer {
                  id
                  businessName
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            isPaid: this.fixSalary.filter === 'paid',
            isShowed: this.fixSalary.filter === 'showed',
            searchDesigner: this.selectedRowId,
            pageSize: 999
          }
        },
        fetchPolicy: 'no-cache'
      }).then(data => {
        this.fixSalary.items = data.data.team.designersFixSalary.data
        this.loading = false
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async getAccountsTypes() {
      this.loading = true
      this.$apollo.query({
        query: gql`query getAccountsTypes {
          finance {
            accounts {
              id
              name
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then(data => {
        this.accountsTypes = data.data.finance.accounts
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async setPayFixSalary() {
      this.loading = true
      if (this.selectedPayment.accountId === null) {
        this.addSnack('item.name', 'Error', 'Need to select account type')
        this.loading = false
        return
      }
      await this.$apollo.mutate({
        mutation: gql`mutation MyMutation($input: FixSalaryPayInput!) {
          team {
            payFixSalary(input: $input) {
              id
            }
          }
        }`,
        variables: {
          input: {
            id: this.selectedPayment.paymentId,
            designerId: this.selectedPayment.designerId,
            amount: {
              amount: this.selectedPayment.amount,
              currency: 'RUB'
            },
            paidAt: this.selectedPayment.date,
            isShowed: false,
            isPaid: true
          }
        },
        fetchPolicy: 'no-cache'
      }).then(data => {
        this.loading = false
        this.createOperation()
        this.addSnack('Succeed', 'Succeed', 'Succeed update PayFixSalary')
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async createOperation() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation createOperation($input: OperationCreateInput!) {
          finance {
            createOperation(input: $input) {
              id
            }
          }
        }`,
        variables: {
          input: {
            type: this.selectedPayment.type,
            categoryId: '4', // Unchanged type
            accountId: this.selectedPayment.accountId,
            date: this.selectedPayment.date,
            amount: {
              amount: this.selectedPayment.amount,
              currency: 'RUB'
            },
            workerId: this.selectedPayment.designerId,
            description: this.selectedPayment.description
          }
        },
        fetchPolicy: 'no-cache'
      }).then(data => {
        this.loading = false
        this.addSnack('Succeed', 'Succeed', 'Succeed update Operation')
        this.getDesignersFixSalary()
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    selectPayment(item) {
      this.selectedPayment.businessName = item.designer.businessName
      this.selectedPayment.amount = item.amount.amount
      this.selectedPayment.designerId = item.designer.id
      this.selectedPayment.paymentId = item.id
      this.isShowPaymentDialog = true
    },
    createOperationAndFixSalary() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.isShowPaymentDialog = false
      this.selectedPayment.date = this.getDate
      this.setPayFixSalary()
      console.log('Hi', this.selectedPayment)
    },
    /* Secondary function */
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    }
  },
  computed: {
    getDate() {
      return locale.format(new Date(), 'YYYY-MM-DD')
    }
  },
  created() {
    this.getFixSalaryStatistics()
    this.getAccountsTypes()
  },
  watch: {
    'fixSalary.filter': {
      handler() {
        this.getDesignersFixSalary()
      }
    },
    queryOption: {
      handler(newVal, oldVal) {
        if (newVal.datePeriod.dateStart !== oldVal.datePeriod.dateStart || newVal.datePeriod.dateEnd !== oldVal.datePeriod.dateEnd || newVal.roleId !== oldVal.roleId) {
          this.selectedRowId = null
          this.fixSalary.items = []
          this.getFixSalaryStatistics()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.salary-filter__active {
  background: white!important;
  font-weight: 600;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background: rgba(0,0,0,.12)!important;
  color: rgba(0,0,0,.26)!important;
}
</style>
