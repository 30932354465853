// export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
// export const defaultStartPath = '/app/dashboards/default'
// export const searchPath = '/app/pages/search'
// export const buyUrl = '#'
//
// export const subHiddenBreakpoint = 1440
// export const menuHiddenBreakpoint = 768
//
// export const defaultLocale = 'en'
// export const defaultDirection = 'ltr'
// export const localeOptions = [
//   { id: 'en', name: 'English LTR', direction: 'ltr' },
//   { id: 'es', name: 'Español', direction: 'ltr' },
//   { id: 'enrtl', name: 'English RTL', direction: 'rtl' }
// ]
//
// export const firebaseConfig = {
//   apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
//   authDomain: 'gogo-react-login.firebaseapp.com',
//   databaseURL: 'https://gogo-react-login.firebaseio.com',
//   projectId: 'gogo-react-login',
//   storageBucket: 'gogo-react-login.appspot.com',
//   messagingSenderId: '216495999563'
// }

export const baseApiUrl = process.env.VUE_APP_SERVER_URL
// export const baseApiUrl = 'https://188.225.76.176:4443/backend'
export const graphqlHttpEndoint = baseApiUrl + '/graphql/'

const target = process.env.ENVIRONMENT
console.log(`Running in: ${target}. Backend: ${baseApiUrl}.`)
//
// export const currentUser = {
//   id: 1,
//   title: 'Sarah Kortney',
//   img: '/assets/img/profile-pic-l.jpg',
//   date: 'Last seen today 15:24'
// }
//
// export const defaultColor = 'light.orange'
// export const colors = [
//   'light.purple',
//   'dark.purple',
//   'light.blue',
//   'dark.blue',
//   'light.green',
//   'dark.green',
//   'light.orange',
//   'dark.orange',
//   'light.red',
//   'dark.red'
// ]
//
// export const tablePerPage = 20
// export const tablePageSizes = [10, 20, 30, 50, 100]
