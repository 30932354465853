<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49893 13.5007L11.125 13.1764C11.3095 13.1538 11.4812 13.0702 11.6127 12.9388L17.9745 6.5812C18.679 5.87641 18.679 4.73409 17.9745 4.0293V4.0293C17.2698 3.32487 16.1274 3.32487 15.4226 4.0293L9.12002 10.3319C8.99238 10.4595 8.90958 10.6251 8.88409 10.8038L8.49893 13.5007Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5831 4.33008C6.66949 4.33008 3.49686 7.50272 3.49686 11.4164C3.49686 15.33 6.66949 18.5026 10.5831 18.5026C14.4968 18.5026 17.6694 15.33 17.6694 11.4164" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.4439 8.10707L13.892 5.55518" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>
