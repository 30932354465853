<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.6667 27.9994C18.9853 27.9994 16 25.0154 16 21.334C16 17.7274 19.0667 14.6634 22.6733 14.666C26.352 14.6687 29.3333 17.6527 29.3333 21.3327C29.3333 25.014 26.3493 27.9994 22.6667 27.9994" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.66669 9.13346H28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28 17.3333V6.66667C28 5.19333 26.8067 4 25.3334 4H5.33335C3.86002 4 2.66669 5.19333 2.66669 6.66667V18.6667C2.66669 20.14 3.86002 21.3333 5.33335 21.3333H16" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.6667 21.3327H22V18.666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'FinanceUnpaidOrders'
}
</script>

<style scoped>

</style>
