import Vue from 'vue'
import App from './App.vue'
import { createProvider } from './vue-apollo'
import vuetify from './plugins/vuetify'
import router from './router'
import { store } from './store'
import VueClipboard from 'vue-clipboard2'
import '@/assets/css/sass/variables.scss'
// DatePicker with 2 month
import VCalendar from 'v-calendar'
// Mask for inputs
import VueMask from 'v-mask'
// Array of rules for inputs
import rules from './helpers/inputRules'

Vue.prototype.$rules = rules

Vue.config.productionTip = false
Vue.use(VCalendar, {
  componentPrefix: 'vc' // Use <vc-calendar /> instead of <v-calendar />
})
Vue.use(VueMask)
Vue.use(VueClipboard)

new Vue({
  apolloProvider: createProvider(),
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
