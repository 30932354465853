<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3333 28H6.66667C5.19333 28 4 26.8067 4 25.3333V6.66667C4 5.19333 5.19333 4 6.66667 4H25.3333C26.8067 4 28 5.19333 28 6.66667V25.3333C28 26.8067 26.8067 28 25.3333 28Z" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8H24V20H8V8Z" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 23.9999H17.3333" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.3334 23.9999H24" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
