<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66663 8.15918C10.7708 8.15918 11.6666 9.05501 11.6666 10.1592C11.6666 11.2633 10.7708 12.1592 9.66663 12.1592C8.16663 12.1592 8.16663 11.6592 6.66663 11.6592C7.66663 10.6592 7.66663 8.15918 9.66663 8.15918Z" stroke="#808490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1476 2.85316V2.85316C16.6984 2.404 15.9792 2.38066 15.5017 2.79816L10.8934 6.82983C10.3942 7.2665 10.3684 8.03483 10.8376 8.50316L11.4967 9.16233C11.9659 9.6315 12.7342 9.60566 13.1701 9.1065L17.2017 4.49816C17.6201 4.0215 17.5959 3.3015 17.1476 2.85316V2.85316Z" stroke="#808490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5 9.1665V15.8332C17.5 16.754 16.7542 17.4998 15.8333 17.4998H4.16667C3.24583 17.4998 2.5 16.754 2.5 15.8332V5.83317C2.5 4.91234 3.24583 4.1665 4.16667 4.1665H8.33333" stroke="#808490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>
