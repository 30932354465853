<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4H6.66667C5.19333 4 4 5.19333 4 6.66667V10" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 22V25.3333C4 26.8067 5.19333 28 6.66667 28H10" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 28H25.3333C26.8067 28 28 26.8067 28 25.3333V22" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28 10V6.66667C28 5.19333 26.8067 4 25.3333 4H22" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.7827 15.0904C18.7392 16.047 18.7392 17.5978 17.7827 18.5543C16.8262 19.5108 15.2754 19.5108 14.3188 18.5543C13.3623 17.5978 13.3623 16.047 14.3188 15.0904C15.2754 14.1339 16.8262 14.1339 17.7827 15.0904" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 13.5002V21.0002C23.5 21.9202 22.7533 22.6668 21.8333 22.6668H10.1667C9.24667 22.6668 8.5 21.9202 8.5 21.0002V13.5002C8.5 12.5802 9.24667 11.8335 10.1667 11.8335H11.8333L13.052 9.74683C13.2013 9.49083 13.4747 9.3335 13.772 9.3335H18.192C18.484 9.3335 18.7547 9.48683 18.9067 9.7375L20.168 11.8335H21.8347C22.7533 11.8335 23.5 12.5802 23.5 13.5002V13.5002Z" stroke="#7552CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
