<!--
ESLINT DISABLE
<div>
        Gantt chart
        Можно закинуть через :v-bind = { handlers } для обработки одних и тех же событий (пример сохранение наименования
        айтема, через frapper gantt и editItem)
      </div>
       <v-row no-gutters class="mb-6">
      <button @click="setupGantt">setupGantt</button>
    </v-row>
-->
<template>
  <div>
    <v-row no-gutters class="flex-nowrap">
      <div class="gantt-sidebar-wrapper position-relative pr-3">
        <!--Sidebar-->
        <div
          v-if="isShowSideBar"
          ref="ganttSidebar"
          class="gantt-sidebar"
        >
          <!--Sidebar's header-->
          <div class="gantt-sidebar-header d-flex align-center fz-20 mr-3">
            <!--Header-->
            <div class="gantt-sidebar-header d-flex align-center fz-20 mr-3">
              <div
                v-for="(col, index) in ['Name', 'Assignee', 'Status', 'Date']"
                :key="index"
                class="fz-14 px-2 py-1"
              >
                {{ col }}
              </div>
            </div>
            <v-spacer/>
            <v-icon
              size="30"
              style="padding: 10px"
              class="rounded-circle"
            >
              $add
            </v-icon>
          </div>
          <!--Table items-->
          <div id="d-table-draggable" class="gantt-sidebar-list">
            <!--List-->
            <template
              v-for="(item) of tasks"
            >
              <div
                class="d-flex flex-column parent-item"
                :key="item.id"
                :data-id="item.id"
              >
                <!--Parent item-->
                <div class="gantt-sidebar-item">
                  <div class="draggable-btn">
                    <v-btn
                      elevation="0"
                      x-small
                      icon
                      fab
                    >
                      <v-icon size="20">$draggable</v-icon>
                    </v-btn>
                  </div>
                  <!--CompositeItem-->
                  <template v-if="item.__typename === 'CompositeItem'">
                    <!--Name, Index-->
                    <v-btn
                      @click="item.isShowChildItems = !item.isShowChildItems"
                      v-if="item.productPlans"
                      elevation="0"
                      x-small
                      icon
                      fab
                    >
                      <v-icon size="12" class="stroke-gray">
                        $ddown
                      </v-icon>
                    </v-btn>
                    <div class="mr-4 ">
                      <div class="d-flex align-center">
                        <div>{{ item.name }}&nbsp;</div>
                      </div>
                    </div>
                  </template>
                  <!--OrdinaryItem-->
                  <template v-if="item.__typename !== 'CompositeItem'">
                    <!--Name, Index-->
                    <div class="mr-4 ">
                      <div class="d-flex align-center">
                        <div @click="focusToInput">{{ item.name }}&nbsp;</div>
                        <span v-if="item.ownName">::&nbsp;</span>
                        <input
                          type="text"
                          :disabled="false"
                          v-model="item.ownName"
                          class="colorlessFocus border-none"
                          @click.stop
                          @blur="changeItemName(item)"
                          v-resizable-input
                        >
                        <!--<DivInput :disabled="false" @blur="changeItemName(item)" v-model="item.ownName"></DivInput>-->
                      </div>
                    </div>
                    <!--Assignee-->
                    <div
                      class="mr-4 pa-0"
                      :class="isDisabled(item.fixedBySalary)"
                    >
                      <selectMenu
                        :disabled="item.fixedBySalary"
                        :items="getDesignersForItem(item.executorRole.roleId)"
                        @clicked="changeAssignee($event, item.id)"
                        header="Select Assignee"
                        value="businessName"
                      >
                        {{ item.executor.businessName }}
                        <template v-slot:additionalField="{ item }">
                          <span class="text-gray">{{ item.isAdditional ? 'Additional Assignee' : '' }}</span>
                        </template>
                      </selectMenu>
                    </div>
                    <!--Status-->
                    <div
                      class="mr-4 pa-0"
                      :class="isDisabled(item.fixedBySalary)"
                    >
                    <span>
                              <selectMenu
                                :disabled="item.fixedBySalary"
                                :items="$store.getters.getStatusLabels"
                                @clicked="changeStatus(item, $event)"
                                header="Select status"
                                value="label"
                              >
                                {{ getStatusLabel(item.status) }}
                              </selectMenu>
                            </span>
                    </div>
                    <!--Date-->
                    <div
                      class="mr-4 pa-0"
                      :class="isDisabled(item.fixedBySalary)"
                    >
                      <span>
                        <DateOneLine
                          :disabled="item.fixedBySalary"
                          :item="item"
                          @change="changeDate"
                        />
                      </span>
                    </div>
                    <span
                      class="gantt-sidebar-item--open-btn border-lightgray bg-mgray rounded-lg pa-1"
                      @click="$emit('task-clicked', item.id)"
                    >
                    Open
                  </span>
                  </template>
                </div>
                <!--Child items-->
                <!--v-if="item.childItems && item.isShowChildItems"
                -->
                <template v-if="item.isShowChildItems">
                  <div
                    class="gantt-sidebar-item child-item"
                    v-for="(item) of item.productPlans"
                    :key="item.id"
                  >
                    <template>
                      <!--Name, Index-->
                      <div class="mr-4">
                        <div class="d-flex align-center ml-8">
                          <div class="draggable-btn mr-2">
                            <v-btn
                              elevation="0"
                              x-small
                              icon
                              fab
                            >
                              <v-icon size="20">$draggable</v-icon>
                            </v-btn>
                          </div>
                          <div @click="focusToInput">{{ item.name }}&nbsp;</div>
                          <span v-if="item.ownName">::&nbsp;</span>
                          <input
                            type="text"
                            :disabled="false"
                            v-model="item.ownName"
                            class="colorlessFocus border-none"
                            @click.stop
                            @blur="changeItemName(item)"
                            v-resizable-input
                          >
                          <!--<DivInput :disabled="false" @blur="changeItemName(item)" v-model="item.ownName"></DivInput>-->
                        </div>
                      </div>
                      <!--Assignee-->
                      <div
                        class="mr-4 pa-0"
                        :class="isDisabled(item.fixedBySalary)"
                        id="sd"
                      >
                        <selectMenu
                          :disabled="item.fixedBySalary"
                          header="Select Assignee"
                          :items="getDesignersForItem(item.executorRole.roleId)"
                          @clicked="changeAssignee($event, item.id)"
                          value="businessName"
                        >
                          {{ item.executor.businessName }}
                          <template v-slot:additionalField="{ item }">
                            <span class="text-gray">{{ item.isAdditional ? 'Additional Assignee' : '' }}</span>
                          </template>
                        </selectMenu>
                      </div>
                      <!--Status-->
                      <div
                        class="mr-4 pa-0"
                        :class="isDisabled(item.fixedBySalary)"
                      >
                        <span>
                          <selectMenu
                            :disabled="item.fixedBySalary"
                            :items="$store.getters.getStatusLabels"
                            @clicked="changeStatus(item, $event)"
                            header="Select status"
                            value="label"
                          >
                            {{ getStatusLabel(item.status) }}
                          </selectMenu>
                        </span>
                      </div>
                      <!--Date-->
                      <div
                        class="mr-4 pa-0"
                        :class="isDisabled(item.fixedBySalary)"
                      >
                        <span>
                          <DateOneLine
                            :item="item"
                            :disabled="item.fixedBySalary"
                            @change="changeDate"
                          />
                        </span>
                      </div>
                      <span
                        class="gantt-sidebar-item--open-btn border-lightgray bg-mgray rounded-lg pa-1"
                        @click="$emit('task-clicked', item.id)"
                      >
                        Open
                      </span>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <!--Sidebar's Footer-->
          <div class="d-flex justify-end mt-2 mr-3 fz-14">
            Count {{ this.allTasks.length }}
          </div>
        </div>
        <!--Resize element-->
        <div
          ref="sideStick"
          class="side-stick"
          @mousedown="resizeFrame"
          @dblclick="toggleSidebarShowing"
        />
      </div>
      <div class="gant-main">
        <svg id="gantt"/>
      </div>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */

import Gantt from 'frappe-gantt'
import TimeFormat from '@/mixins/TimeFormat'
import selectMenu from '@/components/App/Orders/EditItem/selectMenu'
import DateOneLine from '@/components/App/UI/DateOneLine'
import StatusLabel from '@/mixins/StatusLabel'
import sortablejs from 'sortablejs'
import ResizableInput from '@/Directive/ResizableInput.vue'

export default {
  name: 'FrappeGantt',
  components: {
    selectMenu,
    DateOneLine,
  },
  mixins: [
    TimeFormat,
    StatusLabel,
    ResizableInput
  ],
  props: {
    tasks: {
      type: Array,
      default: () => []
    },
    allTasks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      gantt: null,
      dragOption: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      isShowSideBar: true
    }
  },
  methods: {
    isDisabled(value) {
      return value ? 'disabled' : ''
    },
    getDesignersForItem(ExecutorRoleId) {
      const mainAssignee = []
      const additionalAssignee = []
      this.$store.getters.getAllDesigners.forEach(el => {
        if (el.roleRate.roleId === ExecutorRoleId) {
          mainAssignee.push(el)
        }
        if (el.otherRoles.length) {
          el.otherRoles.forEach(subEl => {
            if (subEl.id === ExecutorRoleId) {
              el.isAdditional = true
              additionalAssignee.push(el)
            }
          })
        }
      })
      return [...mainAssignee, ...additionalAssignee]
    },
    changeAssignee(payload, itemId) {
      this.$emit('task-changed-assignee', { id: itemId, executor: { ...payload } })
    },
    focusToInput(event) {
      const input = event.target.parentElement.querySelector('input')
      const outFocus = () => {
        input.removeEventListener('focusout', outFocus)
      }
      setTimeout(() => {
        input.focus()
      })
      input.addEventListener('focusout', outFocus)
    },
    changeItemName(item) {
      if (item.ownName) {
        this.$emit('task-changed-name', { id: item.id, ownName: item.ownName })
      }
    },
    resizeFrame(event) {
      if (!this.isShowSideBar) return
      event.stopPropagation()
      const parent = this.$refs.ganttSidebar

      function move(event) {
        parent.style.width = parent.offsetWidth + event.movementX + 'px'
      }

      function mouseup(event) {
        if (window.getSelection) {
          window.getSelection().removeAllRanges()
        }
        window.removeEventListener('mousemove', move)
        window.removeEventListener('mouseup', mouseup)
      }

      window.addEventListener('mousemove', move)
      setTimeout(() => {
        window.addEventListener('mouseup', mouseup)
      })
    },
    toggleSidebarShowing(event) {
      let parent = this.$refs.ganttSidebar
      if (parent.style.width === '0px') {
        parent.style.width = '350px'
      } else parent.style.width = '0px'
    },
    changeStatus(localItem, item) {
      this.$emit('task-changed-status', localItem, item)
    },
    addSortableJS() {
      const options = {
        animation: 150,
        handle: '.draggable-btn',
        draggable: '.parent-item',
        onUpdate: (event) => {
          const newIndex = event.newIndex
          const oldIndex = event.oldIndex
          const dependentElement = this.tasks[newIndex]
          const movedElement = this.tasks.splice(oldIndex, 1)[0]
          this.tasks.splice(newIndex, 0, movedElement)
          setTimeout(() => {
            this.gantt.refresh(this.tasks)
          })
          this.$emit('task-changed-position', this.tasks.map((el, index) => {
            return { id: el.id, typename: el.__typename, positionInOrder: index }
          }), 'Parent')
          this.updateTasks()
        }
      }
      const optionsChild = {
        animation: 150,
        handle: '.draggable-btn',
        draggable: '.child-item',
        onUpdate: (event) => {
          const parentId = event.target.dataset.id
          const newIndex = event.newIndex - 1
          const oldIndex = event.oldIndex - 1
          let childrenArr = []
          childrenArr = this.tasks.find(el => el.id === parentId).productPlans
          const movedElement = childrenArr.splice(oldIndex, 1)[0]
          childrenArr.splice(newIndex, 0, movedElement)
          setTimeout(() => {
            this.gantt.refresh(this.tasks)
          })
          this.$emit('task-changed-position', childrenArr.map((el, index) => {
            return { id: el.id, typename: el.__typename, positionInOrder: index }
          }), 'Child')
          this.updateTasks()
        }
      }
      sortablejs.create(document.getElementById('d-table-draggable'), options)
      for (const parentItem of document.querySelectorAll('.parent-item')) {
        sortablejs.create(parentItem, optionsChild)
      }
    },
    /* Gantt */
    setupGantt() {
      this.getTasks()
      this.gantt = new Gantt('#gantt', this.tasks, {
        column_width: 10,
        step: 50,
        bar_height: 20,
        custom_popup_html: () => {
          return ``;
        },
        on_click: (task) => {
          this.$emit('task-clicked', task.id)
        },
        on_date_change: (task, start, end) => {
          this.changeDate({ id: task.id, start: start, end: end })
        },
        on_progress_change: (task, progress) => {
          this.$emit('task-changed-completing', { id: task.id, progress: progress })
        }
      })
      this.gantt.change_view_mode('Day')
      const wrapper = document.querySelector('.gantt-sidebar-wrapper')
    },
    changeDate(item) {
      this.$emit('task-date-updated', item)
    },
    getTasks() {
      this.tasks.sort((a, b) => a.positionInOrder - b.positionInOrder)
      for (const item of this.tasks) {
        if (item.__typename === 'CompositeItem') {
          item.dependencies = item.productPlans.map(e => e.id)
          item.start = item.dateStart
          item.end = item.dateEnd
          item.maxProgress = item.totalCount
          item.progress = item.totalCompletedCount
          item.productPlans.forEach(el => {
            el.start = el.correctDateStart ? el.correctDateStart : el.dateStartPrediction
            el.end = el.expirationDate ? el.expirationDate : el.dateEndPrediction
            el.maxProgress = el.count
            el.progress = el.completedCount
            el.fixed = el.fixedBySalary
          })
        } else {
          item.start = item.correctDateStart ? item.correctDateStart : item.dateStartPrediction
          item.end = item.expirationDate ? item.expirationDate : item.dateEndPrediction
          item.maxProgress = item.count
          item.progress = item.completedCount
          item.fixed = item.fixedBySalary
        }
        /* if (item.productPlans) {
          item.productPlans.forEach(el => {
            el.progress = el.completedCount
            el.maxProgress = el.count
          })
          item.dependencies = item.productPlans.map(el => el.id).join(', ')
        } else item.dependencies = '' */
        delete item._start
        delete item._end
        delete item._index
      }
    },
    updateTasks() {
      /* console.log('updateTasks', 'Tasks were updated') */
      this.getTasks()
      setTimeout(() => {
        this.gantt.refresh(this.tasks)
      })
    }
  },
  mounted() {
    this.addSortableJS()
    this.setupGantt()
    this.$emit('interface', { updateTasks: this.updateTasks })
  },
  watch: {
    tasks: {
      handler() {
        this.updateTasks()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '~frappe-gantt/dist/frappe-gantt.min.css';

.side-stick {
  width: 12px;
  height: 100%;
  background: rgb(239 239 239);
  position: absolute;
  right: 0;
  top: 0;
  cursor: col-resize;

  &:hover {
	background: #c1c1c1;
  }
}

.gantt-sidebar-wrapper {
  flex: 1 0 auto;
}

.col {
  display: flex;
  flex-direction: column;
}

.gantt-sidebar {
  overflow-x: scroll;
  min-width: 500px;

  .gantt-sidebar-header {
	height: 60px;
  }

  .gantt-sidebar-list {
	min-width: 750px;
  }

  .parent-item {
	&:nth-child(2n) {
	  background: #f5f5f5;
	}
  }

  .gantt-sidebar-item {
	position: relative;
	height: 38px;
	display: flex;
	align-items: center;
	cursor: pointer;

	.gantt-sidebar-item--open-btn {
	  display: none;
	  position: absolute;
	  top: 50%;
	  right: 15px;
	  transform: translateY(-50%);

	  &:hover {
		color: gray;
	  }
	}

	&:hover {
	  .gantt-sidebar-item--open-btn {
		display: block;
	  }
	}
  }

  .flip-list-move {
	transition: transform 0.5s;
  }

  .ghost {
	opacity: .7;
	background: #c8ebfb;
  }
}

.gant-main {
  overflow: hidden;
  flex: 0 1 auto;
}
</style>