<template>
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7498 11.4994C13.0645 11.4994 13.3372 11.3955 13.569 11.1903L9.6026 7.22372C9.50744 7.29187 9.4152 7.35813 9.32807 7.42114C9.03124 7.63984 8.79033 7.81049 8.60534 7.93278C8.42037 8.05534 8.17428 8.18027 7.86708 8.30782C7.55967 8.43552 7.27329 8.49916 7.00762 8.49916H6.99985H6.99207C6.72638 8.49916 6.43999 8.43554 6.13262 8.30782C5.82524 8.18027 5.57914 8.05534 5.39435 7.93278C5.20939 7.81049 4.96861 7.63986 4.67163 7.42114C4.58886 7.36047 4.49708 7.2939 4.39785 7.22266L0.430664 11.1903C0.662449 11.3955 0.935309 11.4994 1.24998 11.4994H12.7498Z" fill="#091849"/>
    <path d="M0.789147 4.72665C0.492343 4.52877 0.229142 4.30215 0 4.04688V10.0817L3.49599 6.58571C2.79659 6.09743 1.89546 5.47846 0.789147 4.72665Z" fill="#091849"/>
    <path d="M13.2187 4.72665C12.1546 5.44689 11.2502 6.06693 10.5054 6.58711L14 10.0819V4.04688C13.7759 4.29701 13.5155 4.52348 13.2187 4.72665Z" fill="#091849"/>
    <path d="M12.7505 0.5H1.25065C0.849459 0.5 0.541013 0.635477 0.324937 0.906151C0.108682 1.17695 0.000732422 1.51562 0.000732422 1.92177C0.000732422 2.24984 0.143987 2.60531 0.43037 2.98828C0.716752 3.37109 1.02149 3.67179 1.34442 3.89051C1.52145 4.01559 2.05533 4.38674 2.94605 5.00383C3.42688 5.33703 3.84502 5.62746 4.20436 5.87808C4.51064 6.09149 4.77478 6.2763 4.99287 6.42967C5.0179 6.44723 5.05728 6.4754 5.10953 6.51276C5.16583 6.5532 5.23708 6.60452 5.32498 6.66799C5.49423 6.7904 5.63484 6.88935 5.74684 6.96494C5.85867 7.04056 5.99418 7.12502 6.15314 7.21882C6.31198 7.31248 6.46179 7.38291 6.6024 7.42976C6.74304 7.47658 6.87323 7.50006 6.99299 7.50006H7.00077H7.00855C7.12829 7.50006 7.25851 7.47658 7.39917 7.42976C7.53976 7.38291 7.68944 7.31263 7.84841 7.21882C8.00722 7.12502 8.14254 7.04038 8.25473 6.96494C8.36673 6.88935 8.50734 6.79042 8.67662 6.66799C8.76434 6.60452 8.83558 6.55317 8.89188 6.51289C8.94417 6.47537 8.98351 6.44739 9.0087 6.42967C9.1786 6.31145 9.44335 6.1274 9.7993 5.88024C10.447 5.43022 11.4009 4.76785 12.665 3.89051C13.0451 3.62497 13.3628 3.30452 13.618 2.92964C13.8728 2.55475 14.0006 2.16151 14.0006 1.75005C14.0006 1.40627 13.8767 1.11211 13.6295 0.867109C13.3821 0.622412 13.089 0.5 12.7505 0.5Z" fill="#091849"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
