import gql from 'graphql-tag'

export default function getExchange() {
  async function getExchange (currency, obj, valueAmount = 'amount', valueCurrency = 'currency') {
    await this.$apollo.query({
      query: gql`query getOrders ($currency: Currency!, $value: Money! ) {
          pricing {
            convertMoney(currency: $currency, value: $value)
          }
        }`,
      variables: {
        currency: currency,
        value: obj[valueAmount] + ' ' + obj[valueCurrency]
      },
      fetchPolicy: 'no-cache'
    }).then((data) => {
      obj[valueAmount] = Math.round(+data.data.pricing.convertMoney.amount * 100) / 100
      obj[valueCurrency] = data.data.pricing.convertMoney.currency
      obj.symbol = data.data.pricing.convertMoney.symbol
    }).catch((err) => {
      this.$emit('addSnack', 'item.name', 'Error', err)
      console.log(err)
    })
  }
  return {
    getExchange
  }
}
