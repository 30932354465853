<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4H22.0053C25.316 4 28 6.684 28 9.99467V22.0067C28 25.316 25.316 28 22.0053 28H9.99467C6.684 28 4 25.316 4 22.0053V10C4 6.68667 6.68667 4 10 4V4Z" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5066 21.2532L10.08 21.9999" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.92 22L17.56 14.3867" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.6533 9.33333L16 11.68" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.3467 9.33333L12.0667 18.5467" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.8267 18.5467H9.33334" stroke="#010820" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.6667 18.5467H19.9333" stroke="#010820" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
