<template>
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.45609 2.31579V1.08772" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.45609 9.68426V10.9123" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.32561 8.80681C1.64421 9.32751 2.18737 9.68821 2.84281 9.68821H3.45754H4.18877C5.14175 9.68821 5.91228 8.91628 5.91228 7.9647C5.91228 7.17313 5.37333 6.484 4.60702 6.29032L2.30526 5.71207C1.54035 5.51979 1 4.82927 1 4.03769C1 3.0847 1.77193 2.31418 2.72351 2.31418H3.45474H4.06947C4.72491 2.31418 5.26667 2.67207 5.58526 3.19137" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>
