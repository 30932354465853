const rules = {
  required: value => (value !== null && String(value).length > 0) || 'Required.',
  onlyNumber: event => {
    if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault()
  },
  date: (event, value) => {
    if (value.length > 10) return event.preventDefault()
  }
}

export default rules
