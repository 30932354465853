<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 13.067V18.972C21 20.092 20.099 21 18.987 21H5.013C3.901 21 3 20.092 3 18.972V6.028C3 4.908 3.901 4 5.013 4H12" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.82788 17.1718L11.5589 16.2388C11.7349 16.1948 11.8949 16.1037 12.0239 15.9757L20.4139 7.58575C21.1949 6.80475 21.1949 5.53875 20.4139 4.75775L20.2419 4.58575C19.4609 3.80475 18.1949 3.80475 17.4139 4.58575L9.02388 12.9757C8.89588 13.1037 8.80488 13.2648 8.76088 13.4408L7.82788 17.1718" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.8298 6.16992L18.8298 9.16992" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5458 16.2419C11.5598 16.1359 11.5778 16.0309 11.5778 15.9209C11.5778 14.5399 10.4588 13.4209 9.07784 13.4209C8.96784 13.4209 8.86284 13.4399 8.75684 13.4529" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>
