<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.8351 15L16.8351 20C17.6631 20.828 19.0071 20.828 19.8351 20V20C20.6631 19.172 20.6631 17.828 19.8351 17L14.8351 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.916 5.84669C20.94 5.86969 20.958 5.89969 20.967 5.93169C21.437 7.49869 21.073 9.26169 19.835 10.4997C18.584 11.7507 16.797 12.1087 15.218 11.6167L6.87104 19.9637C6.05804 20.7767 4.73204 20.8377 3.89104 20.0537C3.00704 19.2307 2.98904 17.8467 3.83504 16.9997L12.218 8.61669C11.726 7.03769 12.084 5.25069 13.335 3.99969C14.573 2.76169 16.336 2.39769 17.903 2.86769C17.935 2.87769 17.965 2.89469 17.988 2.91869L18.15 3.08069C18.228 3.15869 18.228 3.28569 18.15 3.36369L15.515 5.99969L17.835 8.31969L20.471 5.68369C20.549 5.60569 20.676 5.60569 20.754 5.68369L20.916 5.84669V5.84669Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.93304 4.29296L3.60704 6.31596C3.74304 6.72496 4.12504 6.99996 4.55604 6.99996H6.83504V4.72096C6.83504 4.29096 6.56004 3.90796 6.15104 3.77196L4.12804 3.09796C3.94804 3.03796 3.75004 3.08496 3.61604 3.21896L3.05404 3.78096C2.92004 3.91496 2.87304 4.11296 2.93304 4.29296V4.29296Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.84009 7L10.3401 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'SidebarDesignTools'
}
</script>

<style scoped>

</style>
