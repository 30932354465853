<!--
  Добавлен V if, чтобы избежать ошибок
-->
<template>
  <v-navigation-drawer
    v-if="frame.visible"
    v-model="frame.visible"
    fixed
    right
    :width="frame.width"
    class="edit-item"
  >
    <div class="py-4">
      <v-row no-gutters class="header mb-4 px-4">
        <v-col>
          <v-btn
            @click="closeFrame"
            elevation="0"
            x-small
            icon
            fab
          >
            <svg viewBox="0 0 16 16" class="doubleChevronRight" style="width: 16px; height: 16px; display: block; fill: rgba(55, 53, 47, 0.45); flex-shrink: 0; backface-visibility: hidden;">
              <path
                d="M2.25781 14.1211C2.47656 14.1211 2.66797 14.0391 2.81836 13.8887L8.14355 8.67969C8.32812 8.49512 8.41699 8.29688 8.41699 8.06445C8.41699 7.8252 8.32812 7.62012 8.14355 7.44922L2.81836 2.24023C2.66797 2.08984 2.4834 2.00781 2.25781 2.00781C1.81348 2.00781 1.46484 2.35645 1.46484 2.80078C1.46484 3.0127 1.55371 3.21777 1.7041 3.375L6.50977 8.05762L1.7041 12.7539C1.55371 12.9043 1.46484 13.1094 1.46484 13.3281C1.46484 13.7725 1.81348 14.1211 2.25781 14.1211ZM8.36914 14.1211C8.58789 14.1211 8.77246 14.0391 8.92285 13.8887L14.2549 8.67969C14.4395 8.49512 14.5283 8.29688 14.5283 8.06445C14.5283 7.8252 14.4326 7.62012 14.2549 7.44922L8.92285 2.24023C8.77246 2.08984 8.58789 2.00781 8.36914 2.00781C7.9248 2.00781 7.56934 2.35645 7.56934 2.80078C7.56934 3.0127 7.66504 3.21777 7.81543 3.375L12.6211 8.05762L7.81543 12.7539C7.66504 12.9043 7.56934 13.1094 7.56934 13.3281C7.56934 13.7725 7.9248 14.1211 8.36914 14.1211Z">
              </path>
            </svg>
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            elevation="0"
            x-small
            icon
            fab
          >
            <v-icon size="20" class="stroke-gray">$delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="px-12">
        <v-row no-gutters class="fz-32 font-weight-bold mb-4" align="center">
          <v-img
            v-if="item.iconUrl && $vuetify.breakpoint.smAndUp"
            class="my-auto mr-2"
            max-width="32"
            max-height="32"
            :src="item.iconUrl"
          />
          <div>{{ item.name }}</div>
          <span>&nbsp;({{ Math.round(item.completedCount / item.count * 100) }}%)</span>
        </v-row>
        <v-row no-gutters class="flex-wrap fz-14">
          <!--Difficulty-->
          <v-row no-gutters class="width-100 py-1">
            <v-col cols="2" class="name-col py-1">
              Difficulty
            </v-col>
            <v-col>
              <selectMenu
                header="Select difficulty"
                @clicked="changeDifficulty"
                :items="$store.getters.getDifficultyTypes"
              >
                {{ item.difficulty.name }}
              </selectMenu>
            </v-col>
          </v-row>
          <!--Status-->
          <v-row no-gutters class="width-100 py-1">
            <v-col cols="2" class="name-col py-1">
              Status
            </v-col>
            <v-col :class="isDisabled(item.fixedBySalary)">
              <selectMenu
                :disabled="item.fixedBySalary"
                header="Select status"
                @clicked="changeStatus"
                :items="$store.getters.getStatusLabels"
                value="label"
              >
                {{ getStatusLabel(item.status) }}
              </selectMenu>
            </v-col>
          </v-row>
          <!--Page-->
          <v-row no-gutters class="width-100 py-1">
            <v-col cols="2" class="name-col py-1">
              Pages
            </v-col>
            <v-col>
              <v-col class="px-2 py-1">{{ item.completedCount }} -> {{ item.count }}</v-col>
            </v-col>
          </v-row>
          <!--Assignee-->
          <v-row no-gutters class="width-100 py-1">
            <v-col cols="2" class="name-col py-1">
              Assignee
            </v-col>
            <v-col :class="isDisabled(item.fixedBySalary)">
              <selectMenu
                :disabled="item.fixedBySalary"
                header="Select Assignee"
                :items="getDesignersForItem"
                @clicked="changeAssignee"
                value="businessName"
              >
                {{ item.executor.businessName }}
                <template v-slot:additionalField="{ item }">
                  <span class="text-gray">{{ item.isAdditional ? 'Additional Assignee' : '' }}</span>
                </template>
              </selectMenu>
            </v-col>
          </v-row>
          <!--Date-->
          <v-row no-gutters class="width-100 py-1">
            <v-col
              cols="2"
              class="name-col py-1"
            >
              Date
            </v-col>
            <div :class="isDisabled(item.fixedBySalary)">
              <DateOneLine
                :disabled="item.fixedBySalary"
                :item="item"
                :format="'Extension'"
                @change="changeDate"
              />
            </div>
          </v-row>
          <!--Discount-->
          <v-row v-if="this.order.type !== 'CUSTOM'" no-gutters class="width-100 py-1">
            <v-col cols="2" class="name-col py-1">
              Discount
            </v-col>
            <v-col>
              <selectMenu
                header="Select discount"
                @clicked="changeDiscount"
                :items="$store.getters.getAllDiscounts"
                value="name"
              >
                <span class="text-gray">
                  {{ item.discount ? `${ item.discount.name } (${Math.round(item.discount.rate)}%)` : 'Empty' }}
                </span>
                <template v-slot:additionalField="{ item }">
                  - {{ `${Math.round(item.rate)}%` }}
                </template>
              </selectMenu>
            </v-col>
          </v-row>
          <!--Prices Info-->
          <!--Skeleton loading for testing-->
          <v-row no-gutters class="py-1 width-100 flex-column">
            <v-divider class="my-2 mx-0 bt-gray width-100"/>
            <!--Price-->
            <v-row v-if="this.order.type !== 'CUSTOM'" no-gutters class="width-100 py-1" align="center">
              <v-col cols="2" class="name-col py-1">
                Price
              </v-col>
              <v-col class="px-2" :class="{'skeleton': loading}">
                {{ item.price }}
              </v-col>
            </v-row>
            <!--Subtotal-->
            <v-row no-gutters class="width-100 py-1" align="center">
              <v-col cols="2" class="name-col py-1">
                Subtotal
              </v-col>
              <v-col class="px-2" :class="{'skeleton': loading}">
                {{ item.subtotal }}
              </v-col>
            </v-row>
            <!--Designer/Assignee cost-->
            <v-row no-gutters class="width-100 py-1" align="center">
              <v-col cols="2" class="name-col py-1">
                Assignee
              </v-col>
              <v-col class="px-2" :class="{'skeleton': loading}">
                {{ item.designerCost }}
              </v-col>
            </v-row>
            <!--Profit-->
            <v-row no-gutters class="width-100 py-1" align="center">
              <v-col cols="2" class="name-col py-1">
                Profit
              </v-col>
              <v-col class="px-2" :class="{'skeleton': loading}">
                {{ item.profit }}
              </v-col>
            </v-row>
            <v-divider class="my-2 mx-0 bt-gray width-100"/>
          </v-row>
          <!--Change Rate-->
          <v-row no-gutters class="width-100 py-1" align="center">
            <v-col cols="2" class="name-col py-1">
              Change Rate
            </v-col>
            <v-col class="px-2 py-1">
              <v-btn
                elevation="0"
                x-small
                icon
                fab
              >
                <v-icon size="20" class="stroke-gray">$edit</v-icon>
              </v-btn>
              (Not work)
            </v-col>
          </v-row>
          <!--Comment-->
          <v-row no-gutters class="py-1 width-100 flex-column">
            <v-divider class="my-4 mx-0 bt-gray width-100"/>
            <v-row no-gutters align="center">
              <v-col cols="2" class="name-col py-1">
                Comment
              </v-col>
              <v-text-field
                v-model="item.comment"
                class="clickable border-none fz-14"
                placeholder="Add a comment"
                dense
                outlined
                hide-details
                @blur="changeComment(item)"
              />
            </v-row>
            <v-divider class="my-4 mx-0 bt-gray width-100"/>
          </v-row>
        </v-row>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import TimeFormat from '@/mixins/TimeFormat'
import StatusLabel from '@/mixins/StatusLabel'
import selectMenu from '@/components/App/Orders/EditItem/selectMenu'
import DateOneLine from '@/components/App/UI/DateOneLine'

export default {
  name: 'EditItem',
  components: {
    selectMenu,
    DateOneLine
  },
  mixins: [TimeFormat, StatusLabel],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      frame: {
        width: 900,
        visible: false
      },
      date: ''
    }
  },
  computed: {
    getDesignersForItem() {
      const mainAssignee = []
      const additionalAssignee = []
      this.$store.getters.getAllDesigners.forEach(el => {
        if (el.roleRate.roleId === this.item.executorRole.roleId) {
          mainAssignee.push(el)
        }
        if (el.otherRoles.length) {
          el.otherRoles.forEach(subEl => {
            if (subEl.id === this.item.executorRole.roleId) {
              el.isAdditional = true
              additionalAssignee.push(el)
            }
          })
        }
      })
      return [...mainAssignee, ...additionalAssignee]
    }
  },
  methods: {
    isDisabled(value) {
      return value ? 'disabled' : ''
    },
    closeFrame() {
      this.frame.visible = false
      this.$emit('closeFrame')
    },
    changeDate(date) {
      this.item.correctDateStart = date.start
      this.item.expirationDate = date.end
      this.$emit('date-updated', { id: this.item.id, start: date.start, end: date.end })
    },
    changeDifficulty(item) {
      this.$emit('changed-difficulty', { id: this.item.id, difficulty: { ...item } })
      this.item.difficulty = item
    },
    changeStatus(item) {
      /* Old item, New item */
      this.$emit('changed-status', this.item, item)
    },
    changeAssignee(item) {
      this.$emit('changed-assignee', { id: this.item.id, executor: { ...item } })
    },
    changeDiscount(item) {
      this.$emit('changed-discount', { id: this.item.id, discount: { ...item } })
    },
    changeComment(item) {
      if (item.comment) this.$emit('changed-comment', item.id, item.comment)
    }
  },
  mounted() {
    this.$emit('interface', {
      openFrame: () => {
        this.frame.visible = true
      },
      closeFrame: () => {
        this.frame.visible = false
      }
    })
  },
  watch: {
    item: {
      handler() {
        console.log('EDIT ITEM FRAME UPDATE')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.edit-item {
  .v-input--is-focused {
    border-radius: 6px;
    background: #dddddd;
  }
}

.name-col {
  color: #7E86A0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
