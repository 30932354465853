<template>
  <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" width="2" height="10"/>
    <rect y="6" width="2" height="10" transform="rotate(-90 0 6)"/>
  </svg>
</template>

<script>
export default {
  name: 'Add'
}
</script>

<style scoped>

</style>
