<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 11H14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 14H14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 17H12.33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'PaymentContract'
}
</script>

<style scoped>

</style>
