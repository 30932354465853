<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.501 12.2336C22.501 11.3702 22.4296 10.7402 22.2748 10.0869H12.2153V13.9835H18.12C18.001 14.9519 17.3582 16.4102 15.9296 17.3902L15.9096 17.5206L19.0902 19.9354L19.3106 19.9569C21.3343 18.1252 22.501 15.4302 22.501 12.2336Z" fill="#4285F4"/>
    <path d="M12.2147 22.4996C15.1075 22.4996 17.5361 21.5662 19.3099 19.9562L15.929 17.3895C15.0242 18.0078 13.8099 18.4395 12.2147 18.4395C9.38142 18.4395 6.97669 16.6079 6.11947 14.0762L5.99382 14.0866L2.68656 16.595L2.64331 16.7128C4.40519 20.1428 8.02423 22.4996 12.2147 22.4996Z" fill="#34A853"/>
    <path d="M6.1201 14.0765C5.89391 13.4232 5.76301 12.7231 5.76301 11.9998C5.76301 11.2764 5.89391 10.5765 6.1082 9.92313L6.1022 9.78398L2.75349 7.23535L2.64393 7.28642C1.91777 8.70977 1.5011 10.3081 1.5011 11.9998C1.5011 13.6915 1.91777 15.2897 2.64393 16.7131L6.1201 14.0765Z" fill="#FBBC05"/>
    <path d="M12.2148 5.55997C14.2267 5.55997 15.5838 6.41163 16.3576 7.12335L19.3814 4.23C17.5243 2.53834 15.1076 1.5 12.2148 1.5C8.02426 1.5 4.4052 3.85665 2.64331 7.28662L6.10759 9.92332C6.97672 7.39166 9.38146 5.55997 12.2148 5.55997Z" fill="#EB4335"/>
  </svg>
</template>

<script>
export default {
}
</script>
