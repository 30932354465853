<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    class="z-index-400"
    overlay-color="rgba(9, 24, 73, 0.2)"
    overlay-opacity="1"
  >
    <v-card v-if="dialog" style="max-height: 100vh" class="d-flex flex-column height-100 edit-order">
      <!--Header -->
      <v-toolbar
        elevation="0"
        class="bb-gray flex-grow-0"
        style="z-index: 1"
      >
        <v-col>
          <v-row></v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-spacer></v-spacer>
            <v-toolbar-title class="fz-24 font-weight-bold">
              {{ orderTitle }}
            </v-toolbar-title>
            <RemoveDialog
              @commit="setFixedConditionToOrder(order.id, order.hasFixedBySalary)"
              :deleting="false"
              :disabled="readOnly"
            >
              <template v-slot:message>
                <span>
                  {{ order.hasFixedBySalary ? 'unlocking' : 'locking' }}
                </span>
              </template>
              <template>
                <v-btn
                  :disabled="readOnly"
                  elevation="0"
                  x-small
                  icon
                  fab
                >
                  <v-icon v-if="order.hasFixedBySalary" size="24" class="stroke-black path-black">$lock</v-icon>
                  <v-icon v-else size="24" class="stroke-gray path-gray">$openLock</v-icon>
                </v-btn>
              </template>
            </RemoveDialog>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-icon class="mr-5 close-icon" @click="closeEdit">$close</v-icon>
            </v-toolbar-items>
          </v-row>
        </v-col>
      </v-toolbar>
      <!--Main info -->
      <v-stepper v-model="stepper" elevation="0" class="py-0 flex-grow-1">
        <!--Client-->
        <v-stepper-content step="1" class="px-0 pt-10 pb-3 stepper-height scroll">
          <v-form ref="step1">
            <v-row class="ma-0 align-center justify-center">
              <v-col class="pa-0 min-width-600 flex-grow-0">
                <v-row class="mx-0 mb-3 mt-12 fz-20 fw-600">Client Area</v-row>
                <v-divider class="black mb-10 bb-1"/>
                <v-row class="ma-0 mb-3 relative">
                  <v-col class="pa-0">
                    <v-combobox
                      v-model="order.client"
                      :items="clientsArr"
                      :search-input.sync="clientSearch"
                      class="mx-4 select-client input-border text-black font-weight-medium rounded-lg"
                      item-text="name"
                      :menu-props="{contentClass: 'select-client-menu'}"
                      flat
                      height="80"
                      outlined
                      single-line
                      hide-details
                      @blur="verificationClientInput"
                      :readonly="readOnly"
                      :disabled="clientRegistration"
                      :rules="[rules.client]"
                    >
                      <template slot="label" style="'top': '28px'">
                        <span class="mt-2">Enter name or ID</span>
                      </template>
                      <template slot="append">
                        <v-icon v-if="!showClientInputIcons"></v-icon>
                        <v-btn
                          v-if="showClientInputIcons"
                          elevation="0"
                          height="40"
                          width="40"
                          class="mt-1 mr-3 min-width-unset rounded-4 bg-lgray"
                          icon
                          :disabled="readOnly"
                          @click="editClient"
                        >
                          <v-icon size="20" class="stroke-black">$edit</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="showClientInputIcons"
                          elevation="0"
                          height="40"
                          width="40"
                          icon
                          class="mt-1 min-width-unset rounded-4 bg-lgray"
                          :disabled="readOnly"
                          @click="removeClient()"
                        >
                          <v-icon size="20" class="stroke-black">$delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:selection="data">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-black font-weight-medium">
                              {{ data.item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ data.item.telegram }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item class="select-client-hover" @click="selectClient(data.item)">
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">
                              {{ data.item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ data.item.telegram }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                    <v-btn
                      :disabled="clientRegistration"
                      @click="addNewClient"
                      v-if="addAccountBtnShow && !newClient.id"
                      height="40"
                      right
                      absolute
                      elevation="0"
                      class="primary text-none fz-14 fw-600 mt-5 mr-7 new-client-btn" style="top: 0">
                      + Add new client
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="ma-0" v-if="clientRegistration">
                  <v-col>
                    <v-form ref="clientReg">
                      <v-row class="ma-0 mb-3">
                        <span class="fz-14 text-gray width-140 my-auto mr-8">Name</span>
                        <v-text-field
                          outlined
                          single-line
                          v-model="newClient.name"
                          class="rounded-lg mb-n4 text-black font-weight-medium input-border"
                          dense
                          :rules="[rules.required, rules.minCounter]"
                        ></v-text-field>
                      </v-row>
                      <v-row class="ma-0 mb-3">
                        <span class="fz-14 text-gray width-140 my-auto mr-8">Telegram account</span>
                        <v-text-field
                          outlined
                          single-line
                          v-model="newClient.telegram"
                          class="rounded-lg mb-n4 text-black font-weight-medium input-border"
                          dense
                          v-mask="['@XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX']"
                        ></v-text-field>
                      </v-row>
                      <v-row class="ma-0 mb-3">
                        <span class="fz-14 text-gray width-140 my-auto mr-8">Country</span>
                        <v-menu
                          attach="#select31"
                          content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-col class="pa-0">
                              <v-text-field
                                outlined
                                single-line
                                :value="newClient.country ? newClient.country.name : 'None'"
                                class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                                dense
                                readonly
                                :rules="[rules.required]"
                              >
                                <template v-slot:prepend-inner>
                                  <v-sheet
                                    v-bind="attrs"
                                    v-on="on"
                                    class="absolute"
                                    elevation="0"
                                    color="transparent"
                                    style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                                  ></v-sheet>
                                </template>
                                <template v-slot:append>
                                  <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                                </template>
                              </v-text-field>
                              <div id="select31" class="relative z-index-400"></div>
                            </v-col>
                          </template>
                          <v-card class="rounded-lg mt-1 hidden" elevation="0">
                            <v-card-text class="px-0 py-1">
                              <v-list
                                class="pa-0"
                                color="transparent scroll"
                                style="max-height: 300px"
                              >
                                <v-text-field
                                  @click.stop
                                  outlined
                                  single-line
                                  v-model="searchCountriesGeo"
                                  placeholder="Country"
                                  class="rounded-lg border-none fz-16"
                                  prepend-inner-icon="$search"
                                  dense
                                  hide-details
                                />
                                <v-list-item
                                  v-for="(item, index) in countryGeoFilter"
                                  :key="index"
                                  style="min-height: 30px"
                                  class="px-0 py-1"
                                  @click="changeCountry(item)"
                                >
                                  <img style="max-width: 25px; max-height: 25px" :src="item.flagAbsoluteUrl">
                                  <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                                </v-list-item>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-row>
                      <v-row class="ma-0 mb-3">
                        <span class="fz-14 text-gray width-140 my-auto mr-8">Company</span>
                        <v-col class="ma-0 pa-0">
                          <v-combobox
                            outlined
                            :items="companies"
                            :search-input.sync="newClient.companySearch"
                            item-text="name"
                            single-line
                            return-object
                            v-model="newClient.company"
                            class="rounded-lg mb-n4 inline-input font-weight-medium select-dash input-border"
                            dense
                            item-color="#010820"
                            hide-no-data
                          ></v-combobox>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 mb-3">
                        <span class="fz-14 text-gray width-140 my-auto mr-8">Phone</span>
                        <!--                    <v-text-field-->
                        <!--                      outlined-->
                        <!--                      single-line-->
                        <!--                      v-model="newClient.phone"-->
                        <!--                      class="rounded-lg mb-n4 font-weight-medium input-border"-->
                        <!--                      dense-->
                        <!--                      :rules="[rules.required]"-->
                        <!--                      v-mask="['+######################']"-->
                        <!--                    ></v-text-field>-->
                        <v-text-field
                          outlined
                          single-line
                          v-model="phone.number"
                          :placeholder="phone.placeholder"
                          v-mask="phone.mask"
                          class="rounded-lg input-border text-black fz-14 font-weight-medium input-phone"
                          dense
                          :rules="[rules.phoneValid]"
                        >
                          <template v-slot:prepend-inner>
                            <v-menu offset-y left content-class="elevation-0" min-width="100" max-width="150">
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="pl-2 pr-2 fz-14 font-weight-medium text-black d-flex align-center text-no-wrap"
                                  v-bind="attrs"
                                  v-on="on"
                                  style="z-index: 1; height: 40px"
                                >
                                  {{ phone.country }} +{{ phone.countryCode }}
                                  <v-icon
                                    :class="{'dropdown-arrow-rotate': attrs['aria-expanded'] === 'true' ? true : false }"
                                    class="my-auto mr-n2 dropdown-arrow text-black">mdi-menu-down
                                  </v-icon>
                                </div>
                              </template>
                              <v-list
                                elevation="0"
                                class="px-4 pb-0 pt-4 hidden elevation-2 rounded-lg scroll"
                                style="max-height: 300px"
                              >
                                <v-list-item class="px-0">
                                  <v-text-field
                                    @click.stop
                                    outlined
                                    single-line
                                    v-model="countrySearch"
                                    placeholder="Country"
                                    class="rounded-lg text-black fz-14 font-weight-medium input-border"
                                    dense
                                    hide-details
                                  ></v-text-field>
                                </v-list-item>
                                <v-list-item
                                  v-for="country in countryFilter"
                                  :key="country"
                                  class="mx-0 px-3 scroll"
                                  @click="setCountry(country)"
                                >
                                  <v-list-item-title class="fz-14 d-flex align-center pointer">
                                    <span class="fw-600">{{ country }}</span>
                                    <v-spacer></v-spacer>
                                    <span>+{{ getCountryCode(country) }}</span>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-row>
                      <v-row class="ma-0 mb-3">
                        <span class="fz-14 text-gray inline-input width-140 my-auto mr-8">Source</span>
                        <v-col class="ma-0 pa-0">
                          <v-combobox
                            outlined
                            :items="channels"
                            :search-input.sync="newClient.channelsSearch"
                            item-text="name"
                            single-line
                            return-object
                            v-model="newClient.channel"
                            class="rounded-lg mb-n4 font-weight-medium select-dash input-border"
                            dense
                            item-color="#010820"
                            hide-no-data
                          ></v-combobox>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0">
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="cancelClientRegistration"
                          height="40"
                          class="rounded-lg fz-16 fw-600 text-none mr-3"
                          elevation="0"
                          outlined
                          color="primary">
                          Cancel
                        </v-btn>
                        <v-btn
                          @click="saveClient"
                          height="40"
                          class="rounded-lg fz-16 fw-600 text-none"
                          elevation="0"
                          color="primary">
                          Save
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
                <ProjectSearch
                  :project.sync="order.project"
                  :readOnly="readOnly"
                  @updateProject="selectProject($event)"
                  @removeProject="removeProject()"
                >
                  <template>
                    <v-row class="mx-0 mb-3 mt-12 fz-20 fw-600">Project Area</v-row>
                    <v-divider class="black mb-10 bb-1"/>
                  </template>
                </ProjectSearch>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <!--Order-->
        <v-stepper-content step="2" class="px-0 pt-10 pb-3 stepper-height scroll">
          <v-form ref="step2" :class="{'px-3': $vuetify.breakpoint.smAndDown}">
            <v-row class="ma-0 justify-center align-center">
              <v-col class="pa-0 min-width-600 flex-grow-0">
                <v-row class="mx-0 mb-2 mt-6 fz-20 fw-600 text-black">Order Details</v-row>
                <v-divider class="black mt-3 mb-10 bb-1"/>
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Order Type</span>
                  <v-text-field
                    v-if="readOnly"
                    outlined
                    single-line
                    :value="order.type === 'REGULAR' ? 'Regular order' : order.type === 'CUSTOM' ? 'Hubnero order' : order.type === 'LOADED' ? 'Custom order' : '' "
                    class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                    dense
                    readonly
                  >
                    <template v-slot:prepend-inner>
                      <v-sheet
                        class="absolute pointer"
                        elevation="0"
                        color="transparent"
                        style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                      ></v-sheet>
                    </template>
                    <template v-slot:append>
                      <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                    </template>
                  </v-text-field>
                  <v-menu attach="#select24" v-else
                          content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          single-line
                          :value="order.type === 'REGULAR' ? 'Regular order' : order.type === 'CUSTOM' ? 'Hubnero order' : order.type === 'LOADED' ? 'Custom order' : '' "
                          class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                          dense
                          readonly
                          :rules="[rules.required]"
                        >
                          <template v-slot:prepend-inner>
                            <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              class="absolute"
                              elevation="0"
                              color="transparent"
                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                            ></v-sheet>
                          </template>
                          <template v-slot:append>
                            <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                          </template>
                        </v-text-field>
                        <div id="select24" class="relative z-index-400"></div>
                      </v-col>
                    </template>
                    <v-card class="rounded-lg mt-1 hidden" elevation="0">
                      <v-card-text class="px-0 py-1">
                        <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                          <v-list-item
                            v-for="(item, index) in orderTypes"
                            :key="index"
                            @click="changeOrderType(item.value)"
                            style="min-height: 30px"
                            class="px-0 py-1"
                          >
                            <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-row>
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Manager</span>
                  <v-select
                    v-if="order.id"
                    filled
                    v-model="order.manager"
                    :items="[order.manager]"
                    item-text="businessName"
                    single-line
                    class="rounded-lg select-avatar inline-input fz-13 font-weight-medium border-none"
                    dense
                    return-object
                    hide-details
                    readonly
                    background-color="#F6F8F9"
                  >
                    <template v-slot:selection="data">
                    <span class="ml-n5">
                      <v-avatar
                        :color="data.item.avatarUrl ? 'transparent' : 'primary'"
                        size="22"
                        class="mr-3 my-auto white--text"
                      >
                      <img
                        v-if="data.item.avatarUrl"
                        :src="data.item.avatarUrl"
                      >
                      <span v-else class="white--text">{{ data.item.businessInitials || '??' }}</span>
                    </v-avatar>
                      {{ data.item.businessName }}
                    </span>
                    </template>
                  </v-select>
                  <v-select
                    v-else-if="me"
                    filled
                    v-model="me"
                    :items="[me]"
                    item-text="businessName"
                    single-line
                    class="rounded-lg select-avatar inline-input fz-13 font-weight-medium border-none"
                    dense
                    return-object
                    hide-details
                    readonly
                    background-color="#F6F8F9"
                  >
                    <template v-slot:selection="data">
                    <span class="ml-n5">
                      <v-avatar
                        :color="data.item.designerUser && data.item.designerUser.avatarUrl ? 'transparent' : 'primary'"
                        size="22"
                        class="mr-3 my-auto"
                      >
                      <img
                        v-if="data.item.designerUser && data.item.designerUser.avatarUrl"
                        :src="data.item.designerUser.avatarUrl"
                      >
                      <span v-else class="white--text">{{ data.item.initials || '??' }}</span>
                    </v-avatar>
                      {{ data.item.fullName }}
                    </span>
                    </template>
                  </v-select>
                </v-row>
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Payment Type</span>
                  <v-text-field
                    v-if="readOnly"
                    outlined
                    single-line
                    :value="order.paymentType ? order.paymentType.name : ''"
                    class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                    dense
                    readonly
                  >
                    <template v-slot:prepend-inner>
                      <v-sheet
                        class="absolute pointer"
                        elevation="0"
                        color="transparent"
                        style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                      ></v-sheet>
                    </template>
                    <template v-slot:append>
                      <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                    </template>
                  </v-text-field>
                  <v-menu attach="#select25" v-else
                          content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          single-line
                          :value="order.paymentType ? order.paymentType.name : ''"
                          class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                          dense
                          readonly
                          :rules="[rules.required]"
                        >
                          <template v-slot:prepend-inner>
                            <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              class="absolute"
                              elevation="0"
                              color="transparent"
                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                            ></v-sheet>
                          </template>
                          <template v-slot:append>
                            <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                          </template>
                        </v-text-field>
                        <div id="select25" class="relative z-index-400"></div>
                      </v-col>
                    </template>
                    <v-card class="rounded-lg mt-1 hidden" elevation="0">
                      <v-card-text class="px-0 py-1">
                        <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                          <v-list-item
                            v-for="(item, index) in paymentTypes"
                            :key="index"
                            @click="changePaymentTypes(item)"
                            style="min-height: 30px"
                            class="px-0 py-1"
                          >
                            <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-row>
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Order name</span>
                  <v-text-field
                    outlined
                    single-line
                    v-model="order.name"
                    :readonly="readOnly"
                    class="rounded-lg mb-n4 fz-14 text-black font-weight-medium font-weight-medium input-border"
                    dense
                    @change="orderChanged"
                    :rules="[rules.required, rules.minCounter]"
                  />
                </v-row>
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Niche type</span>
                  <v-menu
                    attach="#select29"
                    content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          single-line
                          :value="order.niche !== null ? order.niche.name : order.niche = { id: 0, name: 'None' }"
                          class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                          dense
                          readonly
                          :rules="[rules.required]"
                        >
                          <template v-slot:prepend-inner>
                            <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              class="absolute"
                              elevation="0"
                              color="transparent"
                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                            ></v-sheet>
                          </template>
                          <template v-slot:append>
                            <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                          </template>
                        </v-text-field>
                        <div id="select29" class="relative z-index-400"></div>
                      </v-col>
                    </template>
                    <v-card class="rounded-lg mt-1 hidden" elevation="0">
                      <v-card-text class="px-0 py-1">
                        <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                          <v-list-item
                            v-for="(item, index) in niches"
                            :key="index"
                            style="min-height: 30px"
                            class="px-0 py-1"
                            @click="changeNiche(item)"
                          >
                            <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-row>
                <!--Removed due to disuse-->
                <!--Mobile version-->
                <!--<v-row class="ma-0 mb-3">
                <span class="fz-14 text-gray width-140 my-auto mr-8">Mobile version</span>
                <v-switch
                  v-model="order.mobile"
                  hide-details
                  dense
                  inset
                  @change="updateMobile"
                  :readonly="readOnly"
                  color="#34C759"
                  class="pa-0 my-2 default-switch"
                ></v-switch>
              </v-row>-->
                <!--Prototype-->
                <!--<v-row class="ma-0 mb-3">
                <span class="fz-14 text-gray width-140 my-auto mr-8">Prototype</span>
                <v-switch
                  v-model="order.prototype"
                  hide-details
                  inset
                  @change="updatePrototype"
                  dense
                  :readonly="readOnly"
                  color="#34C759"
                  class="pa-0 my-2 default-switch"
                ></v-switch>
              </v-row>-->
                <!--Author supervision-->
                <!--<v-row class="ma-0 mb-3">
                <span class="fz-14 text-gray width-140 my-auto mr-8">Author supervision</span>
                <v-switch
                  v-model="order.authorSupervision"
                  hide-details
                  inset
                  dense
                  @change="updateSupervision"
                  :readonly="readOnly"
                  color="#34C759"
                  class="pa-0 my-2 default-switch"
                ></v-switch>
              </v-row>-->
                <!--Fee Rate-->
                <!--<v-row class="ma-0 mb-3">
                <span class="fz-14 text-gray width-140 my-auto mr-8">Fee rate</span>
                <v-text-field
                  outlined
                  single-line
                  :value="feeRate ? feeFocus ? (Math.round(feeRate * 100) / 100) : (Math.round(feeRate * 100) / 100) + '%' : feeFocus ? (Math.round(defaultFee * 100) / 100) : (Math.round(defaultFee * 100) / 100) + '%'"
                  @change="setFee"
                  @focus="feeFocus = true"
                  @blur="feeFocus = false"
                  class="rounded-lg mb-n4 fz-14 text-black font-weight-medium font-weight-medium input-border"
                  dense
                  :readonly="readOnly"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-row>-->
                <!--Date Start-->
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Date Start</span>
                  <v-col class="ma-0 pa-0">
                    <v-text-field
                      outlined
                      single-line
                      :value="order.dateStart ? formatDate(order.dateStart) : ' '"
                      class="rounded-lg mb-n4 fz-14 text-black font-weight-medium input-border"
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!--Date End-->
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Date End</span>
                  <v-col class="ma-0 pa-0">
                    <v-text-field
                      outlined
                      single-line
                      :value="order.dateEnd ? formatDate(order.dateEnd) : ' '"
                      class="rounded-lg mb-n4 fz-14 text-black font-weight-medium input-border"
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!--Why has been canceled-->
                <v-row class="ma-0 mb-3" v-if="canceledItems.length">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Why has been canceled</span>
                  <v-col class="ma-0 pa-0 flex-grow-0">
                    <v-tooltip bottom max-width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="my-auto">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="one-line py-0"
                          >
                            <v-icon size="32">$questionMark</v-icon>
                          </div>
                        </div>
                      </template>
                      <v-col class="d-flex flex-column flex-wrap flex-grow-0">
                        <div
                          v-for="(item, index) of canceledItems"
                          :key="index"
                          class="width-100"
                          style="word-break: break-all"
                        >
                        <span>
                          {{ item.name }}
                        </span>
                          <span>
                          {{ item.orderItemVersion === 'MOBILE' ? '(Mobile version)' : '' }} - {{ item.reason }}
                        </span>
                        </div>
                      </v-col>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <!--Project Difficulties-->
                <!--<v-row class="ma-0 mb-3">
                <span class="fz-14 text-gray width-140 my-auto mr-8">Project Difficulties</span>
                <v-text-field
                  v-if="readOnly"
                  outlined
                  single-line
                  :value="order.difficulty ? order.difficulty.name : ''"
                  class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                  dense
                  readonly
                >
                  <template v-slot:prepend-inner>
                    <v-sheet
                      class="absolute pointer"
                      elevation="0"
                      color="transparent"
                      style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                    ></v-sheet>
                  </template>
                  <template v-slot:append>
                    <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                  </template>
                </v-text-field>
                <v-menu attach="#select26" v-else bottom
                        content-class="menu-shadow rounded-lg bg-white border-lightgray top-n178 left-0 width-100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col class="pa-0">
                      <v-text-field
                        outlined
                        single-line
                        :value="order.difficulty ? order.difficulty.name : ''"
                        class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                        dense
                        readonly
                        :rules="[rules.required]"
                      >
                        <template v-slot:prepend-inner>
                          <v-sheet
                            v-bind="attrs"
                            v-on="on"
                            class="absolute"
                            elevation="0"
                            color="transparent"
                            style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                          ></v-sheet>
                        </template>
                        <template v-slot:append>
                          <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                        </template>
                      </v-text-field>
                      <div id="select26" class="relative z-index-400"></div>
                    </v-col>
                  </template>
                  <v-card class="rounded-lg mt-1 hidden" elevation="0">
                    <v-card-text class="px-0 py-1">
                      <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                        <v-list-item
                          v-for="(item, index) in difficulties"
                          :key="index"
                          @click="changeDifficulties(item)"
                          style="min-height: 30px"
                          class="px-0 py-1"
                        >
                          <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-row>-->
                <!--Designer Lead-->
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Lead</span>
                  <v-menu
                    attach="#select32"
                    content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-col class="pa-0">
                        <v-text-field
                          outlined
                          single-line
                          :value="order.designLead !== null ? order.designLead.businessName : order.designLead = { id: 0, businessName: 'None' }"
                          class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border"
                          dense
                          readonly
                        >
                          <template v-slot:prepend-inner>
                            <v-sheet
                              v-bind="attrs"
                              v-on="on"
                              class="absolute"
                              elevation="0"
                              color="transparent"
                              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                            ></v-sheet>
                          </template>
                          <template v-slot:append>
                            <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                          </template>
                        </v-text-field>
                        <div id="select32" class="relative z-index-400"></div>
                      </v-col>
                    </template>
                    <v-card class="rounded-lg mt-1 hidden" elevation="0">
                      <v-card-text class="px-0 py-1">
                        <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                          <v-list-item
                            v-for="item in leadDesigners"
                            :key="item.id"
                            style="min-height: 30px"
                            class="px-0 py-1"
                            @click="changeDesignLead(item)"
                          >
                            <p class="ma-0 px-2 fz-12 text-black">{{ item.businessName }}</p>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-row>
                <!--RichText-->
                <v-row class="ma-0 mb-3 flex-column">
                  <span class="fz-14 text-gray width-140 mb-4 mr-8">Editor</span>
                  <v-col class="ma-0 custom-border rounded-lg pa-0 height-max">
                    <RichTextEditor
                      ref="RichTextEditor"
                      :isOrderCreated="Boolean(order.id)"
                      :files="order.files"
                      @update:files="(d) => { this.order.files = d }"
                      :value="order.description"
                      @update:value="(d) => { this.order.description = d }"
                      @update:descriptionSlack="(d) => { this.order.descriptionSlack = d }"
                      @updateOrder="updateOrder"
                      @saveFiles="saveFiles"
                      @deleteFile="deleteOrderFile"
                      :isLoaded="dialog"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-0 mb-2 mt-6 fz-20 fw-600 text-black">Additional Links</v-row>
                <v-divider class="black mt-3 mb-10 bb-1"/>
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Figma Link</span>
                  <v-text-field
                    outlined
                    single-line
                    v-model="order.figmaUrl"
                    :readonly="readOnly"
                    dense
                    @change="orderChanged"
                    @click="readOnly ? $refs.linkToFigmaUrl.click() : false"
                    class="rounded-lg mb-n4 fz-14 text-black font-weight-medium font-weight-medium input-border"
                    :class="[readOnly ? 'pointer' : false]"
                    :style="[readOnly ? {'text-decoration': 'underline'} : false]"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="copyFigmaLink"
                        outlined
                        class="transparent my-auto copy-icon"
                        icon
                        elevation="0"
                      >
                        <v-icon size="28">$copyToClipboard</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                  <a
                    v-if="readOnly"
                    :href="order.figmaUrl"
                    target="_blank"
                    ref="linkToFigmaUrl"
                    style="display: none"
                    class="fz-14 text-black font-weight-medium"
                  >
                    {{ order.figmaUrl }}
                  </a>
                </v-row>
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Google Doc</span>
                  <v-text-field
                    outlined
                    single-line
                    v-model="order.googleDocUrl"
                    :readonly="readOnly"
                    dense
                    @change="orderChanged"
                    @click="readOnly ? $refs.linkToGoogleDocUrl.click() : false"
                    class="rounded-lg mb-n4 fz-14 text-black font-weight-medium font-weight-medium input-border"
                    :class="[readOnly ? 'pointer' : false]"
                    :style="[readOnly ? {'text-decoration': 'underline'} : false]"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="copyGoogleDocsUrl"
                        outlined
                        class="transparent my-auto copy-icon"
                        icon
                        elevation="0"
                      >
                        <v-icon size="28">$copyToClipboard</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                  <a
                    v-if="readOnly"
                    target="_blank"
                    :href="order.googleDocUrl"
                    ref="linkToGoogleDocUrl"
                    style="display: none"
                    class="fz-14 text-black font-weight-medium"
                  >
                    {{ order.googleDocUrl }}
                  </a>
                </v-row>
                <v-row class="ma-0 mb-3">
                  <span class="fz-14 text-gray width-140 my-auto mr-8">Jira</span>
                  <v-text-field
                    outlined
                    single-line
                    v-model="order.jiraUrl"
                    :readonly="readOnly"
                    dense
                    @change="orderChanged"
                    @click="readOnly ? $refs.linkToJiraUrl.click() : false"
                    class="rounded-lg mb-n4 fz-14 text-black font-weight-medium font-weight-medium input-border"
                    :class="[readOnly ? 'pointer' : false]"
                    :style="[readOnly ? {'text-decoration': 'underline'} : false]"
                  >
                    <template v-slot:append>
                      <v-btn
                        @click="copyJiraUrl"
                        outlined
                        class="transparent my-auto copy-icon"
                        icon
                        elevation="0"
                      >
                        <v-icon size="28">$copyToClipboard</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                  <a
                    v-if="readOnly"
                    target="_blank"
                    :href="order.jiraUrl"
                    ref="linkToJiraUrl"
                    style="display: none"
                    class="fz-14 text-black font-weight-medium"
                  >
                    {{ order.jiraUrl }}
                  </a>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-stepper-content>
        <!--Items-->
        <v-stepper-content step="3" class="px-0 pt-10 pb-3 stepper-height scroll">
          <v-col v-if="stepper === 3" class="pa-7">
            <v-row
              class="mx-0 mb-3 justify-space-between"
              :class="{
            'mt-12': $vuetify.breakpoint.mdAndUp,
           'flex-column': $vuetify.breakpoint.smAndDown,
           'align-start': $vuetify.breakpoint.smAndDown
          }"
            >
              <v-row
                class="ma-0"
                :class="{'flex-column': $vuetify.breakpoint.xsOnly,
                  'align-start': $vuetify.breakpoint.xsOnly,
                  'flex-grow-0': !me.permissions.orders.orderEdit }"
              >
                <span class="fz-20 fw-600 text-black my-auto">Order Details</span>
                <template v-if="me.permissions.orders.orderEdit">
                  <v-btn
                    width="150"
                    height="40"
                    elevation="0"
                    class="ml-7 my-auto rounded-lg text-none font-weight-medium fz-14 btn-black"
                    :class="{'mt-5': $vuetify.breakpoint.xsOnly}"
                    @click="generateReport"
                    outlined>Download PDF
                  </v-btn>
                  <v-btn
                    width="150"
                    height="40"
                    elevation="0"
                    class="ml-7 my-auto rounded-lg text-none font-weight-medium fz-14 btn-black"
                    :class="{'mt-5': $vuetify.breakpoint.xsOnly}"
                    :href="order.pricingExplanationUrl"
                    target="_blank"
                    outlined>Internal
                  </v-btn>
                </template>
              </v-row>
              <v-btn-toggle
                class="rounded-lg my-auto mr-15 height-30 box-shadow interval black--text hidden"
                style="padding-left: 2px; padding-right: 2px"
                :class="{ 'disable-events': readOnly }"
                background-color="#F2F4F5"
                :value="currency"
                mandatory
                borderless
                dense
              >
                <v-btn
                  :disabled="order.hasFixedBySalary"
                  @click="setCurrency('RUB')"
                  plain
                  height="26"
                  class="rounded-lg my-auto fz-13 text-none"
                >
                  RUB (₽)
                </v-btn>
                <v-btn
                  :disabled="order.hasFixedBySalary"
                  @click="setCurrency('USD')"
                  plain
                  height="26"
                  class="rounded-lg my-auto fz-13 text-none"
                >
                  USD ($)
                </v-btn>
              </v-btn-toggle>
              <v-row class="mx-0 my-5 flex-grow-0">
                <v-sheet
                  v-if="(me.permissions.orders.orderEdit || (me.designerUser && me.designerUser.roleRate.occupation === 'DESIGNER'))&& (+order.totalDesignerCost || +order.totalDesignerSalary)"
                  color="transparent">
                  <!-- Total Designer Salary -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-row class="ma-0 flex-nowrap justify-space-between">
                          <span class="fz-14 font-weight-medium mr-5 my-auto text-dgray">Total Designer Salary</span>
                          <b class="fz-14 fw-600 my-auto">
                            <AmountView :currency="order.currencySymbol">
                              {{ Math.round(+order.totalDesignerSalary) | numberFormat }}
                            </AmountView>
                          </b>
                        </v-row>
                      </div>
                    </template>
                    <v-col>
                      <div class="fz-14 fw-600 my-auto mb-2">
                        <v-icon class="stroke-white mr-2">$desktop</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalDesktopDesignerSalary | numberFormat }}
                        </AmountView>
                      </div>
                      <div class="fz-14 fw-600 my-auto">
                        <v-icon class="stroke-white mr-2">$mobile</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalMobileDesignerSalary | numberFormat }}
                        </AmountView>
                      </div>
                    </v-col>
                  </v-tooltip>
                  <!-- Total Designer Cost -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-row class="ma-0 flex-nowrap justify-space-between">
                          <span class="fz-14 font-weight-medium mr-5 my-auto text-dgray">Total Designer Cost</span>
                          <b class="fz-14 fw-600 my-auto">
                            <AmountView :currency="order.currencySymbol">
                              {{ Math.round(+order.totalDesignerCost) | numberFormat }}
                            </AmountView>
                          </b>
                        </v-row>
                      </div>
                    </template>
                    <v-col>
                      <div class="fz-14 fw-600 my-auto mb-2">
                        <v-icon class="stroke-white mr-2">$desktop</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalDesktopDesignerCost | numberFormat }}
                        </AmountView>
                      </div>
                      <div class="fz-14 fw-600 my-auto">
                        <v-icon class="stroke-white mr-2">$mobile</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalMobileDesignerCost | numberFormat }}
                        </AmountView>
                      </div>
                    </v-col>
                  </v-tooltip>
                </v-sheet>
                <v-sheet
                  v-if="(me.permissions.orders.orderEdit || (me.designerUser && me.designerUser.roleRate.occupation === 'DEVELOPER')) && (+order.totalDeveloperCost || +order.totalDeveloperSalary)"
                  color="transparent" class="ml-15"
                >
                  <!-- Total Dev Salary -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-row class="ma-0 flex-nowrap justify-space-between">
                          <span class="fz-14 font-weight-medium mr-5 my-auto text-dgray">Total Dev Salary</span>
                          <b class="fz-14 fw-600 my-auto">
                            <AmountView :currency="order.currencySymbol">
                              {{ +order.totalDeveloperSalary | numberFormat }}
                            </AmountView>
                          </b>
                        </v-row>
                      </div>
                    </template>
                    <v-col>
                      <div class="fz-14 fw-600 my-auto mb-2">
                        <v-icon class="stroke-white mr-2">$desktop</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalDesktopDeveloperSalary | numberFormat }}
                        </AmountView>
                      </div>
                      <div class="fz-14 fw-600 my-auto">
                        <v-icon class="stroke-white mr-2">$mobile</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalMobileDeveloperSalary | numberFormat }}
                        </AmountView>
                      </div>
                    </v-col>
                  </v-tooltip>
                  <!-- Total Dev Cost -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-row class="ma-0 flex-nowrap justify-space-between">
                          <span class="fz-14 font-weight-medium mr-5 my-auto text-dgray">Total Dev Cost</span>
                          <b class="fz-14 fw-600 my-auto">
                            <AmountView :currency="order.currencySymbol">
                              {{ Math.round(+order.totalDeveloperCost) | numberFormat }}
                            </AmountView>
                          </b>
                        </v-row>
                      </div>
                    </template>
                    <v-col>
                      <div class="fz-14 fw-600 my-auto mb-2">
                        <v-icon class="stroke-white mr-2">$desktop</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalDesktopDeveloperCost | numberFormat }}
                        </AmountView>
                      </div>
                      <div class="fz-14 fw-600 my-auto">
                        <v-icon class="stroke-white mr-2">$mobile</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalMobileDeveloperCost | numberFormat }}
                        </AmountView>
                      </div>
                    </v-col>
                  </v-tooltip>
                </v-sheet>
                <v-sheet v-if="me.permissions.orders.orderEdit" color="transparent" class="ml-15">
                  <!-- Total Salary -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-row class="ma-0 flex-nowrap justify-space-between">
                          <span class="fz-14 font-weight-medium mr-5 my-auto text-dgray">Total Salary</span>
                          <b class="fz-14 fw-600 my-auto">
                            <AmountView :currency="order.currencySymbol">
                              {{ +order.totalSalary | numberFormat }}
                            </AmountView>
                          </b>
                        </v-row>
                      </div>
                    </template>
                    <v-col>
                      <div class="fz-14 fw-600 my-auto mb-2">
                        <v-icon class="stroke-white mr-2">$desktop</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalDesktopSalary | numberFormat }}
                        </AmountView>
                      </div>
                      <div class="fz-14 fw-600 my-auto">
                        <v-icon class="stroke-white mr-2">$mobile</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalMobileSalary | numberFormat }}
                        </AmountView>
                      </div>
                    </v-col>
                  </v-tooltip>
                  <!-- Total Profit -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-row class="ma-0 flex-nowrap justify-space-between">
                          <span class="fz-14 font-weight-medium mr-5 my-auto text-dgray">Total Profit</span>
                          <b class="fz-14 fw-600 my-auto">
                            <AmountView :currency="order.currencySymbol">
                              {{ Math.round(+order.totalProfit) | numberFormat }}
                            </AmountView>
                          </b>
                        </v-row>
                      </div>
                    </template>
                    <v-col>
                      <div class="fz-14 fw-600 my-auto mb-2">
                        <v-icon class="stroke-white mr-2">$desktop</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalDesktopProfit | numberFormat }}
                        </AmountView>
                      </div>
                      <div class="fz-14 fw-600 my-auto">
                        <v-icon class="stroke-white mr-2">$mobile</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalMobileProfit | numberFormat }}
                        </AmountView>
                      </div>
                    </v-col>
                  </v-tooltip>
                </v-sheet>
                <v-sheet v-if="me.permissions.orders.orderEdit" color="transparent">
                  <!-- Total Cost -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-row class="ma-0 ml-15 flex-grow-0">
                          <span class="fz-14 font-weight-medium mr-5 my-auto text-dgray">Total Cost</span>
                          <b class="fz-22 font-weight-bold my-auto">
                            <AmountView :currency="order.currencySymbol">
                              {{ Math.round(+order.totalCost) | numberFormat }}
                            </AmountView>
                          </b>
                        </v-row>
                      </div>
                    </template>
                    <v-col>
                      <div class="fz-14 fw-600 my-auto mb-2">
                        <v-icon class="stroke-white mr-2">$desktop</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalDesktopCost | numberFormat }}
                        </AmountView>
                      </div>
                      <div class="fz-14 fw-600 my-auto">
                        <v-icon class="stroke-white mr-2">$mobile</v-icon>
                        <AmountView :currency="order.currencySymbol">
                          {{ +order.totalMobileCost | numberFormat }}
                        </AmountView>
                      </div>
                    </v-col>
                  </v-tooltip>
                </v-sheet>
              </v-row>
            </v-row>
            <v-divider class="black mb-7"/>
            <v-row v-if="me.permissions.orders.orderEdit" class="ma-0 mb-3">
              <v-col class="width-330 bg-lgray mr-5 height-50 rounded-lg d-flex justify-space-between align-center">
                <span class="fz-14 text-black width-140 my-auto mr-8">Mobile version</span>
                <v-switch
                  v-model="order.mobile"
                  hide-details
                  inset
                  dense
                  @change="setMobile"
                  :disabled="order.hasFixedBySalary"
                  :readonly="readOnly"
                  color="#34C759"
                  class="pa-0 mt-2 mr-n5 default-switch"
                ></v-switch>
              </v-col>
              <!--            <v-col class="width-330 mr-5 bg-lgray rounded-lg height-50 d-flex justify-space-between align-center">
                            <span class="fz-14 text-black width-140 my-auto mr-8">Management</span>
                            <v-switch
                              v-model="order.management"
                              hide-details
                              inset
                              dense
                              color="#34C759"
                              class="pa-0 mt-2 mr-n5 default-switch"
                            ></v-switch>
                          </v-col>
              Prototype, was deleted
              <v-col class="width-330 mr-5 bg-lgray rounded-lg height-50 d-flex justify-space-between align-center">
              <span class="fz-14 text-black width-140 my-auto mr-8">Prototype</span>
              <v-switch
                v-model="order.prototype"
                hide-details
                inset
                dense
                @change="setPrototype"
                :readonly="prototype"
                color="#34C759"
                class="pa-0 mt-2 mr-n5 default-switch"
              ></v-switch>
            </v-col>-->
              <v-col class="width-330 bg-lgray mr-5 rounded-lg d-flex height-50 justify-space-between align-center">
                <span class="fz-14 text-black width-140 my-auto mr-8">Author supervision</span>
                <v-switch
                  v-model="order.authorSupervision"
                  hide-details
                  inset
                  dense
                  @change="setSupervision"
                  :disabled="order.hasFixedBySalary"
                  :readonly="readOnly"
                  color="#34C759"
                  class="pa-0 mt-2 mr-n5 default-switch"
                ></v-switch>
              </v-col>
              <v-col class="width-330 bg-lgray mr-5 rounded-lg d-flex height-50 justify-space-between align-center">
                <span class="fz-14 text-black width-140 my-auto mr-8">Graphics version</span>
                <v-switch
                  v-model="order.hasGraphics"
                  hide-details
                  inset
                  dense
                  @change="setGraphics"
                  :disabled="order.hasFixedBySalary"
                  :readonly="readOnly"
                  color="#34C759"
                  class="pa-0 mt-2 mr-n5 default-switch"
                />
              </v-col>
              <v-col class="width-330 bg-lgray rounded-lg d-flex height-50 justify-space-between align-center">
                <span class="fz-14 text-black width-140 my-auto mr-8">Theme version</span>
                <v-switch
                  v-model="order.hasTheme"
                  hide-details
                  inset
                  dense
                  @change="setTheme"
                  :disabled="order.hasFixedBySalary"
                  :readonly="readOnly"
                  color="#34C759"
                  class="pa-0 mt-2 mr-n5 default-switch"
                />
              </v-col>
            </v-row>
            <v-tabs
              v-model="viewTab"
              class="tabs-link"
              style="max-width: 350px"
              background-color="transparent"
              color="basil"
              grow
            >
              <v-tab>Table</v-tab>
              <!-- FOR BLOCKING RELATED ITEMS -->
              <!--<template v-if="orderItems.length && orderItems.filter(el => el.orderItemType !== null).length === 0">-->
              <template v-if="orderItems.length > 0">
                <v-tab>Timeline</v-tab>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="my-auto">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="one-line py-0"
                      >
                        <v-icon>
                          $questionMark
                        </v-icon>
                      </div>
                    </div>
                  </template>
                  <v-col class="d-flex flex-column flex-wrap flex-grow-0">
                    Нажимай на CTRL + стрелки, чтобы перемещаться между вкладками
                  </v-col>
                </v-tooltip>
              </template>
            </v-tabs>
            <v-tabs-items v-model="viewTab" class="overflow-visible">
              <!--Table-->
              <v-tab-item>
                <v-row>
                  <v-col>
                    <!--Multiselect-->
                    <v-col
                      v-if="!readOnly && selectedRows.length !== 0"
                      class="d-flex align-stretch justify-start flex-grow-0"
                    >
                      <!--Difficulty-->
                      <v-col class="flex-grow-0 mr-4" style="min-width: 230px">
                        <v-row>
                          <span class="fz-14 lh-20 text-black font-weight-bold mb-1">Difficulty</span>
                        </v-row>
                        <v-row>
                          <v-menu attach="#select16"
                                  content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-col class="pa-0">
                                <v-text-field
                                  outlined
                                  single-line
                                  :value="multiselectRows.difficulty ? multiselectRows.difficulty.name : ''"
                                  class="rounded-lg text-black mb-n4 fz-14 font-weight-medium select-dash input-border"
                                  dense
                                  readonly
                                >
                                  <template v-slot:prepend-inner>
                                    <v-sheet
                                      v-bind="attrs"
                                      v-on="on"
                                      class="absolute"
                                      elevation="0"
                                      color="transparent"
                                      style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                                    ></v-sheet>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                                  </template>
                                </v-text-field>
                                <div id="select16" class="relative z-index-400"></div>
                              </v-col>
                            </template>
                            <v-card class="rounded-lg mt-1 hidden" elevation="0">
                              <v-card-text class="px-0 py-1">
                                <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                                  <v-list-item
                                    v-for="(item, index) in difficulties"
                                    :key="index"
                                    style="min-height: 30px"
                                    class="px-0 py-1"
                                    @click="multiselectRows.difficulty = item; multipleChangingDifficulty(selectedRows)"
                                  >
                                    <p class="ma-0 px-2 fz-12 text-black">{{ item.name }}</p>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                          <!--
                        OldVersion
                        <v-sheet>
                          <v-select
                            outlined
                            dense
                            single-line
                            style="max-width: 200px !important;"
                            attach="#select40"
                            :items="difficulties"
                            v-model="multiselectRows.difficulty"
                            @change="multipleChangingDifficulty(selectedRows); multiselectRows.difficulty = null"
                            hide-details
                            item-text="name"
                            class="input-border select-dash rounded-lg fz-14 font-weight-medium text-black"
                            return-object
                          >
                          </v-select>
                          <div id="select40" class="relative select-content"></div>
                        </v-sheet>
                        -->
                        </v-row>
                      </v-col>
                      <!--Designer-->
                      <v-col class="flex-grow-0 mr-4" style="min-width: 230px">
                        <v-row>
                          <span class="fz-14 lh-20 text-black font-weight-bold mb-1">Assignee</span>
                        </v-row>
                        <v-row>
                          <v-menu attach="#select17"
                                  content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-col class="pa-0">
                                <v-text-field
                                  outlined
                                  single-line
                                  :value="multiselectRows.designer ? multiselectRows.designer.businessName : ''"
                                  class="rounded-lg text-black mb-n4 fz-14 font-weight-medium select-dash input-border"
                                  dense
                                  readonly
                                >
                                  <template v-slot:prepend-inner>
                                    <v-sheet
                                      v-bind="attrs"
                                      v-on="on"
                                      class="absolute"
                                      elevation="0"
                                      color="transparent"
                                      style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                                    ></v-sheet>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                                  </template>
                                </v-text-field>
                                <div id="select17" class="relative z-index-400"></div>
                              </v-col>
                            </template>
                            <v-card class="rounded-lg mt-1 hidden" elevation="0">
                              <v-card-text class="px-0 py-1">
                                <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                                  <v-list-item
                                    v-for="(item, index) in allDesigners"
                                    :key="index"
                                    style="min-height: 30px"
                                    class="px-0 py-1"
                                    @click="multiselectRows.designer = item; multipleChangingExecutor(selectedRows)"
                                  >
                                    <p class="ma-0 px-2 fz-12 text-black">{{ item.businessName }} ({{
                                        item.roleRate.roleName
                                      }})</p>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                          <!--
                        OldVersion
                        <v-sheet>
                          <v-select
                            outlined
                            dense
                            single-line
                            style="max-width: 200px !important;"
                            attach="#select41"
                            :items="listDesigners"
                            item-text="businessName"
                            item-value="id"
                            v-model="multiselectRows.designer"
                            @change="multipleChangingExecutor(selectedRows)"
                            hide-details
                            hide-selected
                            class="input-border select-dash rounded-lg fz-14 font-weight-medium text-black"
                            return-object
                          ></v-select>
                          <div id="select41" class="relative select-content"></div>
                        </v-sheet>
                        -->
                        </v-row>
                      </v-col>
                      <!--Status-->
                      <v-col class="flex-grow-0 mr-4" style="min-width: 230px">
                        <v-row>
                          <span class="fz-14 lh-20 text-black font-weight-bold mb-1">Status</span>
                        </v-row>
                        <v-row>
                          <v-menu attach="#select18"
                                  content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-col class="pa-0">
                                <v-text-field
                                  outlined
                                  single-line
                                  :value="multiselectRows.status ? multiselectRows.status.label : ''"
                                  class="rounded-lg text-black mb-n4 fz-14 font-weight-medium select-dash input-border"
                                  dense
                                  readonly
                                >
                                  <template v-slot:prepend-inner>
                                    <v-sheet
                                      v-bind="attrs"
                                      v-on="on"
                                      class="absolute"
                                      elevation="0"
                                      color="transparent"
                                      style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                                    ></v-sheet>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                                  </template>
                                </v-text-field>
                                <div id="select18" class="relative z-index-400"></div>
                              </v-col>
                            </template>
                            <v-card class="rounded-lg mt-1 hidden" elevation="0">
                              <v-card-text class="px-0 py-1">
                                <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                                  <v-list-item
                                    v-for="(item, index) in statusLabels.filter(item => item.value !== 'COMPLETED')"
                                    :key="index"
                                    style="min-height: 30px"
                                    class="px-0 py-1"
                                    @click="multiselectRows.status = item; multipleChangingStatus(selectedRows)"
                                  >
                              <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                                    class="rounded-circle" :style="color(item.value)"></span>
                                    <p class="ma-0 px-2 fz-12 text-black">{{ item.label }}</p>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                          <!--OldVersion
                        <v-sheet>
                          <v-select
                            outlined
                            dense
                            single-line
                            style="max-width: 200px !important;"
                            attach="#select42"
                            :items="statusLabels.filter(item => item.value !== 'COMPLETED')"
                            item-text="label"
                            item-value="value"
                            v-model="multiselectRows.status"
                            @change="multipleChangingStatus(selectedRows)"
                            hide-details
                            hide-selected
                            class="input-border select-dash rounded-lg fz-14 font-weight-medium text-black"
                          >
                            <template v-slot:item="{ item }">
                              <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;" class="rounded-circle" :style="color(item.value)"></span>
                              <span>{{ item.label }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;" class="rounded-circle" :style="color(item.value)"></span>
                              <span>{{ item.label }}</span>
                            </template>
                          </v-select>
                          <div id="select42" class="relative select-content"></div>
                        </v-sheet>
                        -->
                        </v-row>
                      </v-col>
                      <!--Discount-->
                      <v-col v-if="order.type !== 'HUBNERO'" class="flex-grow-0 mr-4" style="min-width: 230px">
                        <v-row>
                          <span class="fz-14 lh-20 text-black font-weight-bold mb-1">Discount</span>
                        </v-row>
                        <v-row>
                          <v-menu attach="#select19"
                                  content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100">
                            <template v-slot:activator="{ on, attrs }">
                              <v-col class="pa-0">
                                <v-text-field
                                  outlined
                                  single-line
                                  :value="multiselectRows.discount ? Math.round(multiselectRows.discount.rate * 100) / 100  + `% ${multiselectRows.discount.name}` : ''"
                                  class="rounded-lg text-black mb-n4 fz-14 font-weight-medium select-dash input-border"
                                  dense
                                  readonly
                                >
                                  <template v-slot:prepend-inner>
                                    <v-sheet
                                      v-bind="attrs"
                                      v-on="on"
                                      class="absolute"
                                      elevation="0"
                                      color="transparent"
                                      style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                                    ></v-sheet>
                                  </template>
                                  <template v-slot:append>
                                    <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                                  </template>
                                </v-text-field>
                                <div id="select19" class="relative z-index-400"></div>
                              </v-col>
                            </template>
                            <v-card class="rounded-lg mt-1 hidden" elevation="0">
                              <v-card-text class="px-0 py-1">
                                <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                                  <v-list-item
                                    v-for="(item, index) in allDiscounts"
                                    :key="index"
                                    style="min-height: 30px"
                                    class="px-0 py-1"
                                    @click="multiselectRows.discount = item; multipleChangingDiscount(selectedRows)"
                                  >
                                    <p class="ma-0 px-2 fz-12 text-black">
                                      {{ Math.round(item.rate * 100) / 100 }}% ({{ item.name }})
                                    </p>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                          <!--
                        <v-sheet>
                          <v-select
                            outlined
                            dense
                            single-line
                            style="max-width: 200px !important;"
                            attach="#select43"
                            :items="allDiscounts"
                            item-text="name"
                            item-value="id"
                            v-model="multiselectRows.discount"
                            @change="multipleChangingDiscount(selectedRows)"
                            hide-details
                            class="input-border select-dash rounded-lg fz-14 font-weight-medium text-black"
                          >
                            <template v-slot:item="{ item }">
                              <span>{{ Math.round(item.rate * 100) / 100  }}% ({{ item.name }})</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ Math.round(item.rate * 100) / 100  }}% ({{ item.name }})</span>
                            </template>
                          </v-select>
                          <div id="select43" class="relative select-content"></div>
                        </v-sheet>
                        -->
                        </v-row>
                      </v-col>
                      <!--Multiselect - Copy-->
                      <!--<v-btn icon>
                      <v-dialog
                        transition="dialog-bottom-transition"
                        content-class="rounded-xl"
                        max-width="370"
                        overlay-color="rgba(9, 24, 73, 0.2)"
                        overlay-opacity="1"
                      >

                        <template v-slot:activator="{on, attrs}">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            elevation="0"
                            x-small
                            icon
                            fab
                          >
                            <v-icon size="20"  class="stroke-gray">$copyToClipboard</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card class="pa-7">
                            <v-card-text class="py-0">
                              <div class="text-black font-weight-medium fz-18 text-center">
                                Do you really want to copy?
                                <span v-for="item in selectedRows" :key="item.id">
                                  {{item.name}}
                                  <br>
                                </span>
                              </div>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="justify-space-between pa-0">
                              <v-btn
                                class="rounded-lg text-none fz-16 fw-600"
                                outlined
                                width="150"
                                height="40"
                                @click="dialog.value = false"
                              >
                                Return
                              </v-btn>
                              <v-btn
                                elevation="0"
                                color="#FF0000"
                                width="150"
                                height="40"
                                class="rounded-lg white&#45;&#45;text text-none fz-16 fw-600"
                                @click="dialog.value = false"
                              >
                                <v-icon left class="stroke-white">
                                  $delete
                                </v-icon>
                                Copy
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-btn>-->
                      <!--Multiselect - Copy-->
                      <v-dialog
                        transition="dialog-bottom-transition"
                        content-class="rounded-xl"
                        max-width="420"
                        overlay-color="rgba(9, 24, 73, 0.2)"
                        overlay-opacity="1"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-col class="mr-4 flex-grow-0" align="center">
                            <v-row>
                              <span class="fz-14 lh-20 text-black font-weight-bold mb-1">Copy</span>
                            </v-row>
                            <v-row justify="center">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                elevation="0"
                                x-small
                                icon
                                fab
                                :disabled="readOnly || selectedRows.length === 0"
                              >
                                <v-icon size="25" class="path-gray">$copyToClipboard</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card class="pa-7">
                            <v-card-text class="py-0">
                              <div class="text-black font-weight-medium fz-18 text-center">
                                The following items will be copied:
                                <br>
                                <span v-for="item in selectedRows" :key="item.id">
                              - {{ item.name }}
                              <br>
                            </span>
                              </div>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="justify-space-between pa-0">
                              <v-btn
                                class="rounded-lg text-none fz-16 fw-600"
                                outlined
                                width="150"
                                height="40"
                                @click="dialog.value = false"
                              >Return
                              </v-btn>
                              <v-btn
                                elevation="0"
                                color="#FF0000"
                                width="150"
                                height="40"
                                class="rounded-lg white--text text-none fz-16 fw-600"
                                @click="copyOrderItems(selectedRows); dialog.value = false"
                              >
                                <v-icon left class="path-white">
                                  $copyToClipboard
                                </v-icon>
                                Copy
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                      <!--Multiselect - Delete-->
                      <v-dialog
                        transition="dialog-bottom-transition"
                        content-class="rounded-xl"
                        max-width="420"
                        overlay-color="rgba(9, 24, 73, 0.2)"
                        overlay-opacity="1"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-col class="flex-grow-0 mr-4" align="center">
                            <v-row>
                              <span class="fz-14 lh-20 text-black font-weight-bold mb-1">Delete</span>
                            </v-row>
                            <v-row justify="center">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                elevation="0"
                                x-small
                                icon
                                fab
                                :disabled="readOnly || selectedRows.length === 0"
                              >
                                <v-icon size="25" class="stroke-gray">$delete</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card class="pa-7">
                            <v-card-text class="py-0">
                              <div class="text-black font-weight-medium fz-18 text-center">
                                The following items will be removed:
                                <br>
                                <span v-for="item in selectedRows" :key="item.id">
                                - {{ item.name }}
                                <br>
                              </span>
                              </div>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="justify-space-between pa-0">
                              <v-btn
                                class="rounded-lg text-none fz-16 fw-600"
                                outlined
                                width="150"
                                height="40"
                                @click="dialog.value = false"
                              >Return
                              </v-btn>
                              <v-btn
                                elevation="0"
                                color="#FF0000"
                                width="150"
                                height="40"
                                class="rounded-lg white--text text-none fz-16 fw-600"
                                @click="removeOrderItems(selectedRows); dialog.value = false"
                              >
                                <v-icon left class="stroke-white">
                                  $delete
                                </v-icon>
                                Delete
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                      <!--Multiselect - Reset Assignee cost-->
                      <v-dialog
                        transition="dialog-bottom-transition"
                        content-class="rounded-xl"
                        max-width="420"
                        overlay-color="rgba(9, 24, 73, 0.2)"
                        overlay-opacity="1"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-col class="flex-grow-0" align="center">
                            <v-row>
                            <span class="fz-14 lh-20 text-black font-weight-bold mb-1 text-no-wrap">
                              Reset CF
                            </span>
                            </v-row>
                            <v-row justify="center">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                elevation="0"
                                x-small
                                icon
                                fab
                                :disabled="readOnly || selectedRows.length === 0"
                              >
                                <v-icon size="25" class="stroke-gray">$delete</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card class="pa-7">
                            <v-card-text class="py-0">
                              <div class="text-black font-weight-medium fz-18 text-center">
                                The Custom Fields will be reset
                              </div>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="justify-space-between pa-0">
                              <v-btn
                                class="rounded-lg text-none fz-16 fw-600"
                                outlined
                                width="150"
                                height="40"
                                @click="dialog.value = false"
                              >
                                Return
                              </v-btn>
                              <v-btn
                                elevation="0"
                                color="#FF0000"
                                width="150"
                                height="40"
                                class="rounded-lg white--text text-none fz-16 fw-600"
                                @click="resetMultipleCustomCost(null, selectedRows); dialog.value = false"
                              >
                                Reset
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                    <!--Table-->
                    <!--:item-class="(item) => { return item.fixedBySalary ? 'bg-lgray' : ''}"-->
                    <v-data-table
                      v-model="selectedRows"
                      :headers="!me.permissions.orders.orderEdit ? headersAssignee : order.type === 'REGULAR' ? headersCreateRegularOrders : order.type === 'CUSTOM' ? headersCreateHubneroOrders : headersCreateCustomOrders"
                      :items="this.orderItems"
                      :options="options"
                      :header-props="{ sortIcon: 'mdi-menu-down' }"
                      :show-select="!readOnly"
                      group-by="compositeItem.id"
                      hide-default-footer
                      class="mt-5 table-vertical-line table-items"
                    >
                      <template v-slot:group.header="{group, items, toggle}">
                        <template v-if="items.length > 1">
                          <td v-if="!readOnly" class="border-r-lightgray"/>
                          <!--Name-->
                          <td>
                            <v-row no-gutters class="flex-nowrap" align="center" style="padding: 32px 0">
                              <v-col class="flex-grow-0 mr-3">
                                <v-img
                                  v-if="getCompositeItem(group).iconUrl && $vuetify.breakpoint.smAndUp"
                                  class="my-auto"
                                  max-width="32"
                                  max-height="32"
                                  :src="getCompositeItem(group).iconUrl"
                                />
                                <v-img
                                  v-else-if="$vuetify.breakpoint.smAndUp"
                                  class="my-auto"
                                  max-width="32"
                                  max-height="32"
                                  src="@/assets/img/svg/Pen.svg"
                                />
                              </v-col>
                              <v-col
                                class="d-flex flex-column justify-center"
                              >
                                <div class="fz-14 fw-600 mb-1 text-black flex-nowrap d-flex align-center">
                                  <span class="one-line">{{ getCompositeItem(group).name }}</span>
                                  <span class="ml-1 text-gray">
                              {{ items[0].serialNumber }}
                            </span>
                                </div>
                              </v-col>
                            </v-row>
                          </td>
                          <!--Qty-->
                          <td>
                            <v-row no-gutters class="flex-column" align="center" justify="center">
                              <div class="font-weight-medium">
                                {{ !checkType(items[0], 1) ? items.map(item => item.count).reduce((t,n) => Math.round(t) + Math.round(n)) : '1'}}
                              </div>
                              <div class="fz-13 text-gray">{{ getCompositeItem(group).itemFormatLabel }}</div>
                              <v-col class="py-0 d-flex justify-center" v-if="getCompositeItem(group).orderItemType === 'PAGE' && !checkType(items[0], 1)">
                                <v-menu
                                  :close-on-content-click="false"
                                  @input="$set(getCompositeItem(group), '__qtyToCreate', items[0].count)"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <div class="mr-2">
                                      <div
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        Add page
                                      </div>
                                    </div>
                                  </template>
                                  <v-list class="d-flex justify-center align-center pa-4 overflow-y-hidden rounded-lg">
                                    <v-text-field
                                      v-model.number="getCompositeItem(group).__qtyToCreate"
                                      type="number"
                                      class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border mr-2"
                                      style="width: 75px;"
                                      outlined
                                      single-line
                                      dense
                                      :rules="[value => value > 1 || 'Min 1 page']"
                                    />
                                    <v-btn
                                      :disabled="readOnly || getCompositeItem(group).__qtyToCreate < 1"
                                      @click="addPage(getCompositeItem(group).id, getCompositeItem(group).__qtyToCreate)"
                                      class="fz-13 text-gray font-weight-regular text-none input-border rounded-lg"
                                      elevation="0"
                                      outlined
                                    >
                                      Create
                                    </v-btn>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </td>
                          <!--Assignee-->
                          <td>
                            <v-row no-gutters class="pa-3" align="center">
                              <div class="font-weight-medium">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div
                                      v-bind="attrs"
                                      v-on="on"
                                      class="one-line py-0"
                                    >
                                    <span>
                                      {{ items[0].executor.businessName }}
                                    </span>
                                      <span
                                        v-if="getExecutorForHeaderRow(items).length > 1"
                                        class="text-gray"
                                      > and {{ getExecutorForHeaderRow(items).length - 1 }}
                                    </span>
                                    </div>
                                  </template>
                                  <v-col class="d-flex flex-column flex-wrap">
                                    <div
                                      v-for="(executor, index) of getExecutorForHeaderRow(items)"
                                      :key="executor + index"
                                    >
                                      {{ executor }}
                                      <v-divider class="white opacity-25 my-2"/>
                                    </div>
                                  </v-col>
                                </v-tooltip>
                              </div>
                              <v-spacer/>
                              <v-btn
                                v-if="!readOnly && checkType(items[0], 1)"
                                elevation="0"
                                x-small
                                icon
                                fab
                                @click="createRelatedItem(items[0], items,true); createNewItem.isActive = false"
                              >
                                <v-icon size="8">$plus</v-icon>
                              </v-btn>
                            </v-row>
                          </td>
                          <!--Status-->
                          <td>
                            <div class="pl-2 font-weight-medium">
                            <span
                              style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                              class="rounded-circle"
                              :style="color(getCompositeItem(group).status)"
                            />
                              <span>
                              {{ getCompositeItem(group).status }}
                            </span>
                            </div>
                          </td>
                          <td v-if="order.type !== 'CUSTOM'">
                            <div class="d-flex flex-column align-center">
                              <div v-if="isExistMobileItems(items)">
                                T:
                                {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                                {{ Math.round(items.map(item => item.price).reduce((t,n) => +t + +n) + items.map(item => item.__mobileItem ? item.__mobileItem.price : null).reduce((t, n) => +t + +n)) }}
                                {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                              </div>
                              <div>
                                <template v-if="isExistMobileItems(items)">
                                  D:
                                </template>
                                {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                                {{ Math.round(items.map(item => item.price).reduce((t,n) => +t + +n)) }}
                                {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                              </div>
                              <div v-if="isExistMobileItems(items)">
                                M:
                                {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                                {{ Math.round(items.map(item => item.__mobileItem ? item.__mobileItem.price : null).reduce((t, n) => +t + +n)) }}
                                {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                              </div>
                            </div>
                          </td>
                          <td v-if="order.type !== 'CUSTOM'"/>
                          <td>
                            <div class="pa-3">
                              {{ formatDate(getCompositeItem(group).dateStart) }}
                            </div>
                          </td>
                          <td>
                            <div class="pa-3">
                              {{ formatDate(getCompositeItem(group).dateEnd) }}
                            </div>
                          </td>
                          <td>
                            <div class="d-flex flex-column align-center">
                              <div v-if="isExistMobileItems(items)">
                                T:
                                {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                                {{ Math.round(items.map(item => item.subtotal).reduce((t,n) => +t + +n) + items.map(item => item.__mobileItem ? item.__mobileItem.subtotal : null).reduce((t, n) => +t + +n)) }}
                                {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                              </div>
                              <div>
                                <template v-if="isExistMobileItems(items)">
                                  D:
                                </template>
                                {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                                {{ Math.round(items.map(item => item.subtotal).reduce((t,n) => +t + +n)) }}
                                {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                              </div>
                              <div v-if="isExistMobileItems(items)">
                                M:
                                {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                                {{ Math.round(items.map(item => item.__mobileItem ? item.__mobileItem.subtotal : null).reduce((t, n) => +t + +n)) }}
                                {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                              </div>
                            </div>
                          </td>
                          <td v-if="order.type === 'REGULAR'">
                            <div v-if="isExistMobileItems(items)">
                              T:
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(items.map(item => item.designerCost).reduce((t,n) => +t + +n) + items.map(item => item.__mobileItem ? item.__mobileItem.designerCost : null).reduce((t, n) => +t + +n)) }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                            </div>
                            <div>
                              <template v-if="isExistMobileItems(items)">
                                D:
                              </template>
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(items.map(item => item.designerCost).reduce((t,n) => +t + +n)) }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                            </div>
                            <div v-if="isExistMobileItems(items)">
                              M:
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(items.map(item => item.__mobileItem ? item.__mobileItem.designerCost : null).reduce((t, n) => +t + +n)) }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                            </div>
                          </td>
                          <td v-if="order.type === 'LOADED' || order.type === 'CUSTOM'">
                            <div v-if="isExistMobileItems(items)">
                              T:
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(items.map(item => item.designerCost).reduce((t,n) => +t + +n) + items.map(item => item.__mobileItem ? item.__mobileItem.designerCost : null).reduce((t, n) => +t + +n)) }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                            </div>
                            <div>
                              <template v-if="isExistMobileItems(items)">
                                D:
                              </template>
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(items.map(item => item.designerCost).reduce((t,n) => +t + +n)) }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                            </div>
                            <div v-if="isExistMobileItems(items)">
                              M:
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(items.map(item => item.__mobileItem ? item.__mobileItem.designerCost : null).reduce((t, n) => +t + +n)) }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                            </div>
                          </td>
                          <td>
                            <div v-if="isExistMobileItems(items)">
                              T:
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(items.map(item => item.profit).reduce((t,n) => +t + +n) + items.map(item => item.__mobileItem ? item.__mobileItem.profit : null).reduce((t, n) => +t + +n)) }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                            </div>
                            <div>
                              <template v-if="isExistMobileItems(items)">
                                D:
                              </template>
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(items.map(item => item.profit).reduce((t,n) => +t + +n)) }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                            </div>
                            <div v-if="isExistMobileItems(items)">
                              M:
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(items.map(item => item.__mobileItem ? item.__mobileItem.profit : null).reduce((t, n) => +t + +n)) }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                            </div>
                          </td>
                          <td style="border-right: none">
                            <RemoveDialog
                              @commit="deleteCompositeItem(getCompositeItem(group).id)"
                            >
                              <template v-slot:message>
                          <span>
                            <br>
                            <span style="color: #FF0000">all related items</span>
                          </span>
                              </template>
                              <template>
                                <v-btn
                                  v-if="!readOnly"
                                  elevation="0"
                                  x-small
                                  icon
                                  fab
                                >
                                  <v-icon size="22" class="stroke-gray">$delete</v-icon>
                                </v-btn>
                              </template>
                            </RemoveDialog>
                          </td>
                          <td>
                            <v-btn
                              @click="toggle"
                              v-show="!readOnly"
                              elevation="0"
                              x-small
                              icon
                              fab
                            >
                              <v-icon size="12" class="stroke-gray">
                                $ddown
                              </v-icon>
                            </v-btn>
                          </td>
                        </template>
                      </template>
                      <template v-slot:header.designerCost="">
                        <span class="ma-0">Assignee Cost</span>
                      </template>
                      <template v-slot:item.data-table-select="{ select, isSelected, item }">
                        <v-simple-checkbox
                          :value="isSelected"
                          :disabled="item.fixedBySalary"
                          :class="isDisabled(item.fixedBySalary)"
                          @input="select(!isSelected)"
                        />
                      </template>
                      <template v-slot:item.title="{ item }">
                        <v-row class="ma-0 pointer flex-nowrap" @click="changeItemValue(item)">
                          <!--Usual item-->
                          <template v-if="(/fakeId/g).test(item.compositeItem.id)">
                            <v-img v-if="item.iconUrl && $vuetify.breakpoint.smAndUp" class="my-auto" max-width="32"
                                   max-height="32" :src="item.iconUrl"></v-img>
                            <v-img v-else-if="$vuetify.breakpoint.smAndUp" class="my-auto" max-width="32" max-height="32"
                                   src="@/assets/img/svg/Pen.svg"></v-img>
                            <v-col class="d-flex flex-column justify-center" style="padding: 22px 12px">
                              <div class="fz-14 fw-600 mb-1 text-black flex-nowrap d-flex align-center">
                                <span class="one-line">{{ item.name }} {{ item.ownName ? `:: ${item.ownName}` : '' }}</span>
                                <span class="ml-1 text-gray">
                            {{ item.serialNumber }}
                          </span>
                                <v-menu
                                  :close-on-content-click="false"
                                  :open-on-hover="readOnly"
                                  top
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      elevation="0"
                                      x-small
                                      icon
                                      fab
                                    >
                                      <v-icon
                                        size="20"
                                        :class="item.comment ? 'stroke-primary' : 'stroke-gray'"
                                      >
                                        $edit
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <template v-slot:default="menu">
                                    <div class="item-comment bg-white">
                                      <v-row no-gutters>
                                        <v-textarea
                                          :readonly="readOnly"
                                          filled
                                          background-color="white"
                                          v-model="item.comment"
                                        />
                                      </v-row>
                                      <v-row
                                        v-if="!readOnly"
                                        no-gutters
                                      >
                                        <v-spacer/>
                                        <span
                                          @click="setComment(item.id, ''), item.comment = '', menu.value = false"
                                          class="fz-15 mr-4 pointer"
                                        >
                                    Reset
                                  </span>
                                        <span
                                          @click="setComment(item.id, item.comment), menu.value = false"
                                          class="fz-15 mr-4 pointer fw-600"
                                        >
                                    Save
                                  </span>
                                      </v-row>
                                    </div>
                                  </template>
                                </v-menu>
                              </div>
                              <div class="ma-0">
                                <p class="fz-14 ma-0 mb-1 text-gray">
                                  Base price: <span class="text-dgray">
                            {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                            {{ +item.itemBasePrice | numberFormat }}
                            {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                          </span>
                                </p>
                                <v-row v-if="item.mobile || item.difficultyFixed" no-gutters class="flex-column">
                                  <template v-if="item.difficultyFixed">
                                    <div class="text-dgray mr-1">
                                      <v-icon v-if="item.__mobileItem" size="20" v-text="'$desktop'" class="stroke-black"/>
                                      {{ item.difficulty.name }}
                                    </div>
                                  </template>
                                  <template v-if="item.__mobileItem">
                                    <div class="text-dgray mr-1">
                                      <v-icon size="20" v-text="'$mobile'" class="stroke-black"/>
                                      {{ item.__mobileItem.difficulty.name }}
                                    </div>
                                  </template>
                                  <!--For old orders-->
                                  <template v-if="item.mobile && !item.__mobileItem">
                                    <v-icon size="20" v-text="'$mobile'" class="stroke-black"/>
                                  </template>
                                </v-row>
                              </div>
                            </v-col>
                          </template>
                          <!--For MULTIPLE TYPE-->
                          <template v-else>
                            <div @click="changeItemValue(item)">
                              <v-col class="d-flex flex-column justify-center" style="padding: 22px 12px">
                                <div class="ma-0">
                                  <v-row no-gutters v-if="item.orderItemType === 'PAGE'">
                                    <input
                                      type="text"
                                      :disabled="readOnly"
                                      v-model="item.ownName"
                                      class="colorlessFocus border-none"
                                      @click.stop
                                      @blur="setOwnName(item)"
                                      v-resizable-input
                                    >
                                    <!--<DivInput
                                      v-model="item.ownName"
                                      class="font-weight-bold"
                                      :disabled="readOnly"
                                      @click.stop
                                      stretchable
                                      @blur="setOwnName(item)"
                                    />-->
                                  </v-row>
                                  <v-row no-gutters class="flex-nowrap" align="center">
                                    <p class="fz-14 ma-0 mb-1 text-gray one-line">
                                      Base price: <span class="text-dgray">
                            {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                            {{ +item.itemBasePrice | numberFormat }}
                            {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                          </span>
                                    </p>
                                    <v-menu
                                      :close-on-content-click="false"
                                      :open-on-hover="readOnly"
                                      top
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          elevation="0"
                                          x-small
                                          icon
                                          fab
                                        >
                                          <v-icon
                                            size="20"
                                            :class="item.comment ? 'stroke-primary' : 'stroke-gray'"
                                          >
                                            $edit
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <template v-slot:default="menu">
                                        <div class="item-comment bg-white">
                                          <v-row no-gutters>
                                            <v-textarea
                                              :readonly="readOnly"
                                              filled
                                              background-color="white"
                                              v-model="item.comment"
                                            />
                                          </v-row>
                                          <v-row
                                            v-if="!readOnly"
                                            no-gutters
                                          >
                                            <v-spacer/>
                                            <span
                                              @click="setComment(item.id, ''), item.comment = '', menu.value = false"
                                              class="fz-15 mr-4 pointer"
                                            >
                                    Reset
                                  </span>
                                            <span
                                              @click="setComment(item.id, item.comment), menu.value = false"
                                              class="fz-15 mr-4 pointer fw-600"
                                            >
                                    Save
                                  </span>
                                          </v-row>
                                        </div>
                                      </template>
                                    </v-menu>
                                  </v-row>
                                  <v-row v-if="item.mobile || item.difficultyFixed" no-gutters class="flex-column">
                                    <template v-if="item.difficultyFixed">
                                      <div class="text-dgray mr-1">
                                        <v-icon v-if="item.__mobileItem" size="20" v-text="'$desktop'" class="stroke-black"/>
                                        {{ item.difficulty.name }}
                                      </div>
                                    </template>
                                    <template v-if="item.__mobileItem">
                                      <div class="text-dgray mr-1">
                                        <v-icon size="20" v-text="'$mobile'" class="stroke-black"/>
                                        {{ item.__mobileItem.difficulty.name }}
                                      </div>
                                    </template>
                                    <template v-if="item.mobile && !item.__mobileItem">
                                      <v-icon size="20" v-text="'$mobile'" class="stroke-black"/>
                                    </template>
                                  </v-row>
                                </div>
                              </v-col>
                            </div>
                          </template>
                        </v-row>
                      </template>
                      <template v-slot:item.qty="{ item }">
                        <v-row
                          no-gutters
                          class="flex-column align-center"
                          :class="isDisabled(item.fixedBySalary)"
                        >
                          <v-col class="mb-n2 py-0 d-flex justify-space-between align-center">
                            <v-btn
                              v-if="(me.permissions.orders.orderEdit && item.orderItemType !== 'PAGE' && item.__typename === 'OrderService') || isUseUpdate"
                              elevation="0"
                              x-small
                              icon
                              fab
                              :disabled="item.count < 2 || readOnly || checkType(item, 1) || item.fixedBySalary"
                              @click="countMinus(item)"
                            >
                              <v-icon
                                size="8"
                                :class="checkType(item, 1) ? 'rect-gray' : ''"
                              >$minus
                              </v-icon>
                            </v-btn>
                            <span class="mx-auto">
                              <v-text-field
                                v-model.number="item.count"
                                dense
                                outlined
                                type="number"
                                :disabled="checkType(item, 1) || item.orderItemType === 'PAGE' || item.fixedBySalary || !isUseUpdate"
                                :readonly="readOnly"
                                @change="setCount(item.id, item.count)"
                                hide-details
                                v-mask="[/\d/, /\d/, /\d/, /\d/]"
                                class="max-width-40 input-center input-qty border-none text-black fz-14 font-weight-medium"
                              />
                            </span>
                            <v-btn
                              v-if="(me.permissions.orders.orderEdit && item.orderItemType !== 'PAGE' && item.__typename === 'OrderService') || isUseUpdate"
                              elevation="0"
                              x-small
                              icon
                              fab
                              :disabled="readOnly || checkType(item, 1) || item.fixedBySalary"
                              @click="countPlus(item)"
                            >
                              <v-icon
                                size="8"
                                :class="checkType(item, 1) ? 'rect-gray' : ''"
                              >$plus
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col class="text-center">
                            <span class="fz-13 text-gray">{{ item.itemFormatLabel }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0" v-if="item.orderItemType !== 'PAGE' && item.compositeItem.id.includes('fakeId') && !checkType(item, 1) && getTypeItem(item.id)">
                          <v-col class="py-0 d-flex justify-center">
                            <v-menu
                              :close-on-content-click="false"
                              @input="$set(item, '__qtyToCreate', item.count + 1)"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div class="mr-2">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="item.fixedBySalary"
                                    rounded
                                    tile
                                    text
                                    style="text-transform: none"
                                    class="fz-14 rounded-2"
                                  >
                                    Add page
                                  </v-btn>
                                </div>
                              </template>
                              <v-list class="d-flex justify-center align-center pa-4 overflow-y-hidden rounded-lg">
                                <v-text-field
                                  v-model.number="item.__qtyToCreate"
                                  type="number"
                                  class="rounded-lg mb-n4 text-black fz-14 font-weight-medium select-dash input-border mr-2"
                                  style="width: 75px;"
                                  outlined
                                  single-line
                                  dense
                                  :rules="[value => value > 2 || 'Min 2 page']"
                                />
                                <v-btn
                                  v-if="item.compositeItem.id.includes('fakeId') && !checkType(item, 1)"
                                  :disabled="readOnly || item.__qtyToCreate < 2"
                                  @click="addCompositeItem(item.id, item.__qtyToCreate)"
                                  class="fz-13 text-gray font-weight-regular text-none input-border rounded-lg"
                                  elevation="0"
                                  outlined
                                >
                                  Create
                                </v-btn>
                              </v-list>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:item.designer="{ item }">
                        <v-row no-gutters class="pointer flex-column" style="padding: 12px 12px;">
                          <v-dialog
                            width="360px"
                            content-class="rounded-xl hidden dialog-flex elevation-0"
                            :disabled="readOnly || item.fixedBySalary"
                            overlay-color="rgba(9, 24, 73, 0.2)"
                            overlay-opacity="1"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-row no-gutters align="center" :class="isDisabled(item.fixedBySalary)">
                                <v-col
                                  class="pa-0"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="changeDesigner(item)"
                                >
                                  <v-text-field
                                    outlined
                                    :value="item.executor ? item.executor.businessName : 'Select Assignee'"
                                    :readonly="readOnly || item.fixedBySalary"
                                    append-icon="mdi-menu-down"
                                    single-line
                                    hide-details
                                    class="rounded-lg mb-n6 border-none fz-14 text-black font-weight-medium pointer select-designer"
                                    dense
                                  />
                                  <span
                                    style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                                    class="rounded-circle ml-3"
                                    :style="colorDesigner(item.executor ? item.executor.statusLabel : '')"
                                  />
                                  <span class="fz-10 text-dgray font-weight-medium">
                                  {{ item.executor ? item.executor.statusLabel : 'Unknown' }}
                                </span>
                                </v-col>
                                <!--Create related item-->
                                <v-col class="flex-grow-0">
                                  <v-btn
                                    elevation="0"
                                    x-small
                                    icon
                                    fab
                                    class="mt-2"
                                    v-if="((/fakeId/g).test(item.compositeItem.id) && !readOnly) && !((/OrderService/g).test(item.id)) && checkType(item, 1)"
                                    :disabled="item.fixedBySalary"
                                    @click="createRelatedItem(item)"
                                  >
                                    <v-icon size="8">$plus</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card class="rounded-xl d-flex flex-column hidden" v-click-outside="clearSearch">
                                <v-card-title class="pt-7 px-5 align-start">
                                  <div>
                                    <p
                                      style="line-height: normal"
                                      class="text-gray fz-14 mb-1"
                                    >
                                      Change Assignee
                                    </p>
                                    <p class="fz-20 text-black fw-600 mb-0 lh-20">
                                      List of Assignees
                                    </p>
                                  </div>
                                  <v-spacer></v-spacer>
                                  <v-icon class="close-icon my-auto" @click="dialog.value = false; clearSearch">$close
                                  </v-icon>
                                </v-card-title>
                                <v-divider class="black bb-1 mb-1"/>
                                <v-card-text class="scroll">
                                  <v-col class="pa-0">
                                    <v-text-field
                                      outlined
                                      hide-details
                                      placeholder="Search by designers"
                                      class="border-none mx-n4 fz-14 text-black font-weight-medium"
                                      prepend-inner-icon="$search"
                                      v-model="designerSearch"
                                    />
                                    <v-divider class="mt-1"/>
                                    <!--Main Assignees-->
                                    <template>
                                      <p class="mb-0">Main assignee</p>
                                      <v-list>
                                        <v-list-item
                                          @click="setExecutor(designer, item); dialog.value = false"
                                          v-for="designer in newAssignee ? newAssignee.filter(el => designerSearch ? el.businessName.toLowerCase().includes(designerSearch.toLowerCase()) : el) : newAssignee"
                                          :key="designer.id"
                                          :disabled="designer.cantPicked"
                                          class="rounded-lg hover-rounded-lg"
                                        >
                                          {{ designer.businessName }}
                                          <v-spacer></v-spacer>
                                          <DesignerStatus :designer="designer"/>
                                        </v-list-item>
                                      </v-list>
                                    </template>
                                    <!--Additional Assignees-->
                                    <template v-if="lengthAdditionalAssignee">
                                      <p class="mb-0">Additional assignee</p>
                                      <v-list>
                                        <!--? newAdditionalAssignee.filter(el => designerSearch ? el.businessName.toLowerCase().includes(designerSearch.toLowerCase()) : el) : newAdditionalAssignee-->
                                        <v-list-item
                                          @click="setExecutor(designer, item); dialog.value = false"
                                          v-for="designer in newAdditionalAssignee ? newAdditionalAssignee.filter(el => designerSearch ? el.businessName.toLowerCase().includes(designerSearch.toLowerCase()) : el) : newAdditionalAssignee"
                                          :key="designer.id"
                                          :disabled="designer.cantPicked"
                                          class="rounded-lg hover-rounded-lg"
                                        >
                                          {{ designer.businessName }}
                                          <v-spacer></v-spacer>
                                          <DesignerStatus :designer="designer"/>
                                        </v-list-item>
                                      </v-list>
                                    </template>
                                  </v-col>
                                </v-card-text>
                              </v-card>
                            </template>
                          </v-dialog>
                          <template v-if="item.__mobileItem">
                            <v-divider class="black my-2 width-100"/>
                            <v-dialog
                              width="360px"
                              content-class="rounded-xl hidden dialog-flex elevation-0"
                              :disabled="readOnly || item.__mobileItem.fixedBySalary"
                              overlay-color="rgba(9, 24, 73, 0.2)"
                              overlay-opacity="1"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-row no-gutters align="center" class="flex-column">
                                  <v-col
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="changeDesigner(item.__mobileItem)"
                                    class="pa-0"
                                    :class="isDisabled(item.__mobileItem.fixedBySalary)"
                                  >
                                    <v-text-field
                                      outlined
                                      :value="item.__mobileItem.executor ? item.__mobileItem.executor.businessName : 'Select Assignee'"
                                      :readonly="readOnly || item.__mobileItem.fixedBySalary"
                                      append-icon="mdi-menu-down"
                                      single-line
                                      hide-details
                                      class="rounded-lg mb-n6 border-none fz-14 text-black font-weight-medium pointer select-designer"
                                      dense
                                    />
                                    <span
                                      style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                                      class="rounded-circle ml-3"
                                      :style="colorDesigner(item.__mobileItem.executor ? item.__mobileItem.executor.statusLabel : '')"
                                    />
                                    <span class="fz-10 text-dgray font-weight-medium">
                                  {{ item.__mobileItem.executor ? item.__mobileItem.executor.statusLabel : 'Unknown' }}
                                </span>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-slot:default="dialog">
                                <v-card class="rounded-xl d-flex flex-column hidden" v-click-outside="clearSearch">
                                  <v-card-title class="pt-7 px-5 align-start">
                                    <div>
                                      <p
                                        style="line-height: normal"
                                        class="text-gray fz-14 mb-1"
                                      >
                                        Change Assignee
                                      </p>
                                      <p class="fz-20 text-black fw-600 mb-0 lh-20">
                                        List of Assignees
                                      </p>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-icon class="close-icon my-auto" @click="dialog.value = false; clearSearch">$close
                                    </v-icon>
                                  </v-card-title>
                                  <v-divider class="black bb-1 mb-1"/>
                                  <v-card-text class="scroll">
                                    <v-col class="pa-0">
                                      <v-text-field
                                        outlined
                                        hide-details
                                        placeholder="Search by designers"
                                        class="border-none mx-n4 fz-14 text-black font-weight-medium"
                                        prepend-inner-icon="$search"
                                        v-model="designerSearch"
                                      />
                                      <v-divider class="mt-1"/>
                                      <!--Main Assignees-->
                                      <template>
                                        <p class="mb-0">Main assignee</p>
                                        <v-list>
                                          <v-list-item
                                            @click="setExecutor(designer, item.__mobileItem); dialog.value = false"
                                            v-for="designer in newAssignee ? newAssignee.filter(el => designerSearch ? el.businessName.toLowerCase().includes(designerSearch.toLowerCase()) : el) : newAssignee"
                                            :key="designer.id"
                                            :disabled="designer.cantPicked"
                                            class="rounded-lg hover-rounded-lg"
                                          >
                                            {{ designer.businessName }}
                                            <v-spacer></v-spacer>
                                            <DesignerStatus :designer="designer"/>
                                          </v-list-item>
                                        </v-list>
                                      </template>
                                      <!--Additional Assignees-->
                                      <template v-if="lengthAdditionalAssignee">
                                        <p class="mb-0">Additional assignee</p>
                                        <v-list>
                                          <!--? newAdditionalAssignee.filter(el => designerSearch ? el.businessName.toLowerCase().includes(designerSearch.toLowerCase()) : el) : newAdditionalAssignee-->
                                          <v-list-item
                                            @click="setExecutor(designer, item.__mobileItem ? item.__mobileItem : item); dialog.value = false"
                                            v-for="designer in newAdditionalAssignee ? newAdditionalAssignee.filter(el => designerSearch ? el.businessName.toLowerCase().includes(designerSearch.toLowerCase()) : el) : newAdditionalAssignee"
                                            :key="designer.id"
                                            :disabled="designer.cantPicked"
                                            class="rounded-lg hover-rounded-lg"
                                          >
                                            {{ designer.businessName }}
                                            <v-spacer></v-spacer>
                                            <DesignerStatus :designer="designer"/>
                                          </v-list-item>
                                        </v-list>
                                      </template>
                                    </v-col>
                                  </v-card-text>
                                </v-card>
                              </template>
                            </v-dialog>
                          </template>
                        </v-row>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <v-row class="mx-n2">
                          <v-col class="pa-2 d-flex flex-column align-center">
                            <!--Desktop or usual item-->
                            <v-menu
                              :disabled="readOnly || item.fixedBySalary"
                              :attach="'#select27i' + replaceDD(item.id)"
                              content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-col class="pa-0 d-flex align-center" :class="isDisabled(item.fixedBySalary)">
                                  <v-select
                                    :items="statusLabels"
                                    v-model="item.status"
                                    item-text="label"
                                    item-value="value"
                                    single-line
                                    outlined
                                    class="rounded-lg fz-14 font-weight-medium inline-input border-none input-clear-small select-dash"
                                    dense
                                    return-object
                                    item-color="#010820"
                                    hide-selected
                                    hide-details
                                    readonly
                                    :menu-props="{ bottom: true, offsetY: true }"
                                  >
                                    <template v-slot:selection="data">
                                      <v-col class="pa-0">
                                  <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                                        class="rounded-circle" :style="color(data.item.value)"></span>
                                        <span
                                          style="max-width: 80%"
                                          class="fz-14 font-weight-medium text-black"
                                        >
                                    {{ data.item.label }}
                                  </span>
                                        <p
                                          class="d-block mb-0 fz-14 text-gray"
                                          style="margin-top: 5px; margin-left: 14px !important; line-height: 14px;"
                                          v-if="item.showCompletionProgress"
                                        >
                                          {{ Math.round(item.completedCount / item.count * 100) }} %
                                        </p>
                                      </v-col>
                                    </template>
                                    <template v-slot:prepend-inner>
                                      <v-sheet
                                        v-bind="attrs"
                                        v-on="on"
                                        class="absolute"
                                        elevation="0"
                                        color="transparent"
                                        style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                                      ></v-sheet>
                                    </template>
                                    <template v-slot:append>
                                      <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                                    </template>
                                  </v-select>
                                  <div :id="'select27i' + replaceDD(item.id)" class="absolute"></div>
                                  <v-tooltip bottom v-if="item.status === 'CANCELED'" max-width="600">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div class="mr-2">
                                        <div
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="changeItemStatus(item, false, true)"
                                          class="pointer"
                                        >
                                          <v-icon>
                                            $questionMark
                                          </v-icon>
                                        </div>
                                      </div>
                                    </template>
                                    <v-col class="d-flex flex-column flex-wrap flex-grow-0" style="word-break: break-all">
                                      {{ item.cancellationReason }}
                                    </v-col>
                                  </v-tooltip>
                                </v-col>
                              </template>
                              <v-card class="rounded-lg mt-1 hidden" elevation="0">
                                <v-card-text class="px-0 py-1">
                                  <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                                    <v-list-item
                                      v-for="(status, index) in statusLabels"
                                      :key="index"
                                      @click="changeItemStatus(item, status)"
                                      style="min-height: 30px"
                                      class="pr-0 pl-2 py-1"
                                    >
                                <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                                      class="rounded-circle" :style="color(status.value)"></span>
                                      <span style="max-width: 80%" class="fz-14 font-weight-medium text-black">{{
                                          status.label
                                        }}</span>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                            <!-- Mobile Item -->
                            <template v-if="item.__mobileItem">
                              <v-divider class="black my-2 width-100"/>
                              <v-menu
                                :disabled="readOnly || item.__mobileItem.fixedBySalary"
                                :attach="'#select28i' + replaceDD(item.__mobileItem.id)"
                                content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-col class="pa-0 d-flex align-center" :class="isDisabled(item.__mobileItem.fixedBySalary)">
                                    <v-select
                                      :items="statusLabels"
                                      v-model="item.__mobileItem.status"
                                      item-text="label"
                                      item-value="value"
                                      single-line
                                      outlined
                                      class="rounded-lg fz-14 font-weight-medium inline-input border-none input-clear-small select-dash"
                                      dense
                                      return-object
                                      item-color="#010820"
                                      hide-selected
                                      hide-details
                                      readonly
                                      :menu-props="{ bottom: true, offsetY: true }"
                                    >
                                      <template v-slot:selection="data">
                                        <v-col class="pa-0">
                                  <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                                        class="rounded-circle" :style="color(data.item.value)"></span>
                                          <span
                                            style="max-width: 80%"
                                            class="fz-14 font-weight-medium text-black"
                                          >
                                    {{ data.item.label }}
                                  </span>
                                          <p
                                            class="d-block mb-0 fz-14 text-gray"
                                            style="margin-top: 5px; margin-left: 14px !important; line-height: 14px;"
                                            v-if="item.__mobileItem.showCompletionProgress"
                                          >
                                            {{ Math.round(item.__mobileItem.completedCount / item.__mobileItem.count * 100) }} %
                                          </p>
                                        </v-col>
                                      </template>
                                      <template v-slot:prepend-inner>
                                        <v-sheet
                                          v-bind="attrs"
                                          v-on="on"
                                          class="absolute"
                                          elevation="0"
                                          color="transparent"
                                          style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                                        ></v-sheet>
                                      </template>
                                      <template v-slot:append>
                                        <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                                      </template>
                                    </v-select>
                                    <div :id="'select28i' + replaceDD(item.__mobileItem.id)" class="absolute"></div>
                                    <v-tooltip bottom v-if="item.__mobileItem.status === 'CANCELED'">
                                      <template v-slot:activator="{ on, attrs }">
                                        <div class="mr-2">
                                          <div
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="changeItemStatus(item.__mobileItem, false, true)"
                                            class="pointer"
                                          >
                                            <v-icon>
                                              $questionMark
                                            </v-icon>
                                          </div>
                                        </div>
                                      </template>
                                      <v-col class="d-flex flex-column flex-wrap flex-grow-0">
                                        {{ item.__mobileItem.cancellationReason }}
                                      </v-col>
                                    </v-tooltip>
                                  </v-col>
                                </template>
                                <v-card class="rounded-lg mt-1 hidden" elevation="0">
                                  <v-card-text class="px-0 py-1">
                                    <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                                      <v-list-item
                                        v-for="(status, index) in statusLabels"
                                        :key="index"
                                        @click="changeItemStatus(item.__mobileItem, status)"
                                        style="min-height: 30px"
                                        class="pr-0 pl-2 py-1"
                                      >
                                      <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
                                            class="rounded-circle" :style="color(status.value)"></span>
                                        <span style="max-width: 80%" class="fz-14 font-weight-medium text-black">{{
                                            status.label
                                          }}</span>
                                      </v-list-item>
                                    </v-list>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </template>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:item.discount="{ item }">
                        <v-row class="mx-n2 flex-column">
                          <v-col class="pa-0">
                            <!---->
                            <v-menu
                              :disabled="readOnly"
                              attach="#select30"
                              content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-col class="pa-0">
                                  <v-select
                                    :items="allDiscounts"
                                    v-model="item.discount"
                                    single-line
                                    outlined
                                    class="rounded-lg mb-n4 fz-14 font-weight-medium inline-input border-none input-clear-small select-dash"
                                    dense
                                    return-object
                                    @change="setDiscount(item)"
                                    item-color="#010820"
                                    hide-selected
                                    :readonly="readOnly"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                  >
                                    <template v-slot:selection="data">
                                      <span>{{ Math.round(data.item.rate * 100) / 100 }}%</span>
                                    </template>
                                    <template v-slot:prepend-inner>
                                      <v-sheet
                                        v-bind="attrs"
                                        v-on="on"
                                        class="absolute"
                                        elevation="0"
                                        color="transparent"
                                        style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                                      ></v-sheet>
                                    </template>
                                    <template v-slot:append>
                                      <!--                               <v-sheet color="transparent" style="margin: auto; z-index: 400" v-if="item.discount" @click.stop>-->
                                      <!--                                 <v-icon  @click="removeDiscount(item)" size="16"  class="stroke-gray">$clear</v-icon>-->
                                      <!--                               </v-sheet>-->
                                      <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                                    </template>
                                  </v-select>
                                  <div id="select30" class="absolute"></div>
                                </v-col>
                              </template>
                              <v-card class="rounded-lg mt-1 hidden" elevation="0">
                                <v-card-text class="px-0 py-1">
                                  <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                                    <v-list-item
                                      @click="changeDiscount({id: item.id, discount: null})"
                                      style="min-height: 30px"
                                      class="pr-0 pl-2 py-1"
                                    >
                                      None
                                    </v-list-item>
                                    <v-list-item
                                      v-for="(discount, index) in allDiscounts"
                                      :key="index"
                                      @click="changeDiscount({id: item.id, discount: discount})"
                                      style="min-height: 30px"
                                      class="pr-0 pl-2 py-1"
                                    >
                                      <span>{{ Math.round(discount.rate * 100) / 100 }}% ({{ discount.name }})</span>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                            <!---->
                            <template v-if="item.__mobileItem">
                              <v-divider class="black my-2 width-100"/>
                              <v-menu
                                :disabled="readOnly"
                                :attach="'#select30' + replaceDD(item.__mobileItem.id)"
                                content-class="menu-shadow rounded-lg bg-white border-lightgray top-0 left-0 width-100"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-col class="pa-0">
                                    <v-select
                                      :items="allDiscounts"
                                      v-model="item.__mobileItem.discount"
                                      single-line
                                      outlined
                                      class="rounded-lg mb-n4 fz-14 font-weight-medium inline-input border-none input-clear-small select-dash"
                                      dense
                                      return-object
                                      @change="setDiscount(item.__mobileItem)"
                                      item-color="#010820"
                                      hide-selected
                                      :readonly="readOnly"
                                      :menu-props="{ bottom: true, offsetY: true }"
                                    >
                                      <template v-slot:selection="data">
                                        <span>{{ Math.round(data.item.rate * 100) / 100 }}%</span>
                                      </template>
                                      <template v-slot:prepend-inner>
                                        <v-sheet
                                          v-bind="attrs"
                                          v-on="on"
                                          class="absolute"
                                          elevation="0"
                                          color="transparent"
                                          style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                                        ></v-sheet>
                                      </template>
                                      <template v-slot:append>
                                        <!--                               <v-sheet color="transparent" style="margin: auto; z-index: 400" v-if="item.discount" @click.stop>-->
                                        <!--                                 <v-icon  @click="removeDiscount(item)" size="16"  class="stroke-gray">$clear</v-icon>-->
                                        <!--                               </v-sheet>-->
                                        <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                                      </template>
                                    </v-select>
                                    <div :id="'select30' + replaceDD(item.__mobileItem.id)" class="absolute"></div>
                                  </v-col>
                                </template>
                                <v-card class="rounded-lg mt-1 hidden" elevation="0">
                                  <v-card-text class="px-0 py-1">
                                    <v-list class="pa-0" color="transparent scroll" style="max-height: 200px">
                                      <v-list-item
                                        @click="changeDiscount({id: item.__mobileItem.id, discount: null})"
                                        style="min-height: 30px"
                                        class="pr-0 pl-2 py-1"
                                      >
                                        None
                                      </v-list-item>
                                      <v-list-item
                                        v-for="(discount, index) in allDiscounts"
                                        :key="index"
                                        @click="changeDiscount({id: item.__mobileItem.id, discount: discount})"
                                        style="min-height: 30px"
                                        class="pr-0 pl-2 py-1"
                                      >
                                        <span>{{ Math.round(discount.rate * 100) / 100 }}% ({{ discount.name }})</span>
                                      </v-list-item>
                                    </v-list>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </template>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:item.dateStartPrediction="{ item }">
                        <vc-date-picker
                          locale="en"
                          class="inline-block h-full"
                          :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
                          :popover="{ visibility: readOnly || item.fixedBySalary ? 'hidden' : 'click' }"
                          :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                          mode="date"
                          @input="setDateStart(item)"
                          :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                          v-model="item.correctDateStart"
                          :attributes="[{key: 'today', highlight: true, dates: new Date()}]"
                        >
                          <template v-slot="">
                            <v-col :class="isDisabled(item.fixedBySalary)">
                              <v-row>
                                <v-text-field
                                  outlined
                                  single-line
                                  :value="item.correctDateStart ? formatDate(item.correctDateStart) : item.dateStartPrediction ? formatDate(item.dateStartPrediction) : ' '"
                                  :disabled="item.fixedBySalary"
                                  class="rounded-lg pointer fz-14 font-weight-medium border-none"
                                  :class="{'color-primary': item.correctDateStart && item.dateStartPrediction, 'fw-600': item.correctDateStart && item.dateStartPrediction}"
                                  dense
                                  hide-details
                                  readonly
                                ></v-text-field>
                              </v-row>
                              <v-row>
                                <v-text-field
                                  outlined
                                  single-line
                                  v-if="item.correctDateStart && item.dateStartPrediction"
                                  :value="item.dateStartPrediction ? formatDate(item.dateStartPrediction) : ' '"
                                  :disabled="item.fixedBySalary"
                                  class="rounded-lg pointer fz-10 mt-n5 font-weight-normal border-none"
                                  dense
                                  hide-details
                                  readonly
                                ></v-text-field>
                              </v-row>
                            </v-col>
                          </template>
                        </vc-date-picker>
                        <template v-if="item.__mobileItem">
                          <v-divider class="black my-2 width-100"/>
                          <vc-date-picker
                            locale="en"
                            class="inline-block h-full"
                            :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
                            :popover="{ visibility: readOnly || item.__mobileItem.fixedBySalary ? 'hidden' : 'click' }"
                            :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                            mode="date"
                            @input="setDateStart(item.__mobileItem)"
                            :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                            v-model="item.__mobileItem.correctDateStart"
                            :attributes="[{key: 'today', highlight: true, dates: new Date()}]"
                          >
                            <template v-slot="">
                              <v-col :class="isDisabled(item.__mobileItem.fixedBySalary)">
                                <v-row>
                                  <v-text-field
                                    outlined
                                    single-line
                                    :value="item.__mobileItem.correctDateStart ? formatDate(item.__mobileItem.correctDateStart) : item.__mobileItem.dateStartPrediction ? formatDate(item.__mobileItem.dateStartPrediction) : ' '"
                                    class="rounded-lg pointer fz-14 font-weight-medium border-none"
                                    :class="{'color-primary': item.correctDateStart && item.dateStartPrediction, 'fw-600': item.__mobileItem.correctDateStart && item.__mobileItem.dateStartPrediction}"
                                    dense
                                    hide-details
                                    readonly
                                  ></v-text-field>
                                </v-row>
                                <v-row>
                                  <v-text-field
                                    outlined
                                    single-line
                                    v-if="item.__mobileItem.correctDateStart && item.__mobileItem.dateStartPrediction"
                                    :value="item.__mobileItem.dateStartPrediction ? formatDate(item.__mobileItem.dateStartPrediction) : ' '"
                                    class="rounded-lg pointer fz-10 mt-n5 font-weight-normal border-none"
                                    dense
                                    hide-details
                                    readonly
                                  ></v-text-field>
                                </v-row>
                              </v-col>
                            </template>
                          </vc-date-picker>
                        </template>
                      </template>
                      <template v-slot:item.dateEndPrediction="{ item }">
                        <vc-date-picker
                          locale="en"
                          class="inline-block h-full"
                          :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
                          :popover="{ visibility: readOnly ? 'hidden' : 'click' }"
                          :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                          mode="date"
                          @input="setExpirationDate(item)"
                          :min-date='item.correctDateStart ? item.correctDateStart : item.dateStartPrediction'
                          :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                          v-model="item.expirationDate"
                          :attributes="[{key: 'today', highlight: true, dates: new Date()}]"
                        >
                          <template v-slot="">
                            <v-col>
                              <v-row>
                                <v-text-field
                                  outlined
                                  single-line
                                  :value="item.expirationDate ? formatDate(item.expirationDate) : item.dateEndPrediction ? formatDate(item.dateEndPrediction) : ' '"
                                  class="rounded-lg pointer fz-14 font-weight-medium border-none"
                                  :class="{'color-primary': item.expirationDate && item.dateEndPrediction, 'fw-600': item.expirationDate && item.dateEndPrediction}"
                                  dense
                                  hide-details
                                  readonly
                                />
                              </v-row>
                              <v-row>
                                <v-text-field
                                  outlined
                                  single-line
                                  v-if="item.expirationDate && item.dateEndPrediction"
                                  :value="item.dateEndPrediction ? formatDate(item.dateEndPrediction) : ''"
                                  class="rounded-lg pointer fz-10 mt-n5 font-weight-normal border-none"
                                  dense
                                  hide-details
                                  readonly
                                />
                              </v-row>
                            </v-col>
                          </template>
                        </vc-date-picker>
                        <template v-if="item.__mobileItem">
                          <v-divider class="black my-2 width-100"/>
                          <vc-date-picker
                            locale="en"
                            class="inline-block h-full"
                            :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
                            :popover="{ visibility: readOnly ? 'hidden' : 'click' }"
                            :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
                            mode="date"
                            @input="setExpirationDate(item.__mobileItem)"
                            :min-date='item.__mobileItem.correctDateStart ? item.__mobileItem.correctDateStart : item.__mobileItem.dateStartPrediction'
                            :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
                            v-model="item.__mobileItem.expirationDate"
                            :attributes="[{key: 'today', highlight: true, dates: new Date()}]"
                          >
                            <template v-slot="">
                              <v-col>
                                <v-row>
                                  <v-text-field
                                    outlined
                                    single-line
                                    :value="item.__mobileItem.expirationDate ? formatDate(item.__mobileItem.expirationDate) : item.__mobileItem.dateEndPrediction ? formatDate(item.__mobileItem.dateEndPrediction) : ' '"
                                    class="rounded-lg pointer fz-14 font-weight-medium border-none"
                                    :class="{'color-primary': item.__mobileItem.expirationDate && item.__mobileItem.dateEndPrediction, 'fw-600': item.__mobileItem.expirationDate && item.__mobileItem.dateEndPrediction}"
                                    dense
                                    hide-details
                                    readonly
                                  />
                                </v-row>
                                <v-row>
                                  <v-text-field
                                    outlined
                                    single-line
                                    v-if="item.__mobileItem.expirationDate && item.__mobileItem.dateEndPrediction"
                                    :value="item.__mobileItem.dateEndPrediction ? formatDate(item.__mobileItem.dateEndPrediction) : ''"
                                    class="rounded-lg pointer fz-10 mt-n5 font-weight-normal border-none"
                                    dense
                                    hide-details
                                    readonly
                                  />
                                </v-row>
                              </v-col>
                            </template>
                          </vc-date-picker>
                        </template>
                      </template>
                      <template v-slot:item.price="{ item }">
                        <v-row no-gutters align="center" class="flex-column">
                          <span class="text-dgray" :class="isDisabled(item.fixedBySalary)">
                          {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                          {{ Math.round(item.price) | numberFormat }}
                          {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                        </span>
                          <template v-if="item.__mobileItem">
                            <v-divider class="black my-2 width-100"/>
                            <span class="text-dgray" :class="isDisabled(item.__mobileItem.fixedBySalary)">
                          {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                          {{ Math.round(item.__mobileItem.price) | numberFormat }}
                          {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                        </span>
                          </template>
                        </v-row>
                      </template>
                      <!--Only use for Regular = subtotal-->
                      <template v-slot:item.subtotal="{ item }">
                        <v-row no-gutters align="center" class="flex-column">
                          <span class="text-dgray" :class="isDisabled(item.fixedBySalary)">
                            {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                            {{ Math.round(item.subtotal) | numberFormat }}
                            {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                          </span>
                          <template v-if="item.__mobileItem">
                            <v-divider class="black my-2 width-100"/>
                            <span class="text-dgray" :class="isDisabled(item.__mobileItem.fixedBySalary)">
                              {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                              {{ Math.round(item.__mobileItem.subtotal) | numberFormat }}
                              {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                             </span>
                          </template>
                        </v-row>
                      </template>
                      <!--Use all, except Regular = subtotal-->
                      <template v-slot:item.customCost="{ item }">
                        <v-row no-gutters align="center" class="flex-column">
                          <input-currency-transparent
                            :classes="'fz-14 text-gray'"
                            :value="item.subtotal"
                            :currency="order.currencySymbol"
                            :readOnly="readOnly || item.fixedBySalary"
                            :disabled="item.fixedBySalary"
                            message="Subtotal"
                            @blur="(event) => setCustomCost(event, item)"
                          />
                          <template v-if="item.__mobileItem">
                            <v-divider class="black my-2 width-100"/>
                            <input-currency-transparent
                              :classes="'fz-14 text-gray'"
                              :value="item.__mobileItem.subtotal"
                              :currency="order.currencySymbol"
                              :readOnly="readOnly || item.__mobileItem.fixedBySalary"
                              :disabled="item.__mobileItem.fixedBySalary"
                              message="Subtotal"
                              @blur="(event) => setCustomCost(event, item)"
                            />
                          </template>
                        </v-row>
                      </template>
                      <!--Use all, DesignerCost/Assignee-->
                      <template v-slot:item.customDesignerCost="{ item }">
                        <!--ONLY REGULAR-->
                        <template v-if="!item.customDesignerCost">
                          <v-row no-gutters align="center" class="flex-column">
                            <input-currency-transparent
                              :classes="'fz-14 text-gray'"
                              :currency="order.currencySymbol"
                              :readOnly="readOnly || order.type === 'REGULAR' || item.fixedBySalary"
                              :disabled="item.fixedBySalary"
                              :value="item.designerCost"
                              message="Assignee Cost"
                              @blur="(event) => setCustomDesignerCost(event, item)"
                            />
                            <template v-if="item.__mobileItem">
                              <v-divider class="black my-n1 width-100"/>
                              <input-currency-transparent
                                :classes="'fz-14 text-gray'"
                                :currency="order.currencySymbol"
                                :readOnly="readOnly || order.type === 'REGULAR' || item.__mobileItem.fixedBySalary"
                                :disabled="item.__mobileItem.fixedBySalary"
                                :value="item.__mobileItem.designerCost"
                                message="Assignee Cost"
                                @blur="(event) => setCustomDesignerCost(event, item.__mobileItem)"
                              />
                            </template>
                          </v-row>
                        </template>
                        <!--Use all other types-->
                        <v-sheet v-else color="transparent">
                          <input-currency-transparent
                            :classes="'fz-14 color-primary font-weight-medium z-index-1'"
                            :readOnly="readOnly"
                            :value="item.customDesignerCost"
                            :currency="order.currencySymbol"
                            message="Assignee Cost"
                            @blur="(event) => setCustomDesignerCost(event, item)"
                          />
                          <input-currency-transparent
                            :classes="'fz-10 mt-n5'"
                            :readOnly="true"
                            :value="item.originalDesignerCost"
                            :currency="order.currencySymbol"
                            message="Assignee Cost"
                            @blur="(event) => setCustomDesignerCost(event, item)"
                          />
                          <template v-if="item.__mobileItem">
                            <v-divider class="black my-n1 width-100"/>
                            <input-currency-transparent
                              :classes="'fz-14 color-primary font-weight-medium z-index-1'"
                              :readOnly="readOnly"
                              :value="item.__mobileItem.customDesignerCost"
                              :currency="order.currencySymbol"
                              message="Assignee Cost"
                              @blur="(event) => setCustomDesignerCost(event, item.__mobileItem)"
                            />
                            <input-currency-transparent
                              :classes="'fz-10 mt-n5'"
                              :readOnly="true"
                              :value="item.__mobileItem.originalDesignerCost"
                              :currency="order.currencySymbol"
                              message="Assignee Cost"
                              @blur="(event) => setCustomDesignerCost(event, item.__mobileItem)"
                            />
                          </template>
                        </v-sheet>
                      </template>
                      <!---->
                      <template v-slot:item.profit="{ item }">
                        <v-row no-gutters align="center" class="flex-column">
                          <span class="text-dgray" :class="isDisabled(item.fixedBySalary)">
                            {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                            {{ Math.round(item.profit) | numberFormat }}
                            {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                         </span>
                          <template v-if="item.__mobileItem">
                            <v-divider class="black my-2 width-100"/>
                            <span class="text-dgray" :class="isDisabled(item.__mobileItem.fixedBySalary)">
                          {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}
                          {{ Math.round(item.__mobileItem.profit) | numberFormat }}
                          {{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}
                       </span>
                          </template>
                        </v-row>
                      </template>
                      <template v-slot:item.changeRate="{ item }">
                        <EditRate
                          :item="changingRate.item"
                          :orderCurrency="{currency: order.currency, exchangeRates: order.exchangeRates, currencySymbol: order.currencySymbol}"
                          :orderId="order.id"
                          @readyToUpdateItem="getOrderItems"
                        >
                          <v-btn
                            v-show="!readOnly"
                            @click="openEditOrder(item.id)"
                            elevation="0"
                            x-small
                            icon
                            fab
                          >
                            <v-icon size="22" class="stroke-gray">$edit</v-icon>
                          </v-btn>
                        </EditRate>
                      </template>
                      <template v-slot:item.remove="{ item }">
                        <v-row no-gutters>
                          <div v-if="me.roles.includes('superuser')" class="d-flex flex-column">
                            <!--Desktop Item-->
                            <RemoveDialog
                              @commit="setFixedCondition(item.id, item.fixedBySalary)"
                              :deleting="false"
                            >
                              <template v-slot:message>
                                <span>
                                  {{ item.fixedBySalary ? 'unlocking' : 'locking'}}
                                </span>
                              </template>
                              <template>
                                <v-btn
                                  v-if="!readOnly"
                                  elevation="0"
                                  x-small
                                  icon
                                  fab
                                >
                                  <v-icon v-if="item.fixedBySalary" size="20" class="stroke-black path-black">$lock</v-icon>
                                  <v-icon v-else size="20" class="stroke-gray path-gray">$openLock</v-icon>
                                </v-btn>
                              </template>
                            </RemoveDialog>
                            <!--Mobile Item-->
                            <template v-if="item.__mobileItem">
                              <v-divider class="black my-2 width-100"/>
                              <RemoveDialog
                                @commit="setFixedCondition(item.__mobileItem.id, item.__mobileItem.fixedBySalary)"
                                :deleting="false"
                              >
                                <template v-slot:message>
                            <span>
                              {{ item.__mobileItem.fixedBySalary ? 'unlocking' : 'locking'}}
                            </span>
                                </template>
                                <template>
                                  <v-btn
                                    v-if="!readOnly"
                                    elevation="0"
                                    x-small
                                    icon
                                    fab
                                  >
                                    <v-icon v-if="item.__mobileItem.fixedBySalary" size="20" class="stroke-black path-black">$lock</v-icon>
                                    <v-icon v-else size="20" class="stroke-gray path-gray">$openLock</v-icon>
                                  </v-btn>
                                </template>
                              </RemoveDialog>
                            </template>
                          </div>
                          <RemoveDialog
                            @commit="removeOrderItem(item)"
                            :disabled="item.fixedBySalary"
                          >
                            <template v-slot:message>
                            <span>
                              {{ item.name }}
                            </span>
                            </template>
                            <template>
                              <v-btn
                                v-if="!readOnly"
                                :disabled="item.fixedBySalary"
                                elevation="0"
                                x-small
                                icon
                                fab
                              >
                                <v-icon size="22" class="stroke-gray">$delete</v-icon>
                              </v-btn>
                            </template>
                          </RemoveDialog>
                        </v-row>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <!--Button to Add new item-->
                <v-row>
                  <v-btn
                    @click="createNewItem.isActive = true; newItemShow = true; relatedItem.isCreate = false; createNewItem.isActive = true"
                    height="50"
                    text
                    tile
                    v-show="!readOnly"
                    color="#7552CC"
                    class="text-none px-3 fz-14"
                  >
                    <b class="fz-16 fw-600 mr-1"> + </b>
                    Add new item
                  </v-btn>
                </v-row>
              </v-tab-item>
              <!--Gantt-->
              <v-tab-item>
                <!--Send compositeItems + ungrouped items-->
                <FrappeGantt
                  v-if="orderItems.length"
                  :tasks="[...this.compositeItems, ...this.orderItems.filter(el => el.compositeItem.id.includes('fakeId'))]"
                  :allTasks="orderItems"
                  @task-clicked="openEditItem"
                  @task-changed-name="setOwnName"
                  @task-date-updated="setDateRange"
                  @task-changed-position="setPositionItem"
                  @task-changed-assignee="setDesigner"
                  @task-changed-status="changeItemStatus"
                  @task-changed-completing="setCompletedCount($event.id, $event.progress)"
                  @interface="getChildInterface"
                />
              </v-tab-item>
            </v-tabs-items>
            <!--PDF-->
            <vue-html2pdf
              v-if="order.id"
              :show-layout="false"
              :float-layout="true"
              :preview-modal="false"
              :enable-download="true"
              :paginate-elements-by-height="1400"
              :filename="'Order_#' + order.number"
              :pdf-quality="2"
              :manual-pagination="true"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              ref="html2Pdf"
              @hasDownloaded="afterDownloadedPDF"
            >
              <section slot="pdf-content">
                <div style="margin: 30px 40px 0 40px">
                  <div style="text-align: center">
                    <img style="width: 100px; max-width: 100px; max-height: 28px;" src="@/assets/img/logo.png" alt="Logo">
                  </div>
                  <div style="margin-top: 40px">
                    <span style="font-size: 14px" class="text-black fw-600">Order Details</span>
                    <span style="font-size: 14px; float: right" class="text-black fw-600">#{{ order.id }}</span>
                  </div>
                  <v-divider style="border-color: #000000; margin-top: 5px" class="black"></v-divider>
                  <div style="margin-top: 30px">
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Manager</span>{{
                        order.manager ? order.manager.businessName : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Payment Type</span>{{
                        order.paymentType ? order.paymentType.name : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Order Name</span>{{
                        order.name ? order.name : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Figma Link</span>{{
                        order.figmaUrl ? order.figmaUrl : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Mobile version</span>{{
                        order.mobile ? 'Yes' : 'No'
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Managment</span>Yes
                    </p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Prototype</span>{{
                        order.prototype ? 'Yes' : 'No'
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Author supervision</span>{{
                        order.authorSupervision ? 'Yes' : 'No'
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Fee rate</span>{{
                        Math.round(defaultFee * 100) / 100
                      }}%</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Date Start</span>{{
                        order.dateStart ? formatDate(order.dateStart) : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Date End Prediction</span>{{
                        order.dateEndPrediction ? formatDate(order.dateEndPrediction) : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Project Difficulty</span>{{
                        order.difficulty ? order.difficulty.name : ' '
                      }}</p>
                  </div>
                  <div style="margin-top: 40px">
                    <span style="font-size: 14px" class="text-black fw-600">Client Area</span>
                  </div>
                  <v-divider style="border-color: #000000; margin-top: 5px" class="black"></v-divider>
                  <div style="margin-top: 30px">
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Name</span>{{
                        {}.toString.call(order.client) === '[object Object]' ? order.client.name : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Telegram account</span>{{
                        {}.toString.call(order.client) === '[object Object]' ? order.client.telegram : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Company</span>{{
                        {}.toString.call(order.client) === '[object Object]' ? order.client.company ? order.client.company.name : ' ' : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Phone</span>{{
                        {}.toString.call(order.client) === '[object Object]' ? order.client.phone : ' '
                      }}</p>
                    <p style="font-size: 10px; font-weight: 500; margin-bottom: 10px" class="text-black"><span
                      style="width: 160px; min-width: 160px; display: inline-block; font-weight: 400;" class="text-gray">Source</span>{{
                        {}.toString.call(order.client) === '[object Object]' ? order.client.channel ? order.client.channel.name : ' ' : ' '
                      }}</p>
                  </div>
                  <div style="margin-top: 40px">
                    <span style="font-size: 14px" class="text-black fw-600">Details</span>
                  </div>
                  <v-divider style="border-color: #000000; margin-top: 5px" class="black"></v-divider>
                  <div style="margin-top: 30px">
                    <div style="margin-bottom: 10px" v-for="(item, index) in orderItems" :key="'print' + item.id">
                      <div v-if="index < 7">
                        <div style="display: inline-block">
                          <img
                            v-if="item.iconUrl"
                            style="max-width: 32px; max-height: 32px; min-width: 32px; min-height: 32px; display: inline-block; margin-bottom: -6px; margin-right: 10px"
                            :src="item.iconUrl">
                          <img
                            v-else
                            style="max-width: 32px; max-height: 32px; min-width: 32px; min-height: 32px; display: inline-block; margin-bottom: -6px; margin-right: 10px"
                            src="@/assets/img/svg/Pen.svg">
                          <!--                        <v-icon style="margin-bottom: 16px">${{ item.iconUrl }}</v-icon>-->
                          <div style="width: 240px; display: inline-block">
                            <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">{{ item.name }}</p>
                            <p style="font-size: 10px; font-weight: 500" class="mb-0 text-gray">Cost: <span
                              class="text-black">{{
                                order.currencySymbol !== '₽' ? order.currencySymbol : ''
                              }}{{
                                +item.price | numberFormat
                              }}{{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}</span></p>
                          </div>
                        </div>
                        <div style="width: 90px; display: inline-block">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Quantity</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">{{ item.count }}
                            {{ item.itemFormatLabel }}</p>
                        </div>
                        <div style="width: 140px; display: inline-block">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Designer</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">
                            {{ item.executor ? item.executor.businessName : '&nbsp;' }}</p>
                        </div>
                        <div style="width: 140px; display: inline-block">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Price</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">
                            {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}{{
                              +item.subtotal | numberFormat
                            }}{{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}</p>
                        </div>
                        <div style="width: 90px; display: inline-block" v-if="item.discount">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Discount</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">{{ item.discount }}%</p>
                        </div>
                      </div>
                      <div v-if="index === 7" style="margin: 30px 0" class="html2pdf__page-break"></div>
                      <div v-if="index === 7" style="margin-top: 30px">
                        <div style="display: inline-block">
                          <img
                            v-if="item.iconUrl"
                            style="max-width: 32px; max-height: 32px; min-width: 32px; min-height: 32px; display: inline-block; margin-bottom: -6px; margin-right: 10px"
                            :src="item.iconUrl">
                          <img
                            v-else
                            style="max-width: 32px; max-height: 32px; min-width: 32px; min-height: 32px; display: inline-block; margin-bottom: -6px; margin-right: 10px"
                            src="@/assets/img/svg/Pen.svg">
                          <!--                        <v-icon style="margin-bottom: 16px">${{ item.iconUrl }}</v-icon>-->
                          <div style="width: 240px; display: inline-block">
                            <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">{{ item.name }}</p>
                            <p style="font-size: 10px; font-weight: 500" class="mb-0 text-gray">Cost: <span
                              class="text-black">{{
                                order.currencySymbol !== '₽' ? order.currencySymbol : ''
                              }}{{
                                +item.price | numberFormat
                              }}{{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}</span></p>
                          </div>
                        </div>
                        <div style="width: 90px; display: inline-block">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Quantity</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">{{ item.count }}
                            {{ item.itemFormatLabel }}</p>
                        </div>
                        <div style="width: 140px; display: inline-block">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Designer</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">
                            {{ item.executor ? item.executor.businessName : '&nbsp;' }}</p>
                        </div>
                        <div style="width: 140px; display: inline-block">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Price</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">
                            {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}{{
                              +item.subtotal | numberFormat
                            }}{{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}</p>
                        </div>
                        <div style="width: 90px; display: inline-block" v-if="item.discount">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Discount</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">{{ item.discount }}%</p>
                        </div>
                      </div>
                      <div v-if="index > 7">
                        <div style="display: inline-block">
                          <img
                            v-if="item.iconUrl"
                            style="max-width: 32px; max-height: 32px; min-width: 32px; min-height: 32px; display: inline-block; margin-bottom: -6px; margin-right: 10px"
                            :src="item.iconUrl">
                          <img
                            v-else
                            style="max-width: 32px; max-height: 32px; min-width: 32px; min-height: 32px; display: inline-block; margin-bottom: -6px; margin-right: 10px"
                            src="@/assets/img/svg/Pen.svg">
                          <!--                        <v-icon style="margin-bottom: 16px">${{ item.iconUrl }}</v-icon>-->
                          <div style="width: 240px; display: inline-block">
                            <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">{{ item.name }}</p>
                            <p style="font-size: 10px; font-weight: 500" class="mb-0 text-gray">Cost: <span
                              class="text-black">{{
                                order.currencySymbol !== '₽' ? order.currencySymbol : ''
                              }}{{
                                +item.price | numberFormat
                              }}{{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}</span></p>
                          </div>
                        </div>
                        <div style="width: 90px; display: inline-block">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Quantity</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">{{ item.count }}
                            {{ item.itemFormatLabel }}</p>
                        </div>
                        <div style="width: 140px; display: inline-block">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Designer</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">
                            {{ item.executor ? item.executor.businessName : '&nbsp;' }}</p>
                        </div>
                        <div style="width: 140px; display: inline-block">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Price</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">
                            {{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}{{
                              +item.subtotal | numberFormat
                            }}{{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}</p>
                        </div>
                        <div style="width: 90px; display: inline-block" v-if="item.discount">
                          <p style="font-size: 10px; font-weight: 700" class="mb-0 text-black">Discount</p>
                          <p style="font-size: 10px; font-weight: 500" class="mb-0 text-black">{{ item.discount }}%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 40px; text-align: right">
                    <span style="font-size: 10px; font-weight: 500" class="text-gray">Total Cost &nbsp;
                        <b style="font-size: 16px" class="text-black">&nbsp;{{
                            order.currencySymbol !== '₽' ? order.currencySymbol : ''
                          }}{{
                            +order.totalCost | numberFormat
                          }}{{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}</b>
                    </span>
                  </div>
                </div>
              </section>
            </vue-html2pdf>
          </v-col>
        </v-stepper-content>
        <!--Bottom line-->
        <v-stepper-header class="white bt-gray flex-grow-0">
          <v-col class="pl-7">
            <v-row v-if="readOnly && me.permissions.orders.orderEdit" class="ma-0">
              <v-btn
                :width="$vuetify.breakpoint.lgAndUp ? '160' : $vuetify.breakpoint.smAndUp ? '120' : '80'"
                height="40"
                class="mr-5 rounded-lg fz-16 fw-600 text-none"
                elevation="0"
                color="primary"
                @click="readOnly = false"
                outlined
              >
                <v-icon size="20" class="stroke-primary mr-1">$edit</v-icon>
                Edit
              </v-btn>
            </v-row>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp">
            <v-row class="ma-0">
              <v-spacer></v-spacer>
              <v-stepper-step
                step="1"
                :complete="stepper > 1"
                edit-icon="$complete"
                class="py-0 px-0 mr-n2 step-border pointer"
                @click="stepper = 1"
              >
              </v-stepper-step>
              <span class="d-inline-block my-auto mx-3"
                    style="background-color: #F6F8F9; width: 10px; height: 1px;"></span>
              <v-stepper-step
                step="2"
                :complete="stepper > 2"
                edit-icon="$complete"
                class="py-0 px-0 mr-n2 step-border pointer"
                @click="step2"
              >
              </v-stepper-step>
              <span class="d-inline-block my-auto mx-3"
                    style="background-color: #F6F8F9; width: 10px; height: 1px;"></span>
              <v-stepper-step
                step="3"
                :complete="stepper > 3"
                edit-icon="$complete"
                class="py-0 px-0 step-border pointer"
                @click="step3"
              >
              </v-stepper-step>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>
          <v-col class="pr-7">
            <v-row class="ma-0 justify-end">
              <v-btn
                :width="$vuetify.breakpoint.lgAndUp ? '160' : $vuetify.breakpoint.smAndUp ? '120' : '80'"
                height="40"
                class="mr-5 rounded-lg fz-16 fw-600 text-none"
                :class="{'ml-n15': $vuetify.breakpoint.smAndDown}"
                elevation="0"
                color="primary"
                outlined
                :disabled="stepper < 2"
                @click="stepper > 1 ? stepper-- : stepper">
                Return
              </v-btn>
              <v-btn
                :width="$vuetify.breakpoint.lgAndUp ? '160' : $vuetify.breakpoint.smAndUp ? '120' : '100'"
                height="40"
                class="rounded-lg fz-16 fw-600 text-none"
                elevation="0"
                color="primary"
                v-if="stepper < 3" @click="nextPage">
                Next step
              </v-btn>
              <v-btn
                :width="$vuetify.breakpoint.lgAndUp ? '160' : $vuetify.breakpoint.smAndUp ? '120' : '100'"
                height="40"
                class="rounded-lg fz-16 fw-600 text-none"
                elevation="0"
                color="primary"
                v-if="stepper > 2"
                :disabled="readOnly"
                @click="closeEdit">
                Save Order
              </v-btn>
            </v-row>
          </v-col>
        </v-stepper-header>
      </v-stepper>
      <!--Bottom navigation -->
      <!--Others-->
      <Snackbar :messages="snackbarMessage"/>
      <!--Adding new item-->
      <v-dialog
        v-model="newItemShow"
        transition="dialog-bottom-transition"
        max-width="800"
        overlay-color="rgba(9, 24, 73, 0.2)"
        overlay-opacity="1"
        content-class="rounded-xl dialog-flex"
        @close="createNewItem.isActive = false"
        @click:outside="createNewItem.isActive = false"
      >
        <v-card
          style="min-height: 800px!important;"
        >
          <v-toolbar
            elevation="0"
            class="bb-gray"
          >
            <v-tabs
              v-model="tabItemCreateOrder"
              align-with-title
              class="tabs-link"
            >
              <v-tab :ripple="false" class="text-none px-0 mr-7 fz-16 font-weight-bold hover-none">Product
                Plan
              </v-tab>
              <v-tab :ripple="false" class="text-none px-0 fz-16 font-weight-bold hover-none">Additional
                Services
              </v-tab>
              <v-spacer></v-spacer>
              <v-icon class="close-icon my-auto mr-5" @click="newItemShow = false">$close</v-icon>
            </v-tabs>
          </v-toolbar>
          <v-card-text class="scroll px-7 py-2 fon" style="height: calc(100% - 64px);">
            <v-tabs-items v-model="tabItemCreateOrder">
              <!--Product Plans-->
              <v-tab-item>
                <!--Search Field-->
                <v-text-field
                  outlined
                  hide-details
                  placeholder="Search by product plans"
                  class="mt-2 fz-14 text-black font-weight-medium bb-gray rounded-4 card-flex pointer"
                  style="border-bottom: 1px solid black"
                  prepend-inner-icon="$search"
                  v-model="productPlanSearch"
                  @input="debounceSearchProduct"
                />
                <!--Cards-->
                <v-col class="mt-5 pa-0 d-flex flex-wrap justify-space-between">
                  <v-card
                    v-for="card in allProductPlans"
                    :key="'plans' + card.id"
                    :id="'plans' + card.id"
                    @click="setNewCard(card, 'Product')"
                    class="mb-5 rounded-4 card-flex card-hover pointer pa-4"
                    max-width="380"
                    width="360"
                    outlined
                  >
                    <v-row class="ma-0 mb-2">
                      <v-img class="mr-2" v-if="card.iconUrl" max-width="32" max-height="32"
                             :src="card.iconUrl"></v-img>
                      <v-icon class="mr-2" v-else>{{ '$appIcon' }}</v-icon>
                      <div class="difficulty d-flex align-center">
                        <div
                          class="difficulty-dot"
                        >
                          <div
                            class="difficulty-bg"
                            :class="
                card.difficulty.name === 'Easy' ||
                card.difficulty.name === 'Medium' ||
                card.difficulty.name === 'Hard' ? 'difficulty-dot--active' : ''"
                          />
                        </div>
                        <div
                          class="difficulty-dot"
                        >
                          <div
                            class="difficulty-bg"
                            :class="
                card.difficulty.name === 'Medium' ||
                card.difficulty.name === 'Hard' ? 'difficulty-dot--active' : ''"
                          />
                        </div>
                        <div
                          class="difficulty-dot"
                        >
                          <div
                            class="difficulty-bg"
                            :class="card.difficulty.name === 'Hard' ? 'difficulty-dot--active' : ''"
                          />
                        </div>
                      </div>
                    </v-row>
                    <template v-if="card.skillCosts[0] && card.skillCosts[0].getWithMobileCost">
                      <v-row no-gutters class="align-stretch">
                        <v-col cols="12" class="mb-2">
                          <p class="fz-14 lh-22 mb-0 card-title font-weight-bold text-black"> {{
                              card.name
                            }}</p>
                          <v-row class="ma-0">
                            <div
                              class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-1 text-gray text-no-wrap">
                              Base cost:
                              <span class="font-weight-medium text-black">
            {{ Math.round(card.skillCosts[0].baseCostRub.amount) }}{{ card.skillCosts[0].baseCostRub.symbol }}/{{
                                  Math.round(card.skillCosts[0].baseCostUsd.amount)
                                }}{{ card.skillCosts[0].baseCostUsd.symbol }}
          </span>
                            </div>
                            <div
                              class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-1 text-gray text-no-wrap">
                              Sale cost:
                              <span class="font-weight-medium text-black">
              {{ Math.round(card.skillCosts[0].saleCostRub.amount) }}{{ card.skillCosts[0].saleCostRub.symbol }}/{{
                                  Math.round(card.skillCosts[0].saleCostUsd.amount)
                                }}{{ card.skillCosts[0].saleCostUsd.symbol }}
            </span>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <p class="fz-14 lh-22 mb-0 mt-1 card-title font-weight-bold text-black">Mobile
                            costs</p>
                          <v-row class="ma-0">
                            <div
                              class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-1 text-gray text-no-wrap">
                              Base cost:
                              <span
                                class="font-weight-medium text-black">{{
                                  Math.round(card.skillCosts[0].getWithMobileCost.mobileBaseCostRub.amount)
                                }}{{ card.skillCosts[0].getWithMobileCost.mobileBaseCostRub.symbol }}/{{
                                  Math.round(card.skillCosts[0].getWithMobileCost.mobileBaseCostUsd.amount)
                                }}{{ card.skillCosts[0].getWithMobileCost.mobileBaseCostUsd.symbol }}</span>
                            </div>
                            <div
                              class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-1 text-gray text-no-wrap">
                              Sale cost:
                              <span
                                class="font-weight-medium text-black">{{
                                  Math.round(card.skillCosts[0].getWithMobileCost.mobileSaleCostRub.amount)
                                }}{{ card.skillCosts[0].getWithMobileCost.mobileSaleCostRub.symbol }}/{{
                                  Math.round(card.skillCosts[0].getWithMobileCost.mobileSaleCostUsd.amount)
                                }}{{ card.skillCosts[0].getWithMobileCost.mobileSaleCostUsd.symbol }}</span>
                            </div>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row no-gutters class="align-stretch">
                        <v-col>
                          <p class="fz-14 lh-22 mb-0 card-title font-weight-bold text-black"> {{
                              card.name
                            }}</p>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 mt-2">
            <span class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-1 text-gray text-no-wrap">
                Base cost:
                <span class="font-weight-medium text-black">
            {{ Math.round(card.skillCosts[0].baseCostRub.amount) }}{{ card.skillCosts[0].baseCostRub.symbol }}/{{
                    Math.round(card.skillCosts[0].baseCostUsd.amount)
                  }}{{ card.skillCosts[0].baseCostUsd.symbol }}
          </span>
          </span>
                        <span class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-1 text-gray text-no-wrap">
                Sale cost:
                <span class="font-weight-medium text-black">
              {{ Math.round(card.skillCosts[0].saleCostRub.amount) }}{{ card.skillCosts[0].saleCostRub.symbol }}/{{
                    Math.round(card.skillCosts[0].saleCostUsd.amount)
                  }}{{ card.skillCosts[0].saleCostUsd.symbol }}
            </span>
          </span>
                      </v-row>
                    </template>
                  </v-card>
                </v-col>
              </v-tab-item>
              <!--Services-->
              <v-tab-item>
                <!--Search Field-->
                <v-text-field
                  outlined
                  hide-details
                  placeholder="Search by additional services"
                  class="mt-2 fz-14 text-black font-weight-medium bb-gray rounded-4 card-flex pointer"
                  style="border-bottom: 1px solid black"
                  prepend-inner-icon="$search"
                  v-model="servicesSearch"
                  @input="debounceSearchServices"
                />
                <!--Cards-->
                <v-col class="mt-5 pa-0 d-flex flex-wrap justify-space-between">
                  <v-card
                    v-for="card in allServices"
                    :key="'services' + card.id"
                    :id="'services' + card.id"
                    @click="setNewCard(card, 'Service')"
                    class="mb-5 pa-4 rounded-4 card-flex card-hover pointer"
                    max-width="380"
                    width="360"
                    outlined
                  >
                    <v-row class="ma-0 mb-2">
                      <v-img class="mr-2" v-if="card.iconUrl" max-width="32" max-height="32"
                             :src="card.iconUrl"></v-img>
                      <v-icon class="mr-2" v-else>{{ '$appIcon' }}</v-icon>
                      <div class="difficulty d-flex align-center">
                        <div
                          class="difficulty-dot"
                        >
                          <div
                            class="difficulty-bg"
                            :class="
                card.difficulty.name === 'Easy' ||
                card.difficulty.name === 'Medium' ||
                card.difficulty.name === 'Hard' ? 'difficulty-dot--active' : ''"
                          />
                        </div>
                        <div
                          class="difficulty-dot"
                        >
                          <div
                            class="difficulty-bg"
                            :class="
                card.difficulty.name === 'Medium' ||
                card.difficulty.name === 'Hard' ? 'difficulty-dot--active' : ''"
                          />
                        </div>
                        <div
                          class="difficulty-dot"
                        >
                          <div
                            class="difficulty-bg"
                            :class="card.difficulty.name === 'Hard' ? 'difficulty-dot--active' : ''"
                          />
                        </div>
                      </div>
                    </v-row>
                    <template v-if="card.skillCosts[0] && card.skillCosts[0].getWithMobileCost">
                      <v-row no-gutters class="align-stretch">
                        <v-col>
                          <p class="fz-14 lh-22 mb-0 card-title font-weight-bold text-black"> {{
                              card.name
                            }}</p>
                        </v-col>
                        <v-col>
                          <p class="fz-14 lh-22 mb-0 card-title font-weight-bold text-black">Mobile costs</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <v-row class="ma-0">
              <span class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-2 text-gray text-no-wrap">
                Base cost:
                <br>
                <span class="font-weight-medium text-black">
            {{ Math.round(card.skillCosts[0].baseCostRub.amount) }}{{ card.skillCosts[0].baseCostRub.symbol }}/{{
                    Math.round(card.skillCosts[0].baseCostUsd.amount)
                  }}{{ card.skillCosts[0].baseCostUsd.symbol }}
          </span>
          </span>
                            <span
                              class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-2 text-gray text-no-wrap">
                Sale cost:
                <br>
                <span class="font-weight-medium text-black">
              {{ Math.round(card.skillCosts[0].saleCostRub.amount) }}{{ card.skillCosts[0].saleCostRub.symbol }}/{{
                    Math.round(card.skillCosts[0].saleCostUsd.amount)
                  }}{{ card.skillCosts[0].saleCostUsd.symbol }}
            </span>
          </span>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row class="ma-0 flex-column">
              <span class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-2 text-gray text-no-wrap">
                Base cost:
                <br>
                <span
                  class="font-weight-medium text-black">{{
                    Math.round(card.skillCosts[0].getWithMobileCost.mobileBaseCostRub.amount)
                  }}{{ card.skillCosts[0].getWithMobileCost.mobileBaseCostRub.symbol }}/{{
                    Math.round(card.skillCosts[0].getWithMobileCost.mobileBaseCostUsd.amount)
                  }}{{ card.skillCosts[0].getWithMobileCost.mobileBaseCostUsd.symbol }}</span>
          </span>
                            <span
                              class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-2 text-gray text-no-wrap">
                Sale cost:
                <br>
                <span
                  class="font-weight-medium text-black">{{
                    Math.round(card.skillCosts[0].getWithMobileCost.mobileSaleCostRub.amount)
                  }}{{ card.skillCosts[0].getWithMobileCost.mobileSaleCostRub.symbol }}/{{
                    Math.round(card.skillCosts[0].getWithMobileCost.mobileSaleCostUsd.amount)
                  }}{{ card.skillCosts[0].getWithMobileCost.mobileSaleCostUsd.symbol }}</span>
          </span>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row no-gutters class="align-stretch">
                        <v-col>
                          <p class="fz-14 lh-22 mb-0 card-title font-weight-bold text-black"> {{
                              card.name
                            }}</p>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0">
            <span class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-2 text-gray text-no-wrap">
                Base cost:
                <span class="font-weight-medium text-black">
            {{ Math.round(card.skillCosts[0].baseCostRub.amount) }}{{ card.skillCosts[0].baseCostRub.symbol }}/{{
                    Math.round(card.skillCosts[0].baseCostUsd.amount)
                  }}{{ card.skillCosts[0].baseCostUsd.symbol }}
          </span>
          </span>
                        <span class="fz-14 pt-0 mr-2 width-100 font-weight-light mt-2 text-gray text-no-wrap">
                Sale cost:
                <span class="font-weight-medium text-black">
              {{ Math.round(card.skillCosts[0].saleCostRub.amount) }}{{ card.skillCosts[0].saleCostRub.symbol }}/{{
                    Math.round(card.skillCosts[0].saleCostUsd.amount)
                  }}{{ card.skillCosts[0].saleCostUsd.symbol }}
            </span>
          </span>
                      </v-row>
                    </template>
                  </v-card>
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--Assignee-->
      <v-dialog
        width="360px"
        content-class="rounded-xl hidden dialog-flex elevation-0"
        overlay-color="rgba(9, 24, 73, 0.2)"
        overlay-opacity="1"
        v-model="newAssigneeShow"
        @click="closeNewAssignee"
        @click:outside="closeNewAssignee"
      >
        <template v-slot:default="">
          <v-card
            v-if="newAssigneeShow"
            class="rounded-xl d-flex flex-column hidden"
          >
            <v-card-title class="pt-7 px-5 align-start">
              <div>
                <p
                  style="line-height: normal"
                  class="text-gray fz-14 mb-1"
                >
                  {{ relatedItem.isCreate || multipleAssigneeIsActive ? 'Create Related Item' : 'Create New Item'}}
                </p>
                <p class="fz-20 text-black fw-600 mb-0 lh-20">
                  List of Assignees
                </p>
              </div>
              <v-spacer/>
              <v-icon
                class="close-icon mt-1"
                @click="closeNewAssignee"
              >
                $close
              </v-icon>
            </v-card-title>
            <v-divider class="black bb-1 mb-1"/>
            <v-card-text class="scroll">
              <v-col class="pa-0">
                <!--Search By Designers-->
                <v-text-field
                  outlined
                  hide-details
                  placeholder="Search by designers"
                  class="border-none mx-n4 fz-14 text-black font-weight-medium"
                  prepend-inner-icon="$search"
                  v-model="designerSearch"
                />
                <v-divider class="mt-1"/>
                <v-list-item-group
                  v-model="multipleAssigneeList"
                  multiple
                >
                  <!--Main Assignee-->
                  <template v-if="lengthAssignee">
                    <v-row no-gutters class="mb-0">
                      <span>Main assignee</span>
                      <v-spacer/>
                      <!--<button
                      v-if="createNewItem.newCard ? createNewItem.newCard.typeCard === 'Product' : false"
                      @click="
                      multipleAssigneeIsActive = !multipleAssigneeIsActive;
                      !multipleAssigneeIsActive ? multipleAssigneeList = [] : false"
                      :class="multipleAssigneeIsActive ? 'font-weight-medium color-primary' : ''"
                    >
                      Multiple
                    </button>-->
                    </v-row>
                    <v-list>
                      <v-list-item
                        v-for="designer in newAssignee ? newAssignee.filter(el => designerSearch ? el.businessName.toLowerCase().includes(designerSearch.toLowerCase()) : el) : newAssignee"
                        :key="designer.id"
                        class="rounded-lg hover-rounded-lg"
                        :disabled="designer.cantPicked"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-action v-if="multipleAssigneeIsActive" class="mr-3">
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content
                            @click="multipleAssigneeIsActive ? null : relatedItem.isCreate ? setExecutorForRelatedItem(designer) : (setExecutor(designer))"
                          >
                            <v-row no-gutters align="center">
                              <div>
                                {{ designer.businessName }}
                              </div>
                              <v-spacer/>
                              <DesignerStatus :designer="designer"/>
                            </v-row>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list>
                  </template>
                  <!--Additional Assignee-->
                  <template v-if="lengthAdditionalAssignee">
                    <p class="mb-0">Additional assignee</p>
                    <v-list>
                      <v-list-item
                        v-for="designer in newAdditionalAssignee ? newAdditionalAssignee.filter(el => designerSearch ? el.businessName.toLowerCase().includes(designerSearch.toLowerCase()) : el) : newAdditionalAssignee"
                        :key="designer.id"
                        class="rounded-lg hover-rounded-lg"
                        :disabled="designer.cantPicked"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-action v-if="multipleAssigneeIsActive" class="mr-3">
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content
                            @click="multipleAssigneeIsActive ? null : relatedItem.isCreate ? setExecutorForRelatedItem(designer) : (setExecutor(designer))"
                          >
                            <v-row no-gutters align="center">
                              <div>
                                {{ designer.businessName }}
                              </div>
                              <v-spacer></v-spacer>
                              <DesignerStatus :designer="designer"/>
                            </v-row>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-list-item-group>
                <!--Apply button, if multiple "Active"-->
                <!--<v-btn
                v-if="multipleAssigneeIsActive"
                @click="setMultipleExecutor"
                :disabled="multipleAssigneeList.length === 0"
                class="rounded-lg fz-16 fw-600 text-none mt-2 width-100"
                elevation="0"
                color="primary"
                height="40"
              >
                Apply
              </v-btn>-->
              </v-col>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <!--Edit Item-->
      <v-dialog
        width="480px"
        content-class="rounded-xl hidden dialog-flex elevation-0"
        overlay-color="rgba(9, 24, 73, 0.2)"
        overlay-opacity="1"
        v-model="itemChangesShow"
      >
        <template v-slot:default="">
          <v-card
            v-if="itemChanges"
            class="rounded-xl pa-10 d-flex flex-column scroll"
            v-click-outside="closeItemChanges"
          >
            <p class="text-center fz-20 font-weight-medium lh-20" style="margin-bottom: 10px;">Edit Item</p>
            <v-card class="mb-10 mx-auto" elevation="0" v-click-outside="closeChangeTextInput">
              <v-text-field
                class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
                dense
                outlined
                hide-details
                v-if="showTextChanger"
                v-model="itemChanges.ownName"
                ref="changeTextInput"
              >
                <template v-slot:prepend-inner>
                  {{ itemChanges.name }} ::
                </template>
              </v-text-field>
              <p
                v-else
                class="fz-16 font-weight-medium text-gray lh-20 change-text-input"
                @click.stop="openChangeTextInput"
              >
                {{ itemChanges.name }} {{ itemChanges.ownName ? `:: ${itemChanges.ownName}` : '' }}
              </p>
            </v-card>
            <!--Completed-->
            <v-row class="ma-0 justify-space-between align-center">
              <template v-if="(itemChanges.orderItemType !== 'PAGE' && itemChanges.__typename !== 'OrderProductPlan') || isUseUpdate">
                <span class="fz-16 lh-20 text-black">Progress</span>
                <v-sheet color="transparent" elevation="0">
                  <v-row class="ma-0 mb-1 align-center" :class="isDisabled(itemChanges.fixedBySalary)">
                    <v-btn
                      width="26"
                      height="26"
                      elevation="0"
                      x-small
                      icon
                      fab
                      :disabled="completedCount < 1 || readOnly || itemChanges.fixedBySalary"
                      @click="completedCount--"
                    >
                      <v-icon size="8">$minus</v-icon>
                    </v-btn>
                    <span class="mx-auto">
                      <v-text-field
                        height="26"
                        v-model.number="completedCount"
                        :disabled="itemChanges.fixedBySalary"
                        dense
                        outlined
                        type="number"
                        hide-details
                        v-mask="[/\d/, /\d/, /\d/, /\d/]"
                        class="max-width-40 min-height-6-5 input-center input-qty border-none text-black fz-14 font-weight-medium"
                      ></v-text-field>
                    </span>
                    <v-btn
                      width="26"
                      height="26"
                      elevation="0"
                      x-small
                      icon
                      fab
                      :disabled="completedCount === itemChanges.count || readOnly || itemChanges.fixedBySalary"
                      @click="completedCount++"
                    >
                      <v-icon size="8">$plus</v-icon>
                    </v-btn>
                  </v-row>
                  <p class="ma-0 fz-14 lh-20 font-weight-light text-gray text-center">{{ itemChanges.itemFormatLabel }}</p>
                </v-sheet>
              </template>
              <template v-else>
                <span class="fz-16 lh-20 text-black">Completed</span>
                <v-row no-gutters class="flex-grow-0">
                  <div>
                    <v-icon v-if="itemChanges.__mobileItem" class="mb-2 stroke-black">$desktop</v-icon>
                    <v-switch
                      :input-value="itemChanges.completedCount"
                      :disabled="itemChanges.fixedBySalary"
                      @change="(value) => { value ? itemChanges.completedCount = 1 : itemChanges.completedCount = 0 }"
                      hide-details
                      dense
                      inset
                      color="#34C759"
                      class="pa-0 my-auto mr-n7 default-switch"
                    />
                  </div>
                  <div v-if="itemChanges.__mobileItem" class="ml-6">
                    <v-icon class="mb-2 stroke-black">$mobile</v-icon>
                    <v-switch
                      :input-value="itemChanges.__mobileItem.completedCount"
                      :disabled="itemChanges.__mobileItem.fixedBySalary"
                      @change="(value) => { value ? itemChanges.__mobileItem.completedCount = 1 : itemChanges.__mobileItem.completedCount = 0 }"
                      hide-details
                      dense
                      inset
                      color="#34C759"
                      class="pa-0 my-auto mr-n7 default-switch"
                    />
                  </div>
                </v-row>
              </template>
            </v-row>
            <v-divider style="border-color: #E3E6EF;"/>
            <!--Additional versions-->
            <template>
              <!--Mobile version-->
              <v-row
                v-if="itemChanges.baseService.serviceType ? itemChanges.baseService.serviceType.id !== '1' : true"
                style="min-height: 50px"
                class="ma-0 justify-space-between align-center"
              >
                <span class="fz-16 lh-20 text-black">Mobile version</span>
                <v-switch
                  v-model="itemChanges.mobile"
                  :disabled="!order.mobile || !getTypeItem(itemChanges.id) || itemChanges.fixedBySalary"
                  hide-details
                  dense
                  inset
                  color="#34C759"
                  class="pa-0 my-auto mr-n7 default-switch"
                ></v-switch>
              </v-row>
              <!--Graphics && Theme-->
              <template v-if="itemChanges.__typename === 'OrderProductPlan'">
                <!--Graphics-->
                <v-row v-if="itemChanges.affectedBy.find(item => item.id === 'Custom graphics')" class="ma-0 justify-space-between align-center" style="min-height: 50px">
                  <span class="fz-16 lh-20 text-black">Graphics Version</span>
                  <v-row no-gutters class="flex-grow-0">
                    <div>
                      <v-switch
                        v-model="itemChanges.graphicsRate"
                        :disabled="!order.hasGraphics || !getTypeItem(itemChanges.id) || itemChanges.fixedBySalary"
                        hide-details
                        dense
                        inset
                        color="#34C759"
                        class="pa-0 my-auto mr-n7 default-switch"
                      />
                    </div>
                    <div v-if="itemChanges.__mobileItem" class="ml-6">
                      <v-switch
                        v-model="itemChanges.__mobileItem.graphicsRate"
                        :disabled="!order.hasGraphics || !getTypeItem(itemChanges.id) || itemChanges.__mobileItem.fixedBySalary"
                        hide-details
                        dense
                        inset
                        color="#34C759"
                        class="pa-0 my-auto mr-n7 default-switch"
                      />
                    </div>
                  </v-row>
                </v-row>
                <!--Theme-->
                <v-row v-if="itemChanges.affectedBy.find(item => item.id === 'Dark/Light Theme')" class="ma-0 justify-space-between align-center" style="min-height: 50px">
                  <span class="fz-16 lh-20 text-black">Theme Version</span>
                  <v-row no-gutters class="flex-grow-0">
                    <div>
                      <v-switch
                        v-model="itemChanges.themeRate"
                        :disabled="!order.hasTheme || !getTypeItem(itemChanges.id) || itemChanges.fixedBySalary"
                        hide-details
                        dense
                        inset
                        color="#34C759"
                        class="pa-0 my-auto mr-n7 default-switch"
                      />
                    </div>
                    <div v-if="itemChanges.__mobileItem" class="ml-6">
                      <v-switch
                        v-model="itemChanges.__mobileItem.themeRate"
                        :disabled="!order.hasTheme || !getTypeItem(itemChanges.id) || itemChanges.__mobileItem.fixedBySalary"
                        hide-details
                        dense
                        inset
                        color="#34C759"
                        class="pa-0 my-auto mr-n7 default-switch"
                      />
                    </div>
                  </v-row>
                </v-row>
              </template>
              <v-divider style="border-color: #E3E6EF;"/>
            </template>
            <!--Difficulty-->
            <v-row class="ma-0 mb-10 justify-space-between align-center" style="min-height: 50px">
              <!--Desktop/-->
              <template>
                <v-row no-gutters align="center" class="mb-2" justify="space-between">
                <span class="fz-16 lh-20 text-black">
                  <span v-if="itemChanges.__mobileItem">Desktop </span>
                  Difficulty
                </span>
                  <v-sheet>
                    <v-select
                      outlined
                      dense
                      single-line
                      style="max-width: 200px !important;"
                      attach="#select39"
                      :items="difficulties"
                      v-model="itemChanges.difficulty"
                      hide-details
                      hide-selected
                      item-text="name"
                      class="input-border select-dash rounded-lg fz-14 font-weight-medium text-black"
                      return-object
                    ></v-select>
                    <div id="select39" class="relative select-content"/>
                  </v-sheet>
                </v-row>
              </template>
              <!--Mobile-->
              <template v-if="itemChanges.__mobileItem">
                <v-row no-gutters align="center" justify="space-between">
                  <span class="fz-16 lh-20 text-black">Mobile Difficulty</span>
                  <v-sheet>
                    <v-select
                      outlined
                      dense
                      single-line
                      style="max-width: 200px !important;"
                      attach="#select40"
                      :items="difficulties"
                      v-model="itemChanges.__mobileItem.difficulty"
                      hide-details
                      hide-selected
                      item-text="name"
                      class="input-border select-dash rounded-lg fz-14 font-weight-medium text-black"
                      return-object
                    ></v-select>
                    <div id="select40" class="relative select-content"/>
                  </v-sheet>
                </v-row>
              </template>
            </v-row>
            <v-row class="ma-0 justify-center">
              <v-btn
                :disabled="itemChanges.fixedBySalary"
                @click="resetChanges"
                outlined
                color="primary"
                height="40"
                width="155"
                style="margin-right: 10px;"
                class="rounded-lg fz-16 font-weight-medium text-none"
              >
                Reset
              </v-btn>
              <v-btn
                @click="saveChanges"
                elevation="0"
                color="primary"
                height="40"
                width="155"
                class="rounded-lg fz-16 fw-600 text-none"
              >
                Save
              </v-btn>
            </v-row>
          </v-card>
        </template>
      </v-dialog>
      <!--Rate Item-->
      <v-dialog
        v-model="rateItemShow"
        max-width="480"
        overlay-color="rgba(9, 24, 73, 0.2)"
        overlay-opacity="1"
        content-class="rounded-xl dialog-flex"
      >
        <template v-slot:default="" v-if="rateItem">
          <v-card class="rate-item py-10 px-0">
            <template v-if="rateItem ? rateItem.type === 'COMPLETED' : false">
              <v-card-title
                elevation="0"
                class="fz-20 font-weight-medium justify-center pa-0 mb-6 flex-column"
              >
                <p class="mb-0">Rate Item</p>
                <p class="fz-16 font-weight-medium text-gray lh-20 mb-0">
                  {{ rateItem.item.name }}
                </p>
              </v-card-title>
              <v-card-text class="scroll py-0 px-10 mb-5" style="height: calc(100% - 132px)">
                <v-col class="pa-0">
                  <template v-if="rateItem.item.executor && rateItem.item.executor.roleRate.occupation === 'DESIGNER'">
                    <p class="fz-14 font-weight-bold mb-5">Design</p>
                    <v-row class="ma-0 justify-space-between align-center">
                      <span>Design quality</span>
                      <v-sheet class="d-flex rate-img">
                        <img @click="rateItem.designQuality = 'GOOD'"
                             src="@/assets/img/rate5.png"
                             class="mr-4"
                             :class="{'rate-img-opacity': rateItem.designQuality !== null && rateItem.designQuality !== 'GOOD', 'rate-img-active': rateItem.designQuality !== null && rateItem.designQuality === 'GOOD'}">
                        <img @click="rateItem.designQuality = 'OK'"
                             src="@/assets/img/rate3.png"
                             class="mr-4"
                             :class="{'rate-img-opacity': rateItem.designQuality !== null && rateItem.designQuality !== 'OK', 'rate-img-active': rateItem.designQuality !== null && rateItem.designQuality === 'OK'}">
                        <img @click="rateItem.designQuality = 'BAD'"
                             src="@/assets/img/rate1.png"
                             class="mr-1"
                             :class="{'rate-img-opacity': rateItem.designQuality !== null && rateItem.designQuality !== 'BAD', 'rate-img-active': rateItem.designQuality !== null && rateItem.designQuality === 'BAD'}">
                      </v-sheet>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="ma-0 justify-space-between align-center">
                      <span>Collaboration with designer</span>
                      <v-sheet class="d-flex rate-img">
                        <img @click="rateItem.designCollaboration = 'GOOD'"
                             src="@/assets/img/rate5.png"
                             class="mr-4"
                             :class="{'rate-img-opacity': rateItem.designCollaboration !== null && rateItem.designCollaboration !== 'GOOD', 'rate-img-active': rateItem.designCollaboration !== null && rateItem.designCollaboration === 'GOOD'}">
                        <img @click="rateItem.designCollaboration = 'OK'"
                             src="@/assets/img/rate3.png"
                             class="mr-4"
                             :class="{'rate-img-opacity': rateItem.designCollaboration !== null && rateItem.designCollaboration !== 'OK', 'rate-img-active': rateItem.designCollaboration !== null && rateItem.designCollaboration === 'OK'}">
                        <img @click="rateItem.designCollaboration = 'BAD'"
                             src="@/assets/img/rate1.png"
                             class="mr-1"
                             :class="{'rate-img-opacity': rateItem.designCollaboration !== null && rateItem.designCollaboration !== 'BAD', 'rate-img-active': rateItem.designCollaboration !== null && rateItem.designCollaboration === 'BAD'}">
                      </v-sheet>
                    </v-row>
                    <v-divider></v-divider>
                  </template>
                  <template v-else>
                    <p class="fz-14 font-weight-bold mb-5">Development</p>
                    <v-row class="ma-0 justify-space-between align-center">
                      <span>Development quality</span>
                      <v-sheet class="d-flex rate-img">
                        <img @click="rateItem.developmentQuality = 'GOOD'"
                             src="@/assets/img/rate5.png"
                             class="mr-4"
                             :class="{'rate-img-opacity': rateItem.developmentQuality !== null && rateItem.developmentQuality !== 'GOOD', 'rate-img-active': rateItem.developmentQuality !== null && rateItem.developmentQuality === 'GOOD'}">
                        <img @click="rateItem.developmentQuality = 'OK'"
                             src="@/assets/img/rate3.png"
                             class="mr-4"
                             :class="{'rate-img-opacity': rateItem.developmentQuality !== null && rateItem.developmentQuality !== 'OK', 'rate-img-active': rateItem.developmentQuality !== null && rateItem.developmentQuality === 'OK'}">
                        <img @click="rateItem.developmentQuality = 'BAD'"
                             src="@/assets/img/rate1.png"
                             class="mr-1"
                             :class="{'rate-img-opacity': rateItem.developmentQuality !== null && rateItem.developmentQuality !== 'BAD', 'rate-img-active': rateItem.developmentQuality !== null && rateItem.developmentQuality === 'BAD'}">
                      </v-sheet>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="ma-0 justify-space-between align-center">
                      <span>Collaboration with developer</span>
                      <v-sheet class="d-flex rate-img">
                        <img @click="rateItem.developmentCollaboration = 'GOOD'"
                             src="@/assets/img/rate5.png"
                             class="mr-4"
                             :class="{'rate-img-opacity': rateItem.developmentCollaboration !== null && rateItem.developmentCollaboration !== 'GOOD', 'rate-img-active': rateItem.developmentCollaboration !== null && rateItem.developmentCollaboration === 'GOOD'}">
                        <img @click="rateItem.developmentCollaboration = 'OK'"
                             src="@/assets/img/rate3.png"
                             class="mr-4"
                             :class="{'rate-img-opacity': rateItem.developmentCollaboration !== null && rateItem.developmentCollaboration !== 'OK', 'rate-img-active': rateItem.developmentCollaboration !== null && rateItem.developmentCollaboration === 'OK'}">
                        <img @click="rateItem.developmentCollaboration = 'BAD'"
                             src="@/assets/img/rate1.png"
                             class="mr-1"
                             :class="{'rate-img-opacity': rateItem.developmentCollaboration !== null && rateItem.developmentCollaboration !== 'BAD', 'rate-img-active': rateItem.developmentCollaboration !== null && rateItem.developmentCollaboration === 'BAD'}">
                      </v-sheet>
                    </v-row>
                    <v-divider></v-divider>
                  </template>
                  <p class="fz-14 font-weight-bold mb-5">Client</p>
                  <v-row class="ma-0 justify-space-between align-center">
                    <span>Comunication with client</span>
                    <v-sheet class="d-flex rate-img">
                      <img @click="rateItem.clientComunication = 'GOOD'"
                           src="@/assets/img/rate5.png"
                           class="mr-4"
                           :class="{'rate-img-opacity': rateItem.clientComunication !== null && rateItem.clientComunication !== 'GOOD', 'rate-img-active': rateItem.clientComunication !== null && rateItem.clientComunication === 'GOOD'}">
                      <img @click="rateItem.clientComunication = 'OK'"
                           src="@/assets/img/rate3.png"
                           class="mr-4"
                           :class="{'rate-img-opacity': rateItem.clientComunication !== null && rateItem.clientComunication !== 'OK', 'rate-img-active': rateItem.clientComunication !== null && rateItem.clientComunication === 'OK'}">
                      <img @click="rateItem.clientComunication = 'BAD'"
                           src="@/assets/img/rate1.png"
                           class="mr-1"
                           :class="{'rate-img-opacity': rateItem.clientComunication !== null && rateItem.clientComunication !== 'BAD', 'rate-img-active': rateItem.clientComunication !== null && rateItem.clientComunication === 'BAD'}">
                    </v-sheet>
                  </v-row>
                  <v-divider></v-divider>
                </v-col>
              </v-card-text>
            </template>
            <template v-if="rateItem ? rateItem.type === 'CANCELED' : false">
              <v-card-title
                elevation="0"
                class="fz-20 font-weight-medium justify-center pa-0 mb-10"
              >
                Cancel Item
              </v-card-title>
              <v-card-text class="py-0 px-10 mb-10">
                <v-col class="pa-0">
                  <v-textarea
                    outlined
                    class="rounded-lg fz-14 text-black font-weight-medium input-border"
                    height="200"
                    no-resize
                    v-model="rateItem.commentToDelete"
                    placeholder="Why item is canceled..."
                    counter
                    :value="rateItem.commentToDelete"
                    :rules="[rules.required, rules.maxSymbols]"
                  />
                  <v-row no-gutters justify="end" :class="{'red-text': rateItem.commentToDelete.length >= 230}">
                    Qty: {{ 230 - rateItem.commentToDelete.length }}
                  </v-row>
                </v-col>
              </v-card-text>
            </template>
            <v-card-actions class="pa-0 justify-center">
              <v-btn
                @click="itemStatusComplete"
                class="rate-send fz-16 text-none fw-600 rounded-lg"
                :disabled="!rateSendBtnShow || rateSendBtnShowIfCanceled"
                width="150"
                height="40"
                elevation="0"
                color="primary"
              >
                Send
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <!--MODAL EditItem-->
      <EditItem
        :loading="loading"
        :order="order"
        :item="selectedItem"
        @closeFrame="selectedItem = {}"
        @interface="getChildInterface"
        @date-updated="setDateRange"
        @changed-difficulty="changeDifficulty"
        @changed-status="changeItemStatus"
        @changed-assignee="setDesigner"
        @changed-comment="setComment"
        @changed-discount="changeDiscount"
      />
      <!--Change Rate for item; Edit Rate-->
      <!--Loader-->
      <div class="preloader" v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import EditRate from '@/components/App/Orders/EditRate'
import AmountView from '@/components/App/UI/AmountView'
import gql from 'graphql-tag'
import { Locale } from 'v-calendar'
import { designerColor, statusColor } from '@/helpers/staticColors'
import VueHtml2pdf from 'vue-html2pdf'
import numberFormat from '@/helpers/numberFormat'
import {
  AsYouType,
  formatNumber,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  isValidPhoneNumber,
  parsePhoneNumber
} from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import { debounce } from 'lodash'
import InputCurrencyTransparent from '../../inputs/inputCurrencyTransparent'
import DesignerStatus from '@/components/App/Functional/DesignerStatus'
import RemoveDialog from '@/components/App/Functional/RemoveDialog'
import Snackbar from '@/components/App/Functional/Snackbar'
import FrappeGantt from '@/components/App/Orders/FrappeGantt'
import EditItem from '@/components/App/Orders/EditItem/index'
import ProjectSearch from '@/components/App/Orders/ProjectSearch'
import RichTextEditor from '@/components/App/Orders/RichTextEditor'
import sortablejs from 'sortablejs'
import ResizableInput from '@/Directive/ResizableInput.vue'

const locale = new Locale()

export default {
  name: 'EditOrder',
  mixins: [
    ResizableInput
  ],
  components: {
    RichTextEditor,
    InputCurrencyTransparent,
    VueHtml2pdf,
    EditRate,
    DesignerStatus,
    RemoveDialog,
    Snackbar,
    FrappeGantt,
    EditItem,
    AmountView,
    ProjectSearch
  },
  filters: {
    numberFormat
  },
  data() {
    return {
      isUseUpdate: false,
      dialog: false,
      order: {
        difficulty: '',
        end: '',
        feeRate: 0,
        mobile: false,
        management: false,
        prototype: false,
        authorSupervision: false,
        name: '',
        payment: '',
        project: {},
        designLead: null,
        files: [],
        description: {},
        descriptionSlack: {},
        start: '',
        niche: null
      },
      readOnly: true,
      resetValidation: false,
      searchCountriesGeo: '',
      countriesGeo: [
        { name: 'None' }
      ],
      viewTab: 0,
      showTextChanger: false,
      defaultFee: '',
      niches: [],
      statusLabels: [],
      focus: {
        subtotal: false,
        designerCost: false
      },
      itemChangesShow: false,
      allProductPlans: [],
      allServices: [],
      allDiscounts: [],
      loading: false,
      companies: [],
      channels: [],
      clientRegistration: false,
      countries: [],
      countrySearch: '',
      clientInput: '',
      designers: [],
      listDesigners: [],
      designerSearch: '',
      difficulties: [],
      allDesigners: [],
      productPlanSearch: '',
      servicesSearch: '',
      designersLoading: true,
      feeRate: null,
      feeFocus: false,
      me: null,
      itemChanges: null,
      itemChangesOriginal: null,
      headersAssignee: [
        {
          text: 'Service name',
          align: 'start',
          value: 'title',
          sortable: false
        },
        { text: 'Quantity', value: 'qty', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Start date', value: 'dateStartPrediction', width: '125px' },
        { text: 'Date End', value: 'dateEndPrediction', width: '125px' },
        { text: 'Assignee Cost', value: 'designerCost' }
      ],
      headersCreateRegularOrders: [
        {
          text: 'Service name',
          align: 'start',
          value: 'title',
          sortable: false
        },
        { text: 'Quantity', value: 'qty', sortable: false, width: '115px' },
        { text: 'Assignee', value: 'designer', sortable: false, class: 'min-width-150' },
        { text: 'Status', value: 'status', sortable: false, class: 'min-width-200' },
        { text: 'Price', value: 'price', class: 'min-width-100' },
        { text: 'Discount', value: 'discount', width: '100px' },
        { text: 'Start date', value: 'dateStartPrediction', width: '125px' },
        { text: 'Date End', value: 'dateEndPrediction', width: '125px' },
        { text: 'Subtotal', value: 'subtotal', class: 'min-width-150' },
        { text: 'Assignee Cost', value: 'customDesignerCost' },
        { text: 'Profit', value: 'profit', class: 'min-width-100' },
        { text: 'Rate', value: 'changeRate', sortable: false, filterable: false },
        { text: '', value: 'remove', sortable: false, filterable: false, width: '80px' }
      ],
      headersCreateHubneroOrders: [
        {
          text: 'Service name',
          align: 'start',
          value: 'title',
          sortable: false
        },
        { text: 'Quantity', value: 'qty', sortable: false, width: '115px' },
        { text: 'Assignee', value: 'designer', sortable: false, class: 'min-width-150' },
        { text: 'Status', value: 'status', sortable: false, class: 'min-width-200' },
        { text: 'Start date', value: 'dateStartPrediction', width: '125px' },
        { text: 'Date End', value: 'dateEndPrediction', width: '125px' },
        { text: 'Subtotal', value: 'customCost', class: 'min-width-150' },
        { text: 'Assignee Cost', value: 'customDesignerCost' },
        { text: 'Profit', value: 'profit', class: 'min-width-100' },
        { text: 'Rate', value: 'changeRate', sortable: false, filterable: false },
        { text: '', value: 'remove', sortable: false, filterable: false, width: '80px' }
      ],
      headersCreateCustomOrders: [
        {
          text: 'Service name',
          align: 'start',
          value: 'title',
          sortable: false
        },
        { text: 'Quantity', value: 'qty', sortable: false, width: '115px' },
        { text: 'Assignee', value: 'designer', sortable: false, class: 'min-width-150' },
        { text: 'Status', value: 'status', sortable: false, class: 'min-width-200' },
        { text: 'Price', value: 'price', class: 'min-width-100' },
        { text: 'Discount', value: 'discount', width: '100px' },
        { text: 'Start date', value: 'dateStartPrediction', width: '125px' },
        { text: 'Date End', value: 'dateEndPrediction', width: '125px' },
        { text: 'Subtotal', value: 'customCost', class: 'min-width-150' },
        { text: 'Designer Cost', value: 'customDesignerCost' },
        { text: 'Profit', value: 'profit', class: 'min-width-100' },
        { text: 'Rate', value: 'changeRate', sortable: false, filterable: false },
        { text: '', value: 'remove', sortable: false, filterable: false, width: '80px' }
      ],
      selectedRows: [],
      selectedItem: {},
      multiselectRows: {
        difficulty: null,
        designer: null,
        mobile: false,
        status: null,
        discount: null
      },
      snackbarMessage: [],
      newClient: {
        name: '',
        telegram: '',
        country: null
      },
      orderItems: [],
      compositeItems: [],
      options: {
        itemsPerPage: 999
      },
      phone: {
        country: 'US',
        mask: '',
        countryCode: '',
        placeholder: '',
        valid: false,
        number: ''
      },
      orderTypes: [{
        name: 'Regular order',
        value: 'REGULAR'
      }, {
        name: 'Hubnero order',
        value: 'CUSTOM'
      }, {
        name: 'Custom order',
        value: 'LOADED'
      }],
      paymentTypes: [],
      rules: {
        required: value => (value !== null && String(value).length > 0) || 'Required.',
        minCounter: value => value.length >= 3 || 'Min 3 characters',
        maxSymbols: value => value.length <= 230 || 'Max symbols 256',
        client: value => ({}.toString.call(this.order.client) === '[object Object]') || 'Select Client',
        phoneValid: value => isValidPhoneNumber(value, this.phone.country) || 'Number is invalid'
      },
      searchClients: [],
      stepper: 1,
      tabItemCreateOrder: 0,
      rateItemShow: false,
      rateItem: null,
      changingRate: {
        item: {},
        currentItemId: null
      },
      newItemShow: false,
      relatedItem: {
        isCreate: false,
        parentId: 0
      },
      newCard: null,
      createNewItem: {
        isActive: false,
        newCard: {},
        assignee: {}
      },
      multipleAssigneeIsActive: false,
      multipleAssigneeList: [],
      newAssigneeShow: false,
      newAssignee: null,
      newAdditionalAssignee: [],
      newItem: null,
      clickedItem: []
    }
  },
  computed: {
    leadDesigners() {
      return this.$store.getters.getAllDesigners.filter(el => {
        if (el.otherRoles.find(el => +el.id === 14)) return true
      })
    },
    canceledItems() {
      const arr = []
      if (this.order.items) {
        this.order.items.forEach(el => {
          if (el.cancellationReason !== null) {
            arr.push({ name: el.name, reason: el.cancellationReason, orderItemVersion: el.orderItemVersion })
          }
        })
      }
      return arr
    },
    markNumberForItems() {
      const initialArr = this.orderItems
      let number = 1
      for (const el of initialArr) {
        for (const subEl of initialArr) {
          if (el.name === subEl.name && subEl.serialNumber === undefined && (String(this.getNumberId(subEl.id)).includes(subEl.compositeItem.id) || String(subEl.compositeItem.id).includes('fakeId'))) {
            subEl.serialNumber = number
            number++
          }
        }
        number = 1
      }
      return initialArr
    },
    lengthAssignee() {
      return this.newAssignee.length
    },
    lengthAdditionalAssignee() {
      return this.newAdditionalAssignee.length
    },
    completedCount: {
      get() {
        return this.itemChanges.completedCount
      },
      set(val) {
        if (val <= this.itemChanges.count && val >= 0) this.itemChanges.completedCount = val
        else if (val > this.itemChanges.count) this.itemChanges.completedCount = this.itemChanges.count
      }
    },
    countryGeoFilter() {
      const countries = this.$store.getters.getCountries ? this.$store.getters.getCountries : []
      return countries.filter(el => el.name.toLowerCase().includes(this.searchCountriesGeo.toLowerCase()))
    },
    countryFilter() {
      let search = this.countries
      search = search.filter(el => el.toLowerCase().includes(this.countrySearch.toLowerCase()))
      return search
    },
    clientsArr() {
      if (!this.clientRegistration && this.clientInput && !this.searchClients.includes(this.order.client)) {
        return this.searchClients
      } else return []
    },
    prototype() {
      if (this.readOnly || this.orderItems.find(el => el.name === 'UX Wireframes')) return true
      else return false
    },
    addAccountBtnShow() {
      if ((this.clientInput && {}.toString.call(this.order.client) !== '[object Object]') || this.clientRegistration) return true
      else return false
    },
    showClientInputIcons() {
      if ({}.toString.call(this.order.client) === '[object Object]') return true
      else return false
    },
    clientSearch: {
      get() {
        return this.clientInput
      },
      set(val) {
        this.clientInput = val
        //   ? val.trimEnd().replace(/[А-я.]/g, function (x) {
        //   return x === x.toLowerCase() ? replacer[x] : replacer[x.toLowerCase()].toUpperCase()
        // }) : val
        this.getSearchClients()
      }
    },
    orderTitle() {
      if (!this.order.id) return 'Create order'
      // else if (this.readOnly) return 'View'
      else return this.order.name
    },
    currency() {
      if (this.order.currency === 'USD') return 1
      else return 0
    },
    rateSendBtnShow() {
      return this.rateItem.commentToDelete || (this.rateItem && ((this.rateItem.designQuality && this.rateItem.designCollaboration) || (this.rateItem.developmentQuality && this.rateItem.developmentCollaboration)) && this.rateItem.clientComunication)
    },
    rateSendBtnShowIfCanceled() {
      return this.rateItem.type === 'CANCELED' && this.rateItem.commentToDelete.length >= 231
    }
  },
  methods: {
    saveMessage() {
      this.$refs.RichTextEditor.saveMessage()
    },
    isDisabled(value) {
      return value ? 'disabled' : ''
    },
    invokeAlert(msg = 'Cannot change, because item is fixed') {
      alert(msg)
    },
    getCompositeItem(id) {
      return this.compositeItems.find(i => i.id === id)
    },
    isExistMobileItems(items) {
      return items.filter(el => el.__mobileItem).length
    },
    getExecutorForHeaderRow(items) {
      let arr = items.map(el => el.executor.businessName)
      arr.push(...items.map(el => {
        if (el.__mobileItem) {
          return el.__mobileItem.executor.businessName
        }
      }))
      arr = arr.filter(el => el)
      return [...new Set(arr)]
    },
    addSortableJS() {
      const options = {
        animation: 150,
        /* handle: '.draggable-btn', */
        /* draggable: '.gantt-sidebar-item', */
        filter: '.v-row-group__header'
      }
      setTimeout(() => {
        sortablejs.create(document.querySelector('.table-items tbody'), options)
      }, 1500)
    },
    getChildItem(items) {
      items.sort((a, b) => a.positionInOrder - b.positionInOrder)
      items.forEach(item => {
        if (item.ownRelatedItems) {
          item.isShowChildItems = true
          item.childItems = []
          const childIds = item.ownRelatedItems.map(el => el.id)
          childIds.forEach(id => {
            item.childItems.push(items.find(el => el.id.includes(id)))
          })
          item.childItems.forEach(el => {
            el.isChild = true
          })
          item.childItems.sort((a, b) => a.positionInOrder - b.positionInOrder)
          item.isParent = true
          item.isChild = false
        } else {
          item.isParent = false
        }
      })
      return items
    },
    getMobileItem() {
      const indexForDeletingCompositeItem = []
      for (const el of this.compositeItems) {
        el.productPlans.forEach(el => {
          if (el.orderItemVersion === 'HAS_MOBILE') {
            const mobileChild = this.orderItems.find(subEl => subEl.id.includes(el.relatedMobileItem.id))
            el.__mobileItem = mobileChild
            indexForDeletingCompositeItem.push(mobileChild.id)
          }
        })
      }
      indexForDeletingCompositeItem.forEach(id => {
        this.compositeItems.forEach(el => {
          const position = el.productPlans.findIndex(subEl => subEl.id.includes(id))
          if (position !== -1) el.productPlans.splice(position, 1)
        })
      })
      const indexForDeleting = []
      for (const el of this.orderItems) {
        if (el.orderItemVersion === 'HAS_MOBILE') {
          const mobileChild = this.orderItems.find(subEl => subEl.id.includes(el.relatedMobileItem.id))
          el.__mobileItem = mobileChild
          indexForDeleting.push(mobileChild.id)
        }
      }
      indexForDeleting.forEach(id => {
        this.orderItems.splice(this.orderItems.findIndex(subEl => subEl.id.includes(id)), 1)
      })
    },
    openChangeTextInput() {
      this.showTextChanger = true
      setTimeout(() => {
        this.$refs.changeTextInput.focus()
      })
    },
    closeChangeTextInput() {
      this.showTextChanger = false
    },
    checkType(item, verifiableId) {
      return item.baseService.serviceType ? +item.baseService.serviceType.id === verifiableId : false
    },
    groupRelatedItems(items) {
      let fakeId = 0
      this.orderItems.forEach(el => {
        if (!el.compositeItem) {
          el.compositeItem = { id: `fakeId${fakeId}` }
          fakeId++
        }
      })
    },
    getNumberId(id) {
      return Number(id.replace(/\D/g, ''))
    },
    getTypeItem(id) {
      return id.includes('OrderProductPlan')
    },
    /* Edit Rate */
    openEditOrder(id) {
      this.changingRate.item = this.orderItems.find(el => el.id === id)
      this.changingRate.currentItemId = id
    },
    /* --- */
    /* Add New Item */
    setNewCard(card, typeCard) {
      card.typeCard = typeCard
      this.createNewItem.newCard = card
      this.newItemShow = false
      this.getDesignersForNewItem(this.createNewItem.newCard.executorRole.roleId)
    },
    async setExecutor(designer, item) {
      this.newAssigneeShow = false
      /* Change executor or create item */
      if (item) {
        item.executor = designer
        await this.setDesigner(item)
      } else {
        /* Create product plan or service */
        if (this.createNewItem.newCard.typeCard === 'Product') {
          await this.addProductPlan(this.createNewItem.newCard.id)
        } else {
          await this.addService(this.createNewItem.newCard.id)
        }
        this.newItem.executor = designer
        console.log(this.newItem)
        await this.setDesigner(this.newItem)
        this.createNewItem.newCard = null
      }
      this.closeNewAssignee()
      this.reloadOrderItems()
    },
    async setMultipleExecutor() {
      this.newAssigneeShow = false
      const allAssignee = [...this.newAssignee, ...this.newAdditionalAssignee]
      for (const [index, id] of this.multipleAssigneeList.entries()) {
        if (index === 0) {
          await this.addProductPlan(this.createNewItem.newCard.id)
          this.addCompositeItem(this.newItem.id, 1, id, false)
          console.log(allAssignee)
        }
      }
      this.reloadOrderItems()
      this.closeNewAssignee()
    },
    /* --- */
    replaceDD(item) {
      return item.replace(/:/g, '')
    },
    setFee(value) {
      if (this.feeRate !== value) {
        this.feeRate = value
        this.orderChanged()
      }
    },
    closeNewAssignee() {
      console.log('done')
      this.clearSearch()
      this.createNewItem.isActive = false
      this.newAssigneeShow = false
      this.relatedItem.isCreate = false
      this.multipleAssigneeIsActive = false
      this.multipleAssigneeList = []
      this.newAssignee = null
      this.newItem = null
    },
    changeOrderType(item) {
      if (this.order.type !== item) {
        this.order.type = item
        this.orderChanged()
      }
    },
    changePaymentTypes(item) {
      if (this.order.paymentType !== item) {
        this.order.paymentType = item
        this.orderChanged()
      }
    },
    changeItemStatus(item, status, edit = false) {
      if (item.status !== status.value) {
        if (status.value === 'COMPLETED') {
          this.rateItem = {
            item: item,
            type: 'COMPLETED',
            designQuality: null,
            designCollaboration: null,
            developmentQuality: null,
            developmentCollaboration: null,
            clientComunication: null
          }
          this.rateItemShow = true
        } else if (status.value === 'CANCELED' || edit) {
          if (this.readOnly) return
          this.rateItem = {
            item: item,
            type: 'CANCELED',
            commentToDelete: edit ? item.cancellationReason : ''
          }
          this.rateItemShow = true
        } else {
          item.status = status.value
          this.setItemStatus(item)
        }
      }
    },
    async changeDifficulty(item, reload = true) {
      await this.$apollo.mutate({
        mutation: gql`mutation setDifficulty($id: ID!, $difficultyId: ID! ) {
            orders {
              orderItem(id: $id) {
                setDifficulty(difficultyId: $difficultyId) {
                  id
                }
              }
            }
          }`,
        variables: {
          id: item.id,
          difficultyId: item.difficulty.id
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        if (reload) this.reloadOrderItems()
        this.snackbarMessage = ['Success', 'Item was updated difficulty with success']
      }).catch((err) => {
        console.log('changeDifficulty', err)
        this.snackbarMessage = ['Error', 'There was some mistake, check setting of item again']
      })
    },
    changeNiche(item) {
      if (this.order.niche !== item) {
        this.order.niche = item
        this.orderChanged()
      }
    },
    changeDesignLead(item) {
      if (this.order.designLead !== item) {
        this.order.designLead = item
        this.orderChanged()
      }
    },
    changeCountry(item) {
      if (this.order.changeCountry !== item) {
        if (this.order.client) this.order.client.country = item
        this.newClient.country = item
      }
    },
    changeDiscount(item) {
      this.setDiscount(item)
    },
    verificationClientInput() {
      const vm = this
      setTimeout(function () {
        if ({}.toString.call(vm.order.client) !== '[object Object]') vm.order.client = null
      }, 500)
    },
    getCountries() {
      this.countries = getCountries()
    },
    setCountry(country) {
      this.phone.country = country
      this.getPhoneExample()
    },
    getPhoneExample() {
      const example = getExampleNumber(this.phone.country, examples)
      if (example) {
        const asYouType = new AsYouType(this.phone.country)
        asYouType.input(example.formatNational())
        this.phone.mask = asYouType.getTemplate()
        if (example.formatNational()[0] === '0' || example.country === 'RU') this.phone.mask = this.phone.mask.replace('x', '')
        this.phone.mask = this.phone.mask.replace(/x/g, '#')
        this.phone.countryCode = example.countryCallingCode
        this.phone.placeholder = this.phone.mask.replace(/#/g, '_')
      }
    },
    getCountryCode(country) {
      return getCountryCallingCode(country)
    },
    formatNumberInput() {
      if (this.newClient && this.newClient.phone) {
        try {
          const vm = this
          const phoneNumber = parsePhoneNumber(this.newClient.phone)
          this.phone.number = formatNumber(this.newClient.phone, 'NATIONAL')
          if (this.phone.number[0] === '0') this.phone.number = this.phone.number.replace('0', '')
          else if (phoneNumber.country === 'RU') this.phone.number = this.phone.number.replace('8', '')
          this.phone.country = phoneNumber.country
          this.phone.countryCode = phoneNumber.countryCallingCode
          setTimeout(function () {
            vm.phone.mask = vm.phone.number.replace(/[0-9]/g, '#')
          }, 0)
        } catch {
          this.phone.number = ''
          this.setCountry('US')
        }
      } else this.setCountry('US')
    },
    parseNumber() {
      if (this.phone.number) {
        const phoneNumber = parsePhoneNumber(this.phone.number, this.phone.country)
        return phoneNumber.formatInternational()
      } else return null
    },
    clearSearch() {
      this.designerSearch = ''
    },
    copyFigmaLink() {
      navigator.clipboard.writeText(this.order.figmaUrl)
      this.snackbarMessage = ['Copy Figma link', 'Copy link succeed']
    },
    copyGoogleDocsUrl() {
      navigator.clipboard.writeText(this.order.googleDocUrl)
      this.snackbarMessage = ['Copy Google Docs link', 'Copy link succeed']
    },
    copyJiraUrl() {
      navigator.clipboard.writeText(this.order.jiraUrl)
      this.snackbarMessage = ['Copy Jira link', 'Copy link succeed']
    },
    multipleChangingExecutor(items) {
      for (const item of items) {
        item.executor = this.multiselectRows.designer
        this.setDesigner(item)
      }
    },
    multipleChangingDifficulty(items) {
      for (const item of items) {
        this.saveMultipleChanges(item, false)
      }
    },
    multipleChangingStatus(items) {
      for (const item of items) {
        this.setMultipleStatus(item)
      }
    },
    multipleChangingDiscount(items) {
      for (const item of items) {
        this.setMultipleDiscount(item)
      }
    },
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    color(value) {
      return statusColor(value)
    },
    colorDesigner(value) {
      return designerColor(value)
    },
    getItemCustomDesignerCost(cost) {
      let result = Math.round(cost)

      if (!this.focus.designerCost) {
        result = (this.order.currencySymbol !== '₽' ? this.order.currencySymbol : '') + result + (this.order.currencySymbol === '₽' ? this.order.currencySymbol : '')
      }
      return result
    },
    orderChanged() {
      if (this.order.id && this.$refs.step2.validate()) {
        this.updateOrder()
      }
    },
    orderClientChanged() {
      if (this.order.id && this.order.client) {
        this.updateOrder()
      }
    },
    updatePrototype() {
      if (this.order.id) {
        this.setPrototype()
      }
    },
    updateSupervision() {
      if (this.order.id) {
        this.setSupervision()
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    addNewClient() {
      this.getAllCompanies()
      this.checkAllChannels()
      this.newClient.name = this.clientSearch
      this.clientRegistration = true
    },
    copyOrderItems(items) {
      for (const i of items) {
        this.selectedRows = this.selectedRows.filter(el => el.id !== i.id) // Delete selectedRows that was deleted
        this.copyOrderItem(i)
      }
    },
    cancelClientRegistration() {
      this.clientRegistration = false
      this.newClient = {
        name: '',
        telegram: '',
        country: null
      }
      this.phone = {
        country: 'US',
        mask: '',
        countryCode: '',
        placeholder: '',
        valid: false,
        number: ''
      }
      this.setCountry('US')
    },
    afterDownloadedPDF() {
      this.snackbarMessage = ['Download successful', `PDF Order_# ${this.order.number} was succeed downloaded `]
    },
    saveClient() {
      const vm = this
      setTimeout(function () {
        if (vm.newClient.id) vm.updateClient()
        else vm.createNewClient()
      }, 0)
    },
    editClient() {
      this.getAllCompanies()
      this.checkAllChannels()
      this.newClient = JSON.parse(JSON.stringify(this.order.client))
      this.formatNumberInput()
      this.clientRegistration = true
    },
    removeClient() {
      this.order.client = ''
    },
    removeProject() {
      this.order.project = null
      if (this.order.id) this.updateOrder()
    },
    removeDiscount(item) {
      item.discount = ''
    },
    countMinus(item) {
      item.count--
      this.debounceCount(item.id, item.count)
    },
    countPlus(item) {
      item.count++
      this.debounceCount(item.id, item.count)
    },
    debounceCount: debounce(function (id, count) {
      this.setCount(id, count)
    }, 1000),
    getDesignersList() {
      this.orderItems.forEach(el => {
        this.getDesigners(el, el.executorRole.id)
      })
    },
    changeItemValue(item) {
      if (!this.readOnly) {
        this.itemChangesShow = true
        this.itemChanges = JSON.parse(JSON.stringify(item))
        this.itemChangesOriginal = JSON.parse(JSON.stringify(item))
      }
    },
    closeItemChanges() {
      this.itemChanges = null
      this.itemChangesShow = false
    },
    selectClient(client) {
      this.order.client = client
      if (this.order.id && client) this.updateOrder()
    },
    selectProject(project) {
      this.order.project = project
      this.$forceUpdate()
      if (this.order.id && project) this.updateOrder()
    },
    checkAllProductPlans() {
      this.$store.getters.getAllProductPlans ? this.allProductPlans = this.$store.getters.getAllProductPlans : this.getAllProductPlans()
    },
    checkAllServices() {
      this.$store.getters.getAllServices ? this.allServices = this.$store.getters.getAllServices : this.getAllServices()
    },
    checkAllDiscounts() {
      this.$store.getters.getAllDiscounts ? this.allDiscounts = this.$store.getters.getAllDiscounts : this.getDiscounts()
    },
    checkAllChannels() {
      this.$store.getters.getAllChannels ? this.channels = this.$store.getters.getAllChannels : this.getAllChannels()
    },
    async setComment(id, comment) {
      await this.$apollo.mutate({
        mutation: gql`mutation setComment($id: ID!, $comment: String!) {
          orders {
            orderItem(id: $id) {
              setComment(comment: $comment) {
                id
                comment
              }
            }
          }
        }`,
        variables: {
          id: id,
          comment: comment
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.snackbarMessage = ['Success', 'Comment set down with success']
      }).catch((err) => {
        console.log(err)
      })
    },
    async setFixedCondition(id, value) {
      value = !value
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setFixedBySalary($id: ID!, $value: Boolean!) {
          orders {
            orderItem(id: $id) {
              setFixedBySalary(value: $value) {
                fixedBySalary
              }
            }
          }
        }`,
        variables: {
          id: id,
          value: value
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.loading = false
        this.snackbarMessage = ['Success', 'Item has been successfully modified']
        this.reloadOrderItems()
      }).catch((err) => {
        this.loading = false
        this.reloadOrderItems()
        console.log(err)
      })
    },
    async setFixedConditionToOrder(id, value) {
      value = !value
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setFixedBySalary($id: ID!, $value: Boolean!) {
          orders {
            order(id: $id) {
              setFixedBySalary(value: $value) {
                hasFixedBySalary
              }
            }
          }
        }`,
        variables: {
          id: id,
          value: value
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.loading = false
        this.snackbarMessage = ['Success', 'Order has been successfully modified']
        this.reloadOrderItems()
      }).catch((err) => {
        this.loading = false
        this.reloadOrderItems()
        console.log(err)
      })
    },
    async getAllPaymentsAndDifficultuies() {
      await this.$apollo.query({
        query: gql`query OrdersPaymentsAndDifficulties {
          design {
            orders {
              allDifficulties {
                name
                id
              }
              allPaymentTypes {
                id
                name
              }
            }
          }
        }`
      }).then((data) => {
        this.paymentTypes = data.data.design.orders.allPaymentTypes
        this.difficulties = data.data.design.orders.allDifficulties
        this.$store.commit('updatePaymentsAndDifficulties', {
          difficultyTypes: this.difficulties,
          paymentTypes: this.paymentTypes
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    async getAllChannels() {
      await this.$apollo.query({
        query: gql`query getAllChannels {
          design {
            clientele {
              allChannels {
                name
                id
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.channels = data.data.design.clientele.allChannels
        this.$store.commit('updateAllChannels', data.data.design.clientele.allChannels)
      }).catch((err) => {
        console.log(err)
      })
    },
    async getAllCompanies() {
      await this.$apollo.query({
        query: gql`query OrdersCompanies {
          design {
            clientele {
              allCompanies {
                id
                name
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.companies = data.data.design.clientele.allCompanies
      }).catch((err) => {
        console.log(err)
      })
    },
    async getSearchClients() {
      await this.$apollo.query({
        query: gql`query SearchClients($input: ClientSearchInput!) {
          design {
            clientele {
              searchClients(input: $input) {
                data {
                  id
                  name
                  phone
                  telegram
                  company {
                    id
                    name
                  }
                  channel {
                    id
                    name
                  }
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            page: 1,
            pageSize: 10,
            search: this.clientInput
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.searchClients = data.data.design.clientele.searchClients.data
      }).catch((err) => {
        console.log(err)
      })
    },
    async getDesigners(element, roleId) {
      await this.$apollo.query({
        query: gql`query getDesigners($input: DesignerFilterInput!) {
          design {
            team {
              paginatedDesigners(input: $input) {
                data {
                  businessName
                  avatarUrl
                  id
                  businessInitials
                  statusLabel
                  dateEnd
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            roleId: element.executedByAnyone ? '' : roleId,
            fired: false
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const item = this.orderItems.find(el => el.id === element.id)
        this.listDesigners = data.data.design.team.paginatedDesigners.data
        item.designers = data.data.design.team.paginatedDesigners.data
        this.designersLoading = false
      }).catch((err) => {
        console.log(err)
      })
    },
    async getDesignersMultiselect(element) {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getAllDesigners($input: DesignerFilterInput!) {
          design {
            team {
              paginatedDesigners(input: $input) {
                data {
                  businessName
                  avatarUrl
                  id
                  businessInitials
                  statusLabel
                  dateEnd
                  roleRate {
                    id
                    roleName
                  }
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            roleId: null
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.allDesigners = data.data.design.team.paginatedDesigners.data
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    async getDesignersForNewItem(roleId) {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getDesignersForNewItem($input: DesignerFilterInput!) {
          design {
            team {
              paginatedDesigners(input: $input) {
                data {
                  businessName
                  avatarUrl
                  id
                  businessInitials
                  statusLabel
                  dateEnd
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            roleId: roleId,
            fired: false
          }
        },
        fetchPolicy: 'no-cache'
      }).then(async data => {
        this.newAssignee = data.data.design.team.paginatedDesigners.data
        await this.$apollo.query({
          query: gql`query SearchAdditionalDesigners($input: DesignerFilterInput!) {
          design {
            team {
              paginatedDesigners(input: $input) {
                data {
                  businessName
                  avatarUrl
                  id
                  businessInitials
                  statusLabel
                }
              }
            }
          }
        }`,
          variables: {
            input: {
              hasInOtherRoleId: roleId,
              fired: false
            }
          },
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.newAdditionalAssignee = data.data.design.team.paginatedDesigners.data
          this.newAdditionalAssignee = this.newAdditionalAssignee.filter(el => {
            for (const subEl of this.newAssignee) {
              if (subEl.id === el.id) return false
            }
            return el
          })
        }).catch((err) => {
          console.log(err)
        })
        this.newAssigneeShow = true
      }).catch((err) => {
        console.log(err)
      })
      this.loading = false
    },
    async getDesignersForUpdateItem(roleId) {
      await this.$apollo.query({
        query: gql`query getDesignersForUpdateItem($input: DesignerFilterInput!) {
          design {
            team {
              paginatedDesigners(input: $input) {
                data {
                  businessName
                  avatarUrl
                  id
                  businessInitials
                  statusLabel
                  dateEnd
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            roleId: roleId,
            fired: false
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.newAssignee = data.data.design.team.paginatedDesigners.data
      }).catch((err) => {
        console.log(err)
      })
      await this.$apollo.query({
        query: gql`query SearchAdditionalDesigners($input: DesignerFilterInput!) {
          design {
            team {
              paginatedDesigners(input: $input) {
                data {
                  businessName
                  avatarUrl
                  id
                  businessInitials
                  statusLabel
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            hasInOtherRoleId: roleId,
            fired: false
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.newAdditionalAssignee = data.data.design.team.paginatedDesigners.data
        this.newAdditionalAssignee = this.newAdditionalAssignee.filter(el => {
          for (const subEl of this.newAssignee) {
            return el.id !== subEl.id
          }
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    debounceSearchProduct: debounce(function () {
      this.getAllProductPlans(false)
    }, 500),
    async getAllProductPlans(isCommit = true) {
      this.loading = true
      await this.$apollo.query({
        query: gql`query OrdersAllServices ($input: ProductPlanFilterInput!){
          design {
            pricing {
              allProductPlans(input: $input) {
                wireframeDiscount
                name
                id
                iconUrl
                designerMobile
                developerMobile
                authorSupervision
                prototype
                executorRole {
                  id
                  roleId
                  roleName
                }
                difficulty {
                  id
                  margin
                  name
                }
                skillCosts {
                    baseCostRub
                    baseCostUsd
                    saleCostRub
                    saleCostUsd
                    baseCostRubWithWireframe
                    baseCostUsdWithWireframe
                    getWithMobileCost {
                      mobileBaseCostRub
                      mobileBaseCostUsd
                      mobileSaleCostRub
                      mobileSaleCostUsd
                    }
                  customEstimationTimeCost
                  timeCost
                  skillLevel {
                    id
                    name
                  }
                }
                itemFormat {
                  id
                  name
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            searchName: this.productPlanSearch
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.allProductPlans = data.data.design.pricing.allProductPlans
        if (isCommit) this.$store.commit('updateAllProductPlans', data.data.design.pricing.allProductPlans)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    debounceSearchServices: debounce(function () {
      this.getAllServices(false)
    }, 500),
    async getAllServices(isCommit = true) {
      this.loading = true
      await this.$apollo.query({
        query: gql`query OrdersAllServices($input: ServiceFilterInput!) {
          design {
            pricing {
              allServices(input: $input) {
                iconUrl
                id
                itemType
                name
                executorRole {
                  id
                  roleId
                  roleName
                }
                difficulty {
                  id
                  margin
                  name
                }
                itemFormat {
                  id
                  name
                }
                skillCosts {
                baseCostRub
                baseCostUsd
                saleCostUsd
                saleCostRub
                  customEstimationTimeCost
                  timeCost
                  skillLevel {
                    id
                    name
                  }
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            searchName: this.servicesSearch ? this.servicesSearch : ''
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.allServices = data.data.design.pricing.allServices
        if (isCommit) this.$store.commit('updateAllServices', data.data.design.pricing.allServices)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    async getDiscounts() {
      await this.$apollo.query({
        query: gql`query OrdersAllDiscounts {
          design {
            pricing {
              allDiscounts {
                id
                name
                rate
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.allDiscounts = data.data.design.pricing.allDiscounts
        this.$store.commit('updateAllDiscounts', data.data.design.pricing.allDiscounts)
      }).catch((err) => {
        console.log(err)
      })
    },
    async createNewClient() {
      await this.$apollo.mutate({
        mutation: gql`mutation OrdersCreateNewClient($input: ClientCreateInput! ) {
          design {
            clientele {
              createClient(input: $input) {
                id
                name
                phone
                telegram
                country {
                  id
                  name
                }
                company {
                  id
                  name
                }
                channel {
                  id
                  name
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            name: this.newClient.name,
            channelName: this.newClient.channel ? this.newClient.channel === this.newClient.channelsSearch ? this.newClient.channel : this.newClient.channel.name : '',
            companyName: this.newClient.company ? this.newClient.company === this.newClient.companySearch ? this.newClient.company : this.newClient.company.name : '',
            phone: this.newClient.phone,
            telegram: this.newClient.telegram,
            countryId: this.newClient.country.id
            // companyId: this.newClient.company === this.newClient.companySearch ? null : this.newClient.company.id,
            // channelId: this.newClient.channel === this.newClient.channelSearch ? null : this.newClient.channel.id
          }
        }
      }).then((data) => {
        const item = data.data.design.clientele.createClient
        this.snackbarMessage = ['Success', 'Client: ' + item.name + ' was created with success']
        this.selectClient(item)
        this.cancelClientRegistration()
        this.getAllCompanies()
        this.getAllChannels()
        this.getSearchClients()
      }).catch((err) => {
        console.log(err)
      })
    },
    async updateClient() {
      await this.$apollo.mutate({
        mutation: gql`mutation OrdersUpdateClient($input: ClientUpdateInput! ) {
          design {
            clientele {
              updateClient(input: $input) {
                id
                name
                phone
                telegram
                country {
                  id
                  name
                }
                company {
                  id
                  name
                }
                channel {
                  id
                  name
                }
              }
            }
          }
        }`,
        variables: {
          input: {
            id: this.newClient.id,
            name: this.newClient.name,
            channelName: this.newClient.channel ? this.newClient.channel === this.newClient.channelsSearch ? this.newClient.channel : this.newClient.channel.name : '',
            companyName: this.newClient.company ? this.newClient.company === this.newClient.companySearch ? this.newClient.company : this.newClient.company.name : '',
            phone: this.newClient.phone,
            telegram: this.newClient.telegram,
            countryId: this.newClient.country.id
          }
        }
      }).then((data) => {
        const item = data.data.design.clientele.updateClient
        this.snackbarMessage = ['Success', 'Client: ' + item.name + ' was updated with success']
        this.order.client = item
        this.getSearchClients()
        this.getAllCompanies()
        this.getAllChannels()
        this.cancelClientRegistration()
      }).catch((err) => {
        console.log(err)
      })
    },
    async addProductPlan(id) {
      await this.$apollo.mutate({
        mutation: gql`mutation AddProductPlanInput($id: ID!, $input: AddProductPlanInput! ) {
          design {
            orders {
              order(id: $id) {
                addProductPlan(input: $input ) {
                  id
                  name
                  executorRole {
                    id
                  }
                  executedByAnyone
                }
              }
            }
          }
        }`,
        variables: {
          id: this.order.id,
          input: {
            id: id
          }
        }
      }).then(async (data) => {
        const item = data.data.design.orders.order.addProductPlan
        if (item.name === 'UX Wireframes' && this.order.prototype === false) {
          this.order.prototype = true
          this.setPrototype()
        }
        this.newItem = item
        this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was added with success']
      }).catch((err) => {
        console.log(err)
      })
    },
    async addService(id) {
      await this.$apollo.mutate({
        mutation: gql`mutation OrdersUpdateClient($id: ID!, $input: AddServiceInput! ) {
          design {
            orders {
              order(id: $id) {
                addService(input: $input ) {
                  id
                  name
                  executorRole {
                    id
                  }
                  executedByAnyone
                }
              }
            }
          }
        }`,
        variables: {
          id: this.order.id,
          input: {
            id: id
          }
        }
      }).then((data) => {
        const item = data.data.design.orders.order.addService
        this.newItem = item
        this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was added with success']
      }).catch((err) => {
        console.log(err)
      })
    },
    sortRelatedItems() {
      if (this.viewTab === 0) {
        this.orderItems.sort((a, b) => {
          if (a.correctDateStart) {
            a = Date.parse(a.correctDateStart)
          } else a = Date.parse(a.dateStartPrediction)
          if (b.correctDateStart) {
            b = Date.parse(b.correctDateStart)
          } else b = Date.parse(b.dateStartPrediction)
          if (a > b) {
            return 1
          }
          if (a < b) {
            return -1
          }
          return 0
        })
      } else {
        this.orderItems.sort((a, b) => a.positionInOrder - b.positionInOrder)
        this.orderItems.forEach((el) => {
          if (el.ownRelatedItems) {
            el.ownRelatedItems.sort((a, b) => {
              a = this.orderItems.find(el => el.id.includes(a.id)).positionInOrder
              b = this.orderItems.find(el => el.id.includes(b.id)).positionInOrder
              if (a > b) {
                return -1
              }
              if (a < b) {
                return 1
              }
              return 0
            })
            /* Will delete */
            /* el.ownRelatedItems.forEach((relatedItem) => {
              const indexRelatedItem = this.orderItems.findIndex(el => el.id.includes(compositeItem.id))
              const cut = this.orderItems.splice(indexRelatedItem, 1)
              const indexParent = this.orderItems.findIndex(subEl => subEl.id === el.id)
              this.orderItems.splice(indexParent + 1, 0, cut[0])
            }) */
          }
        })
      }
    },
    async getOrderItems() {
      this.loading = true
      await this.$apollo.query({
        query: gql`
          query GetOrderItems($id: ID!) {
            design {
              orders {
                order(id: $id) {
                  compositeItems {
                    __typename
                    id
                    name
                    dateStart
                    dateEnd
                    status
                    totalCount
                    totalCompletedCount
                    itemFormatLabel
                    orderItemType
                    positionInOrder
                    iconUrl
                    productPlans {
                      compositeItem {
                        id
                      }
                      ...items
                    }
                  }
                  items {
                    ...items
                  }
                  hasTheme
                  hasGraphics
                  currency
                  totalCost
                  totalDesignerCost
                  totalDesignerSalary
                  totalDeveloperCost
                  totalDeveloperSalary
                  totalProfit
                  totalSalary
                  totalDesktopDesignerSalary
                  totalMobileDesignerSalary
                  totalMobileDesignerCost
                  totalDesktopDesignerCost
                  totalMobileDeveloperSalary
                  totalDesktopDeveloperSalary
                  totalDesktopDeveloperCost
                  totalMobileDeveloperCost
                  totalMobileProfit
                  totalDesktopProfit
                  totalDesktopCost
                  totalMobileCost
                  totalDesktopSalary
                  totalMobileSalary
                  type
                }
              }
            }
          }

          fragment items on OrderItem {
            __typename
            id
            name
            ownName
            cancellationReason
            fixedBySalary
            positionInOrder
            themeRate
            graphicsRate
            orderItemType
            comment
            difficultyFixed
            completedCount
            showCompletionProgress
            count
            dateEndPrediction
            correctDateStart
            dateStartPrediction
            expirationDate
            designerCost
            customDesignerCost
            originalDesignerCost
            itemFormatLabel
            discount {
              id
              name
              rate
            }
            difficulty {
              name
              id
            }
            executor {
              businessName
              avatarUrl
              id
              businessInitials
              statusLabel
              roleRate {
                id
                occupation
                roleId
                roleName
                value
                valueMoney
                valueMoneyWithMargin
                valueWithMargin
              }
            }
            executedByAnyone
            executorRole {
              id
              value
              roleId
              roleName
              valueMoney
              valueMoneyWithMargin
              margin
              skillLevel {
                id
              }
            }
            iconUrl
            timeCost
            status
            subtotal
            profit
            price
            mobile
            mobileMargin
            itemBasePrice
            ... on OrderProductPlan {
              affectedBy {
                id
                name
              }
              orderItemVersion
              relatedMobileItem {
                id
              }
            }
            baseService {
              id
              name
              skillCosts {
                timeCost
              }
              executorRole {
                roleName
                roleId
              }
              serviceType {
                name
                id
              }
            }
          }
          `,
        variables: {
          id: this.order.id
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const items = JSON.parse(JSON.stringify(data.data.design.orders.order.items))
        const compositeItems = data.data.design.orders.order.compositeItems
        compositeItems.sort((a, b) => a.positionInOrder - b.positionInOrder)
        compositeItems.forEach(el => {
          el.isShowChildItems = true
          el.productPlans.sort((a, b) => a.positionInOrder - b.positionInOrder)
        })
        /* Push compositeItems in Items for table */
        compositeItems.map(el => el.productPlans).forEach(el => el.forEach(el => items.push(el)))
        this.orderItems = this.getChildItem(items)
        this.compositeItems = compositeItems
        this.getMobileItem()
        this.sortRelatedItems()
        this.groupRelatedItems()
        this.changingRate.item = this.orderItems.find(el => el.id === this.changingRate.currentItemId)
        this.order.totalCost = data.data.design.orders.order.totalCost
        this.order.totalDesktopCost = data.data.design.orders.order.totalDesktopCost
        this.order.totalMobileCost = data.data.design.orders.order.totalMobileCost
        /* --- */
        this.order.totalDesignerCost = data.data.design.orders.order.totalDesignerCost
        this.order.totalDesktopDesignerCost = data.data.design.orders.order.totalDesktopDesignerCost
        this.order.totalMobileDesignerCost = data.data.design.orders.order.totalMobileDesignerCost
        /* --- */
        this.order.totalDesignerSalary = data.data.design.orders.order.totalDesignerSalary
        this.order.totalDesktopDesignerSalary = data.data.design.orders.order.totalDesktopDesignerSalary
        this.order.totalMobileDesignerSalary = data.data.design.orders.order.totalMobileDesignerSalary
        /* --- */
        this.order.totalDeveloperCost = data.data.design.orders.order.totalDeveloperCost
        this.order.totalDesktopDeveloperCost = data.data.design.orders.order.totalDesktopDeveloperCost
        this.order.totalMobileDeveloperCost = data.data.design.orders.order.totalMobileDeveloperCost
        /* --- */
        this.order.totalDeveloperSalary = data.data.design.orders.order.totalDeveloperSalary
        this.order.totalDesktopDeveloperSalary = data.data.design.orders.order.totalDesktopDeveloperSalary
        this.order.totalMobileDeveloperSalary = data.data.design.orders.order.totalMobileDeveloperSalary
        /* --- */
        this.order.totalProfit = data.data.design.orders.order.totalProfit
        this.order.totalDesktopProfit = data.data.design.orders.order.totalDesktopProfit
        this.order.totalMobileProfit = data.data.design.orders.order.totalMobileProfit
        /* --- */
        this.order.totalSalary = data.data.design.orders.order.totalSalary
        this.order.totalDesktopSalary = data.data.design.orders.order.totalDesktopSalary
        this.order.totalMobileSalary = data.data.design.orders.order.totalMobileSalary
        /* --- */
        this.designersLoading = true
        if (this.orderItems.find(el => el.name === 'UX Wireframes') && this.order.prototype === false) {
          this.order.prototype = true
          this.setPrototype()
        }
        this.getDesignersList()
        this.checkAllProductPlans()
        this.checkAllServices()
        this.checkAllDiscounts()
        this.loading = false
        this.stepper = 3
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    async reloadOrderItems() {
      await this.$apollo.query({
        query: gql`
          query reloadOrderItems($id: ID!) {
            design {
              orders {
                order(id: $id) {
                  compositeItems {
                    __typename
                    id
                    name
                    dateStart
                    dateEnd
                    status
                    totalCount
                    totalCompletedCount
                    itemFormatLabel
                    orderItemType
                    positionInOrder
                    iconUrl
                    productPlans {
                      compositeItem {
                        id
                      }
                      ...items
                    }
                  }
                  items {
                    ...items
                  }
                  hasTheme
                  hasGraphics
                  hasFixedBySalary
                  currency
                  currencySymbol
                  totalCost
                  totalDesignerCost
                  totalDesignerSalary
                  totalDeveloperCost
                  totalDeveloperSalary
                  totalProfit
                  totalSalary
                  totalDesktopDesignerSalary
                  totalMobileDesignerSalary
                  totalMobileDesignerCost
                  totalDesktopDesignerCost
                  totalMobileDeveloperSalary
                  totalDesktopDeveloperSalary
                  totalDesktopDeveloperCost
                  totalMobileDeveloperCost
                  totalMobileProfit
                  totalDesktopProfit
                  totalDesktopCost
                  totalMobileCost
                  totalDesktopSalary
                  totalMobileSalary
                  type
                }
              }
            }
          }

          fragment items on OrderItem {
            __typename
            id
            name
            ownName
            cancellationReason
            fixedBySalary
            positionInOrder
            themeRate
            graphicsRate
            orderItemType
            comment
            difficultyFixed
            completedCount
            showCompletionProgress
            count
            dateEndPrediction
            correctDateStart
            dateStartPrediction
            expirationDate
            designerCost
            customDesignerCost
            originalDesignerCost
            itemFormatLabel
            discount {
              id
              name
              rate
            }
            difficulty {
              name
              id
            }
            executor {
              businessName
              avatarUrl
              id
              businessInitials
              statusLabel
              roleRate {
                id
                occupation
                roleId
                roleName
                value
                valueMoney
                valueMoneyWithMargin
                valueWithMargin
              }
            }
            executedByAnyone
            executorRole {
              id
              value
              roleId
              roleName
              valueMoney
              valueMoneyWithMargin
              margin
              skillLevel {
                id
              }
            }
            iconUrl
            timeCost
            status
            subtotal
            profit
            price
            mobile
            mobileMargin
            itemBasePrice
            ... on OrderProductPlan {
              affectedBy {
                id
                name
              }
              orderItemVersion
              relatedMobileItem {
                id
              }
            }
            baseService {
              id
              name
              skillCosts {
                timeCost
              }
              executorRole {
                roleName
                roleId
              }
              serviceType {
                name
                id
              }
            }
          }
          `,
        variables: {
          id: this.order.id
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const items = JSON.parse(JSON.stringify(data.data.design.orders.order.items))
        const compositeItems = data.data.design.orders.order.compositeItems
        compositeItems.sort((a, b) => a.positionInOrder - b.positionInOrder)
        compositeItems.forEach(el => {
          el.isShowChildItems = true
          el.productPlans.sort((a, b) => a.positionInOrder - b.positionInOrder)
        })
        /* Push compositeItems in Items for table */
        compositeItems.map(el => el.productPlans).forEach(el => el.forEach(el => items.push(el)))
        this.orderItems = this.getChildItem(items)
        this.compositeItems = compositeItems
        this.getMobileItem()
        this.sortRelatedItems()
        this.groupRelatedItems()
        this.order.hasFixedBySalary = data.data.design.orders.order.hasFixedBySalary
        this.changingRate.item = this.orderItems.find(el => el.id === this.changingRate.currentItemId)
        this.order.totalCost = data.data.design.orders.order.totalCost
        this.order.totalDesktopCost = data.data.design.orders.order.totalDesktopCost
        this.order.totalMobileCost = data.data.design.orders.order.totalMobileCost
        /* --- */
        this.order.totalDesignerCost = data.data.design.orders.order.totalDesignerCost
        this.order.totalDesktopDesignerCost = data.data.design.orders.order.totalDesktopDesignerCost
        this.order.totalMobileDesignerCost = data.data.design.orders.order.totalMobileDesignerCost
        /* --- */
        this.order.totalDesignerSalary = data.data.design.orders.order.totalDesignerSalary
        this.order.totalDesktopDesignerSalary = data.data.design.orders.order.totalDesktopDesignerSalary
        this.order.totalMobileDesignerSalary = data.data.design.orders.order.totalMobileDesignerSalary
        /* --- */
        this.order.totalDeveloperCost = data.data.design.orders.order.totalDeveloperCost
        this.order.totalDesktopDeveloperCost = data.data.design.orders.order.totalDesktopDeveloperCost
        this.order.totalMobileDeveloperCost = data.data.design.orders.order.totalMobileDeveloperCost
        /* --- */
        this.order.totalDeveloperSalary = data.data.design.orders.order.totalDeveloperSalary
        this.order.totalDesktopDeveloperSalary = data.data.design.orders.order.totalDesktopDeveloperSalary
        this.order.totalMobileDeveloperSalary = data.data.design.orders.order.totalMobileDeveloperSalary
        /* --- */
        this.order.totalProfit = data.data.design.orders.order.totalProfit
        this.order.totalDesktopProfit = data.data.design.orders.order.totalDesktopProfit
        this.order.totalMobileProfit = data.data.design.orders.order.totalMobileProfit
        /* --- */
        this.order.totalSalary = data.data.design.orders.order.totalSalary
        this.order.totalDesktopSalary = data.data.design.orders.order.totalDesktopSalary
        this.order.totalMobileSalary = data.data.design.orders.order.totalMobileSalary
        /* --- */
        this.order.currency = data.data.design.orders.order.currency
        this.order.currencySymbol = data.data.design.orders.order.currencySymbol
        this.designersLoading = true
        if (this.orderItems.find(el => el.name === 'UX Wireframes') && this.order.prototype === false) {
          this.order.prototype = true
          this.setPrototype()
        }
        this.getDesignersList()
      }).catch((err) => {
        console.log(err)
      })
    },
    async copyOrderItem(item) {
      this.loading = true
      const idNumber = item.id.match(/\d/g).join('')
      if (item.id.includes('OrderProductPlan')) {
        await this.$apollo.mutate({
          mutation: gql`mutation copyItem($id: ID!, $input: OrderProductPlanInput!) {
          orders {
            order(id: $id) {
              copyProductPlan(input: $input) {
                id
                name
                executor {
                    id
                  }
              }
            }
          }
        }`,
          variables: {
            id: this.order.id,
            input: {
              id: idNumber
            }
          }
        }).then((data) => {
          this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was deleted with success']
          this.newItem = data.data.orders.order.copyProductPlan
          this.reloadOrderItems()
          this.loading = false
        }).catch((err) => {
          this.loading = false
          console.log(err)
        })
      } else {
        await this.$apollo.mutate({
          mutation: gql`mutation copyItem($id: ID!, $input: OrderServiceInput!) {
            orders {
              order(id: $id) {
                copyService(input: $input) {
                  id
                  name
                }
              }
            }
          }`,
          variables: {
            id: this.order.id,
            input: {
              id: idNumber
            }
          }
        }).then(() => {
          this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was deleted with success']
          this.newItem = item
          this.reloadOrderItems()
          this.loading = false
        }).catch((err) => {
          this.loading = false
          console.log(err)
        })
      }
    },
    async removeOrderItem(item) {
      await this.deleteOrderItem(item)
      await this.reloadOrderItems()
    },
    async removeOrderItems(items) {
      for (const i of items) {
        this.selectedRows = this.selectedRows.filter(el => el.id !== i.id) // Delete selectedRows that was deleted
        await this.deleteOrderItem(i)
      }
      await this.reloadOrderItems()
    },
    async deleteCompositeItem(id) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`
        mutation deleteCompositeItem($id: ID!) {
          orders {
            compositeItem(id: $id) {
              delete {
                id
              }
            }
          }
        }
        `,
        variables: {
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.loading = false
        this.reloadOrderItems()
      }).catch((err) => {
        this.reloadOrderItems()
        this.snackbarMessage = ['Error', 'Failed to create page']
        console.log('deleteCompositeItem', err, id)
        this.loading = false
      })
    },
    async deleteOrderItem(item) {
      await this.$apollo.mutate({
        mutation: gql`mutation DeleteOrderItem($id: ID! ) {
          design {
            orders {
              orderItem(id: $id) {
                delete {
                  id
                  name
                }
              }
            }
          }
        }`,
        variables: {
          id: item.id
        }
      }).then(() => {
        this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was deleted with success']
      }).catch((err) => {
        console.log(err)
      })
    },
    async saveFiles(data) {
      const location = window.location.origin
      await data.forEach(async el => {
        const prevId = el.id
        const data = new FormData()
        data.append('file', el)
        let response = await fetch(location + `/backend/orders/upload_order_file/${this.order.id}/`, {
          method: 'POST',
          body: data
        })
        response = await response.json()
        const changedFile = this.order.files.find(file => file.id === prevId)
        changedFile.id = response.id
      })
    },
    async deleteOrderFile(id) {
      await this.$apollo.mutate({
        mutation: gql`
        mutation deleteOrderFile($orderId: ID!, $fileId: Int!) {
          orders {
            order(id: $orderId) {
              deleteOrderFile(id: $fileId)
            }
          }
        }
        `,
        variables: {
          orderId: this.order.id,
          fileId: id
        }
      }).then(() => {
        const deleteIndex = this.order.files.findIndex(el => el.id === id)
        this.order.files.splice(deleteIndex, 1)
        this.snackbarMessage = ['Success', 'File was deleted with success']
      }).catch((err) => {
        console.log(err)
      })
    },
    getChildInterface(childInterface) {
      if (!this.$options.childInterface) this.$options.childInterface = {}
      for (const [key, value] of Object.entries(childInterface)) {
        this.$options.childInterface[key] = value
      }
    },
    async setPositionItem(item, type) {
      item.forEach(item => {
        if (item.typename === 'CompositeItem') {
          this.compositeItems.find(el => el.id === item.id).positionInOrder = item.positionInOrder
        } else {
          this.orderItems.find(el => el.id === item.id).positionInOrder = item.positionInOrder
        }
      })
      if (type === 'Parent') {
        console.log(item)
        this.sortRelatedItems()
        this.$apollo.mutate({
          mutation: gql`
          mutation setCompositeItemsPositions($positions: [Int!]!, $ids: [Int!]!) {
            orders {
              setCompositeItemsPositions(positions: $positions, ids: $ids)
            }
          }
          `,
          variables: {
            ids: item.map(el => el.typename === 'CompositeItem' ? Number(el.id) : null).filter(Boolean),
            positions: item.map(el => el.typename === 'CompositeItem' ? el.positionInOrder : null).filter(el => el !== null) // Cuz one of index has 0, 0 in filter -> false
          }
        }).catch((err) => {
          this.snackbarMessage = ['Error', 'Item didn\'t change position']
          console.log(err)
        })
        this.$apollo.mutate({
          mutation: gql`mutation setItemsPositions($ids: [String!]!, $positions: [Int!]!) {
            orders {
              setItemsPositions(positions: $positions, ids: $ids)
            }
          }`,
          variables: {
            ids: item.map(el => el.typename !== 'CompositeItem' ? el.id : null).filter(Boolean),
            positions: item.map(el => el.typename !== 'CompositeItem' ? el.positionInOrder : null).filter(el => el !== null) // Cuz one of index has 0, 0 in filter -> false
          }
        }).catch((err) => {
          this.snackbarMessage = ['Error', 'Item didn\'t change position']
          console.log(err)
        })
      } else {
        this.sortRelatedItems()
        this.$apollo.mutate({
          mutation: gql`mutation setItemsPositions($ids: [String!]!, $positions: [Int!]!) {
            orders {
              setItemsPositions(positions: $positions, ids: $ids)
            }
          }`,
          variables: {
            ids: item.map(el => el.id),
            positions: item.map(el => el.positionInOrder)
          }
        }).catch((err) => {
          this.snackbarMessage = ['Error', 'Item didn\'t change position']
          console.log(err)
        })
      }
    },
    openEditItem(id) {
      /* console.log('this.$options.childInterface.openFrame', this.$options.childInterface.openFrame) */
      this.selectedItem = this.orderItems.find(el => el.id === id)
      this.$options.childInterface.openFrame()
    },
    closeEditItem(id) {
      this.$options.childInterface.closeFrame()
    },
    async setDateStart(item) {
      await this.$apollo.mutate({
        mutation: gql`mutation setCorrectDateStart($id: ID!, $correctDateStart: date ) {
          design {
            orders {
              orderItem(id: $id) {
                setCorrectDateStart(correctDateStart: $correctDateStart) {
                  id
                }
              }
            }
          }
        }`,
        variables: {
          id: item.id,
          correctDateStart: locale.format(item.correctDateStart, 'DD.MM.YYYY')
        }
      }).then(() => {
        this.snackbarMessage = ['Success', 'Item ' + item.name + ' Start date was changed to: ' + locale.format(item.correctDateStart, 'DD.MM.YYYY')]
        this.reloadOrderItems()
      }).catch((err) => {
        console.log(err)
      })
    },
    async setExpirationDate(item) {
      await this.$apollo.mutate({
        mutation: gql`mutation setExpirationDate($id: ID!, $expirationDate: date ) {
          design {
            orders {
              orderItem(id: $id) {
                setExpirationDate(expirationDate: $expirationDate) {
                  id
                }
              }
            }
          }
        }`,
        variables: {
          id: item.id,
          expirationDate: locale.format(item.expirationDate, 'DD.MM.YYYY')
        }
      }).then(() => {
        this.snackbarMessage = ['Success', 'Item ' + item.name + ' expiration date was changed to: ' + locale.format(item.expirationDate, 'DD.MM.YYYY')]
        this.reloadOrderItems()
      }).catch((err) => {
        console.log(err)
      })
    },
    async setDateRange(item) {
      const originalItem = this.orderItems.find(el => el.id === item.id)
      originalItem.correctDateStart = item.start
      originalItem.expirationDate = item.end
      if (!originalItem.compositeItem.id.includes('fakeId')) {
        const parentItem = this.getCompositeItem(originalItem.compositeItem.id)
        let minDate = Date.parse(parentItem.productPlans[0].correctDateStart) // Initial value
        let maxDate = Date.parse(parentItem.productPlans[0].expirationDate) // Initial value
        parentItem.productPlans.forEach(el => {
          if (Date.parse(el.correctDateStart) <= minDate) {
            parentItem.dateStart = el.correctDateStart
            minDate = el.correctDateStart
          }
          if (Date.parse(el.expirationDate) >= maxDate) {
            console.log(Date.parse(el.expirationDate), el.ownName)
            parentItem.dateEnd = el.expirationDate
            maxDate = el.expirationDate
          }
        })
      }
      this.$options.childInterface.updateTasks()
      await this.$apollo.mutate({
        mutation: gql`mutation setDateRange($id: ID!, $correctDateStart: date, $expirationDate: date) {
            design {
              orders {
                orderItem(id: $id) {
                  __typename
                  setCorrectDateStartAndExpirationDate(correctDateStart: $correctDateStart, expirationDate: $expirationDate) {
                    id
                  }
                }
                __typename
              }
              __typename
            }
          }`,
        variables: {
          id: item.id,
          correctDateStart: locale.format(item.start, 'YYYY-MM-DD'),
          expirationDate: locale.format(item.end, 'YYYY-MM-DD')
        }
      }).then(() => {
        this.snackbarMessage = ['Success', 'Dates was changed']
      }).catch((err) => {
        console.log('setDateRangeStart', [err, item])
      })
    },
    async setCount(id, count) {
      await this.$apollo.mutate({
        mutation: gql`mutation setCount($id: ID!, $count: Int! ) {
          design {
            orders {
              orderItem(id: $id) {
                setCount(count: $count) {
                  id
                  name
                  price
                  profit
                  subtotal
                  itemFormatLabel
                  count
                }
              }
            }
          }
        }`,
        variables: {
          id: id,
          count: count
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const item = data.data.design.orders.orderItem.setCount
        this.reloadOrderItems()
        this.snackbarMessage = ['Success', 'Item ' + item.name + ' count of ' + item.itemFormatLabel + ' was changed to: ' + item.count]
      }).catch((err) => {
        this.invokeAlert()
        console.log(err)
      })
    },
    async setCompletedCount(id, count) {
      const item = this.orderItems.find(el => {
        if (el.id === id) return el
        if (el.__mobileItem ? el.__mobileItem.id === id : false) return el
      })
      item.completedCount = count
      await this.$apollo.mutate({
        mutation: gql`mutation setCompletedCount($id: ID!, $count: Int!) {
            orders {
              orderItem(id: $id) {
                setCompletedCount(count: $count) {
                  completedCount
                }
              }
            }
          }`,
        variables: {
          id: id,
          count: count
        },
        fetchPolicy: 'no-cache'
      }).catch((err) => {
        console.log(err.message, 'setCompletedCount')
        this.invokeAlert(err.message)
        this.snackbarMessage = ['Error', 'There was some mistake, check setting of item again']
      })
    },
    checkChangesInEditItem(value) {
      value = value.split('.')
      if (Array.isArray(value) && value.length > 1) {
        console.log(this.itemChangesOriginal[value[0]][value[1]], this.itemChanges[value[0]][value[1]])
        return this.itemChangesOriginal[value[0]][value[1]] !== this.itemChanges[value[0]][value[1]]
      } else {
        console.log(this.itemChangesOriginal[value], this.itemChanges[value])
        return this.itemChangesOriginal[value] !== this.itemChanges[value]
      }
    },
    checkChangesInMobileEditItem(value) {
      value = value.split('.')
      if (Array.isArray(value) && value.length > 1) {
        console.log('mobile', this.itemChangesOriginal.__mobileItem[value[0]][value[1]], this.itemChanges.__mobileItem[value[0]][value[1]])
        return this.itemChangesOriginal.__mobileItem[value[0]][value[1]] !== this.itemChanges.__mobileItem[value[0]][value[1]]
      } else {
        console.log('mobile', this.itemChangesOriginal.__mobileItem[value], this.itemChanges.__mobileItem[value])
        return this.itemChangesOriginal.__mobileItem[value] !== this.itemChanges.__mobileItem[value]
      }
    },
    async saveChanges() {
      this.loading = true
      this.itemChangesShow = false

      /* For standard item */
      if (this.checkChangesInEditItem('ownName')) await this.setOwnName({ id: this.itemChanges.id, ownName: this.itemChanges.ownName })
      if (this.checkChangesInEditItem('difficulty.id')) await this.changeDifficulty(this.itemChanges, false)
      if (!this.itemChanges.fixedBySalary) {
        if (this.checkChangesInEditItem('completedCount')) await this.setCompletedCount(this.itemChanges.id, this.itemChanges.completedCount)
        if (this.checkChangesInEditItem('graphicsRate')) await this.setGraphicsLocally(this.itemChanges.id, this.itemChanges.graphicsRate, false)
        if (this.checkChangesInEditItem('themeRate')) await this.setThemeLocally(this.itemChanges.id, this.itemChanges.themeRate, false)
      }

      /* For mobile item */
      if (this.itemChanges.__mobileItem) {
        if (!this.itemChanges.__mobileItem.fixedBySalary) {
          if (this.checkChangesInMobileEditItem('completedCount')) await this.setCompletedCount(this.itemChanges.__mobileItem.id, this.itemChanges.__mobileItem.completedCount)
          if (this.checkChangesInMobileEditItem('graphicsRate')) await this.setGraphicsLocally(this.itemChanges.__mobileItem.id, this.itemChanges.__mobileItem.graphicsRate, false)
          if (this.checkChangesInMobileEditItem('themeRate')) await this.setThemeLocally(this.itemChanges.__mobileItem.id, this.itemChanges.__mobileItem.themeRate, false)
        }
        if (this.checkChangesInMobileEditItem('difficulty.id')) await this.changeDifficulty(this.itemChanges.__mobileItem, false)
      }
      if (this.itemChanges.__typename !== 'OrderService' && !this.itemChanges.fixedBySalary && this.checkChangesInEditItem('mobile')) await this.setMobileLocally(this.itemChanges.id, this.itemChanges.mobile, false)

      this.itemChanges = null
      this.itemChangesOriginal = null
      setTimeout(async () => {
        await this.reloadOrderItems()
      }, 200)
      this.loading = false
    },
    async setOwnName(item) {
      await this.$apollo.mutate({
        mutation: gql`mutation setOwnName($id: ID!, $ownName: String) {
            orders {
              orderItem(id: $id) {
                setOwnName(ownName: $ownName) {
                  ownName
                }
              }
            }
          }`,
        variables: {
          id: item.id,
          ownName: item.ownName
        },
        fetchPolicy: 'no-cache'
      }).catch((err) => {
        console.log(err, 'setOwnName')
        this.snackbarMessage = ['Error', 'There was some mistake, check setting of item again']
      })
    },
    async saveMultipleChanges(item, isMobile) {
      this.loading = true
      if (isMobile) {
        await this.$apollo.mutate({
          mutation: gql`mutation setUpdates($id: ID!,$value: Boolean! ) {
            orders {
              orderItem(id: $id) {
                setMobile(value: $value) {
                  id
                }
              }
            }
          }`,
          variables: {
            id: item.id,
            value: this.multiselectRows.mobile
          },
          fetchPolicy: 'no-cache'
        }).then(() => {
          this.reloadOrderItems()
          this.snackbarMessage = ['Success', 'Items was updated with success']
          this.loading = false
        }).catch((err) => {
          this.loading = false
          console.log(err)
        })
      } else {
        await this.$apollo.mutate({
          mutation: gql`mutation setUpdates($id: ID!, $difficultyId: ID! ) {
            orders {
              orderItem(id: $id) {
                setDifficulty(difficultyId: $difficultyId) {
                  id
                }
              }
            }
          }`,
          variables: {
            id: item.id,
            difficultyId: this.multiselectRows.difficulty.id
          },
          fetchPolicy: 'no-cache'
        }).then(() => {
          this.reloadOrderItems()
          this.snackbarMessage = ['Success', 'Items was updated with success']
          this.loading = false
        }).catch((err) => {
          this.loading = false
          console.log(err)
        })
      }
    },
    async resetChanges() {
      this.itemChangesShow = false
      await this.$apollo.mutate({
        mutation: gql`mutation setUpdates($id: ID! ) {
          orders {
            orderItem(id: $id) {
              resetCustomRates {
                completedCount
              }
            }
          }
        }`,
        variables: {
          id: this.itemChanges.id
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.reloadOrderItems()
        this.snackbarMessage = ['Success', 'Item ' + this.itemChanges.name + ' was reseted with success']
        this.itemChanges = null
      }).catch((err) => {
        console.log(err)
      })
    },
    async setDesigner(item) {
      console.log('setDesigner', item.executor)
      /* Check item is created */
      const existItem = this.orderItems.find(el => el.id === item.id)
      if (existItem) existItem.executor = item.executor
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setDesigner($id: ID!, $designerId: ID! ) {
          design {
            orders {
              orderItem(id: $id) {
                setDesigner(designerId: $designerId) {
                  id
                  name
                  executor {
                    businessName
                  }
                }
              }
            }
          }
        }`,
        variables: {
          id: item.id,
          designerId: item.executor.id
        },
        fetchPolicy: 'no-cache'
      }).then(async (data) => {
        const item = data.data.design.orders.orderItem.setDesigner
        this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was added designer: ' + item.executor.businessName]
        this.loading = false
      }).catch((err) => {
        this.reloadOrderItems()
        this.snackbarMessage = ['Error', `Selected executor is not correct
         ${err}`]
        console.log('setDesigner', err)
        this.loading = false
      })
    },
    /* Related Items */
    async changeDesigner(item) {
      console.log('changeDesigner', item)
      if (this.readOnly) return
      await this.getDesignersForUpdateItem(item.executorRole.id)
      await this.checkPickedDesigners(item)
    },
    checkPickedDesigners(item, items = null) {
      /* if (!this.checkType(item, 1)) return */
      const allAssignee = [...this.newAssignee, ...this.newAdditionalAssignee]
      if (!item.compositeItem.id.includes('fakeId') && item.orderItemType === 'MULTIPLE') {
        const listDesigners = []
        this.getCompositeItem(item.compositeItem.id).productPlans.forEach(el => {
          listDesigners.push(el.executor.id)
        })
        if (listDesigners.length) {
          listDesigners.forEach(id => {
            allAssignee.forEach(el => {
              if (+el.id === +id) {
                this.$set(el, 'cantPicked', true)
              }
            })
          })
        }
      } else {
        allAssignee.forEach(el => {
          if (+el.id === +item.executor.id) {
            this.$set(el, 'cantPicked', true)
          }
        })
      }
    },
    async addPage(id, qty, designerId = null, reload = true) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`
        mutation addPages($count: Int!, $id: ID!, $designerId: ID) {
          orders {
            compositeItem(id: $id) {
              addPages(count: $count, designerId: $designerId) {
                id
              }
            }
          }
        }
        `,
        variables: {
          id: id,
          count: Number(qty),
          designerId: designerId
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.loading = false
        if (reload) this.reloadOrderItems()
      }).catch((err) => {
        this.invokeAlert()
        this.reloadOrderItems()
        this.snackbarMessage = ['Error', 'Failed to create page']
        console.log('addPage', err, id)
        this.loading = false
      })
    },
    async addCompositeItem(id, count, designerId = null, reload = true) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation addCompositeItem ($id: ID!, $count: Int!, $designerId: ID) {
          orders {
            orderItem(id: $id) {
              turnIntoCompositeItem(count: $count, designerId: $designerId) {
                count
              }
            }
          }
        }`,
        variables: {
          id: id,
          count: Number(count),
          designerId: designerId
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.loading = false
        if (reload) this.reloadOrderItems()
      }).catch((err) => {
        this.invokeAlert()
        this.reloadOrderItems()
        this.snackbarMessage = ['Error', 'Failed to create page']
        console.log('addCompositeItem', err, id)
        this.loading = false
      })
    },
    async createRelatedItem(item, items, fromComposite = false) {
      /* Marker to v-dialog */
      this.relatedItem.isCreate = true
      if (item.compositeItem.id.includes('fakeId')) {
        this.relatedItem.parentId = item.id
      } else {
        this.relatedItem.parentId = item.compositeItem.id
      }
      this.relatedItem.fromComposite = fromComposite
      await this.getDesignersForNewItem(item.executorRole.id)
      await this.checkPickedDesigners(item, items)
    },
    async setExecutorForRelatedItem(designer) {
      if (this.relatedItem.fromComposite) {
        await this.addPage(this.relatedItem.parentId, 1, designer.id, false)
      } else await this.addCompositeItem(this.relatedItem.parentId, 2, designer.id, false)
      this.relatedItem.fromComposite = false
      this.newAssigneeShow = false
      /* console.log(item.id, 'HERE FGUNCTION')
      await this.addProductPlan(item.baseService.id)
      this.newItem.executor = designer
      await this.setDesigner(this.newItem)
      /!* Give parent relatedItem  *!/
      if (this.relatedItem.parent.compositeItem.id.includes('fakeId')) {
        await this.setRelateItem(this.relatedItem.parent.id, this.relatedItem.parent.id.replace(/\D/g, ''))
      }
      /!* Give newItem relatedItem *!/
      await this.setRelateItem(this.newItem.id, this.relatedItem.parentId.replace(/\D/g, ''))
      if (item.baseService.serviceType ? item.baseService.serviceType.id === '1' : false) {
        await this.setOrderItemType(item.id, 'MULTIPLE')
        await this.setOrderItemType(this.newItem.id, 'MULTIPLE')
      } else {
        await this.setOrderItemType(item.id, 'PAGE')
        await this.setOrderItemType(this.newItem.id, 'PAGE')
        console.log('setOrderItem')
      }
       */
      this.closeNewAssignee()
      this.reloadOrderItems()
    },
    /* --- */
    addNewOrder () {
      this.order = {
        difficulty: '',
        dateEnd: '',
        feeRate: 0,
        client: '',
        currency: 'USD',
        mobile: false,
        prototype: false,
        authorSupervision: false,
        name: '',
        payment: '',
        designLead: null,
        files: [],
        description: {},
        descriptionSlack: {},
        niche: null,
        project: null,
        dateStart: new Date()
      }
      this.me = this.$store.getters.getMe
      if (this.resetValidation) {
        this.clearValidation()
        this.resetPage()
        this.resetItems()
      }
      this.readOnly = false
      this.dialog = true
      this.resetValidation = true
    },
    clearValidation() {
      /* this.$refs.step2.resetValidation() */
      /* this.$refs.step1.resetValidation() */
    },
    resetPage() {
      this.stepper = 1
      this.cancelClientRegistration()
    },
    resetItems() {
      this.orderItems = []
      this.cancelClientRegistration()
    },
    async setDiscount(item) {
      await this.$apollo.mutate({
        mutation: gql`mutation setDiscount($id: ID!, $discountId: ID ) {
          design {
            orders {
              orderItem(id: $id) {
                setDiscount(discountId: $discountId) {
                  id
                }
              }
            }
          }
        }`,
        variables: {
          id: item.id,
          discountId: item.discount ? item.discount.id : null
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was changed discount with success']
        this.reloadOrderItems()
      }).catch((err) => {
        console.log(err)
      })
    },
    async setMultipleDiscount(item) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setDiscount($id: ID!, $discountId: ID ) {
          design {
            orders {
              orderItem(id: $id) {
                setDiscount(discountId: $discountId) {
                  id
                }
              }
            }
          }
        }`,
        variables: {
          id: item.id,
          discountId: this.multiselectRows.discount.id
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was changed discount with success']
        this.reloadOrderItems()
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    resetMultipleCustomCost(value, items) {
      items.forEach(async item => {
        await this.setCustomCost(value, item, false)
        await this.setCustomDesignerCost(value, item, false)
        if (item.__mobileItem) {
          await this.setCustomCost(value, item.__mobileItem, false)
          await this.setCustomDesignerCost(value, item.__mobileItem, false)
        }
      })
      this.reloadOrderItems()
    },
    async setCustomCost(value, item, reload = true) {
      if (Math.round(value * 100) / 100 !== Math.round(item.subtotal * 100) / 100) {
        item.subtotal = Number.parseFloat(value)
        await this.$apollo.mutate({
          mutation: gql`mutation setCustomCost($id: ID!, $value: Decimal ) {
            design {
              orders {
                orderItem(id: $id) {
                  setCustomCost(value: $value) {
                    id
                  }
                }
              }
            }
          }`,
          variables: {
            id: item.id,
            value: item.subtotal
          },
          fetchPolicy: 'no-cache'
        }).then(() => {
          this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was changed custom cost with success']
          if (reload) this.reloadOrderItems()
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    async setCustomDesignerCost(value, item, reload = true) {
      if (Math.round(value * 100) / 100 !== Math.round(item.designerCost * 100) / 100) {
        item.designerCost = Number.parseFloat(value)
        await this.$apollo.mutate({
          mutation: gql`mutation setCustomDesignerCost($id: ID!, $value: Decimal ) {
            design {
              orders {
                orderItem(id: $id) {
                  setCustomDesignerCost(value: $value) {
                    id
                  }
                }
              }
            }
          }`,
          variables: {
            id: item.id,
            value: item.designerCost.toString()
          },
          fetchPolicy: 'no-cache'
        }).then(() => {
          this.snackbarMessage = ['Success', 'Item: ' + item.name + ' was changed custom assignee cost with success']
          if (reload) this.reloadOrderItems()
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    async createOrder() {
      this.saveMessage()
      await this.$apollo.mutate({
        mutation: gql`mutation OrdersCreateOrder($input: DesignOrderCreateInput!) {
          design {
            orders {
              createOrder(input: $input) {
                id
                name
              }
            }
          }
        }`,
        variables: {
          input: {
            type: this.order.type,
            clientId: this.order.client.id,
            projectId: this.order.project ? this.order.project.id : null,
            paymentTypeId: this.order.paymentType.id,
            difficultyId: this.order.difficulty.id,
            name: this.order.name,
            figmaUrl: this.order.figmaUrl,
            googleDocUrl: this.order.googleDocUrl,
            jiraUrl: this.order.jiraUrl,
            mobile: this.order.mobile,
            prototype: this.order.prototype,
            authorSupervision: this.order.authorSupervision,
            feeRate: this.feeRate ? this.feeRate : this.defaultFee,
            nicheId: this.order.niche.id !== 0 ? this.order.niche.id : null,
            designLeadId: this.order.designLead.id !== 0 ? this.order.designLead.id : null,
            description: this.order.description,
            descriptionSlack: this.order.descriptionSlack
          }
        }
      }).then((data) => {
        this.orderCreated(data.data.design.orders.createOrder.id)
        const item = data.data.design.orders.createOrder
        this.snackbarMessage = ['Success', 'Order: ' + item.name + ' was created with success']
      }).catch((err) => {
        console.log(err)
      })
    },
    async orderCreated (id) {
      await this.$apollo.query({
        query: gql`query getOrder ($id: ID! ) {
            orders {
              order(id: $id) {
                authorSupervision
                createdAt
                dateEndPrediction
                dateEnd
                dateStart
                description
                descriptionSlack
                files: filesInfo {
                  id
                  url
                  customName: name
                  type: fileType
                }
                designLead {
                  id
                  businessName
                }
                type
                figmaUrl
                googleDocUrl
                jiraUrl
                items {
                  id
                  name
                  cancellationReason
                  ... on OrderProductPlan {
                    orderItemVersion
                  }
                }
                niche {
                  id
                  name
                }
                project {
                  id
                  name
                }
                client {
                  id
                  name
                  telegram
                  phone
                  company {
                    id
                    name
                  }
                  channel {
                    id
                    name
                  }
                }
                currency
                currencySymbol
                exchangeRates {
                  rub
                  usd
                }
                difficulty {
                  id
                  name
                }
                expirationDate
                feeRate
                id
                pricingExplanationUrl
                manager {
                  avatarUrl
                  id
                  businessInitials
                  businessName
                }
                mobile
                name
                number
                paymentType {
                  name
                  id
                }
                prototype
                status {
                  label
                  value
                  id
                }
                itemsCancellationReasons
                totalCost
                totalSalary
                totalDesignerCost
                totalDesignerSalary
                totalDeveloperCost
                totalDeveloperSalary
                totalProfit
                updatedAt
              }
            }
        }`,
        variables: {
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        console.log('created')
        this.order = data.data.orders.order
        this.$emit('getOrders')
        this.pageThree()
      }).catch((err) => {
        this.snackbarMessage = ['Error', 'Have got error while create the order']
        console.log(err)
      })
    },
    async updateOrder() {
      await this.$apollo.mutate({
        mutation: gql`mutation OrdersUpdateOrder($id: ID!, $input: DesignOrderUpdateInput! ) {
          design {
            orders {
              order(id: $id) {
                update(input: $input) {
                   id
                   name
                   number
                }
              }
            }
          }
        }`,
        variables: {
          id: this.order.id,
          input: {
            type: this.order.type,
            paymentTypeId: this.order.paymentType.id,
            name: this.order.name,
            figmaUrl: this.order.figmaUrl,
            googleDocUrl: this.order.googleDocUrl,
            jiraUrl: this.order.jiraUrl,
            feeRate: this.feeRate ? this.feeRate : this.order.feeRate,
            expirationDate: this.formatDate(this.order.expirationDate),
            difficultyId: this.order.difficulty.id,
            clientId: this.order.client.id,
            projectId: this.order.project ? this.order.project.id : null,
            nicheId: this.order.niche.id !== 0 ? this.order.niche.id : null,
            designLeadId: this.order.designLead.id !== 0 ? this.order.designLead.id : null,
            description: this.order.description,
            descriptionSlack: this.order.descriptionSlack
          }
        }
      }).then((data) => {
        const item = data.data.design.orders.order.update
        this.$store.commit('showSnackbar', ['Success', 'Order #' + item.number + ': ' + item.name + ' was updated with success'])
      }).catch((err) => {
        console.log(err)
      })
    },
    async getOrder (id) {
      await this.$apollo.query({
        query: gql`query getOrder ($id: ID! ) {
            orders {
              order(id: $id) {
                authorSupervision
                createdAt
                dateEndPrediction
                dateEnd
                dateStart
                hasFixedBySalary
                type
                figmaUrl
                googleDocUrl
                jiraUrl
                description
                fileUploadUrl
                files: filesInfo {
                  id
                  url
                  customName: name
                  type: fileType
                }
                designLead {
                  id
                  businessName
                }
                hasTheme
                hasGraphics
                project {
                  id
                  name
                }
                items {
                  id
                  name
                  cancellationReason
                  ... on OrderProductPlan {
                    orderItemVersion
                  }
                }
                niche {
                  id
                  name
                }
                client {
                  id
                  name
                  telegram
                  phone
                  country {
                    id
                    name
                  }
                  company {
                    id
                    name
                  }
                  channel {
                    id
                    name
                  }
                }
                currency
                currencySymbol
                exchangeRates {
                  rub
                  usd
                }
                difficulty {
                  id
                  name
                }
                expirationDate
                feeRate
                id
                pricingExplanationUrl
                manager {
                  avatarUrl
                  id
                  businessInitials
                  businessName
                }
                mobile
                name
                number
                paymentType {
                  name
                  id
                }
                prototype
                status {
                  label
                  value
                  id
                }
                itemsCancellationReasons
                totalCost
                totalSalary
                totalDesignerCost
                totalDesignerSalary
                totalDeveloperCost
                totalDeveloperSalary
                totalProfit
                updatedAt
              }
            }
        }`,
        variables: {
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.me = this.$store.getters.getMe
        this.dialog = true
        this.order = data.data.orders.order
        console.log(data.data.orders.order.description)
        const updateDate = new Date('2023-03-28T00:00:00')
        this.isUseUpdate = Date.parse(this.order.createdAt) < Date.parse(updateDate)
        if (this.resetValidation) {
          this.clearValidation()
          this.resetPage()
          this.resetItems()
        }
        this.resetValidation = true
      }).catch((err) => {
        this.snackbarMessage = ['Error', 'Error in attempt to load order']
        console.log(err)
      })
    },
    async itemStatusComplete() {
      if (this.rateItem.type === 'COMPLETED') {
        await this.$apollo.mutate({
          mutation: gql`mutation setRatings ($id: ID!, $input: OrderItemRatingsInput! ) {
          design {
            orders {
              orderItem(id: $id) {
                setRatings(input: $input) {
                  id
                }
              }
            }
          }
        }`,
          variables: {
            id: this.rateItem.item.id,
            input: {
              client: this.rateItem.clientComunication,
              collaboration: this.rateItem.designCollaboration,
              quality: this.rateItem.designQuality
              // developmentQuality: null,
              // developmentCollaboration: null,
            }
          }
        }).then(() => {
          this.rateItemShow = false
          this.rateItem.item.status = 'COMPLETED'
          this.setItemStatus(this.rateItem.item)
        }).catch((err) => {
          console.log(err)
        })
      }
      if (this.rateItem.type === 'CANCELED') {
        await this.$apollo.mutate({
          mutation: gql`mutation setCancellationReason($id: ID!, $cancellationReason: String!) {
            orders {
              orderItem(id: $id) {
                setCancellationReason(cancellationReason: $cancellationReason) {
                  cancellationReason
                }
              }
            }
          }`,
          variables: {
            id: this.rateItem.item.id,
            cancellationReason: this.rateItem.commentToDelete
          }
        }).then(() => {
          this.rateItemShow = false
          this.rateItem.item.status = 'CANCELED'
          this.rateItem.item.cancellationReason = this.rateItem.commentToDelete
          this.setItemStatus(this.rateItem.item)
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    async setItemStatus(item) {
      await this.$apollo.mutate({
        mutation: gql`mutation setItemStatus ($id: ID!, $status: DesignOrderStatusEnum! ) {
          design {
            orders {
              orderItem(id: $id) {
                setStatus(status: $status) {
                  status
                  name
                  showCompletionProgress
                  completedCount
                }
              }
            }
          }
        }`,
        variables: {
          id: item.id,
          status: item.status
        }
      }).then((data) => {
        const resp = data.data.design.orders.orderItem.setStatus
        item.showCompletionProgress = data.data.design.orders.orderItem.setStatus.showCompletionProgress
        item.completedCount = resp.completedCount
        item.showCompletionProgress = resp.showCompletionProgress
        this.rateItem = null
        this.snackbarMessage = ['Success', 'Item: ' + resp.name + ' was changed status with success']
        this.reloadOrderItems()
      }).catch((err) => {
        console.log(err)
      })
    },
    async setMultipleStatus(item) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setItemStatus ($id: ID!, $status: DesignOrderStatusEnum! ) {
          design {
            orders {
              orderItem(id: $id) {
                setStatus(status: $status) {
                  status
                  name
                  showCompletionProgress
                  completedCount
                }
              }
            }
          }
        }`,
        variables: {
          id: item.id,
          status: this.multiselectRows.status.value
        }
      }).then((data) => {
        const resp = data.data.design.orders.orderItem.setStatus
        item.showCompletionProgress = data.data.design.orders.orderItem.setStatus.showCompletionProgress
        this.rateItem = null
        item.completedCount = resp.completedCount
        item.showCompletionProgress = resp.showCompletionProgress
        this.snackbarMessage = ['Success', 'Item: ' + resp.name + ' was changed status with success']
        this.loading = false
        this.getOrderItems()
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    async oldSetMobileGlobally(value) {
      this.loading = true
      await this.$apollo.mutate({
        ...(value) && {
          mutation: gql`mutation setMobileToOrder($orderId: ID!) {
          orders {
            order(id: $orderId) {
              setMobileVersions
            }
          }
        }`
        },
        ...(!value) && {
          mutation: gql`mutation unsetMobileToOrder($orderId: ID!) {
          orders {
            order(id: $orderId) {
              deleteMobileVersions
            }
          }
        }`
        },
        variables: {
          orderId: this.order.id
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.snackbarMessage = ['Success', 'Order was updated Mobile version']
        this.reloadOrderItems()
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    async oldSetMobileLocally(id, value, reload = true) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setMobileVersionLocally($id: ID!, $value: Boolean!) {
          orders {
            orderItem(id: $id) {
              setMobileVersion(value: $value) {
                id
                name
                ... on OrderProductPlan {
                  id
                  name
                  relatedMobileItem {
                    id
                  }
                  orderItemVersion
                  orderItemType
                }
              }
            }
          }
        }`,
        variables: {
          id: id,
          value: value
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const response = data.data.orders.orderItem.setMobileVersion
        this.snackbarMessage = ['Success', 'Item: ' + response.name + ' ' + 'was changed mobile status']
        if (reload) this.reloadOrderItems()
        if (!this.order.mobile) this.order.mobile = true
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    async setMobileLocally(id, value, reload = true) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setMobile($id: ID!, $value: Boolean!) {
            orders {
              orderItem(id: $id) {
                setMobile(value: $value)
              }
            }
          }`,
        variables: {
          id: id,
          value: value
        },
        fetchPolicy: 'no-cache'
      }).then(() => {
        this.loading = false
        if (reload) this.reloadOrderItems()
        this.snackbarMessage = ['Success', 'Mobile version was updated']
      }).catch((err) => {
        console.log(err, 'setMobileLocally')
        this.snackbarMessage = ['Error', 'There was some mistake, check setting of item again']
        this.invokeAlert()
      })
    },
    async setMobile() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setMobile($id: ID!, $value: Boolean! ) {
          design {
            orders {
              order(id: $id) {
                setMobile(value: $value) {
                  id
                  name
                  number
                }
              }
            }
          }
        }`,
        variables: {
          id: this.order.id,
          value: this.order.mobile
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const item = data.data.design.orders.order.setMobile
        this.snackbarMessage = ['Success', 'Order: #' + item.number + ' ' + item.name + ' was updated Mobile version']
        this.reloadOrderItems()
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.invokeAlert()
        console.log(err)
      })
    },
    async setCurrency(currency) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setCurrency($id: ID!, $currency: Currency! ) {
          design {
            orders {
              order(id: $id) {
                setCurrency(currency: $currency) {
                  name
                  number
                  currency
                  currencySymbol
                }
              }
            }
          }
        }`,
        variables: {
          id: this.order.id,
          currency: currency
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const item = data.data.design.orders.order.setCurrency
        this.snackbarMessage = ['Success', 'Order: #' + item.number + ' ' + item.name + ' was changed currency to: ' + item.currency]
        this.reloadOrderItems()
        this.loading = false
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    async setPrototype() {
      await this.$apollo.mutate({
        mutation: gql`mutation setPrototype($id: ID!, $value: Boolean! ) {
          design {
            orders {
              order(id: $id) {
                setPrototype(value: $value) {
                  id
                  name
                  number
                }
              }
            }
          }
        }`,
        variables: {
          id: this.order.id,
          value: this.order.prototype
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const item = data.data.design.orders.order.setPrototype
        this.snackbarMessage = ['Success', 'Order: #' + item.number + ' ' + item.name + ' was updated Prototype']
        this.reloadOrderItems()
      }).catch((err) => {
        console.log(err)
      })
    },
    async setSupervision() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setAuthorSupervision($id: ID!, $value: Boolean! ) {
          design {
            orders {
              order(id: $id) {
                setAuthorSupervision(value: $value) {
                  id
                  name
                  number
                }
              }
            }
          }
        }`,
        variables: {
          id: this.order.id,
          value: this.order.authorSupervision
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        const item = data.data.design.orders.order.setAuthorSupervision
        this.snackbarMessage = ['Success', 'Order: #' + item.number + ' ' + item.name + ' was updated Author supervision']
        this.reloadOrderItems()
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.invokeAlert()
        console.log(err)
      })
    },
    async setGraphics() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setGraphics($id: ID!, $value: Boolean!) {
          orders {
            order(id: $id) {
              setGraphics(value: $value) {
                hasGraphics
              }
            }
          }
        }`,
        variables: {
          id: this.order.id,
          value: this.order.hasGraphics
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.snackbarMessage = ['Success', 'Order  was updated Graphics value']
        this.reloadOrderItems()
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.invokeAlert()
        console.log(err)
      })
    },
    async setTheme() {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setTheme($id: ID!, $value: Boolean!) {
          orders {
            order(id: $id) {
              setTheme(value: $value) {
                hasTheme
              }
            }
          }
        }`,
        variables: {
          id: this.order.id,
          value: this.order.hasTheme
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.snackbarMessage = ['Success', 'Order was updated Theme value']
        this.reloadOrderItems()
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.invokeAlert()
        console.log(err)
      })
    },
    async setGraphicsLocally(id, value, reload = true) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setGraphicsLocally($id: ID!, $value: Boolean!) {
          orders {
            orderItem(id: $id) {
              setGraphics(value: $value) {
                graphicsRate
                graphicsRateMargin
              }
            }
          }
        }`,
        variables: {
          id: id,
          value: value
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.snackbarMessage = ['Success', 'Item  was updated Graphics value']
        if (reload) this.reloadOrderItems()
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.invokeAlert()
        console.log(err)
      })
    },
    async setThemeLocally(id, value, reload) {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`mutation setThemeLocally($id: ID!, $value: Boolean!) {
          orders {
            orderItem(id: $id) {
              setTheme(value: $value) {
                themeRate
                themeRateMargin
              }
            }
          }
        }`,
        variables: {
          id: id,
          value: value
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.snackbarMessage = ['Success', 'Item was updated Theme value']
        if (reload) this.reloadOrderItems()
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.invokeAlert()
        console.log(err)
      })
    },
    async getAllDesigners() {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getAllDesigners {
              design {
                team {
                  paginatedDesigners {
                    data {
                      id
                      avatarUrl
                      businessName
                      businessInitials
                      fired
                      statusLabel
                      dateEnd
                      roleRate {
                        id
                        roleName
                        roleId
                      }
                      otherRoles {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.$store.commit('updateAllDesigners', data.data.design.team.paginatedDesigners.data)
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    /* Fee */
    checkDefaultFee () {
      this.$store.getters.getDefaultFee ? this.defaultFee = this.$store.getters.getDefaultFee : this.getFee()
    },
    async getFee () {
      await this.$apollo.query({
        query: gql`query getFee {
          design {
            orders {
              defaultFee
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.defaultFee = data.data.design.orders.defaultFee
        this.$store.commit('updateDefaultFee', data.data.design.orders.defaultFee)
      }).catch((err) => {
        this.snackbarMessage = ['Error', err]
        console.log(err)
      })
    },
    checkNiches () {
      this.$store.getters.getNiches ? this.niches = this.$store.getters.getNiches : this.getAllNiches()
    },
    async getAllNiches () {
      await this.$apollo.query({
        query: gql`query getNiches {
          design {
            orders {
              niche {
                data {
                  id
                  name
                }
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.niches = data.data.design.orders.niche.data
        this.niches.unshift({
          id: 0,
          name: 'None'
        })
        this.$store.commit('updateNiches', this.niches)
      }).catch((err) => {
        console.log(err)
      })
    },
    checkStatusLabels () {
      this.$store.getters.getStatusLabels ? this.statusLabels = this.$store.getters.getStatusLabels : this.getStatusLabels()
    },
    async getStatusLabels () {
      await this.$apollo.query({
        query: gql`query getStatusLabels {
          design {
            orders {
              orderStatusLabels {
                id
                label
                value
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.statusLabels = data.data.design.orders.orderStatusLabels
        this.$store.commit('updateStatusLabels', data.data.design.orders.orderStatusLabels)
      }).catch((err) => {
        this.snackbarMessage = ['Error', err]
        console.log(err)
      })
    },
    step2() {
      if (this.stepper === 1 && !this.readOnly) {
        if (this.$refs.step1.validate()) {
          this.stepper = 2
        }
      } else this.stepper = 2
    },
    step3() {
      if (this.stepper === 1 && !this.readOnly) {
        if (this.$refs.step1.validate()) {
          this.stepper = 2
          this.nextPage()
        }
      } else if (this.stepper === 2 && !this.readOnly) {
        this.nextPage()
      } else {
        this.stepper = 2
        this.nextPage()
      }
    },
    nextPage() {
      const vm = this
      const step2 = this.$refs.step2
      if (vm.stepper === 1) {
        if (!this.readOnly) {
          if (this.$refs.step1.validate()) {
            vm.stepper = 2
          }
        } else vm.stepper = 2
      } else if (vm.stepper === 2) {
        if (this.order.id) {
          setTimeout(function () {
            if (step2.validate()) {
              vm.getOrderItems()
            }
          }, 0)
        } else {
          step2.validate()
          setTimeout(function () {
            if (step2.validate()) {
              vm.createOrder()
            }
          }, 0)
        }
      }
    },
    /* nextPage() {
      const vm = this
      const step2 = this.$refs.step2
      if (vm.stepper === 1) {
        if (!this.readOnly) {
          if (this.$refs.step1.validate()) {
            vm.stepper = 2
          }
        } else vm.stepper = 2
      } else if (vm.stepper === 2) {
        if (this.order.id) {
          setTimeout(function () {
            if (step2.validate()) {
              vm.getOrderItems()
            }
          }, 0)
        } else {
          step2.validate()
          setTimeout(function () {
            if (step2.validate()) {
              vm.createOrder()
            }
          }, 0)
        }
      }
    }, */
    closeEdit() {
      this.dialog = false
      this.$emit('getOrders')
    },
    pageThree() {
      this.checkAllProductPlans()
      this.checkAllServices()
      this.stepper = 3
    }
  },
  created() {
    this.getAllPaymentsAndDifficultuies()
    this.checkDefaultFee()
    this.checkNiches()
    this.checkStatusLabels()
  },
  mounted() {
    if (this.$store.state.countries === null) this.$store.dispatch('fetchCountries')
    window.addEventListener('keydown', (event) => {
      if (event.code === 'ArrowRight' && event.ctrlKey) {
        if (this.viewTab === 0) this.viewTab++
      } else if (event.code === 'ArrowLeft' && event.ctrlKey) {
        if (this.viewTab === 1) this.viewTab--
      }
    })
    this.getCountries()
    this.getAllDesigners()
    this.getDesignersMultiselect()
    this.getPhoneExample()
    this.checkAllProductPlans()
    this.checkAllServices()
    this.checkAllDiscounts()
    /* this.addSortableJS() */
    /*
    For testing
    if (this.stepper === 3) {
      console.log('Временно ордер открывается на 3 шаге')
      this.getOrderItems()
    }
    */
  },
  watch: {
    orderItems: {
      handler() {
        if (Object.keys(this.selectedItem).length) this.openEditItem(this.selectedItem.id)
      }
    },
    viewTab: {
      handler() {
        if (this.selectedItem && Object.keys(this.selectedItem).length) this.closeEditItem()
        this.sortRelatedItems()
      }
    }
  }
}
</script>

<style lang="scss">
@import '~quill/dist/quill.snow.css';

.ql-container.ql-snow, .ql-toolbar.ql-snow {
  border: unset;
}

.edit-order {
  .v-stepper__wrapper {
    overflow: visible;
  }
}

.table-items {
  .v-row-group__header {
    background-color: transparent !important;

    td:not(:last-child) {
      border-right: 1px solid #EEF2F7 !important;
    }
  }
}

.select-client-menu {
  border: 1px solid #E3E6EF;
}

.position-input {
  top: 20px;
  right: 28px;
}

.btn-black {
  color: #010820;
  border: 1px solid #010820;

  &:hover {
    color: #FFFFFF;
    background-color: #010820;
  }
}

.rate-send.v-btn.v-btn--disabled.v-btn--has-bg.theme--light {
  color: #FFFFFF !important;
  background-color: #7552CC !important;
  opacity: 0.5 !important;
}

.rate-img {
  img {
    width: 33px;
    height: 32px;
    transition: .26s;

    &:hover {
      transform: scale(1.25);
    }
  }

  &-opacity {
    opacity: .4;
  }

  &-active {
    transform: scale(1.25);
  }
}

.copy-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  stroke: #010820;
}

.difficulty {
  .difficulty-dot {
    position: relative;
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: #7E86A0;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  .difficulty-dot--active {
    background: #7552cc !important;
  }

  .difficulty-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: transparent;
  }
}

.item-comment {
  .v-textarea {
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }
}

.v-input__slot {
  .v-input__prepend-inner {
    margin: auto 0 !important;
  }
}

.rate-item {
  .v-text-field__details {
    display: none;
  }
}

.change-text-input {
  &:hover {
    text-decoration: underline;
  }
}
</style>
