<template>
  <v-app>
    <v-navigation-drawer
      dark
      app
      width="280"
      v-model="drawer"
      :mini-variant.sync="sidebarMini && $vuetify.breakpoint.mdAndUp"
      :permanent="$vuetify.breakpoint.mdAndUp"
      class="design-drawer z-index-300"
      :class="{'z-index-500': $vuetify.breakpoint.smAndDown}"
    >
      <SidebarDesign :sidebarMini="sidebarMini" :currency="currency" :me="me" @hideSidebar="hideSidebar"/>
    </v-navigation-drawer>

    <TopNavDesign v-if="!$store.getters.isDesigner" :sidebarMobile="sidebarMobile" :sidebarMini="sidebarMini" :currency="currency" :me="me" :drawer="drawer" @showSidebar="showSidebar"/>
      <!-- -->

    <v-main>
      <v-container fluid style="height: calc(100vh - 80px)" class="scroll pa-0">
        <div class="preloader" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
        <router-view v-else></router-view>
      </v-container>
    </v-main>
    <Snackbar :messages="$store.state.snackbarMessage"/>
  </v-app>
</template>

<script>
import SidebarDesign from '@/containers/SidebarDesign'
import TopNavDesign from '@/containers/TopNavDesign'
import Snackbar from '@/components/App/Functional/Snackbar'
import gql from 'graphql-tag'

export default {
  name: 'MainLayout',
  components: {
    SidebarDesign,
    TopNavDesign,
    Snackbar
  },
  data () {
    return {
      loading: false,
      sidebarMini: null,
      drawer: false,
      me: {},
      currency: {
        symbol: '$',
        title: '$ USD',
        value: 0,
        diff: 0
      }
    }
  },
  computed: {
    sidebarMobile() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    hideSidebar () {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.drawer = false
        this.sidebarMini = false
      } else this.sidebarMini = true
    },
    showSidebar () {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.drawer = true
        this.sidebarMini = false
      } else this.sidebarMini = false
    },
    async getCurrency () {
      await this.$apollo.query({
        query: gql`query GetCurrency {
          pricing {
            usdRate {
              change
              value
            }
          }
        }`
      }).then((data) => {
        this.currency.value = data.data.pricing.usdRate.value
        this.currency.diff = data.data.pricing.usdRate.change
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  created () {
    this.getCurrency()
  }
}
</script>

<style lang="scss">
  .design-drawer {
    background-color: #0E141E !important;
  }
  .selected-item {
    background-color: #272F3D;
  }
</style>
