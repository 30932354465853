<!--eslint-disable-->
<template>
</template>

<script>
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'TimeFormat',
  methods: {
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    formatDateMonthToDay(date) {
      return locale.format(date, 'MMMM D, YYYY')
    }
  }
}
</script>
