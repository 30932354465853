<template>
  <div>
    <span
      style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;"
      class="rounded-circle"
      :style="color(status.value)"
    />
    <span style="max-width: 80px">
      {{ status.label }}
    </span>
    <v-tooltip bottom max-width="600" v-if="cancellationReasons.length && status.id === 'CANCELED'">
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          class="one-line py-0"
        >
          <v-icon size="32">$questionMark</v-icon>
        </span>
      </template>
      <v-col class="d-flex flex-column flex-wrap flex-grow-0">
        <div
          v-for="(reason, index) of cancellationReasons"
          :key="index"
          class="width-100"
          style="word-break: break-all"
        >
          <span>
            {{ reason }}
          </span>
        </div>
      </v-col>
    </v-tooltip>
  </div>
</template>

<script>
import { statusColor } from '@/helpers/staticColors'

export default {
  name: 'OrderStatus',
  props: {
    status: {
      default: () => ({}),
      required: true
    },
    cancellationReasons: {
      default: [],
      required: false
    }
  },
  methods: {
    color(value) {
      return statusColor(value)
    }
  }
}
</script>

<style scoped>

</style>
