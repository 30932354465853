<!--eslint-disable-->
<template v-if="isLoaded">
  <div>
    <input
      id="fileInput"
      type="file"
      multiple
      style="display: none"
      @change="uploadFile"
    >
    <div id="rich-text-editor"></div>
    <v-row no-gutters class="flex-wrap pl-2">
      <div
          v-for="file in files"
          :key="file.url"
          class="d-flex flex-column align-center fz-12 position-relative mr-4"
          style="width: 75px;"
        >
        <RemoveDialog :withoutModal="!Boolean(file.id)" @commit="deleteFile(file)">
          <v-btn
            icon
            width="24"
            height="24"
            style="position: absolute; top: -15px; right: -15px;"
          >
            <v-icon
              size="15"
              class="stroke-black path-gray"
            >$close
            </v-icon>
          </v-btn>
        </RemoveDialog>
        <template v-if="file.type.includes('image')">
          <img
            width="50px"
            height="50px"
            :src="file.url"
          >
        </template>
        <template v-else>
          <div
            class="file-img"
            style="width: 35px; height: 35px;"
          ></div>
          <div class="width-100 text-center">
            {{ file.customName }}
          </div>
        </template>
      </div>
    </v-row>
    <v-row v-if="isOrderCreated || isChanged" no-gutters class="px-2 pb-2 justify-end">
      <v-btn
        height="40"
        class="rounded-lg fz-16 fw-600 text-none"
        elevation="0"
        color="primary"
        @click="saveMessage"
      >
        Save
      </v-btn>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */

import Quill from 'quill'
import axios from 'axios'
import gql from 'graphql-tag';
import RemoveDialog from '@/components/App/Functional/RemoveDialog'

export default {
  name: 'RichTextEditor',
  components: { RemoveDialog },
  props: {
    isOrderCreated: {
      type: Boolean,
      default: false
    },
    isLoaded: {
      type: Boolean,
      default: false
    },
    files: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: [Object, String],
      default: () => ({})
    }
  },
  data() {
    return {
      isChanged: false,
      delta: {},
      finalText: []
    }
  },
  methods: {
    uploadFile(e) {
      const files = Array.from(e.target.files)
      files.forEach(file => {
        const splittedName = file.name.split(/\.+/g)
        splittedName[0] = splittedName[0].split('')
        splittedName[0].splice(10)
        file.customName = splittedName[0].join('') + '...' + splittedName[1]
        file.url = URL.createObjectURL(file)
      })
      if (this.files === null) this.$emit('update:files', files)
      else {
        const tempArr = this.files
        tempArr.push(...files)
        this.$emit('update:files', tempArr)
      }
      this.isChanged = true
    },
    deleteFile(item) {
      if (item.id) {
        this.$emit('deleteFile', item.id)
      } else {
        const deletedIndex = this.files.findIndex(el => el.url === item.url)
        this.files.splice(deletedIndex, 1)
      }
      this.isChanged = true
    },
    createRichTextEditor() {
      /* eslint-disable */
      const quill = new Quill('#rich-text-editor', {
        modules: {
          toolbar: [
            [ 'bold', 'italic', 'strike' ],
            [ 'link' ],
            [ { 'list': 'ordered'}, { 'list': 'bullet'} ],
            [ 'blockquote' ],
            [ 'code-block' ],
            [ 'file' ]
          ]
        },
        /* toolbar: [
            [ 'bold', 'italic', 'underline', 'strike' ],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
            [{ 'list': 'ordered' }, { 'list': 'bullet'}],
            [ { 'align': [] }],
            [ 'link', 'image', 'file' ]
          ] */
        theme: 'snow'
      })
      quill.setContents(this.value ? this.value.delta : {});
      quill.on('selection-change', function (range, oldRange, source) {
        if (range === null && oldRange !== null) {
          console.log(source)
          console.log('changes')
        }
      })
      quill.on('text-change', () => {
        const content = quill.getContents().ops
        this.delta = {
          delta: quill.getContents().ops
        }
        this.isChanged = true
      })

      const editor = document.querySelector('#rich-text-editor')
      const uploadBtn = document.querySelector('.ql-file')
      const iconUploadBtn = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" height="18px" width="18px">\n' +
        '<g id="File / File_Upload">\n' +
        '<path id="Vector" d="M12 18V12M12 12L9 14M12 12L15 14M13 3.00087C12.9045 3 12.7973 3 12.6747 3H8.2002C7.08009 3 6.51962 3 6.0918 3.21799C5.71547 3.40973 5.40973 3.71547 5.21799 4.0918C5 4.51962 5 5.08009 5 6.2002V17.8002C5 18.9203 5 19.4801 5.21799 19.9079C5.40973 20.2842 5.71547 20.5905 6.0918 20.7822C6.51921 21 7.079 21 8.19694 21L15.8031 21C16.921 21 17.48 21 17.9074 20.7822C18.2837 20.5905 18.5905 20.2842 18.7822 19.9079C19 19.4805 19 18.9215 19 17.8036V9.32568C19 9.20296 19 9.09561 18.9991 9M13 3.00087C13.2856 3.00347 13.4663 3.01385 13.6388 3.05526C13.8429 3.10425 14.0379 3.18526 14.2168 3.29492C14.4186 3.41857 14.5918 3.59182 14.9375 3.9375L18.063 7.06298C18.4089 7.40889 18.5809 7.58136 18.7046 7.78319C18.8142 7.96214 18.8953 8.15726 18.9443 8.36133C18.9857 8.53376 18.9963 8.71451 18.9991 9M13 3.00087V5.8C13 6.9201 13 7.47977 13.218 7.90759C13.4097 8.28392 13.7155 8.59048 14.0918 8.78223C14.5192 9 15.079 9 16.1969 9H18.9991M18.9991 9H19.0002" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
        '</g>\n' +
        '</svg>'
      uploadBtn.insertAdjacentHTML('beforeend', iconUploadBtn)
      const uploadInput = document.querySelector('#fileInput')
      uploadBtn.addEventListener('click', () => {
        uploadInput.click()
      })


      /* const footer = document.createElement('div') */
      /* footer.classList.add('ql-footer') */

      /* const footerTool = document.createElement('span')
      footerTool.textContent = '+'
      footerTool.addEventListener('click', () => {
        const reader = new FileReader()
        console.log(reader)
      }) */

      /* footer.append(footerTool) */
      /* editor.insertAdjacentElement('afterend', footer) */

      /* eslint-enable */
    },
    saveMessage() {
      const data = []
      if (this.files) {
        this.files.forEach(d => {
          if (!d.id) data.push(d)
          d.id = d.url
        })
      }
      if (data.length && this.files.length) {
        this.$emit('saveFiles', data)
      }

      this.finalText = []
      document.querySelectorAll('.ql-editor > *').forEach(el => {
        const htmlEl = el.localName
        if (htmlEl === 'p' || htmlEl === 'blockquote' || htmlEl === 'ul' || htmlEl === 'ol') {
          if (el.children.length > 1) {
            Array.from(el.children).forEach(el => {
            })
          }
          let text = el.outerHTML
          if (el.querySelector('.ql-cursor')) {
            this.finalText.push('\n')
            return
          }
          if (htmlEl === 'ol') {
            /* eslint-disable */
            let counter = 0
            function replacer() {
              counter++
              return `${String(counter)}. `
            }
            /* eslint-enable */
            text = text.replace(/<li>/g, replacer)
          }
          text = text.replace(/<p>|<ul>|<\/ul>|<ol>|<\/ol>/g, '')
          text = text.replace(/<strong>/g, '*')
          text = text.replace(/<\/strong>/g, '* ')
          text = text.replace(/<em>/g, '_')
          text = text.replace(/<\/em>/g, '_ ')
          text = text.replace(/<s>/g, '~')
          text = text.replace(/<\/s>/g, '~ ')

          text = text.replace(/<blockquote>/g, '>')

          text = text.replace(/<li>/g, '• ')
          text = text.replace(/<br>|<\/p>|<\/blockquote>|<\/li>/g, '\n')

          /* <a href=\"Ay\" rel=\"noopener noreferrer\" target=\"_blank\">*_~Ay~_*</a> */
          const link = el.querySelector('a')
          if (link) {
            text = text.replace(/<a href.*">/g, `<${link.href}|`)
            text = text.replace(/<\/a>/g, '>')
          }
          this.finalText.push(text)
        }
        if (htmlEl === 'pre') {
          let text = el
          text = '```' + text.textContent + '```'
          this.finalText.push(text)
        }
      })
      console.log(JSON.stringify(this.finalText.join('')))
      this.$emit('update:value', JSON.stringify(this.delta))
      this.$emit('update:descriptionSlack', JSON.stringify({
        blocks: [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: this.finalText.join('')
            }
          }
        ]
      }))

      this.$emit('updateOrder')
      this.isChanged = false
    }
  },
  mounted() {
    this.createRichTextEditor()
  }
}
</script>

<style lang="scss">
.ql-footer {
  padding: 8px;
}
</style>
<style
  scoped
  lang="scss"
>
.file-img {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' %3E%3Cpath d='M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z'/%3E%3C/svg%3E");
}
</style>
