<script>
import { labelStatus } from '@/helpers/staticColors'

export default {
  name: 'StatusLabel',
  methods: {
    getStatusLabel(value) {
      return labelStatus(value)
    }
  }
}
</script>
