<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3333 25.334L16 26.6673L12.6667 25.334L9.33334 26.6673V9.33398H22.6667V26.6673L19.3333 25.334Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3333 13.3327H18.6667" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.6667 17.3327H13.3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3333 21.3327H17.3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.6667 14.6667H25.3333C26.8067 14.6667 28 13.4733 28 12V6.66667C28 5.19333 26.8067 4 25.3333 4H6.66667C5.19333 4 4 5.19333 4 6.66667V12C4 13.4733 5.19333 14.6667 6.66667 14.6667H9.33333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'FinanceTotalOrders'
}
</script>

<style scoped>

</style>
