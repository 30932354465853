<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3333 10C13.3333 11.8408 11.8408 13.3333 10 13.3333C8.15917 13.3333 6.66667 11.8408 6.66667 10C6.66667 8.15917 8.15917 6.66667 10 6.66667V6.66667C11.8408 6.66667 13.3333 8.15917 13.3333 10V11.25C13.3333 12.4008 14.2658 13.3333 15.4167 13.3333C16.5675 13.3333 17.5 12.4008 17.5 11.25V10C17.5 5.8575 14.1425 2.5 10 2.5C5.8575 2.5 2.5 5.8575 2.5 10C2.5 14.1425 5.8575 17.5 10 17.5C10.9575 17.5 11.9308 17.3183 12.87 16.9292C13.3767 16.7192 13.845 16.4558 14.2775 16.155" stroke="#808490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>
