<template>
  <v-dialog
    max-width="480"
    overlay-color="rgba(9, 24, 73, 0.2)"
    overlay-opacity="1"
    content-class="rounded-xl dialog-flex"
    v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot/>
      </div>
    </template>
    <template v-slot:default="dialog">
      <v-card class="rounded-xl d-flex flex-column hidden pa-10">
        <!--Header-->
        <v-row class="mb-10 position-relative" justify="center">
          <div class="lh-20 font-weight-medium text-center">
            <p
              class="fz-20"
              style="margin-bottom: 10px;"
            >
              Edit Rate
            </p>
            <p
              class="fz-16 text-gray"
              style="margin-bottom: 10px;"
              v-if="item"
            >
              {{ item.executorRole.roleName }}
            </p>
          </div>
          <v-icon
            style="position: absolute; top: 0; right: 0;"
            class="close-icon mt-1"
            @click="dialog.value = false"
          >
            $close
          </v-icon>
        </v-row>
        <!--Cost-->
        <v-row no-gutters class="justify-space-between align-center" style="min-height: 50px">
          <v-col>
            <span class="fz-16 lh-20 text-black">Assignee cost</span>
          </v-col>
          <v-col>
            <v-text-field
              class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
              dense
              hide-spin-buttons
              outlined
              hide-details
              type="number"
              v-if="item"
              v-model="saleCostOption.rate"
              :rules="[$rules.required]"
            />
          </v-col>
        </v-row>
        <!--Margin-->
        <v-row no-gutters class="justify-space-between align-center" style="min-height: 50px">
          <v-col>
            <span class="fz-16 lh-20 text-black">Margin</span>
          </v-col>
          <v-col>
            <v-text-field
              class="rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border"
              dense
              hide-spin-buttons
              outlined
              hide-details
              type="number"
              v-if="item"
              v-model="saleCostOption.margin"
              :rules="[$rules.required]"
            />
          </v-col>
        </v-row>
        <!--Time Cost-->
        <!--TimeCost for parent-->
        <template v-if="item ? (item.baseService.serviceType ? (item.baseService.serviceType.id == 1 ? false : true) : true) : false">
          <v-row no-gutters class="justify-space-between align-center" style="min-height: 50px">
            <v-col>
              <span class="fz-16 lh-20 text-black">Time Cost</span>
            </v-col>
            <v-col>
              <v-text-field
                class="edit-rate-time-cost rounded-lg text-black mb-n1 mt-1 fz-14 font-weight-medium select-dash input-border position-relative"
                dense
                hide-spin-buttons
                outlined
                hide-details
                type="number"
                v-if="item"
                v-model="saleCostOption.timeCost"
                :rules="[$rules.required]"
                ref="dependentTimeCost"
              />
            </v-col>
          </v-row>
        </template>
        <!--Sale cost-->
        <v-row no-gutters class="justify-space-between align-center" style="min-height: 50px">
          <v-col>
            <span class="fz-16 lh-20 text-black">Sale cost</span>
          </v-col>
          <v-col>
            <p class="mb-0" v-if="item">{{ Math.round(saleCost) }}{{ orderCurrency.currencySymbol }}</p>
          </v-col>
        </v-row>
        <!--Modal's buttons-->
        <v-row class="ma-0 mt-10 justify-center">
          <v-btn
            :disabled="item.fixedBySalary"
            color="primary"
            height="40"
            width="155"
            class="rounded-lg fz-16 font-weight-medium text-none"
            style="margin-right: 10px;"
            outlined
            @click="resetRate"
          >
            Reset
          </v-btn>
          <v-btn
            :disabled="item.fixedBySalary"
            elevation="0"
            color="primary"
            height="40"
            width="155"
            class="rounded-lg fz-16 fw-600 text-none"
            @click="searchRate"
          >
            Apply
          </v-btn>
        </v-row>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'EditRate',
  props: {
    orderId: {
      type: String,
      required: true
    },
    orderCurrency: {
      type: Object,
      default: () => ({ currency: '', exchangeRates: '', currencySymbol: '' }),
      required: true
    },
    item: {
      type: [Object, null],
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: false,
      saleCostOption: {
        rate: 0,
        margin: 0,
        timeCost: 0
      },
      saleCost: 0
    }
  },
  methods: {
    async getSaleCost() {
      await this.$apollo.query({
        query: gql`query getSaleCost($id: String!, $newMargin: Decimal!, $newRate: Decimal!, $newTimeCost: Decimal!) {
          orders {
            getQuickSaleCost(
              input: {newRate: $newRate, newMargin: $newMargin, newTimeCost: $newTimeCost, id: $id}
            )
          }
        }`,
        fetchPolicy: 'no-cache',
        variables: {
          id: this.item.id,
          newRate: this.saleCostOption.rate,
          newMargin: this.saleCostOption.margin,
          newTimeCost: this.saleCostOption.timeCost
        }
      }).then(async (data) => {
        this.saleCost = Math.round(data.data.orders.getQuickSaleCost)
      }).catch((err) => {
        console.log(err)
        this.$parent.invokeAlert()
      })
    },
    async searchRate() {
      await this.$apollo.query({
        query: gql`query SearchTeamRoleRate($input: TeamRoleRateFilterInput!) {
           pricing {
              filteredRoleRates(input: $input) {
                id
                roleName
                valueMoney
                valueMoneyWithMargin
                margin
                skillLevel {
                  name
                  id
                }
              }
            }
          }`,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            rateMoneyWithoutMargin: this.saleCostOption.rate,
            rateMoneyWithoutMarginCurrency: this.item.executorRole.valueMoney.currency,
            margin: this.saleCostOption.margin,
            skillLevelId: this.item.executorRole.skillLevel.id,
            roleId: this.item.baseService.serviceType ? (Number(this.item.baseService.serviceType.id) === 2 ? this.item.executor.role.id : this.item.baseService.executorRole.roleId) : this.item.baseService.executorRole.roleId,
            page: 1,
            pageSize: 999
          }
        }
      }).then((data) => {
        if (data.data.pricing.filteredRoleRates.length === 0) {
          this.createRate()
        } else {
          console.log('Rate found')
          const id = data.data.pricing.filteredRoleRates[0].id
          this.setNewRate(id)
        }
      }).catch((err) => {
        console.log(err)
        this.$parent.invokeAlert()
      })
    },
    async createRate() {
      await this.$apollo.mutate({
        mutation: gql`mutation TeamRoleRateCreateInput($input: TeamRoleRateUpdateInput!) {
          pricing {
            teamRoleRate {
              update(input: $input) {
                id
                margin
                roleName
                skillLevel {
                  name
                  id
                  __typename
                }
                valueMoney
                valueWithMargin
                valueMoneyWithMargin
                __typename
              }
              __typename
            }
            __typename
          }
        }`,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            value: {
              amount: this.saleCostOption.rate,
              currency: this.item.executorRole.valueMoney.currency
            },
            id: this.item.executorRole.id,
            roleName: this.item.executorRole.roleName,
            margin: this.saleCostOption.margin,
            orderNumber: Number(this.orderId)
          }
        }
      }).then(async (data) => {
        /* this.addSnack('', 'Change successful', 'Rate for item was succeed created') */
        console.log('Rate was created')
        this.setNewRate(data.data.pricing.teamRoleRate.update.id)
        /* await this.getOrderItems() */
      }).catch((err) => {
        console.log(err)
        this.$parent.invokeAlert()
      })
    },
    async setNewRate(id, reset) {
      await this.$apollo.mutate({
        mutation: gql`mutation setNewRate($id: ID!, $idRate: ID!) {
          design {
            orders {
              orderItem(id: $id) {
                setRoleRate(roleRateId: $idRate) {
                  id
                }
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache',
        variables: {
          id: this.item.id,
          idRate: id
        }
      }).then(async (data) => {
        /* this.addSnack('', 'Change successful', 'Rate for item was succeed changed') */
        if (reset) {
          this.changeTimeCost(this.item.baseService.skillCosts[0].timeCost)
        } else {
          this.changeTimeCost(this.saleCostOption.timeCost)
        }
      }).catch((err) => {
        console.log(err)
        this.$parent.invokeAlert()
      })
    },
    async changeTimeCost(timeCost) {
      await this.$apollo.mutate({
        mutation: gql`mutation setTimeCost($id:ID!, $timeCost: Decimal!) {
          orders {
            orderItem(id: $id) {
              setTimeCost(timeCost: $timeCost) {
                id
              }
            }
          }
        }`,
        fetchPolicy: 'no-cache',
        variables: {
          id: this.item.id,
          timeCost: Number(timeCost)
        }
      }).then(async (data) => {
        this.$emit('readyToUpdateItem')
        this.dialog = false
      }).catch((err) => {
        console.log(err)
        this.$parent.invokeAlert()
      })
    },
    resetRate() {
      console.log(this.item.executor.roleRate)
      console.log(this.item.baseService.skillCosts[0].timeCost)
      this.setNewRate(this.item.executor.roleRate.id, true)
    }
  },
  watch: {
    dialog: {
      handler() {
        this.saleCostOption.rate = this.item.executorRole.valueMoney.amount
        this.saleCostOption.margin = this.item.executorRole.margin
        this.saleCostOption.timeCost = this.item.timeCost
        this.saleCost = this.item.subtotal
      }
    },
    saleCostOption: {
      handler() {
        this.getSaleCost()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.edit-rate-time-cost {
  .v-input__append-inner {
    &::before {
      display: none;
    }
  }
}
</style>
