<script>
export default {
  directives: {
    resizableInput: {
      bind(el) {
        resizable(el, 10)

        function resizable(el, factor) {
          const int = Number(factor) || 7.7

          function resize() {
            el.style.width = ((el.value.length + 1) * int) + 'px'
          }

          const e = 'keyup,keypress,focus,blur,change'.split(',')
          for (const i in e) {
            el.addEventListener(e[i], resize, false)
          }
          resize()
        }
      }
    }
  }
}
</script>
