import { render, staticRenderFns } from "./AdditionalCustomIllustrations.vue?vue&type=template&id=3e5b28fa&scoped=true&"
import script from "./AdditionalCustomIllustrations.vue?vue&type=script&lang=js&"
export * from "./AdditionalCustomIllustrations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e5b28fa",
  null
  
)

export default component.exports