<template>
  <v-menu
    :disabled="disabled"
    bottom
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="px-2 py-1"
        :class="[attrs['aria-expanded'] === 'true' ? 'clickable--active' : '', disabled ? '' : 'clickable']"
      >
        <slot/>
      </div>
    </template>
    <v-card class="rounded-lg hidden" elevation="0">
      <v-card-text class="px-0 py-1">
        <v-card-title v-if="header" class="pa-0 fz-12 ml-4">
          {{ header }}
        </v-card-title>
        <v-list class="pa-0" color="transparent scroll">
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            class="px-0 py-1"
            @click="changeOption(item)"
          >
            <p class="ma-0 px-2 fz-12 text-black">
              {{ item[value] }}
              <slot name="additionalField" :item="item"></slot>
            </p>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'selectMenu',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: 'name'
    }
  },
  data() {
    return {
      chosenItem: null
    }
  },
  methods: {
    changeOption(item) {
      console.log('selectMenu', item)
      this.$emit('clicked', item)
    }
  }
}
</script>

<style scoped lang="scss">
.v-list {
  max-height: 200px;
}

.v-list-item {
  min-height: 30px !important;
}
</style>
