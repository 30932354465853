<template>
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="9.60132" y="4" width="1.79708" height="12" rx="0.898541" fill="white"/>
<rect x="4.5" y="10.9004" width="1.79818" height="12" rx="0.89909" transform="rotate(-90 4.5 10.9004)" fill="white"/>
</svg>

</template>

<script>
export default {
  name: 'plusLarge'
}
</script>

<style scoped>

</style>
