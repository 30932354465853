<template>
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.82917 10.0885L7.10834 11.7626C6.81 12.0526 6.31084 11.9243 6.19 11.526L5.04333 7.75098" stroke="#808490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.7533 5.10591C10.7533 5.10591 8.59581 7.05257 7.48915 8.05174C7.15831 8.35007 7.18581 8.87507 7.54415 9.13924L12.0258 12.4501C12.4666 12.7759 13.0966 12.5367 13.2108 12.0001L15.4116 1.62757C15.5183 1.12591 15.0258 0.706739 14.5475 0.890905L1.61831 5.87757C1.23748 6.02424 1.25581 6.56841 1.64498 6.69007L5.04248 7.75007" stroke="#808490" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>
