<template>
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L4 4L7 1" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
}
</script>
