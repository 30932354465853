<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.10669 12L8.85736 5.4653C9.23869 4.04264 10.7014 3.19864 12.1227 3.57997L25.5654 7.18264C26.988 7.56397 27.832 9.02664 27.4507 10.448L27.0374 12" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.96265 20L4.54665 21.552C4.16532 22.9747 5.00932 24.4373 6.43199 24.8173L19.8747 28.42C21.2973 28.8013 22.76 27.9573 23.14 26.5347L24.8933 20" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.66666 16H5.33332" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6667 16H13.3333" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.6667 16H21.3333" stroke="#010820" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.6667 16H29.3333" stroke="#010820" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
