<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.9159 2.49707V3.9977C7.89509 4.25024 8.08059 4.47283 8.33274 4.4979H11.6675C11.9196 4.47283 12.1051 4.25024 12.0843 3.9977V2.49707" stroke="#B0B5C2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.33276 15.0028H11.6675" stroke="#B0B5C2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.3349 17.5033H6.66541C5.74455 17.5033 4.99805 16.7568 4.99805 15.836V4.16443C4.99805 3.24357 5.74455 2.49707 6.66541 2.49707H13.3349C14.2557 2.49707 15.0022 3.24357 15.0022 4.16443V15.836C15.0022 16.7568 14.2557 17.5033 13.3349 17.5033Z" stroke="#B0B5C2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
export default {
  name: 'MobileVersion'
}
</script>

<style scoped>

</style>
