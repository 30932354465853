<template>
  <v-dialog
    :disabled="disabled || withoutModal"
    transition="dialog-bottom-transition"
    max-width="370"
    content-class="rounded-xl"
    overlay-color="rgba(9, 24, 73, 0.2)"
    overlay-opacity="1"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="d-flex align-center pointer"
        v-bind="attrs"
        v-on="on"
        @click="withoutModal ? commitIt() : ''"
      >
        <slot></slot>
      </div>
    </template>
    <template v-slot:default="dialog">
      <v-card class="pa-7">
        <v-card-text class="py-0">
          <div class="fz-18 text-center text-black">
            Do you really want to {{ deleting ? 'delete' : 'commit' }}
            <slot name="message"/>
            ?
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between pa-0">
          <v-btn
            class="rounded-lg text-none fz-16 fw-600"
            outlined
            width="150"
            height="40"
            @click="dialog.value = false"
          >
            Return
          </v-btn>
          <v-btn
            elevation="0"
            :color="deleting ? '#FF0000' : 'primary'"
            width="150"
            height="40"
            class="rounded-lg fz-16 fw-600 text-none"
            :class="deleting ? 'white--text' : ''"
            @click="commitIt(); dialog.value = false"
          >
            <v-icon v-if="deleting" left class="stroke-white">
              $delete
            </v-icon>
            {{ deleting ? 'Delete' : 'Commit' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'RemoveDialog',
  props: {
    withoutModal: {
      type: Boolean,
      default: false
    },
    deleting: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    commitIt() {
      this.$emit('commit')
    }
  }
}
</script>

<style scoped>

</style>
