<template>
  <v-snackbars
    :messages.sync="localMessages"
    color="red"
    timeout="2500"
    multi-line
    max-width="350"
    left
    rounded="lg"
    light
    elevation="4"
    content-class="snackbar"
  >
    <template v-slot:default="{ message }">
      <strong>{{ message.title }}</strong>
      <p>{{ message.text }}</p>
      <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
        <v-btn
          class="rounded-lg fz-12 fw-600 text-none border-none"
          outlined
          height="26"
          elevation="0"
          @click="doCopy('Error', message.text)"
        >
          Copy
        </v-btn>
      </v-row>
    </template>
  </v-snackbars>
</template>

<script>
import VSnackbars from 'v-snackbars'

export default {
  name: 'Snackbar',
  components: {
    'v-snackbars': VSnackbars
  },
  props: {
    messages: {
      type: Array
    }
  },
  data() {
    return {
      localMessages: []
    }
  },
  methods: {
    addSnack(result, text) {
      this.localMessages.push({ title: result, text: text })
    },
    doCopy(action, value) {
      navigator.clipboard.writeText(value)
      this.addSnack('Text copied', 'Text was copied')
    }
  },
  watch: {
    messages: {
      handler() {
        this.addSnack(this.messages[0], this.messages[1])
      }
    }
  }
}
</script>

<style scoped>

</style>
