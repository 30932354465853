<template>
  <v-dialog
    v-model="dialog"
    width="980px"
    content-class="rounded-xl hidden dialog-flex elevation-0"
    overlay-color="rgba(9, 24, 73, 0.2)"
    overlay-opacity="1"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        v-on="on"
        label
        :style="designerColor(designer.statusLabel)"
        text-color="white"
        x-small
        class="px-2"
        style="line-height: 12px; height: 28px;"
      >
        {{ designer.statusLabel || 'unknown' }}
        <br>
        <template v-if="designer.fired">
          {{ formatDate(designer.firedDate) }}
        </template>
        <template v-else>
          {{ designer.dateEnd ? formatDate(designer.dateEnd) : '' }}
        </template>
      </v-chip>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title class="pt-7 px-5">
          <span class="fz-20 text-black fw-600">List of Active Items</span>
          <v-spacer></v-spacer>
          <v-icon class="close-icon my-auto" @click="dialog.value = false">$close</v-icon>
        </v-card-title>
        <v-divider class="black bb-1 mb-2"/>
        <v-card-text class="scroll" style="height: 500px">
          <v-data-table
            :headers="tableHeader"
            :items="listActiveItems"
            group-by="order.id"
            :loading="tableLoading"
            :items-per-page="-1"
            @click:row="$refs.editOrder.getOrder($event.order.id)"
          >
            <template v-slot:group.header="{ items }">
              <td class="font-weight-medium">
                {{ items[0].order.id }}
              </td>
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
              <td/>
            </template>
            <template v-slot:item.nameItem="{ item }">
              <div>{{ item.name }}</div>
            </template>
            <template v-slot:item.name="{ item }">
              <div style="max-width: 130px">{{ item.order.name }}</div>
            </template>
            <template v-slot:item.status="{ item }">
              <v-row no-gutters class="flex-nowrap" align="center">
                <span
                  style="width: 8px; height: 8px; margin-right: 6px;"
                  class="rounded-circle"
                  :style="statusColor(item.order.status.value)"
                />
                <span
                  style="min-width: 120px"
                  class="fz-14 text-black"
                >
                    {{ item.order.status.label }}
                  </span>
              </v-row>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span class="one-line">{{ formatDate(item.order.createdAt) }}</span>
            </template>
            <template v-slot:item.dateStart="{ item }">
              <span class="one-line">{{ item.order.dateStart ? formatDate(item.order.dateStart) : '' }}</span>
            </template>
            <template v-slot:item.updatedAt="{ item }">
              <span class="one-line">{{ formatDate(item.order.updatedAt) }}</span>
            </template>
            <template v-slot:item.dateEnd="{ item }">
              <v-col class="pa-0">
                <v-row class="ma-0">
                  <span class="one-line"
                        :class="{'color-primary': item.order.dateEndPrediction && item.order.dateEnd !== item.order.dateEndPrediction, 'fw-600': item.order.dateEndPrediction && item.order.dateEnd !== item.order.dateEndPrediction}">
                    {{ item.order.dateEnd ? formatDate(item.order.dateEnd) : '' }}
                  </span>
                </v-row>
                <v-row v-if="item.order.dateEndPrediction && item.order.dateEnd !== item.order.dateEndPrediction"
                       class="ma-0">
                  <span class="one-line fz-10 mt-n1">
                    {{ formatDate(item.order.dateEndPrediction) }}
                  </span>
                </v-row>
              </v-col>
            </template>
          </v-data-table>
        </v-card-text>
        <EditOrder
          ref="editOrder"
        />
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { designerColor, statusColor } from '@/helpers/staticColors'
import { Locale } from 'v-calendar'
import gql from 'graphql-tag'

const locale = new Locale()

export default {
  name: 'DesignerStatus',
  components: {
    EditOrder: () => import('@/components/App/Orders/EditOrder')
  },
  props: {
    designer: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      listActiveItems: [],
      tableLoading: false,
      tableHeader: [
        {
          text: 'Number',
          value: 'number',
          filterable: false,
          sortable: false,
          class: 'max-width-10p',
          cellClass: 'pointer'
        },
        {
          text: 'Project',
          value: 'name',
          filterable: false,
          sortable: false,
          cellClass: 'pointer'
        },
        {
          text: 'Status',
          value: 'status',
          filterable: true,
          sortable: false,
          class: 'px-0',
          cellClass: 'pointer'
        },
        {
          text: 'Item\'s name',
          value: 'nameItem',
          filterable: false,
          sortable: false,
          class: 'max-width-10p',
          cellClass: 'pointer'
        },
        {
          text: 'Date of creation',
          value: 'createdAt',
          filterable: false,
          sortable: false,
          class: 'min-width-94',
          cellClass: 'pointer'
        },
        {
          text: 'Start date',
          value: 'dateStart',
          filterable: false,
          sortable: false,
          class: 'min-width-94',
          cellClass: 'pointer'
        },
        {
          text: 'Update date',
          value: 'updatedAt',
          filterable: false,
          sortable: false,
          class: 'min-width-94',
          cellClass: 'pointer'
        },
        {
          text: 'Expiration date',
          value: 'dateEnd',
          filterable: false,
          sortable: false,
          class: 'min-width-94',
          cellClass: 'pointer'
        }
      ]
    }
  },
  methods: {
    designerColor(value) {
      return designerColor(value)
    },
    statusColor(value) {
      return statusColor(value)
    },
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    async getActiveItems() {
      this.tableLoading = true
      this.$apollo.query({
        query: gql`query getActiveItemsForDesigner($id: ID!) {
          team {
            designerItems(id: $id) {
              activeProductPlans {
                id
                name
                status
                order {
                  name
                  id
                  createdAt
                  dateStart
                  updatedAt
                  dateEnd
                  dateEndPrediction
                  expirationDate
                  status {
                    id
                    label
                    value
                  }
                }
              }
              activeServices {
                id
                name
                order {
                  id
                  name
                  createdAt
                  dateStart
                  updatedAt
                  dateEnd
                  dateEndPrediction
                  expirationDate
                  status {
                    id
                    label
                    value
                  }
                }
                status
              }
            }
          }
        }`,
        variables: {
          id: this.designer.id
        },
        fetchPolicy: 'no-cache'
      }).then(data => {
        this.listActiveItems = [...data.data.team.designerItems.activeProductPlans, ...data.data.team.designerItems.activeServices]
        this.tableLoading = false
      }).catch((err) => {
        this.tableLoading = false
        console.log(err)
      })
    }
  },
  watch: {
    dialog: {
      handler(newValue) {
        if (newValue) this.getActiveItems()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
